import React, { useEffect, useState, Component, useRef } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { FaEdit, FaEye } from "react-icons/fa";
import ApiService from '../../services/apiService';
import Button from 'react-bootstrap/Button';
import { TbBallpenOff } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import { MdOutlineEditOff } from "react-icons/md";

const BankGrid = ({ fetchRoleData, rowData, canEditMaster }) => {

    useEffect(() => {
        fetchRoleData();
    }, []);
    const [selectedRowData, setSelectedRowData] = useState(null);
    let navigate = useNavigate();
    const gridRef = useRef();
    const handleEdit = (data) => {
        navigate("/inventory/Master/Bank", { state: data })
        setSelectedRowData(data);
    };
    const [ColDefs, setColDefs] = useState([
        { headerName: "Bank Code", field: "descriptionBI", filter: true, floatingFilter: true, minWidth: 320 },
        { headerName: "Bank Name", field: "description", filter: true, floatingFilter: true, minWidth: 320 },
        { headerName: "Country", field: "country", filter: true, floatingFilter: true, minWidth: 320 },
        { headerName: "City", field: "city", filter: true, floatingFilter: true, minWidth: 320 },
        {
            headerName: "Action", field: "recordId", filter: true, floatingFilter: true, minWidth: 320,
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                canEditMaster: canEditMaster,
                handleEdit: handleEdit,
                clicked: function (field, isActive) {
                    updateActiveStatus(field, isActive);
                },
            },
        },
    ])
    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };
    const updateActiveStatus = async (id, isActive) => {
        try {
            const apiService = new ApiService();
            let body = {
                recordId: id,
                isActive: isActive
            }
            const res = await apiService.put(`UPDATE_BANK`, body);
            if (res?.data?.statusCode === 201) {
                console.log('Updated successfully');
                fetchRoleData();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };

    return (
        <div >
            <div className='brdor'>
                <div className="ag-theme-quartz" style={{ height: 500 }}>
                    <AgGridReact
                        rowData={rowData}
                        ref={gridRef}
                        enableFilter={true}
                        floatingFilter={true}
                        columnDefs={ColDefs}
                        suppressExcelExport={true}
                        suppressRowClickSelection={true}
                        suppressRowVirtualisation={true}
                    />
                </div>
            </div>
        </div>
    )
}
class BtnCellRenderer extends Component {

    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this); // Bind edit button handler
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data); // Pass rowData to handleEdit function
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value, !this.props.data.isActive); // Toggle isActive value
    }

    render() {
        const { isActive } = this.props.data; // Extract isActive from rowData
        const { canEditMaster } = this.props;
        return (
            <div className='d-flex gap-3'>
                {canEditMaster === true ?
                    <div onClick={this.btnEditClickedHandler}><FaEdit size={25} /></div> :
                    <div><MdOutlineEditOff size={25} /></div>
                }
                {canEditMaster === true ?
                    <div className="form-check form-switch center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive}
                            onClick={this.btnClickedHandler}
                        />
                    </div>
                    :
                    <div className="form-check form-switch center">
                        <input disabled
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive}
                        />
                    </div>
                }
            </div>
        )
    }
}
export default BankGrid;