import ApiService from "../../POS/services/apiService";
import { CURRENCY } from "../selecters";
const initialState = {
  currency: {
    name: "",
    price: 1
  }
};

    const apiService = new ApiService();
    const res = await apiService.get('GET_CURRENCY');
    const filteredDenominations = res?.data?.result?.find(currency => currency?.default === true);
    initialState.currency.name = filteredDenominations?.code





const CurrencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case CURRENCY:
      return {
        ...state,
        currency: action.payload,
      };
    default:
      return state;
  }
};

export default CurrencyReducer;