import React, { useEffect, useState } from 'react';
import userManagement from '../../../../services/userManagement';
import ApiService from '../../../../services/apiService';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { ClipLoader } from "react-spinners";
import TableGrid from './grid';
import background from '../../../../assets/images/Logos/RestaurantSeatingPlan.png'
import "./tablemaster.css"
import FloorPlan from './arrangment/floor';
const TableMaster = () => {

    const [tables, setTable] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [area, setArea] = useState([]);

    useEffect(() => {
        GetTable();
        GetArea()
    }, [searchTerm]);
    useEffect(() => {
        GetArea()
    }, []);
    const TableCapacity = [
        { value: '1', label: 'Single Seater' },
        { value: '2', label: 'Double Seater' },
        { value: '3', label: 'Triple Seater' },
        { value: '4', label: 'Four Seater' },
        { value: '5', label: 'Five Seater' },
        { value: '6', label: 'Six Seater' },
        { value: '7', label: 'Seven Seater' },
        { value: '8', label: 'Eight Seater' },
        { value: '9', label: 'Nine Seater' },
        { value: '10', label: 'Ten Seater' },
        { value: '11', label: 'Eleven Seater' },
        { value: '12', label: 'Twelve Seater' },
        { value: '13', label: 'Custom Seater' },
    ];

    const TableShapes = [
        { value: '1', label: 'Rectangle ' },
        { value: '2', label: 'Circle ' },
        { value: '3', label: 'Square ' },
        { value: '4', label: 'Oval ' }
    ];

    const [formData, setFormData] = useState({
        name: "",
        seatCapacity: "",
        tablePosition: "",
        description: "",
        area: area?.[0],
        tableShape: "",
        topPosition: 221,
        leftPosition: 716,
    });
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const CreateTable = async () => {
        const UserId = userManagement.getUserId();
        setLoading(true)
        try {
            if (!formData?.name) toast.error("Please Enter Name")
            else if (!formData?.seatCapacity) toast.error("Please Enter Seat Capacity")
            else if (!formData?.area) toast.error("Please Enter Area")
            else {
                let body = {
                    loggedInUserId: UserId,
                    name: formData?.name,
                    seatCapacity: parseInt(formData?.seatCapacity),
                    position: parseInt(formData?.tablePosition),
                    area: parseInt(formData?.area?.id),
                    shape: parseInt(formData?.tableShape),
                    topPosition: formData?.topPosition,
                    leftPosition: formData?.leftPosition,
                    status: 1
                }

                const apiService = new ApiService();
                let res = await apiService.post('CREATE_TABLE', body);

                if (res?.data?.statusCode == 201) {
                    toast?.success("Table Created Successfully")
                    setLoading(false)
                    setFormData({
                        name: " ",
                        seatCapacity: " ",
                        tablePosition: " ",
                        area: " ",
                        description: " ",
                        shape: " "
                    });
                    window.location.reload(false);
                    GetTable();
                } else if (res?.data?.statusCode === 208) {
                    toast.warning("Table Already Exist")
                }
                setLoading(false)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error('Error fetching Tax data:', error);
        }
    };

    const handleSeatChange = (selectedOption) => {
        setFormData({ ...formData, seatCapacity: selectedOption?.value });
    }
    const GetTable = async () => {
        try {
            const apiService = new ApiService();
            let parameters = `?Name=${searchTerm}`;
            const res = await apiService.getApiParamater('GET_TABLE', parameters);
            setTable(res?.data?.result);
        } catch (error) {
            console.error('Error fetching category data:', error);
        }
    };
    const handleTableChange = (selectedOption) => {
        setFormData({ ...formData, tablePosition: selectedOption?.value });
    }
    const handleAreaChange = (selectedOption) => {
        setFormData({ ...formData, area: selectedOption?.value });
    }
    const handleShapChange = (selectedOption) => {
        setFormData({ ...formData, tableShape: selectedOption?.value });
    }
    const GetArea = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get('GET_RESTAURANT_AREA');
            let filteredArea = res?.data?.result?.filter(area => area.active == true);
            setArea(filteredArea);
        } catch (error) {
            console.error('Error fetching area data:', error);
        }
    };
    const firstFloor = tables?.filter(table => table?.area === 1);
    const secondFloor = tables?.filter(table => table?.area === 2);
    const thirdFloor = tables?.filter(table => table?.area === 3);
    const forthFloor = tables?.filter(table => table?.area === 4);
    const fifth = tables?.filter(table => table?.area === 5);
    const sixth = tables?.filter(table => table?.area === 6);
    const seventh = tables?.filter(table => table?.area === 7);
    const eighth = tables?.filter(table => table?.area === 8);
    const ninth = tables?.filter(table => table?.area === 9);
    const tenthFloor = tables?.filter(table => table?.area === 10);
    const eleventhFloor = tables?.filter(table => table?.area === 11);
    const twelfthFloor = tables?.filter(table => table?.area === 12);
    const groundFloor = tables?.filter(table => table?.area === 13);
    const patio = tables?.filter(table => table?.area === 14);
    const terrace = tables?.filter(table => table?.area === 15);
    const privateRoom = tables?.filter(table => table?.area === 16);

    return (
        <div className='p-5 container'>
            <div className='catlist'>
                <div className='row'>
                    <div className='col-lg-4'>
                        <label htmlFor="validationCustom01" className="form-label">
                            Table Name   *
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <label htmlFor="validationCustom02" className="form-label">
                            Seat Capacity   *
                        </label>
                        <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                            className=''
                            onChange={handleSeatChange}
                            options={TableCapacity}
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <label htmlFor="validationCustom05" className="form-label">
                            Select Area   *
                        </label>
                        <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                            defaultValue={area?.[0]}
                            onChange={handleAreaChange}
                            options={area?.map((area) => ({ value: area, label: area.name }))}
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                        <label className="form-label">
                            ( Exp: 1st floor , ground  floor )
                        </label>
                    </div>
                </div>
                <div className='row pt-3'>
                    <div className='col-lg-4'>
                        <label htmlFor="validationCustom04" className="form-label">
                            Description
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="validationCustom04"
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <label htmlFor="validationCustom05" className="form-label">
                            Select Table Shapes   *
                        </label>
                        <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                            className=''
                            onChange={handleShapChange}
                            options={TableShapes}
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                        <label className="form-label">
                            ( Exp: Rectangle, Circle, Square  )
                        </label>
                    </div>
                </div>
                <div className="pt-5 end">
                    {loading ? (
                        <div className="addItem-btn center">
                            <ClipLoader loading={loading} size={30} color="#fff" />
                        </div>
                    ) : (
                        <button className='addItem-btn' onClick={() => CreateTable()}>Save</button>
                    )}
                </div>
            </div>
            <div className='pt-5'>
                <div className="col-lg-4 pb-3">
                    <label htmlFor="Search" className="form-label">
                        Table Search by name
                    </label>
                    <input
                        type='Search'
                        className='form-control'
                        id='validationCustom01'
                        placeholder='Search'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className='valid-feedback'>Looks good!</div>
                </div>
                <div>
                    {
                        formData?.area?.id > 0 ?
                            <>

                                {!formData?.area?.id ?
                                    <FloorPlan tablesData={firstFloor} area={formData?.area} GetTable={GetTable} />
                                    : ""
                                }
                                {formData?.area?.id === 1 ?
                                    <FloorPlan tablesData={firstFloor} area={formData?.area} GetTable={GetTable} />
                                    : ""
                                }
                                {formData?.area?.id === 2 ?
                                    <FloorPlan tablesData={secondFloor} area={formData?.area} GetTable={GetTable} />
                                    : ""
                                }
                                {formData?.area?.id === 3 ?
                                    <FloorPlan tablesData={thirdFloor} area={formData?.area} GetTable={GetTable} />
                                    : ""
                                }
                                {formData?.area?.id === 4 ?
                                    <FloorPlan tablesData={forthFloor} area={formData?.area} GetTable={GetTable} />
                                    : ""
                                }
                                {formData?.area?.id === 5 ?
                                    <FloorPlan tablesData={fifth} area={formData?.area} GetTable={GetTable} />
                                    : ""
                                }
                                {formData?.area?.id === 6 ?
                                    <FloorPlan tablesData={sixth} area={formData?.area} GetTable={GetTable} />
                                    : ""
                                }
                                {formData?.area?.id === 7 ?
                                    <FloorPlan tablesData={seventh} area={formData?.area} GetTable={GetTable} />
                                    : ""
                                }
                                {formData?.area?.id === 8 ?
                                    <FloorPlan tablesData={eighth} area={formData?.area} GetTable={GetTable} /> : ""
                                }
                                {formData?.area?.id === 9 ?
                                    <FloorPlan tablesData={ninth} area={formData?.area} GetTable={GetTable} /> : ""
                                }
                                {formData?.area?.id === 10 ?
                                    <FloorPlan tablesData={tenthFloor} area={formData?.area} GetTable={GetTable} /> : ""
                                }
                                {formData?.area?.id === 11 ?
                                    <FloorPlan tablesData={eleventhFloor} area={formData?.area} GetTable={GetTable} /> : ""
                                }
                                {formData?.area?.id === 12 ?
                                    <FloorPlan tablesData={twelfthFloor} area={formData?.area} GetTable={GetTable} /> : ""
                                }
                                {formData?.area?.id === 13 ?
                                    <FloorPlan tablesData={groundFloor} area={formData?.area} GetTable={GetTable} /> : ""
                                }
                                {formData?.area?.id === 14 ?
                                    <FloorPlan tablesData={patio} area={formData?.area} GetTable={GetTable} /> : ""
                                }
                                {formData?.area?.id === 15 ?
                                    <FloorPlan tablesData={terrace} area={formData?.area} GetTable={GetTable} /> : ""
                                }
                                {formData?.area?.id === 16 ?
                                    <FloorPlan tablesData={privateRoom} area={formData?.area} GetTable={GetTable} /> : ""
                                }
                            </>
                            :
                            <div className='center mt-5 '>
                                <h4 className='mt-5'>
                                    Select the area please.....</h4>
                            </div>
                    }
                </div>
            </div>
        </div >
    );
};

export default TableMaster;
