import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import Plus from "./../../Assets/images/plusIcon.png"
import { useNavigate } from 'react-router-dom';
import { FiPrinter } from "react-icons/fi";
import ApiService from '../../services/apiService';

const MessageList = () => {
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        getItems();
    }, []);

    const getItems = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService.get('GET_PR');
            setRowData(res?.data?.result || []);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    }

    const Comparison = [
        { headerName: 'Sr No', field: 'requisitionNo', filter: true, floatingFilter: true, minWidth: 310 },
        { headerName: 'Message From', field: 'location', filter: true, floatingFilter: true, minWidth: 310 },
        { headerName: 'Message', field: 'location', filter: true, floatingFilter: true, minWidth: 450 },
        { headerName: 'Message To', field: 'costCenter1', filter: true, floatingFilter: true, minWidth: 210 },
        { headerName: 'Date', field: 'createdDate', filter: true, floatingFilter: true, minWidth: 110 },
    ];

    return (
        <div className='px-5'>
            <div className='brderbox'>
                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        rowSelection={'single'}
                        columnDefs={Comparison}
                        pagination={false}
                        suppressExcelExport={true}
                        domLayout='autoHeight'
                        suppressRowClickSelection={true}
                    />
                </div>
            </div>
        </div>
    );
};
export default MessageList; 