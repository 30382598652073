import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useState, Component } from 'react';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ApiService from '../../../../services/apiService';
import { CounterType } from '../../../../../Enums/config';

// Create a lookup object for type names
const typeLookup = CounterType.reduce((acc, item) => {
    acc[item.type] = item.label;
    return acc;
}, {});

const CounterGrid = ({ Counter, GetCounter }) => {
    const [show, setShow] = useState(false);
    const [id, setId] = useState();

    const handleClose = () => setShow(false);
    const handleShow = (field) => {
        setId(field);
        setShow(true);
    }

    const handleDelete = async (id) => {
        const apiService = new ApiService();
        let body = {
            id: parseInt(id)
        }
        let res = await apiService.delete("DELETE_COUNTER", { data: body });

        if (res?.data?.statusCode === 204 || res?.data?.statusCode === 200 || res?.data?.statusCode === 201) {
            // Show success toast
            setShow(false);
            GetCounter();
            toast.success(res?.data?.message);
        } else {
            toast.warning('Waiting for menu update');
        }
    };

    const [colDefs, setColDefs] = useState([
        { field: "name", filter: true, floatingFilter: true, minWidth: 250 },
        {
            field: "type",
            filter: true,
            floatingFilter: true,
            minWidth: 300,
            valueFormatter: (params) => typeLookup[params.value] || params.value, // Use value formatter
        },
        { field: "active", filter: true, floatingFilter: true, minWidth: 290 },
        { field: "ipAddress", filter: true, floatingFilter: true, minWidth: 200 },

        {
            headerName: 'Action',
            field: 'id', minWidth: 200,
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                clicked: function (field) {
                    handleShow(field);
                },
            },
        },
    ]);

    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };

    return (
        <>
        <div className='brdor  mt-3' style={{ }}>

            <div className="ag-theme-alpine" style={{ height: 550, backgroundColor: "#6E5199" }}>
                <AgGridReact
                    rowData={Counter}
                    columnDefs={colDefs}
                    enableFilter={true}
                    floatingFilter={true}
                    rowSelection="multiple"
                    frameworkComponents={frameworkComponents}
                    pagination={true}
                    suppressRowClickSelection={true}
                />
            </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Counter</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => handleDelete(id)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }

    render() {
        return (
            <button onClick={this.btnClickedHandler} className="delbtn">Delete</button>
        )
    }
}

export default CounterGrid;