import { React, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ImageUpload from '../menus/addItems/imagUpload';
import ApiService from '../../../services/apiService';
import { toast } from 'react-toastify';
import userManagement from '../../../services/userManagement';

const CreateRestaurant = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const { restaurant: restaurantData, isEditMode } = location.state || { restaurant: null, isEditMode: false };
    debugger
    const initialFormData = {
        name: "",
        address: "",
        branches: "",
        employees: "",
        images: [],
        imageUrl: ""
    };

    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        
        if (isEditMode && restaurantData) {
            
            setFormData({
                name: restaurantData.name,
                address: restaurantData.address,
                branches: restaurantData.branches || "",  // Assuming branches data might not be available
                employees: restaurantData.noOfEmployees,
                images: [], // Assume images are not provided directly; they would be handled separately if needed
                imageUrl: restaurantData.imageUrl
            });
        }
    }, [isEditMode, restaurantData]);

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleImagesSelect = (images) => {
        setFormData({ ...formData, images: images });
    };

    const handleImageRemove = (index) => {
        const updatedImages = [...formData.images];
        updatedImages.splice(index, 1);
        setFormData({ ...formData, images: updatedImages });
    };

    const handleSubmit = async () => {
        if (isEditMode) {
            await updateRestaurant();
        } else {
            await createRestaurant();
        }
    };

    const createRestaurant = async () => {
        try {
            if (!formData?.images.length && !formData?.imageUrl) {
                toast.error("Upload Image");
                return;
            } else if (formData?.images.length > 0) {
                const imageUrl = await uploadImages(formData.images);
                if (!imageUrl) {
                    toast.error("Image upload failed");
                    return;
                }
                await createRestaurantWithImage(imageUrl);
            } else {
                await createRestaurantWithImage(formData.imageUrl);
            }
        } catch (error) {
            console.error('Error creating restaurant:', error);
        }
    };

    const uploadImages = async (images) => {
        try {
            const apiService = new ApiService();
            const formdata = new FormData();
            for (const image of images) {
                if (image.file) {
                    formdata.append('Images', image?.file);
                }
            }
            const response = await apiService.postMultiPart("UPLOAD_IMG", formdata);
            if (response?.data?.statusCode === 201) {
                return response?.data?.result;
            }
        } catch (error) {
            console.error('Error uploading images:', error);
            toast.error("Something went wrong with the image. Please try again!");
        }
        return null;
    };

    const createRestaurantWithImage = async (imageUrl) => {
        try {
            const UserId = userManagement.getUserId();
            const body = {
                name: formData.name,
                imageUrl: imageUrl,
                address: formData.address,
                noOfEmployees: formData.employees,
                loggedInUserId: UserId,
                isActive: true
            };
            const apiService = new ApiService();
            let res = await apiService.post('CREATE_RESTAURANT', body);
            if (res?.data?.statusCode === 201) {
                toast.success("Restaurant Created Successfully");
                setFormData(initialFormData);
                navigate("/admin/Restaurant");
            }
        } catch (error) {
            console.error('Error creating restaurant:', error);
        }
    };

    const updateRestaurant = async () => {
        try {
            const apiService = new ApiService();
            const UserId = userManagement.getUserId();

            // Check if new images are selected for upload
            let imageUrl = formData.imageUrl;
            if (formData.images.length > 0) {
                imageUrl = await uploadImages(formData.images);
                if (!imageUrl) {
                    toast.error("Image upload failed");
                    return;
                }
            }

            const body = {
                id: restaurantData.id,
                name: formData.name,
                imageUrl: imageUrl,
                address: formData.address,
                noOfEmployees: formData.employees,
                loggedInUserId: UserId,
                isActive: restaurantData.isActive
            };

            let res = await apiService.put("UPDATE_RESTAURANT", body);
            if (res?.data?.statusCode === 200) {
                toast.success("Restaurant Updated Successfully");
                navigate("/admin/Restaurant");
            } else {
                toast.error('Failed to update restaurant');
            }
        } catch (error) {
            console.error('Error updating restaurant:', error);
            toast.error('An error occurred while updating the restaurant.');
        }
    };

    const handleDND = () => {
        navigate('/admin/ordersDND');
    };

    return (
        <div className="container">
            <div className='p-5'>
                <ImageUpload onImagesSelect={handleImagesSelect} onImageRemove={handleImageRemove} formData={formData} />
            </div>
            <div className='px-5'>
                <div className="form-group row justify-content-between">
                    <div className="col-md-5 col-lg-5">
                        <label htmlFor="validationCustom01" className="form-label">Restaurant Name</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom01"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div className="col-md-5">
                        <label htmlFor="validationCustom02" className="form-label">Address</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom02"
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                </div>
                <div className="form-group row justify-content-between py-3">
                    <div className="col-md-5 col-lg-5">
                        <label htmlFor="validationCustom03" className="form-label">Employees</label>
                        <input
                            type="number"
                            className="p-2 form-control"
                            id="validationCustom03"
                            name="employees"
                            value={formData.employees}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                </div>
                <div className='row mt-5 mb-5'>
                    <div className='end'>
                        <button className='addItem-btn' type="submit" onClick={handleSubmit}>
                            {isEditMode ? "Update" : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateRestaurant;
