export function hasPermission(moduleName, action, permissions) {
    const module = permissions.find(
        (perm) => perm.description.toLowerCase() === moduleName.toLowerCase()
    );

    if (!module) {
        console.warn(`Module ${moduleName} not found.`);
        return false;
    }

    const actionCommandMap = {
        create: 'newCommand',
        edit: 'editCommand',
        delete: 'deleteCommand',
        save: 'saveCommand',
        query: 'queryCommand',
        runQuery: 'runQueryCommand',
        print: 'printCommand',
        export: 'exportCommand'
    };

    const commandKey = actionCommandMap[action.toLowerCase()];

    if (!commandKey) {
        console.warn(`Action ${action} is not recognized.`);
        return false;
    }

    return !!module[commandKey];
}
