import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import { ClipLoader } from "react-spinners";
import "./report.css"
import { FaCalendarAlt } from "react-icons/fa";
// import GridReport from './Grid/grid';

const OrderHistory = ({ Currency }) => {
    let navigate = useNavigate();
    const location = useLocation();
    const [Currencies, setCurrency] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('4');
    useEffect(() => {
    }, []);
    const handleFilterChange = async (selectedFilter) => {
        setSelectedFilter(selectedFilter?.value);
        console.log('Selected Option:', selectedFilter);
    };
    const ThirdFilter = [
        { value: '0', label: 'Day' },
        { value: '1', label: 'Week' },
        { value: '2', label: 'Month' },
        { value: '3', label: 'Year' },
        { value: '4', label: 'All Time' },
    ];
    const handlechart = () => {
        navigate('/admin/Reports/home')
    }
    return (
        <div className='p-lg-5'>
            <div className='px-lg-5'>
                <div className='d-flex justify-content-between' >
                    <div>
                        <input className='form-control' type='search' placeholder='Search' />
                    </div>
                    <div className='d-flex mbl-center gap-3'>
                        <div className='d-flex'>
                            <nav className="nav nav-pills weekfilter ">
                                {ThirdFilter?.map((filter) => (
                                    <button
                                        key={filter.value}
                                        className={`nav-link ${selectedFilter === filter?.value ? 'activecls' : ''}`}
                                        onClick={() => handleFilterChange(filter)}
                                    >
                                        {filter.label}
                                    </button>
                                ))}
                            </nav>
                        </div>
                        <div className='custom center gap-2'>Custom <FaCalendarAlt /></div>
                    </div>
                </div>
                <div className='pt-5'>
                    {/* <GridReport /> */}
                </div>
            </div>
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(OrderHistory);