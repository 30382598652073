import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import Select from 'react-select';
import "./reports.css"
import { RiSkipLeftFill, RiSkipRightFill } from "react-icons/ri";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";
import { SlRefresh } from "react-icons/sl";
import { HiOutlinePrinter } from "react-icons/hi2";
import { PiDownloadSimple } from "react-icons/pi";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import { BsFileEarmarkPdf, BsFiletypeCsv } from "react-icons/bs";
import { LiaFileExcel } from "react-icons/lia";
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';

const InvoiceReport = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [PoList, setPOlist] = useState();
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [toDate, setToDate] = useState();
    const [fromDate, setFromDate] = useState();
    const [dateFilter, setdateFilter] = useState(false);
    const [SupplierList, setSuppliers] = useState();
    const gridRef = useRef();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [emailModal, setEmailModal] = useState(false);
    const onOpenModal = () => setEmailModal(true);
    const onCloseModal = () => setEmailModal(false);
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const [formData, setFormData] = useState({
        email: '',
        InvoiceNo: "",
        costcenter3: "",
        SupplierName: [{ label: 'Select Supplier', value: '' }],
        supplierId: [{ label: 'Select Supplier Id', value: '' }],
        Status: [{ label: 'Filter By', value: '' }],
        PONo: [{ label: 'Select PO', value: 'Select PO' }],
    });
    useEffect(() => {
        if (formData || pageSize) {
            getItems();
        }
        if (dateFilter === true) {
            getItems();
        } else {
            return
        }
    }, [pageSize, formData, dateFilter, toDate, fromDate]);
    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const apiService = new ApiService();
                let param = `?isActive=true`
                const requsition = await apiService.getApiParamater("GET_PO", param);
                const suppliers = await apiService.get('GET_SUPPLIER');
                const activeSupplier = suppliers?.data?.result.filter(res => res.exp4 === "1");
                setSuppliers(activeSupplier);
                setPOlist(requsition?.data.result)
            } catch (error) {
                console.error('Error fetching dropdown data:', error);
            }
        };
        fetchDropdownData();
        getItems()
    }, []);
    const getItems = async (pageChange) => {
        try {

            const apiService = new ApiService();
            const params = new URLSearchParams({
                VendorName: formData?.SupplierName?.label || "",
                Status: formData.Status.value || "",
                FromDate: formData.fromDate || "",
                ToDate: formData.toDate || "",
                POrderNo: formData.PONo.value?.pOrderNo || "",
                InvoiceRegNo: formData.InvoiceNo || "",
                CostCenter3: formData.costcenter3 || "",
            });

            let newPageNo = pagination?.currentPage;

            if (pageChange === "next") {
                newPageNo += 1;
            } else if (pageChange === "prev") {
                newPageNo -= 1;
            } else if (pageChange === "skipNext") {
                newPageNo += 10;
            } else if (pageChange === "skipPrev") {
                newPageNo -= 10;
            }
            if (dateFilter === true) {
                params.append('FromDate', fromDate || "");
                params.append('ToDate', toDate || "");
            }
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);

            const res = await apiService.getApiParamater('GET_INVOICE_REPORT', `?${params.toString()}`);
            setRowData(res?.data?.result?.reverse() || []);
            setPagination(res.data.paginationData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const StatusFilter = [
        { value: '0', label: 'Pending' },
        { value: '1', label: 'Aproved' },
        { value: '2', label: 'Hold' },
        { value: '3', label: 'Rejected' },
    ]
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const dateFormatter = (params) => {
        const date = moment.utc(params.value).local();
        return date.format("DD-MM-YYYY");
    };
    const Comparison = [
        { headerName: 'Invoice Reg No', field: 'rcNo', filter: true, floatingFilter: true, minWidth: 110 },
        { headerName: 'Invoice No ', field: 'invoiceNo', filter: true, floatingFilter: true, minWidth: 110 },
        {
            headerName: 'Invoice Date', field: 'invoiceDate', filter: true, floatingFilter: true, minWidth: 110,

            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Invoice Ref', field: 'invoiceRef', filter: true, floatingFilter: true, minWidth: 110 },
        { headerName: 'Invoice Type', field: 'stockType', filter: true, floatingFilter: true, minWidth: 110 },
        {
            headerName: 'Supplier Invoice Date', field: 'docDate', filter: true, floatingFilter: true, minWidth: 110,

            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Terms', field: 'terms', filter: true, floatingFilter: true, minWidth: 110 },
        { headerName: 'Po No', field: 'pOrderNo', filter: true, floatingFilter: true, minWidth: 150 },
        { headerName: 'Vendor Name', field: 'vendorName', filter: true, floatingFilter: true, minWidth: 110 },
        { headerName: 'Currency Code', field: 'currencyCode', filter: true, floatingFilter: true, minWidth: 110 },
        { headerName: 'Exch Rate', field: 'exchangeRate', filter: true, floatingFilter: true, minWidth: 110 },
        { headerName: 'Amount', field: 'amount', filter: true, floatingFilter: true, minWidth: 110 },
    ]
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const handleDateFilterSelection = (e) => {
        setdateFilter(e);
    }
    const handlePOChange = (selectedOption) => {
        setFormData({ ...formData, PONo: selectedOption });
    }
    const handleSupplierChange = (selectedOption) => {
        setFormData({ ...formData, SupplierName: selectedOption });
    }
    const handleSupplierIdChange = (selectedOption) => {
        setFormData({
            ...formData,
            SupplierId: { value: selectedOption.value, label: selectedOption.label },
            SupplierName: { value: selectedOption.value, label: selectedOption.value?.supplierName }
        });
    }
    const handleStatusFilter = (selectedOption) => {
        setFormData({ ...formData, Status: selectedOption });
    }
    const onBtnCSVExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: 'InvoiceReport.csv'
        });
    }, []);
    const onBtExcelExport = useCallback(() => {
        gridRef.current.api.exportDataAsExcel({
            fileName: 'InvoiceReport.xlsx'
        });
    }, []);
    const onBtPDFExport = useCallback(() => {
        const doc = new jsPDF();
        const rowData = [];
        const columnHeaders = [];
        const comparisonFields = [
            'invoiceRegNo',
            'invoiceNo',
            'invoiceDate',
            'docDate',
            'terms',
            'pOrderNo',
            'vendorName',
            'amount',
        ];

        comparisonFields.forEach(field => {
            const header = Comparison.find(col => col.field === field).headerName;
            columnHeaders.push(header);
        });

        gridRef.current.api.forEachNode((node) => {
            const row = [];
            comparisonFields.forEach(field => {
                let value = node.data[field];
                row.push(value);
            });
            rowData.push(row);
        });

        const poppinsFont = 'data:font/ttf;base64,AAEAAAARAQAABAAw7HA...';
        doc.addFileToVFS("Poppins-Regular.ttf", poppinsFont);
        doc.addFont("Poppins-Regular.ttf", "Poppins", "normal");
        doc.setFont("Poppins");
        doc.setFontSize(18);
        doc.setTextColor(40, 40, 40);
        doc.text("Item Master Report", 75, 12);
        doc.text("__________________________", 60, 15);
        doc.setLineWidth(2);
        doc.autoTable({
            head: [columnHeaders],
            body: rowData,
            styles: {
                fontSize: 6,
            },
            headStyles: {
                fillColor: [209, 126, 6],
                textColor: [255, 255, 255],
                fontSize: 6,
            },
            columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 20 },
                2: { cellWidth: 20 },
                3: { cellWidth: 25 },
                4: { cellWidth: 20 },
                5: { cellWidth: 20 },
                6: { cellWidth: 20 },
                7: { cellWidth: 20 },
            },
            startY: 20,
            theme: 'striped'
        });
        doc.save('InvoiceReport.pdf');
    }, []);
    const handlePageSizeChange = (event) => {
        const newSize = Number(event.target.value);
        setPageSize(newSize);
        gridRef.current.api.paginationSetPageSize(newSize);
    }
    const handleClearFilters = () => {
        setFormData({
            email: "",
            InvoiceNo: "",
            costcenter3: "",
            SupplierName: [{ label: 'Select Supplier', value: '' }],
            supplierId: [{ label: 'Select Supplier Id', value: '' }],
            Status: [{ label: 'Filter By', value: '' }],
            PONo: [{ label: 'Select PO', value: 'Select PO' }],
        });
        setFromDate("")
        setToDate(" ")
        setdateFilter(false)
    }
    const handleFromDate = (e) => {
        setFromDate(e.target.value);
    };
    const handleToDate = (e) => {
        setToDate(e.target.value);
    };
    const SendEmail = async () => {
        try {
            if (!formData.email) {
                toast.warning("Enter User Email to send Report")
                return
            }
            const requestBody = {
                // toEmail: "shahzaibawan864@gmail.com",
                toEmail: formData.email,
                subject: "Invoice Report",
                body: `
                <div className='printhead2 mb-5 px-4'>
                    <table>
                        <thead className='dynoTable' style='background-color: #6E5199; color: #fff;'>
                            <tr>
                                <th>Invoice Reg No</th>
                                <th>Invoice No </th>
                                <th>Invoice Date</th>
                                <th>Supplier Invoice Date</th>
                                <th>Payment Term</th>
                                <th>Po No</th>
                                <th>Vendor Name</th>
                                <th>Currency Code</th>
                                <th>Exch Rate</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${rowData?.map((row, index) => `
                                <tr key=${index}>
                                    <td className='m-0 p-0'><input style='width: 100px;' className='p-2' type='text' value='${row.rcNo}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' disabled value='${row.invoiceNo}' /></td>
                                    <td className='m-0 p-0'><input style='width: 140px;' className='p-2' disabled value='${row.invoiceDate}' /></td>
                                    <td className='m-0 p-0'><input style='width: 140px;' className='p-2' disabled value='${row.docDate}' /></td>
                                    <td className='m-0 p-0'><input style='width: 80px;' className='p-2' disabled value='${row.terms}' /></td>
                                    <td className='m-0 p-0'><input style='width: 150px;' className='p-2' disabled value='${row.pOrderNo}' /></td>
                                    <td className='m-0 p-0'><input style='width: 120px;' className='p-2' disabled value='${row.vendorName}' /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' disabled value='${row.currencyCode}' /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.exchangeRate}' disabled /></td>
                                    <td className='m-0 p-0'><input className='p-2 w-100' type='text' value='${row.amount?.toFixed(2) || 0}' disabled /></td>
                                </tr>
                            `).join('')}
                        </tbody>
                    </table>
                </div>`
            }
            const apiService = new ApiService();
            const response = await apiService.post("SEND_EMAIL", requestBody);
            if (response.status === 200) {
                onCloseModal()
                toast.success("Email send successfully")
                handleClearFilters()
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    return (
        <div className='p-5'>
            <div className='col'>
                <h4 className='py-3'>
                    Invoice Report
                </h4>
            </div>
            <div className=' justify-content-between d-flex pb-4 gap-3'>
                <div className='col'>
                    <Select
                        value={formData.supplierId}
                        onChange={(selectedOption) => handleSupplierIdChange(selectedOption)}
                        options={SupplierList?.map((sup) => ({ value: sup, label: sup?.record_Id }))}
                    />
                </div>
                <div className='col'>
                    <Select
                        value={formData.SupplierName}
                        onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                        options={SupplierList?.map((sup) => ({ value: sup, label: sup?.supplierName }))}
                    />
                </div>
                <div className='col'>
                    <input
                        type='number'
                        className="form-control"
                        id="validationCustom02"
                        value={formData.InvoiceNo}
                        name="InvoiceNo"
                        onChange={handleInputChange}
                        placeholder='Invoice No'
                    />
                </div>
                <div className='col'>
                    <Select
                        value={formData.Status}
                        options={StatusFilter}
                        onChange={handleStatusFilter}
                    />
                </div>
            </div>
            <div className='d-flex pb-4 gap-3'>
                <div className='col-3'>
                    <Select
                        value={formData.PONo}
                        onChange={(selectedOption) => handlePOChange(selectedOption)}
                        options={PoList?.map((rec) => ({ value: rec, label: `${rec?.pOrderNo}` }))}
                    />
                </div>
                <div className='col-3'>
                    <input
                        type='number'
                        className="form-control"
                        id="validationCustom02"
                        value={formData.costcenter3}
                        name="costcenter3"
                        onChange={handleInputChange}
                        placeholder='costcenter3'
                    />
                </div>
                <div className='col-6 d-flex gap-2 jutify-content-between'>
                    <div className='' style={{ width: "270px" }}>
                        <input
                            type='date'
                            className="form-control"
                            value={fromDate}
                            name="fromDate"
                            disabled={!dateFilter}
                            onChange={handleFromDate}
                            placeholder='From  Date '
                        />
                    </div>
                    <div style={{ width: "270px" }}>
                        <input
                            type='date'
                            disabled={!dateFilter}
                            className="form-control"
                            placeholder='To  Date '
                            value={toDate}
                            name="toDate"
                            onChange={handleToDate}
                        />
                    </div>
                    <div className='gap-2 center'>
                        <input className="form-check-input"
                            type="checkbox"
                            id="dateFilter" style={{ width: "26px", height: "26px" }}
                            name="dateFilter"
                            checked={dateFilter}
                            onChange={(e) => handleDateFilterSelection(e.target.checked)} />
                        <label className=''>Date Filter</label>
                    </div>
                </div>
            </div>
            <div className='pageBodr'>
                <div className='row'>
                    <div className='col-6 d-flex gap-5'>
                        <div className='arrows d-flex gap-5'>
                            {pagination?.totalPages < 10 ?
                                <div className='center'><RiSkipLeftFill size={30} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><RiSkipLeftFill size={30} onClick={() => getItems("skipPrev")} /></div>
                            }
                            {pagination?.currentPage === 1 ?
                                <div className='center'><FaAngleLeft size={25} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><FaAngleLeft size={25} onClick={() => getItems("prev")} /></div>
                            }
                            <div className='d-flex gap-3 center'>
                                <div className=''>{pagination?.currentPage}</div>
                                <div className=''>of</div>
                                <div className=''>{pagination?.totalPages}</div>
                            </div>
                            {pagination?.currentPage === pagination?.totalPages ?
                                <div className='center'><FaAngleRight size={25} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><FaAngleRight size={25} onClick={() => getItems("next")} /></div>
                            }
                            {pagination?.totalPages > 10 ?
                                <div className='center'><RiSkipRightFill size={30} onClick={() => getItems("skipNext")} /></div> :
                                <div className='center'><RiSkipRightFill size={30} style={{ color: "#ABA3A3" }} /></div>
                            }
                        </div>
                        <div className="gap-4 d-flex">
                            <div className='center'>Page Size</div>
                            <div>
                                <select
                                    className="form-select pagclr"
                                    aria-label="Default select example"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                >
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 '>
                        <div className='end arrows d-flex gap-4'>
                            <div className='center' onClick={handleClearFilters}><SlRefresh size={30} style={{ color: "5B93FF" }} /></div>
                            <div className='center'>
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                                        <PiDownloadSimple size={30} style={{ color: "5B93FF" }} />
                                    </DropdownToggle>
                                    <DropdownMenu className='mt-3'>
                                        <DropdownItem onClick={onBtnCSVExport} className='gap-3 d-flex center'><BsFiletypeCsv /><div>Export CSV</div></DropdownItem>
                                        {/* <DropdownItem onClick={onBtExcelExport} className='gap-3 d-flex center'><LiaFileExcel/><div>Export Excel</div></DropdownItem> */}
                                        <DropdownItem onClick={onBtPDFExport} className='gap-3 d-flex center'><BsFileEarmarkPdf /><div>Export PDF</div></DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <div className='center'><HiOutlinePrinter size={30} style={{ color: "5B93FF" }} /></div>
                            <div className='center'><TfiEmail size={28} style={{ color: "5B93FF" }} onClick={onOpenModal} /></div>
                            <Modal size="md"
                                show={emailModal} onHide={onCloseModal}
                                onRequestClose={onCloseModal}
                                contentLabel='Email Modal'
                                style={customStyles} closeButton
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title className='center'>Send Email</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        <input
                                            type='email'
                                            className="form-control"
                                            id="validationCustom02"
                                            value={formData.email}
                                            name="email"
                                            onChange={handleInputChange}
                                            placeholder='Enter Email'
                                        />
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className='end p-3'>
                                    <button className='AddBtn' style={{ background: "#6E5199" }} onClick={SendEmail}>Save</button>
                                </Modal.Footer>
                            </Modal >
                        </div>
                    </div>
                </div>
            </div>
            <div className='brdor'>
                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        rowSelection={'single'}
                        columnDefs={Comparison}
                        ref={gridRef}
                        suppressExcelExport={true}
                    />
                </div>
            </div>
        </div >
    );
};
export default InvoiceReport;
const customStyles = {
    content: {
        width: '30%', // Set the desired width
        height: '75%', // Set the desired 
        top: '50%', // Center vertically
        left: '50%', // Center horizontally
        right: '50%', // Center horizontally
        transform: 'translate(-50%, -50%)', // Center both vertically and horizontally
    },
};