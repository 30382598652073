import { useState, React, useEffect } from 'react'
import "./bucket.css"
import { ReactComponent as Delete } from '../../assets/images/svgIcons/Delete.svg';
import { useNavigate } from 'react-router-dom'
import userManagement from '../../services/userManagement';
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import { RemoveFromCart, clearCart, downgradeCartItemQuantity, updateCartItemQuantity } from '../../../Redux/actions/cartAction';
import { useSelector } from 'react-redux';
import { getCartData, getComboData } from '../../../Redux/selecters';
import { RemoveComboFromCart, clearComboCart, downgradeComboQuantity, updateComboQuantity } from '../../../Redux/actions/comboAction';

const Bucket = ({ Currency }) => {

    const cartData = useSelector(getCartData)
    const comboCart = useSelector(getComboData);

    let navigate = useNavigate();
    let dispatch = useDispatch()
    const calculateTotalPrice = () => {
        let totalPrice = 0

        cartData.forEach((item) => {
            const itemPrice =
                (item?.selectedVariant ? (item?.selectedVariant.variantPrice) : (item?.itemData?.price)) +
                calculateAddOnsPrice(item?.selectedAdOn) + calculateModifierPrice(item?.selectedModifier);
            totalPrice += itemPrice * item?.quantity;
        });
        comboCart?.forEach(item => {
            totalPrice += item?.price * item?.quantity;
        });
        return totalPrice;
    };

    const calculateAddOnsPrice = (addOns) => {
        return addOns?.reduce((total, addOn) => total + parseInt(addOn?.metaItemPrice), 0);
    };
    const calculateModifierPrice = (addOns) => {
        return addOns?.reduce((total, addOn) => total + parseInt(addOn?.metaItemPrice), 0);
    };

    useEffect(() => {
        setTotalRate(calculateTotalPrice());
    }, [cartData, comboCart]);

    const handleDelete = (id, index) => {
        dispatch(RemoveFromCart(id, index));
        setTotalRate(calculateTotalPrice());
    };

    const handleQuantityChange = (item, index) => {
        dispatch(updateCartItemQuantity(index, item?.itemData?.id || item?.itemData?.itemId))
        setTotalRate(calculateTotalPrice());
    };
    const handleQuantityChangeDec = (item, index) => {
        dispatch(downgradeCartItemQuantity(index, item?.itemData?.id || item?.itemData?.itemId))
        setTotalRate(calculateTotalPrice());
    };

    const handlePayment = () => {
        navigate('/customer/menu/Cart/Payment', { state: { cartData, totalRate, comboCart } });
    };
    const [totalRate, setTotalRate] = useState(calculateTotalPrice());


    const truncateText = (text, maxLength) => {
        return text?.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    };
    const clearcart = () => {
        dispatch(clearCart())
        dispatch(clearComboCart())
    };
    // combo

    const handleComboQuantity = (item, index) => {
        dispatch(updateComboQuantity(index, item?.id));
        setTotalRate(calculateTotalPrice());
    };
    const handlecomboQuantityDec = (item, index) => {
        dispatch(downgradeComboQuantity(index, item?.id));
        setTotalRate(calculateTotalPrice());
    };
    const handlecomboDelete = (item, index) => {
        dispatch(RemoveComboFromCart(item?.id, index));
        setTotalRate(calculateTotalPrice());
    };
    return (
        <div style={{ overflow: "auto" }}>
            <div className='pt-lg-5'>
                <div className='cartBG d-flex justify-content-between'>
                    <div>Your Item Cart</div>
                    <div onClick={() => clearcart()}>Clear Cart</div>
                </div>
                <div className='table-responsive'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Modifiers</th>
                                <th>Quantity</th>
                                <th>Rate</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cartData?.map((item, index) => (
                                <tr key={index}>
                                    <td title={item?.itemData?.name}>{(item?.itemData?.name)}</td>
                                    <td>
                                        {item?.selectedModifier?.map((modifier, i) => (
                                            <div key={i} title={modifier.metaItemName}>
                                                {truncateText(modifier.metaItemName, 6)}
                                            </div>
                                        ))}
                                    </td>

                                    <td>
                                        <div className="quantity-control d-flex justify-content-around center">
                                            {item?.quantity == 1
                                                ? <div

                                                    className="addbtn"
                                                >
                                                    -
                                                </div> :
                                                <div
                                                    onClick={() =>
                                                        handleQuantityChangeDec(
                                                            item, index
                                                        )
                                                    }
                                                    className="addbtn"
                                                >
                                                    -
                                                </div>
                                            }

                                            <div className="">{item.quantity}</div>
                                            <div
                                                onClick={() =>
                                                    handleQuantityChange(
                                                        item, index
                                                    )
                                                }
                                                className="addbtn"
                                            >
                                                +
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {Currency.currency.name}  {item?.selectedVariant ? item.selectedVariant?.variantPrice + calculateAddOnsPrice(item?.selectedAdOn) + calculateModifierPrice(item?.selectedModifier) : item?.itemData?.price + calculateAddOnsPrice(item?.selectedAdOn) + calculateModifierPrice(item?.selectedModifier)}
                                    </td>
                                    <td>
                                        <Delete onClick={() => handleDelete(item.itemData.id, index)} />
                                    </td>
                                </tr>
                            ))}
                            {comboCart?.map((item, index) => (
                                <tr key={index}>
                                    <td title={item?.name}>{item?.name}</td>
                                    <td title={item?.name}>{item?.comboItems?.length} Modifier</td>
                                    <td>
                                        <div className="quantity-control d-flex justify-content-around center">
                                            <div
                                                onClick={() => handlecomboQuantityDec(item, index)}
                                                className="addbtn center">
                                                -
                                            </div>
                                            <div className="center">{item?.quantity}</div>
                                            <div
                                                onClick={() => handleComboQuantity(item, index)}
                                                className="addbtn center"
                                            >
                                                +
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {Currency.currency.name} {item?.price}
                                    </td>
                                    <td>
                                        <Delete onClick={() => handlecomboDelete(item, index)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <button onClick={handlePayment} className='AddBtn w-100'>
                    Checkout - Total: {Currency.currency.name} {totalRate?.toLocaleString('en-US')}
                </button>
                {/* <div className='center'> <button onClick={handlePayment} className='AddBtn w-100'>Checkout</button></div> */}
            </div>
        </div>
    )
}

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(Bucket);