import React, { useState, useEffect } from 'react';
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';

const IssueReturnItem = ({ onRequisitionData, itemData, formData }) => {
    const apiService = new ApiService();
    const [rowsUpdated, setRowsUpdated] = useState(false);
    const [rows, setRows] = useState([{
        requisitionNo: '',
        srno: '',
        recordId: '',
        ItemNo: '',
        itemDescription: '',
        specification: '',
        uom: '',
        qty: '',
        amount: '',
        invQty: '',
        convRate: '',
        stock: '',
        unitPrice: '',
        balpo: '',
        balQty: ''
    }]);
    const [itms, setItems] = useState([])
    const totalAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
    // const totalVatAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.vatAmount) || 0), 0)
    useEffect(() => {
        getItems();
    }, [formData]);
    useEffect(() => {
        getItems();
    }, []);
    const getItems = async () => {
        try {
            // let param = `?RequisitionNo=${formData.requisitionNo.label}`
            // const response = await apiService.getApiParamater('GET_REQ_ITEM_ISSUE', param);
            const itmsresponse = await apiService.get('GET_ITEM');
            setItems(itmsresponse.data.result)
            debugger
            if (itemData) {
                // const grnItemMap = new Map(GrnItemData.map(detail => [detail.itemCode, detail]));

                const formattedRows = itemData?.purchaseReturnDetails?.map(detail => {
                    // const grnDetail = grnItemMap.get(detail.itemCode);
                    // const selectedPO = response?.data?.result?.find(req => req.pOrderNo === grnDetail?.pOrderNo);
                    let amount = detail.balQty * detail.unitPrice
                    const perAmount = (amount / 100) * detail?.vatPerc
                    let ExcludedAmount = amount + perAmount
                    // if (grnDetail) {
                    //     return {
                    //         ItemNo: detail.itemCode,
                    //         itemDescription: detail.itemDescription,
                    //         qty: detail.qty,
                    //         uom: detail.uom,
                    //         specification: detail.specification,
                    //         balqty: grnDetail.balQty || detail.qty,
                    //         stock: detail.convToInvUOM,
                    //         baseAmount: grnDetail.baseAmount,
                    //         unitPrice: grnDetail.unitPrice,
                    //         amount: selectedPO.vatIncluded === "Included" ? amount : ExcludedAmount,
                    //         vat: detail.vatPerc,
                    //         vatAmount: perAmount,
                    //     };
                    // }
                    return null;
                }).filter(row => row !== null);
                if (formattedRows.length > 0) {
                    setRows(formattedRows);
                    onRequisitionData(formattedRows);
                }
            } else {
                debugger
                const formattedRowsPromises = formData?.issueNo?.value?.issueDetails?.map(async (detail, index) => {
                    debugger
                    const selecteditm = await apiService.getApiParamater('GET_ISSUE_RETURN_ITEM', `?IssueNo=${formData?.issueNo.label}&Mode=1`);
                    const selecteditmstock = await apiService.getApiParamater('GET_ITEM', `?ItemNo=${detail.itemCode}&Warehouse=${formData?.location.label}`);
                    debugger
                    const selectedItem = itmsresponse?.data?.result?.find(req => req.itemDescription === detail.itemDescription);
                    const item = selecteditm?.data.result?.find(req => req.itemDescription === detail.itemDescription);
                    debugger
                    let itemstock = selecteditmstock?.data?.result?.[0]
                    return {
                        srno: index + 1,
                        ItemNo: detail.itemCode,
                        itemDescription: detail.itemDescription,
                        specification: detail.specification,
                        uom: detail.uom,
                        amount: item.amount || 0,
                        // invQty: selectedItem.convToInvUOM * detail.qty,
                        invQty: 0,
                        convRate: selectedItem.convToInvUOM,
                        stock: itemstock?.stock || 0,
                        unitPrice: item.unitPrice || 0,
                        balpo: detail.cgsAcc,
                        balQty: item.balQty,
                    };
                }).filter(row => row !== null);
                const formattedRows = await Promise.all(formattedRowsPromises);
                if (formattedRows.length > 0) {
                    setRows(formattedRows);
                    onRequisitionData(formattedRows);
                }
                debugger
                if (formData && formData.location.label && rows[0].ItemNo) {
                    updateRowsWithNewStock();
                    setRowsUpdated(true);
                }
            }

        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };
    const handleQtyChange = (index, value) => {
        const newQty = parseInt(value) || '';
        const balQty = rows[index].balQty;
        const stock = rows[index].stock;
        const unitPrice = rows[index].unitPrice;
        const invQty = rows[index].invQty;
        const convRate = rows[index].convRate;
        if (newQty > balQty) {
            toast.warning('Enter a quantity less than or equal to the Issue quantity.');
            return
        } else {
            setRows(prevRows => {
                const newRows = [...prevRows];
                newRows[index].qty = newQty;
                newRows[index].amount = newQty * unitPrice;
                // newRows[index].invQty = newQty * convRate || invQty;
                newRows[index].invQty = newQty * 1 || 1;
                return newRows;
            });
        }
    };
    const updateRowsWithNewStock = async () => {
        try {
            const updatedRows = rows.map(row => ({ ...row }));
            const apiPromises = updatedRows.map(async (row, index) => {
                const queryParam = `itemNo=${row.ItemNo}`;
                const response = await apiService.getApiParamater(
                    'GET_ITEM',
                    `?isActive=true&Warehouse=${formData?.location.label}&${queryParam}`
                );
                if (response?.data?.statusCode === 200) {
                    const itemData = response.data.result[0];
                    updatedRows[index].stock = itemData.stock || 0;
                    updatedRows[index].itemDescription = itemData.itemDescription;
                } else if (response?.data?.statusCode === 204) {
                    updatedRows[index] = {
                        ...row,
                        stock: 0,
                    };
                }
            });
            await Promise.all(apiPromises);
            onRequisitionData(updatedRows);
        } catch (error) {
            console.error('Error updating rows with new stock:', error);
        }
    }
    return (
        <div>
            <div className='table-container p-2 mb-5' style={{ overflowX: 'auto', height: "400px" }}>
                <table>
                    <thead className='dynoTable' >
                        <tr>
                            <th>Sr no</th>
                            <th>Item No</th>
                            <th>Description</th>
                            <th>Item Specs</th>
                            <th>Qty</th>
                            <th>Bal Qty</th>
                            <th>Inventory UOM</th>
                            <th>Inventory Qty</th>
                            <th>Unit Price</th>
                            <th>Amount</th>
                            <th>Stock</th>
                            <th>BalForPo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows?.map((row, index) => (
                            <tr key={index}>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.srno} disabled style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.ItemNo} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.itemDescription} style={{ width: "150px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.specification} style={{ width: "150px" }} /></td>
                                <td className='m-0 p-0'>
                                    <input
                                        className='p-2' style={{ width: "100px" }}
                                        type="number" min={0} maxLength={row.qty} max={row.qty}
                                        onWheel={(e) => e.target.blur()}
                                        value={row.qty}
                                        onChange={(e) => handleQtyChange(index, e.target.value)}
                                    />
                                </td>
                                <td className='m-0 p-0'><input className='p-2' value={row.balQty} disabled style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' value={row.uom} disabled style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' value={row.invQty} disabled style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.unitPrice || 0} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.amount || 0} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.stock || 0} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.balpo || 0} style={{ width: "120px" }} /></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className='d-flex boxs'>
                <div className='d-flex gap-2 center'>
                    <div className="totalhead center">Total Amount</div>
                    <div className='field'>{totalAmount || "---"}</div>
                </div>
            </div>
        </div>
    );
};

export default IssueReturnItem;
