import React, { useState, useEffect } from 'react';
import ApiService from '../../services/apiService';

const AuthorizationGrid = ({ handleAuthorization, usersDetails, setUser, itemData }) => {
    const [selectedRec, setSelectedRec] = useState();
    useEffect(() => {
        fetchApprovalData();
    }, [usersDetails]);
    function getStatusButton(status) {
        if (status === 0 || status === null) {
            return (<input className="p-2 w-100" value={"Pending"} disabled />);
        }
        else if (status === 1 || status === null) {
            return (<input value={"Approved"} className="p-2 w-100" disabled />);
        }
        else if (status === 2 || status === null) {
            return (<input value={"Hold"} className="p-2 w-100" disabled />);
        }
        else if (status === 3 || status === null) {
            return (<input value={"Rejected"} className="p-2 w-100" disabled />);
        } else {
            return (<input className="p-2 w-100" value={"Pending"} disabled />);
        }
    }
    const fetchApprovalData = async () => {
        try {
            let parameters = `?Status=${itemData?.status}&DocType=${'Purchase Order'}&UserName=${usersDetails[0]?.userNo}`
            const apiService = new ApiService();
            const response = await apiService.getApiParamater('GET_DOC_AUTHORIZATION_STATUS', parameters);
            if (itemData.docNo) {
                const selectedRec = response.data.result?.find(rec => rec.docNo === itemData?.docNo)
                setSelectedRec(selectedRec)
            } else {
                const selectedRec = response.data.result?.find(rec => rec.docNo === itemData?.recordId.replace('PO-24-', '').replace(/^0+/, ''))
                setSelectedRec(selectedRec)
            }
        } catch (error) {
            console.error("Error fetching Purchase data:", error);
        }
    };
    return (
        <div className='table-container mb-5 p-2' style={{ overflowX: 'auto' }}>
            <table className='w-100'>
                <thead className='dynoTable' >
                    <tr>
                        <th>Sr No</th>
                        <th>User Code</th>
                        <th>User Name</th>
                        <th>Approval Date</th>
                        <th>Remarks</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {usersDetails?.map((user, index) => (
                        <tr key={user.rcid}>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={index + 1} disabled /></td>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={user?.userCode} disabled /></td>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={user?.userNo} disabled /></td>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={user?.createdOn || "---"} disabled /></td>
                            {itemData?.eye === true ?
                                <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={selectedRec?.remarks || ''} disabled /></td>
                                :
                                <td className='m-0 p-0'>
                                    <input
                                        type="text" disabled
                                        className='p-2 w-100' value={selectedRec?.remarks || ''}
                                    />
                                </td>}
                            {/* <td className='m-0 p-0'><input className='p-2 w-100' value={itemData?.status} disabled />
                            </td> */}
                            <td className='m-0 p-0'>
                                {getStatusButton(itemData?.status)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AuthorizationGrid;
