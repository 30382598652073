// selectors/index.js
import userManagement from '../../POS/services/userManagement';
export const getToken = (state) => state?.userAuth?.user?.token
export const getProfile = (state) => state?.userAuth?.user?.profileImage
export const getName = (state) => state?.userAuth?.user?.fullName

export const getRole = (state) => userManagement.getUserRole(getToken(state));
export const getUserId = (state) => userManagement.getUserId(getToken(state));
export const getUserName = (state) => userManagement.getUserName(getToken(state));
export const getCartData = (state) => state?.cart?.items;
export const getComboData = (state) => state?.comboCart?.items;
export const getTableData = (state) => state?.table?.items;
export const getOrderEditData = (state) => state?.orderEdit?.items;
export const getEmployeData = (state) => state?.employeData;
export const getUserRights = (state) => state?.userDetails;
//ADD ON SELECTOR
export const getAddons = (state) => state.addons.addons;
export const getAddonById = (state, id) => state.addons.addons.find(addon => addon.id === id);

export const CURRENCY = 'CURRENCY'