import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import MessageGrid from '../../purchaseRequisition/messageGrid';
import ApiService from '../../../services/apiService';
import ItemGridGRN from './itemGrid';
import { toast } from 'react-toastify';
import AuthorizationGrn from '../authorization';
import { useLocation, useNavigate } from 'react-router-dom';
import UpdateItemGridGRN from './updateitemgrid';
import { useSelector } from 'react-redux';
import { getUserName } from '../../../../Redux/selecters';


const UpdateGrn = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const apiService = new ApiService();
    const [messageData, setMessageData] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [ItemData, setItemData] = useState([]);
    const [POData, setPOData] = useState([]);
    const [selectedPOData, setSelectedPOData] = useState([]);
    const [supplier, setSupplier] = useState([]);
    const [usersDetails, setUser] = useState([]);
    const [Department, setDepartment] = useState([]);
    const [WareHouse, setWareHouse] = useState([]);
    const [selectedWareHouse, setSelectedWareHouse] = useState([]);
    const [PurchaseOrder, setPurchaseOrder] = useState([]);
    const [SelectedPO, setSelectedPO] = useState([]);
    const [SelectedSupplier, setSelectedSupplier] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [currency, setCurrency] = useState([]);
    const location = useLocation();
    const [grnNo, setGrnNumber] = useState([]);
    const navigate = useNavigate();
    const userName = useSelector(getUserName);
    const itemData = location.state;
    const formatDateToDatetimeLocal = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    };
    useEffect(() => {
        fetchData()
        getApprovalUser()
        getCurrency()
    }, [])
    useEffect(() => {
        const item = location.state;
        const selectedOption = PurchaseOrder?.find(po => po?.pOrderNo === item?.pOrderNo);
        const selectedLoc = WareHouse?.find(war => war?.description === item?.warehouse);
        setSelectedPO({ label: selectedOption?.pOrderNo, value: selectedOption })
        setSelectedSupplier({ label: selectedOption?.vendorName, value: selectedOption })
        setSelectedPOData(selectedOption)
        setPOData(item?.grnDetails)
        setSelectedWareHouse({ label: selectedLoc?.description, value: selectedLoc })
        const formattedPODate = selectedOption?.docDate ? formatDateToDatetimeLocal(selectedOption?.docDate) : getCurrentLocalDatetime();
        const formattedInvDate = item?.invoiceDate ? formatDateToDatetimeLocal(item?.invoiceDate) : getCurrentLocalDatetime();
        const formattedDelivDate = item?.delivery_Date ? formatDateToDatetimeLocal(item?.delivery_Date) : getCurrentLocalDatetime();
        setFormData({
            GRNNO: item?.grnNo,
            remarks: item?.remarks,
            InvoiceNo: item?.invoiceNo,
            InvoiceDate: formattedInvDate,
            deliveryDate: formattedDelivDate,
            Department: item?.department,
            PurchaseOrderDate: formattedPODate,
            deliveryNote: item?.invoiceRef
        });
        setGrnNumber(item?.grnNo)
    }, [PurchaseOrder, location.state, WareHouse]);
    const fetchData = async () => {
        const Supplier = await apiService.get("GET_SUPPLIER");
        setSupplier(Supplier?.data?.result);
        const department = await apiService.get("GET_DEPARTMENT_MASTER");
        setDepartment(department?.data?.result);
        const WareHouse = await apiService.get("GET_WAREHOUSE_MASTER");
        setWareHouse(WareHouse?.data?.result);
        const PurchaseOrder = await apiService.get("GET_PO");
        const approvedPO = PurchaseOrder.data.result.filter(data => data.status === 1);
        approvedPO.sort((a, b) => b.recordId - a.recordId);
        setPurchaseOrder(approvedPO);
    }
    const getApprovalUser = async () => {
        try {
            let param = `?DocType=GRN`;
            const apiService = new ApiService();
            const response = await apiService.getApiParamater('GET_APPROVAL_USER', param);
            if (response?.data?.result && response.data.result.length > 0) {
                setUser(response.data.result);
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')

    };
    const handleRequisitionData = (data) => {
        setItemData(data);
        console.log(data, 'reqData')
    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    };
    const [formData, setFormData] = useState({
        GRNNO: "",
        deliveryNote: "",
        remarks: "",
        InvoiceNo: "",
        InvoiceDate: "",
        deliveryDate: "",
        PropertyGroup: "",
        TaxInvoice: "",
        Property: "",
        Department: "",
        Outlet: "",
        Currency: "",
        exchangeRate: "",
        deliveryPoint: "",
        PurchaseOrderDate: "",
        contactPerson: "",
        contatNo: "",
        taskCode: "",
        email: '',
        subcatname: "",
        location: []
    });
    const staticOptions = [];
    const handlePoChange = (selectedOption) => {
        setSelectedPO(selectedOption);
        setSelectedSupplier({ label: selectedOption.value.vendorName, value: selectedOption?.value })
        let docDateUTC = new Date(selectedOption.value.docDate);
        let localDateString = new Date(docDateUTC.getTime() - (docDateUTC.getTimezoneOffset() * 60000))
            .toISOString()
            .slice(0, 16);

        setFormData({ ...formData, PurchaseOrderDate: localDateString });
        setSelectedPOData(selectedOption.value)
        // fetchPOData(selectedOption)
        setPOData(selectedOption.value?.poDetails)
    };
    const fetchPOData = async (selectedOption) => {
        let param = `?POrderNo=${parseInt(selectedOption?.label)}`
        const PO = await apiService.getApiParamater("GET_GRN_PO", param);
        if (PO.data.statusCode === 204) {
            toast.error("No Items In this Purchase Order")
        }
        setPOData(PO.data.result)
    }
    const handleDepartmentChange = (selectedOption) => {
        setFormData({ ...formData, Department: selectedOption?.description });
    }
    const handleLocationChange = (selectedOption) => {
        setSelectedWareHouse(selectedOption)
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'deliveryDate' || name === 'PurchaseOrderDate' || name === 'InvoiceDate') {
            const dateParts = e.target.value.split("-");
            if (dateParts[0].length > 4) {
                return;
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const UpdateGRN = async () => {
        if (ItemData.length === 0) {
            toast.warning("Please add items");
            return;
        }
        if (!selectedWareHouse) {
            toast.warning("Select Location")
            return
        }
        if (!formData?.deliveryDate) {
            toast.warning("Select Delivery Date")
            return
        }
        if (!formData?.PurchaseOrderDate) {
            toast.warning("Select Purchase Order Date")
            return
        }
        if (!formData?.InvoiceDate) {
            toast.warning("Select Invoice Date")
            return
        }
        const missingQtyItem = ItemData.find(item => !item.qty);
        if (missingQtyItem) {
            toast.warning(`Please enter quantity for ${missingQtyItem.itemDescription}`);
            return;
        }
        if (!formData.remarks) {
            toast.warning("Enter remarks");
            return;
        }
        try {
            const totalAmount = ItemData.reduce((sum, row) => sum + (row.amount || 0), 0);
            const currentDatetimeUTC = new Date().toISOString();
            const apiService = new ApiService()
            const GRNDetailsData = ItemData.map((item, index) => ({
                requisitionNo: item?.requisitionNo,
                srno: index + 1,
                itemCode: item?.ItemNo,
                itemDescription: item.itemDescription,
                uom: item.uom,
                qty: parseFloat(item.qty),
                baseUOM: item?.uom,
                baseQty: parseFloat(item.baseQty),
                specification: item.specification,
                convValue: parseFloat(item?.convValue),
                unitPrice: parseFloat(item.baseAmount),
                amount: item.amount,
                baseAmount: parseFloat(item.amount),
                convToInvUOM: parseFloat(item.convValue),
                // convToInvUOM: 1,
                importedQty: 0,
                vatPerc: item.vat,
                extraQty: 0,
                vatAmount: item.vatAmount,
                prodDate: getCurrentLocalDatetime(),
                expDate: item.expDate,
                isActive: true,
                grnNo: grnNo,
                returnQty: 0,
                invoicedQty: 0,
                vatCode: 1,
                vatDesc: item.vatDes,
                vatUnitPrice: item.vatAmount / item.qty,
                vatBaseAmount: item.vatAmount,
                vatInclusive: selectedPOData.costCenter5 === "Included" ? true : false,
                grossAmount: 0,
                addCharges: 0,
            }));
            const userData = {
                grnNo: itemData.grnNo,
                pOrderNo: selectedPOData?.pOrderNo,
                delivery_Date: selectedPOData?.deliveryDate || formData.InvoiceDate,
                status: 0,
                remarks: formData?.remarks,
                modifiedOn: getCurrentLocalDatetime(),
                modifiedBy: userName,
                terms: selectedPOData.terms,
                vendorId: SelectedSupplier?.value?.vendorId,
                vendorName: SelectedSupplier?.label,
                department: "IT",
                // department: formData?.Department,
                costCenter2: selectedPOData?.costCenter2,
                costCenter3: selectedPOData?.costCenter3,
                costCenter4: selectedPOData?.costCenter4,
                isActive: true,
                grnDetailsDtos: GRNDetailsData,
                warehouse: selectedWareHouse.label,
                poType: "GRN",
                invoiceNo: formData.InvoiceNo,
                invoiceDate: formData.InvoiceDate,
                invoiceRef: formData.deliveryNote,
                currencyCode: SelectedCurrency.label,
                exchangeRate: SelectedCurrency?.value.conversionRate,
                amount: totalAmount,
                baseAmount: totalAmount,
                transport_Mode: "From PO",
                vatInclusive: selectedPOData.costCenter5 === "Included" ? true : false,
            };
            const response = await apiService.put("UPDATE_GRN", userData);
            if (response?.data?.statusCode === 201) {
                toast.success(`${itemData.grnNo} Updated successfully`);
                navigate("/inventory/GRN/Listing")
            }
        } catch (error) {
            console.error("Error Creating PR:", error);
        }
    };
    const getCurrency = async () => {
        let param = `?isActive=true`
        const apiService = new ApiService()
        let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
        setCurrency(res?.data?.result)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        setFormData({ ...formData, exchangeRate: filteredDenominations.conversionRate });
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
    }
    return (
        <div className='p-5'>
            <div className='h4'>Goods Receipt Note</div>
            <div className='brderbox p-lg-3 mb-5'>
                <div className="form-group justify-content-between row pb-3">
                    <div className="col">
                        <label htmlFor="GRNNO" className="form-label">GRN No</label>
                        <input
                            className="form-control" value={formData.GRNNO}
                            onChange={handleInputChange} disabled
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="catcode" className="form-label">Delivery Date </label>
                        <input className='form-control' type='datetime-local'
                            name="deliveryDate" min={getCurrentLocalDatetime()} disabled={itemData?.eye === true ? true : false}
                            value={formData.deliveryDate || getCurrentLocalDatetime()} onChange={handleInputChange}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="Location" className="form-label">Location</label>
                        <Select
                            value={selectedWareHouse} isDisabled={itemData?.eye === true ? true : false}
                            onChange={handleLocationChange} options={WareHouse?.map((sup) => ({ value: sup, label: sup?.description }))}
                        />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Purchase Order No *</label>
                        <Select
                            value={SelectedPO} isDisabled={itemData?.eye === true ? true : false}
                            options={PurchaseOrder?.map((po) => ({ value: po, label: po?.pOrderNo }))} onChange={(selectedOption) => handlePoChange(selectedOption)}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="deliveryNote" className="form-label">Delivery Note No</label>
                        <input
                            type="text" className="form-control"
                            disabled={itemData?.eye === true ? true : false}
                            id="deliveryNote" value={formData.deliveryNote}
                            onChange={handleInputChange} name="deliveryNote"
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="TaxInvoice" className="form-label">Tax Invoice No ( KRA )</label>
                        <input
                            type="number" className="form-control"
                            disabled={itemData?.eye === true ? true : false} id="TaxInvoice" onWheel={(e) => e.target.blur()}
                            value={formData.TaxInvoice} onChange={handleInputChange} name="TaxInvoice"
                        />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="PurchaseOrderDate" className="form-label">Purchase Order Date</label>
                        <input
                            type="datetime-local" className="form-control"
                            disabled={itemData?.eye === true ? true : false} id="PurchaseOrderDate"
                            value={formData.PurchaseOrderDate} onChange={handleInputChange} name="PurchaseOrderDate"
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="InvoiceNo" className="form-label">Invoice No</label>
                        <input
                            type="number" onWheel={(e) => e.target.blur()} className="form-control"
                            id="InvoiceNo" disabled={itemData?.eye === true ? true : false}
                            value={formData.InvoiceNo} onChange={handleInputChange} name="InvoiceNo"
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Delivery Type</label>
                        <Select
                            isDisabled={itemData?.eye === true ? true : false}
                            options={staticOptions}
                            onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                        />
                    </div>
                    <div className="form-group row justify-content-between pb-3">
                        <div className="col">
                            <label htmlFor="inventoryAccountCode" className="form-label">Supplier</label>
                            <Select
                                isDisabled={itemData?.eye === true ? true : false}
                                value={SelectedSupplier}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="InvoiceDate" className="form-label">Invoice Date</label>
                            <input
                                type="datetime-local" className="form-control"
                                id="InvoiceDate" value={formData.InvoiceDate} disabled={itemData?.eye === true ? true : false}
                                onChange={handleInputChange} name="InvoiceDate"
                            />
                        </div>
                        <div className="col d-flex gap-3">
                            <div className="col">
                                <label htmlFor="Currency" className="form-label">Currency</label>
                                <Select
                                    isSearchable={false}
                                    isDisabled={itemData?.eye === true ? true : false}
                                    value={SelectedCurrency}
                                    className="Currency-dropdown mbl-dropdown"
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="EXRate" className="form-label">EX Rate</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    disabled
                                    onWheel={(e) => e.target.blur()}
                                    id="exchangeRate"
                                    value={formData.exchangeRate}
                                    onChange={handleInputChange}
                                    name="exchangeRate"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='h4'>Cost Center</div>
            <div className='brderbox p-lg-3 mb-5'>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="PropertyGroup" className="form-label">Property Group</label>
                        <Select
                            isDisabled={itemData?.eye === true ? true : false}
                            defaultValue={formData.PropertyGroup}
                            onChange={handleDepartmentChange}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="Property" className="form-label">Property</label>
                        <Select
                            isDisabled={itemData?.eye === true ? true : false}
                            defaultValue={formData.Property}
                            onChange={handleDepartmentChange}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="Department" className="form-label">Department</label>
                        <Select
                            isDisabled={itemData?.eye === true ? true : false}
                            defaultValue={formData.Department}
                            onChange={handleDepartmentChange}
                        />
                    </div>
                </div>
                <div className="form-group row  pb-3">
                    <div className="col-4">
                        <label htmlFor="Outlet" className="form-label">Outlet</label>
                        <Select
                            isDisabled={itemData?.eye === true ? true : false}
                            defaultValue={formData.Outlet}
                            onChange={handleDepartmentChange}
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="remarks" className="form-label">Remarks</label>
                        <input
                            name="remarks"
                            type='text'
                            className="form-control"
                            disabled={itemData?.eye === true ? true : false}
                            id="remarks"
                            value={formData.remarks}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='col-2 mt-4 brderbox p-3'>
                        <a href=''>
                            Attachment
                        </a>
                    </div>
                    <div className='col-2'></div>
                </div>
            </div>
            <div className="topnav2 maon-BG ">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Message
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 3 ? 'active' : ''}`}
                            onClick={() => handleTabClick(3)}
                        >
                            Additional Charges
                        </div>
                    </div>

                </div>
            </div>
            <div className="grid-1 brderbox2 p-4 mb-3">
                {gridVisibility[0] && (
                    <UpdateItemGridGRN onRequisitionData={handleRequisitionData} itemData={POData} grnData={itemData} selectedPOData={selectedPOData} />
                )}
                {gridVisibility[1] && (
                    <AuthorizationGrn handleAuthorization={handleAuthorization}
                        usersDetails={usersDetails}
                        setUser={setUser}
                        itemData={POData} />
                )}
                {gridVisibility[2] && (
                    <div className="grid-3 border">
                        <MessageGrid handleMessage={handleMessage} />
                    </div>
                )}
                {gridVisibility[3] && (
                    <ItemGridGRN onRequisitionData={handleRequisitionData} />
                )}
                <div className='d-flex justify-content-end pt-4'>
                    {itemData?.eye === true ? "" :
                        <button className='mainbtn ' onClick={UpdateGRN} type="submit" >Update</button>
                    }
                </div>
            </div>
        </div>
    )
}

export default UpdateGrn