import React, { useState, useEffect } from 'react';
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';

const ItemGridPurchaseReturn = ({ onRequisitionData, GrnItemData, item, SelectedPO }) => {
    const apiService = new ApiService();
    const [rows, setRows] = useState([{
        requisitionNo: '',
        srno: '',
        recordId: '',
        ItemNo: '',
        itemDescription: '',
        specification: '',
        balqty: '',
        uom: '',
        baseQty: '',
        baseAmount: '',
        amount: '',
        discount: '',
        vat: '',
        vatAmount: '',
        vatDec: '',
        conValue: '',
        expDate: ''
    }]);
    const [itms, setItems] = useState([])
    const totalAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
    const totalVatAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.vatAmount) || 0), 0)
    useEffect(() => {
        getItems();
    }, [GrnItemData]);
    const getItems = async () => {
        try {
            const response = await apiService.get('GET_PO');
            const itmsresponse = await apiService.get('GET_ITEM');
            setItems(itmsresponse.data.result)

            if (item) {
                const grnItemMap = new Map(GrnItemData.map(detail => [detail.itemCode, detail]));
                const formattedRows = item?.purchaseReturnDetails.map(detail => {
                    const grnDetail = grnItemMap.get(detail.itemCode);
                    const selectedPO = response?.data?.result?.find(req => req.pOrderNo === grnDetail?.pOrderNo);
                    let amount = detail.balQty * detail.unitPrice
                    const perAmount = (amount / 100) * detail?.vatPerc
                    let ExcludedAmount = amount + perAmount
                    debugger
                    if (grnDetail) {
                        return {
                            ItemNo: detail.itemCode,
                            itemDescription: detail.itemDescription,
                            qty: detail.qty,
                            uom: detail.uom,
                            specification: detail.specification,
                            balqty: grnDetail.balQty || detail.qty,
                            stock: 0,
                            conValue: detail.convToInvUOM,
                            baseAmount: grnDetail.baseAmount,
                            unitPrice: grnDetail.unitPrice,
                            amount: selectedPO.vatIncluded === "Included" ? amount : ExcludedAmount,
                            vat: detail.vatPerc,
                            vatAmount: perAmount,
                        };
                    }
                    return null;
                }).filter(row => row !== null);
                if (formattedRows.length > 0) {
                    setRows(formattedRows);
                    onRequisitionData(formattedRows);
                }
            } else {
                const formattedRows = GrnItemData?.map(detail => {
                    debugger
                    let selectedPO = SelectedPO?.value;
                    let selectedItem = itmsresponse.data.result?.find(req => req.itemNo === detail.itemCode);
                    if (selectedPO?.pOrderNo !== SelectedPO?.label) {
                        selectedPO = response?.data?.result?.find(req => req.pOrderNo === SelectedPO?.value.pOrderNo);
                    }
                    if (!selectedPO) {
                        return null;
                    }
                        const amount = detail.balQty * detail.unitPrice;
                    const perAmount = (amount / 100) * (detail?.vatPerc || 0);
                    const ExcludedAmount = amount + perAmount;
                    
                    return {
                        ItemNo: detail.itemCode,
                        itemDescription: detail.itemDescription,
                        balqty: detail.balQty || detail.qty,
                        stock: 0,
                        conValue: detail.convToInvUOM,
                        uom: detail.uom,
                        baseAmount: detail.amount,
                        specification: detail.specification,
                        unitPrice: detail.unitPrice,
                        amount: selectedPO.costCenter5 === "Included" ? amount : ExcludedAmount,
                        vat: detail.vatPerc || 0,
                        vatAmount: perAmount,
                        vatDec: detail?.vatDesc,
                    };
                }).filter(row => row !== null);
                if (formattedRows.length > 0) {
                    setRows(formattedRows);
                    onRequisitionData(formattedRows);
                }
            }

        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };
    const handleQtyChange = (index, value) => {
        const newQty = parseInt(value) || '';
        const balQty = rows[index].balqty;
        const unitPrice = rows[index].unitPrice;
        const vatPerc = rows[index].vat;
        const UOM = rows[index].conValue;
        const item = rows[index].itemDescription;
        if (newQty > balQty) {
            toast.warning('Enter a quantity less than or equal to the balance quantity.');
        } else {
            const amountBeforeVat = newQty * unitPrice;
            const vatAmount = (amountBeforeVat * vatPerc) / 100;
            let totalAmount;

            if (SelectedPO?.value.vatInclusive === true) {
                totalAmount = amountBeforeVat + vatAmount;
            } else {
                totalAmount = amountBeforeVat;
            }
            const selecteditem = itms?.find(rec => rec.itemDescription === item)

            setRows(prevRows => {
                const newRows = [...prevRows];
                newRows[index].qty = newQty;
                newRows[index].stock = newQty * selecteditem?.convToInvUOM || 0;
                newRows[index].amount = totalAmount;
                newRows[index].vatAmount = vatAmount;
                return newRows;
            });
        }
    };
    const handleDateChange = (index, expDate) => {
        const updatedRows = [...rows];
        updatedRows[index].expDate = expDate;
        const updatedSelectedRows = rows.map((row, i) =>
            i === index ? { ...row, expDate: expDate } : row
        );
        setRows(updatedSelectedRows);
        onRequisitionData(updatedSelectedRows);
    };
    return (
        <div>
            <div className='table-container p-2 mb-5' style={{ overflowX: 'auto', height: "400px" }}>
                <table>
                    <thead className='dynoTable' >
                        <tr>
                            <th>Sr no</th>
                            <th>Item No</th>
                            <th>Description</th>
                            <th>Item Specs</th>
                            <th>Qty</th>
                            <th>Packing UOM</th>
                            <th>Inventory Qty</th>
                            <th>Bal Qty</th>
                            <th>Unit Price</th>
                            <th>Discount</th>
                            <th>VAT %</th>
                            <th>VAT Amount</th>
                            <th>Amount</th>
                            <th>Exp Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows?.map((row, index) => (
                            <tr key={index}>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={index + 1} disabled style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.ItemNo} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.itemDescription} style={{ width: "150px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.specification} style={{ width: "150px" }} /></td>
                                <td className='m-0 p-0'>
                                    <input
                                        className='p-2' style={{ width: "100px" }}
                                        type="number" onWheel={(e) => e.target.blur()}
                                        value={row.qty}
                                        max={row.balqty}
                                        onChange={(e) => handleQtyChange(index, e.target.value)}
                                    />
                                </td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.uom} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.stock || 0} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.balqty || 0} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.unitPrice || 0} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.discount} disabled style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.vat} disabled style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.vatAmount} disabled style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.amount || 0} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type='date' value={row?.expDate}
                                    onChange={(e) => handleDateChange(index, e.target.value)} style={{ width: "130px" }} /></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/* <div className='d-flex justify-content-between boxs'>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Discount</div>
                    <div className='field'>---</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Amount</div>
                    <div className='field'>{totalAmount || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Discount</div>
                    <div className='field'>---</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Vat</div>
                    <div className='field'>{totalVatAmount || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Net Amount</div>
                    <div className='field'>{totalAmount || "---"}</div>
                </div>
            </div> */}
        </div>
    );
};

export default ItemGridPurchaseReturn;
