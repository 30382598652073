import React from 'react';
import Carousel from "react-elastic-carousel";
import P1 from '../../../../assets/images/P1.png';
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

const ImageSlider = ({ val }) => {
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 1, itemsToScroll: 2, pagination: false },
        { width: 850, itemsToShow: 2 },
        { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
        { width: 1450, itemsToShow: 4 },
        { width: 1750, itemsToShow: 5 },
    ]
    return (
        <Carousel loop breakPoints={breakPoints} className="" >
            <div className='imgBox'>
                <div>
                    <img src={val?.imageUrl === null || "" ? P1 :
                        IMAGE_URL + val?.imageUrl} className="img-fluid imgBox" />
                </div>
            </div>
        </Carousel >
    );
};

export default ImageSlider;
