import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import "../../pages/cashierMenu.css"
import ApiService from '../../../services/apiService';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUserId, getUserName } from '../../../../Redux/selecters';
import Select from 'react-select';
import { ClipLoader } from "react-spinners";
import { logout } from '../../../../Redux/actions';
import { useDispatch } from 'react-redux';
import { CashEnums } from '../../../../Enums/config';
import userManagement from '../../../services/userManagement';
import { generateUniqueVoucherNumber } from '../../../services/globalService';

const HandOverForm = ({ Currency }) => {

    let navigate = useNavigate();
    const location = useLocation();
    const [Currencies, setCurrency] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [noteQuantities, setNoteQuantities] = useState({});
    const [fillQuantities, setFillQuantities] = useState({});
    const [userIds, setUserId] = useState('');
    let currency = Currency.currency.name
    const userId = useSelector(getUserId);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const userName = useSelector(getUserName);
    const [Restaurants, setRestaurant] = useState([]);
    const [branches, setBranches] = useState([]);
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [emp, setEmp] = useState(null);
    useEffect(() => {
        GetCurrency()
        getEmployee()
    }, []);
    const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    useEffect(() => {
        const initialQuantities = {};
        separateAndCountValues(Currencies, currency)?.forEach(item => {
            initialQuantities[item.value] = item.count;
        });
        setQuantities(initialQuantities);
    }, [Currencies, currency]);

    const [formData, setFormData] = useState({
        userName: "",
        closingDate: getCurrentDateTime(), // Sets the current date in "YYYY-MM-DD" format
        amount: "",
        counter: { value: '', label: '' },
        shift: "",
        note: "",
        branchId: "",
        restaurant: "", // Corrected spelling from "restaurent" to "restaurant"
        digitalAmount: "",
    });

    const totalAmount = Object.keys(quantities).reduce((total, value) => {
        return total + (value * quantities[value]);
    }, 0);

    const totalCashAmount = Object.keys(noteQuantities).reduce((total, value) => {
        return total + (value * noteQuantities[value]);
    }, 0);

    const totalFills = Object.keys(fillQuantities).reduce((total, value) => {
        return total + (value * fillQuantities[value]);
    }, 0);

    const grandTotalAmount = ((parseFloat(formData?.digitalAmount || 0) + (totalAmount + totalCashAmount) + (totalFills / 100)).toFixed(2));
    const getEmployee = async () => {
        try {
            const apiService = new ApiService();
            let token = localStorage.getItem('Token')
            let param = `?UserId=${userId}`;
            const res = await apiService.getApiParamater('GET_EMPLOYEE', param);
            
            setEmp(res?.data?.result[0]);
        } catch (error) {
            console.error('Error fetching Employe:', error);
        }
    };

    const CreateShiftOpen = async () => {

        try {
            const now = new Date();
            const currentTime = `${now.getUTCHours().toString().padStart(2, '0')}:${now.getUTCMinutes().toString().padStart(2, '0')}:${now.getUTCSeconds().toString().padStart(2, '0')}`;

            const cashMeta = separateAndCountValues(Currencies, currency)?.map(item => {
                const coinQuantity = quantities[item.value] || 0;
                const noteQuantity = noteQuantities[item.value] || 0;
                const fillQuantity = fillQuantities[item.value] || 0;
                const totalQuantity = coinQuantity + noteQuantity + (fillQuantity);

                if (totalQuantity > 0) {
                    const type = coinQuantity > 0 ? 0 : (noteQuantity > 0 ? 1 : 2);

                    return {
                        currencyDenominationId: item.currencyDenominationId,
                        quantity: totalQuantity,
                        type: type,
                    };
                }
                return null;
            }).filter(item => item !== null); // Filter out null items

            const allQuantitiesZero = cashMeta.every(item => item.quantity === 0);
            const currentDatetime = new Date().toISOString();

            if (allQuantitiesZero) {
                toast.error("Please Enter Currency Denominations");
            } else if (!emp?.branchId) {
                toast.error("Please Enter Branch");
            } else if (!formData?.counter) {
                toast.error("Please Enter Counter Number");
            } else {
                const datareq = {
                    userId: userId,
                    // counterNumber: parseInt(formData?.counter.value),
                    branchId: parseInt(emp?.branchId),
                    amount: parseFloat(grandTotalAmount),
                    restaurantId: parseInt(emp?.restaurantId),
                    remarks: formData?.note || "",
                    digitalAmount: parseFloat(formData?.digitalAmount),
                    type: CashEnums.ShiftClose,
                    loggedInUserId: userId,
                    cashMeta: cashMeta,
                    date: currentDatetime,
                    time: currentTime,
                    voucherNo: generateUniqueVoucherNumber()

                }
                const apiService = new ApiService();
                debugger
                let res = await apiService.post("CREATE_CASH", datareq);
                if (res?.data?.statusCode === 201) {
                    dispatch(logout())
                    localStorage.clear()
                    navigate('/login')
                    window.location.reload(false);
                }
                else {
                    toast.error(res?.data?.message)
                    setLoading(false)
                }
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error('Error fetching Tax data:', error);
        }
    };
    const GetCurrency = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService.get('GET_CURRENCY');
            setCurrency(res?.data?.result);
        } catch (error) {
            console.error('Error fetching currency data:', error);
            toast.error('Error fetching currency data');
        }
    };
    const separateAndCountValues = (denominations) => {
        const filteredDenominations = denominations?.find(currency => currency.default === true)
        return filteredDenominations?.currencyDenomination?.map(denomination => {
            const count = denomination?.count || 0;
            return {
                currencyDenominationId: denomination?.currencyDenominationId,
                value: denomination?.value,
                count: count,
                type: denomination?.type
            };
        });
    };
    const incrementQuantity = (value, type) => {
        if (type === 0) { // Coin
            setQuantities(prevState => ({
                ...prevState,
                [value]: (prevState[value] || 0) + 1
            }));
        }
    };
    const decrementQuantity = (value, type) => {
        if (type === 0) { // Coin
            setQuantities(prevState => ({
                ...prevState,
                [value]: Math.max((prevState[value] || 0) - 1, 0)
            }));
        }
    };
    const incrementFillQuantity = (value, type) => {
        if (type === 2) { // Coin
            setFillQuantities(prevState => ({
                ...prevState,
                [value]: (prevState[value] || 0) + 1
            }));
        }
    };
    const decrementFillQuantity = (value, type) => {
        if (type === 2) { // Coin
            setFillQuantities(prevState => ({
                ...prevState,
                [value]: Math.max((prevState[value] || 0) - 1, 0)
            }));
        }
    };

    const incrementNoteQuantity = (value, type) => {
        if (type === 1) { // Note
            setNoteQuantities(prevState => ({
                ...prevState,
                [value]: (prevState[value] || 0) + 1
            }));
        }
    };

    const decrementNoteQuantity = (value, type) => {
        if (type === 1) { // Note
            setNoteQuantities(prevState => ({
                ...prevState,
                [value]: Math.max((prevState[value] || 0) - 1, 0)
            }));
        }
    };
    const handleEmployeChange = (selectedOption) => {
        
        setFormData({ ...formData, counter: selectedOption });
    };
    const handleInputChange = (e) => {
        
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const cashDenominations = separateAndCountValues(Currencies, currency)?.filter(item => item.type === 1);
    const coinDenominations = separateAndCountValues(Currencies, currency)?.filter(item => item.type === 0);
    const fillDenominations = separateAndCountValues(Currencies, currency)?.filter(item => item.type === 2);

    return (
        <div className='p-lg-5'>
            <div className='p-lg-5'>
                <div className='row' >
                    <div className='col-lg-4 col-sm-5'>
                        <form>
                            <div className='pb-lg-5'>
                                <div className="form-group row justify-content-between">
                                    <div className="col-md-6 col-lg-6">
                                        <label htmlFor="validationCustom01" className="form-label">User</label>
                                        <input
                                            type="text"
                                            className="p-2 form-control"
                                            id="validationCustom01"
                                            name="user"
                                            value={userName}
                                            disabled
                                            required
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="validationCustom11" className="form-label">Counter</label>
                                        <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                            type="number"
                                            id="validationCustom08"
                                            name="counter"
                                            value={formData.counter}
                                            placeholder="Select Counter"
                                            onChange={(selectedOption) => handleEmployeChange(selectedOption)} // Pass the entire object to the function
                                            options={emp?.counters?.map(count => ({ value: count?.counterId, label: count.counterName }))}
                                            required
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group row mt-3 justify-content-between'>
                                    <div className="col-md-6 col-lg-6">
                                        <label htmlFor="restaurantSelect" className="form-label">Restaurant</label>
                                        <input
                                            className="p-2 form-control"
                                            id="validationCustom08"
                                            name="restaurant"
                                            value={emp?.restaurantName}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="branchSelect" className="form-label">Branch</label>
                                        <input
                                            className="p-2 form-control"
                                            id="validationCustom08"
                                            name="branch"
                                            value={emp?.branchName}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form-group row mt-3 justify-content-between">
                                    <div className="col-md-6">
                                        <label htmlFor="validationCustom04" className="form-label">Closing Date</label>
                                        <input
                                            type="datetime-local"
                                            className="p-2 form-control"
                                            id="validationCustom04"
                                            name="closingDate"
                                            value={formData.closingDate}
                                            required
                                            onChange={handleInputChange}
                                        // disabled
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="validationCustom08" className="form-label">Total</label>
                                        <input
                                            type="text"
                                            className="p-2 form-control"
                                            id="validationCustom08"
                                            name="amount"
                                            value={grandTotalAmount}
                                            required
                                            disabled
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row mt-3 justify-content-between">

                                    <div className="col-md-6">
                                        <label htmlFor="validationCustom13" className="form-label">Digital Amount</label>
                                        <input
                                            type="number"
                                            className="p-2 form-control"
                                            id="validationCustom12"
                                            name="digitalAmount"
                                            value={formData.digitalAmount}
                                            onChange={handleInputChange}
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="validationCustom12" className="form-label">Remarks</label>
                                        <textarea
                                            type=""
                                            className="p-2 form-control"
                                            id="validationCustom12"
                                            name="note"
                                            value={formData.note}
                                            onChange={handleInputChange}
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-5'>
                                {loading ? (
                                    <div className="addItem-btn center">
                                        <ClipLoader loading={loading} size={30} color="#fff" />
                                    </div>
                                ) : (
                                    <div className='end'>
                                        <button className='addItem-btn' type="button" onClick={() => CreateShiftOpen()}>Save</button>
                                    </div>
                                )}
                            </div>
                        </form>
                    </div>
                    {cashDenominations?.length === 0 ? " " :
                        <div className='col'>
                            <div className=''>
                                <div>
                                    Cash Denominations
                                </div>
                                <div className='borderMain mt-3 p-4'>
                                    {cashDenominations?.map((item, index) => (
                                        <div className='row p-2 my-3 innerBorder' key={index}>
                                            <div className='col-3'>{item.value} X</div>
                                            <div className='col-6 d-flex justify-content-between'>
                                                <div className="vr"></div>
                                                <div className="quantity-control d-flex justify-content-around center">
                                                    <div onClick={() => decrementNoteQuantity(item.value, item?.type)} className="addbtn center">
                                                        -
                                                    </div>
                                                    <div className="center px-2">{noteQuantities[item.value] || 0}</div>
                                                    <div onClick={() => incrementNoteQuantity(item.value, item?.type)} className="addbtn center">
                                                        +
                                                    </div>
                                                </div>
                                                <div className="vr"></div>
                                            </div>
                                            <div className='col-3'>{item.value * (noteQuantities[item.value] || 0)}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
                    {coinDenominations?.length === 0 ? " " :
                        <div className='col'>
                            <div className=''>
                                <div>
                                    Coin Denominations
                                </div>
                                <div className='borderMain mt-3 p-4'>
                                    {coinDenominations?.map((item, index) => (
                                        <div className='row p-2 my-3 innerBorder' key={index}>
                                            <div className='col-3'>{item.value} X</div>
                                            <div className='col-6 d-flex justify-content-between'>
                                                <div className="vr"></div>
                                                <div className="quantity-control d-flex justify-content-around center">
                                                    <div onClick={() => decrementQuantity(item.value, item?.type)} className="addbtn center">
                                                        -
                                                    </div>
                                                    <div className="center px-2">{quantities[item.value] || 0}</div>
                                                    <div onClick={() => incrementQuantity(item.value, item?.type)} className="addbtn center">
                                                        +
                                                    </div>
                                                </div>
                                                <div className="vr"></div>
                                            </div>
                                            <div className='col-3'>{item.value * (quantities[item.value] || 0)}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
                    {fillDenominations?.length === 0 ? " " :
                        <div className='col'>
                            <div className=''>
                                <div>
                                    Fill Denominations
                                </div>
                                <div className='borderMain mt-3 p-4'>
                                    {fillDenominations?.map((item, index) => (
                                        <div className='row p-2 my-3 innerBorder' key={index}>
                                            <div className='col-3'>{item.value} X</div>
                                            <div className='col-6 d-flex justify-content-between'>
                                                <div className="vr"></div>
                                                <div className="quantity-control d-flex justify-content-around center">
                                                    <div onClick={() => decrementFillQuantity(item.value, item?.type)} className="addbtn center">
                                                        -
                                                    </div>
                                                    <div className="center px-2">{fillQuantities[item.value] || 0}</div>
                                                    <div onClick={() => incrementFillQuantity(item.value, item?.type)} className="addbtn center">
                                                        +
                                                    </div>
                                                </div>
                                                <div className="vr"></div>
                                            </div>
                                            <div className='col-3'>{item.value * (fillQuantities[item.value] || 0)}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(HandOverForm);