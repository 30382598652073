import React, { useState, useEffect } from 'react';
import './itemCard.css';
import "./receipt.css";
import "./bucket.css";
import { useNavigate } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import ApiService from '../../services/apiService';
import Select from 'react-select';

const CustomerCreatePOpup = ({ orderType, GetCostumer, setAddCustomer,close, selectedCustomer }) => {
    debugger
    let navigate = useNavigate();

    const [formData, setFormData] = useState({
        id: '',
        name: "",
        idNumber: "",
        city: "",
        gender: "",
        address: "",
        address01: "",
        phoneNumber: "",
        email: "",
        deliveryCharges: '',
        rider: ''
    });

    const [isEditable, setIsEditable] = useState(true); // Tracks if form fields are editable

    useEffect(() => {
        if (selectedCustomer) {
            // Autofill form when customer is selected and disable editing
            setFormData({
                id: selectedCustomer.id,
                name: selectedCustomer.name,
                idNumber: selectedCustomer.idNumber,
                city: selectedCustomer.city,
                gender: selectedCustomer.gender,
                address: selectedCustomer.address,
                address01: selectedCustomer.address01,
                phoneNumber: selectedCustomer.phoneNumber,
                email: selectedCustomer.email,
                deliveryCharges: selectedCustomer.deliveryCharges || '',
                rider: selectedCustomer.rider || ''
            });
            setIsEditable(false); // Disable form fields
        } else {
            // Make form editable when no customer is selected
            setIsEditable(true);
        }
    }, [selectedCustomer]);

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleAddCostumer = async () => {
        try {
            if (!formData?.name) {
                toast?.warning("Enter Customer Name");
            } else {
                debugger
                const body = {
                    customerCategoryId: 1,
                    address: formData?.address,
                    address2: formData?.address01,
                    status: true,
                    name: formData.name,
                    identityCardNumber: formData.idNumber,
                    mobileNumber: formData?.phoneNumber,
                    area: formData?.city,
                    gender: formData.gender,
                    phoneNo1: formData?.phoneNumber,
                    email: formData?.email,
                    enableLoyalty: true,
                    password:'1234567'
                };

                const apiService = new ApiService();
                let response = await apiService.post("ADD_COSTUMER", body);

                if (response.data.statusCode === 201) {
                    toast.success('Customer created successfully');
                    setAddCustomer(false);
                    GetCostumer();
                    close()
                } else if (response?.data?.statusCode === 400) {
                    toast.warning(response?.data?.message);
                    close()

                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleGenderChange = (selectedOption) => {
        setFormData({ ...formData, gender: selectedOption?.value });
    };

    const handleRiderChange = (selectedOption) => {
        setFormData({ ...formData, rider: selectedOption?.value });
    };

    return (
        <Modal.Body>
            <div>
                <h5 className='p-1'>{selectedCustomer ? 'Edit Customer' : 'Add New Customer'}</h5>
                <div className="form-group row py-4">
                    <div className="col-md-6">
                        <label htmlFor="validationCustom01" className="form-label">Name</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom01"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                            disabled={!isEditable} // Disable when customer is selected
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="validationCustom02" className="form-label">Phone</label>
                        <input
                            type="number"
                            className="p-2 form-control"
                            id="validationCustom02"
                            name="phoneNumber"
                            value={formData?.phoneNumber}
                            onChange={handleInputChange}
                            required
                            disabled={!isEditable}
                        />
                    </div>
                </div>

                {/* Email, City */}
                <div className="form-group row">
                    <div className="col-md-6">
                        <label htmlFor="validationCustom03" className="form-label">Email</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom03"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                            disabled={!isEditable}
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="validationCustom04" className="form-label">City</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom04"
                            name="city"
                            value={formData?.city}
                            onChange={handleInputChange}
                            required
                            disabled={!isEditable}
                        />
                    </div>
                </div>

                {/* Gender, ID */}
                <div className="form-group row py-4">
                    <div className="col-md-6">
                        <label htmlFor="validationCustom09" className="form-label">
                            Gender
                        </label>
                        <Select styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                color: state.isFocused ? '#000 ' : '#000',
                                ':hover': {
                                    backgroundColor: '#F5F5F5',
                                    color: '#000 ',
                                },
                            }),
                        }}
                            defaultValue={formData.gender}
                            onChange={handleGenderChange}
                            options={[
                                { value: 'Male', label: 'Male' },
                                { value: 'Female', label: 'Female' }
                            ]}
                            isDisabled={!isEditable}
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="validationCustom06" className="form-label">ID Number</label>
                        <input
                            type="number"
                            className="p-2 form-control"
                            id="validationCustom06"
                            name="idNumber"
                            value={formData?.idNumber}
                            onChange={handleInputChange}
                            required
                            disabled={!isEditable}
                        />
                    </div>
                </div>

                {/* Address 1, Address 2 */}
                <div className="form-group row">
                    <div className="col-md-6">
                        <label htmlFor="validationCustom07" className="form-label">Address 1</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom07"
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange}
                            required
                            disabled={!isEditable}
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="validationCustom08" className="form-label">Address 2</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom08"
                            name="address01"
                            value={formData?.address01}
                            onChange={handleInputChange}
                            required
                            disabled={!isEditable}
                        />
                    </div>
                </div>

                {orderType === 'Delivery' && (
                    <>
                        <div className="form-group row py-4">
                            <div className="col-md-6">
                                <label htmlFor="validationCustom06" className="form-label">Delivery Charges</label>
                                <input
                                    type="number"
                                    className="p-2 form-control"
                                    id="validationCustom06"
                                    name="deliveryCharges"
                                    value={formData?.deliveryCharges}
                                    onChange={handleInputChange}
                                    required
                                    disabled={!isEditable}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="validationCustom09" className="form-label">Rider</label>
                                <Select
                                    value={formData.rider ? { value: formData.rider, label: formData.rider } : null}
                                    onChange={handleRiderChange}
                                    options={[
                                        { value: 'Rider1', label: 'Rider1' },
                                        { value: 'Rider2', label: 'Rider2' }
                                    ]}
                                    isDisabled={!isEditable}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="validationCustom09" className="form-label">
                                Rider
                            </label>
                            <Select styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                    color: state.isFocused ? '#000 ' : '#000',
                                    ':hover': {
                                        backgroundColor: '#F5F5F5',
                                        color: '#000 ',
                                    },
                                }),
                            }}
                                defaultValue={formData.rider}
                                onChange={handleRiderChange}
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </div>
                    
                    </>
                )}
                    <div>
                        <button className='addItem-btn text-center mt-4' onClick={() => handleAddCostumer()}>Create</button>
                            
                        </div>
            </div>

        </Modal.Body >
    );
};

export default CustomerCreatePOpup;
