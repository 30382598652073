// actions/index.js
import { LOGIN, USER_LOGOUT, USER_ROLE } from './types';
import userManagement from '../../POS/services/userManagement';
import { getToken } from '../selecters';
import { useSelector } from 'react-redux';

export const saveLogin = (data) => ({ type: LOGIN, payload: data });

export const logout = () => ({ type: USER_LOGOUT, payload: null });


// Async action using Redux Thunk
export const setUserRole = () => {
  return (dispatch) => {    
    const token = localStorage.getItem('Token');

    if (token) {
      const role = userManagement.getUserRole(token);
      dispatch({ type: USER_ROLE, payload: role });
    }
  };
}
