import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import UserRoleGrid from './userRoleGrid';
const ManageUserRole = () => {

    let navigate = useNavigate();
    const location = useLocation();

    const [formData, setFormData] = useState({
        recordId: "",
        Category: "",
    });
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    return (
        <div className='p-lg-5'>
            <h4>Manage User Roles</h4>
            <form  >
                <div className='py-lg-5'>
                    <div className='brderbox p-lg-3'>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col-md-3 col-lg-3">
                                <label htmlFor="validationCustom01" className="form-label">Manage User Code</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom01"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <label htmlFor="validationCustom01" className="form-label">Manage User Roll</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom01"
                                    name="Category"
                                    value={formData.Category}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor="validationCustom01" className="form-label">(Type Module name)</label>
                            </div>
                            <div className='col-lg-2 center pt-4'>
                                <button className='mainbtn ' type="submit" >Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className='pt-3'>
                <UserRoleGrid />
            </div>
        </div>
    )
}
export default ManageUserRole