

import React from 'react';
import 'bootstrap'
import Banner1 from "../../assets/images/Banner-1.png"
import Banner2 from "../../assets/images/Banner-2.png"
import Banner3 from "../../assets/images/Banner-3.png"
import Banner4 from "../../assets/images/Banner-4.png"
const CostumerBanner = ({ val }) => {
    return (
        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src={Banner1} class="d-block w-100" alt="..." />
                </div>
                <div class="carousel-item">
                    <img src={Banner2} class="d-block w-100" alt="..." />
                </div>
                <div class="carousel-item">
                    <img src={Banner3} class="d-block w-100" alt="..." />
                </div>
                <div class="carousel-item">
                    <img src={Banner4} class="d-block w-100" alt="..." />
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    );
};

export default CostumerBanner;
