import React, { useEffect, useState } from 'react';
import Logo from '../../Assets/images/Qfinitylogos.png';
// import BinLogo from '../../Assets/images/pro360.png';
import BinLogo from '../../Assets/images/pro360.png';
import BlueLogo from '../../../POS/assets/images/blueCoco.png';
import YourLogo from "../../Assets/images/yourlogo.png";
import './login.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { IoMailOutline } from "react-icons/io5";
import ApiService from '../../../POS/services/apiService';
// import ApiServiceInv from '../../services/apiService';
import { saveLogin, setUserRole } from '../../../Redux/actions';
import { toast } from 'react-toastify';
import userManagement from '../../../POS/services/userManagement';
import { connection, createHubConnection, invokeLogin, isSignalRConnected } from '../../../POS/services/signalRService';
import { CashEnums, shiftEnums } from '../../../Enums/config';



const InventoryLogin = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const location = useLocation();
    const { state } = location;
    const apiService = new ApiService();
    const user = useSelector((state) => state)
    const [companies, setCompanies] = useState([]);
    const [showPassword, setShowPassword] = useState(false);

    const [formData, setFormData] = useState({
        emailOrPhone: '',
        Company: [],
        password: '',
    });
    useEffect(() => {
        getRoles();

        const handleReceiveMessage = (user, message) => {
            // Handle received message
            console.log(`Received message from ${user}: ${message}`);
        };

        const handleReceiveAck = (user, message) => {
            // Handle received acknowledgment
            console.log(`Received acknowledgment from ${user}: ${message}`);
        };

        const handleReceiveRejection = (user, message) => {
            // Handle received rejection
            console.log(`Received rejection from ${user}: ${message}`);
        };

        // Initialize SignalR connection
        const hubConnection = createHubConnection(
            handleReceiveMessage,
            handleReceiveAck,
            handleReceiveRejection
        );

        return () => {
            hubConnection.stop();
        };
    }, []);
    const getRoles = async () => {
        try {
            const CompanyRes = await apiService.get('GET_COMPANY');
            setCompanies(CompanyRes.data.result);
        } catch (error) {
            console.error('Error fetching User Roles:', error);
        }
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleCompanyChange = (selectedOption) => {
        setFormData({ ...formData, Company: selectedOption });
    };
    const GetCash = async () => {
        debugger
        const apiService = new ApiService();
        let token = localStorage.getItem('Token');
        let param1 = `?UserId=${userManagement.getUserId(token)}`;
        const res = await apiService.getApiParamater('GET_EMPLOYEE', param1)
        let emp = res?.data?.result[0]
        const params = `?UserId=${userManagement.getUserId(token)}&Date=${new Date().toISOString()}&ShiftNumber=${emp?.shiftNumber}&Type=${CashEnums?.ShiftOpen}&RestaurantId=${emp?.restaurantId}&BranchId=${emp?.branchId}`
        try {
            const response = await apiService.getApiParamater('GET_CASH', params);
debugger
            if (response.data.statusCode === 204) {
                navigate('/Cashier/ShiftOpen')
                window.location.reload(false);
            }
            else {
                navigate('/cashier/home')
                window.location.reload(false);
            }
        } catch (error) {

        }
    }
    const handleLogin = async () => {

        const apiService = new ApiService();
        // const apiServiceInv = new ApiServiceInv();
        let body = {
            email: formData.emailOrPhone,
            password: formData.password,
        };  
        try {
            const response = await apiService.post('USER_LOGIN', body);
            if (response && response.status === 200) {
                debugger
                dispatch(saveLogin(response?.data?.result))
                const userRole = userManagement.getUserRole(response?.data?.result?.token);
                dispatch(setUserRole(userRole))
                localStorage.setItem('Token', response?.data?.result?.token)
                const userId = userManagement.getUserId(response?.data?.result?.token);
                const InvUserId = userManagement?.getUserId2(response?.data?.result?.token);
                let param = `?User_Id=${InvUserId}`
                // const roleRes = await apiServiceInv.getApiParamater('GET_USER_ROLE', param);
                // localStorage.setItem('RoleAccess', JSON.stringify(roleRes?.data.result));
                if (userId) {
                    invokeLogin(userId);
                }
                debugger
                if (userRole == 'Admin' && response?.data?.result?.token) {
                    navigate('/home');
                    // navigate('/Inventory/Dashboard');
                    window.location.reload(false);
                } else if (userRole == 'Cashier') {
                    navigate('/cashier/home')

                    GetCash()
                } else if (userRole == 'Waiter') {
                    navigate('/Cashier/home');
                    window.location.reload(false);
                } else if (userRole == 'Customer') {

                    navigate('/customer/menu', { state: { state } });
                    window.location.reload(false)

                } else if (userRole == 'Inventory Manager') {
                    navigate('/Inventory/Dashboard');
                    window.location.reload(false);
                } else if (!userRole) {
                    toast.error("Incorrect User")
                }
            } else if (response && response.status === 400) {
                for (const error of response.data.errors) {
                    toast.error(error, {
                        position: 'top-right',
                        autoClose: 5000,
                    });
                }
            } else {
                toast.error(`Login failed: ${response ? response.data.message : 'Unknown error'}`, {
                    position: 'top-right',
                    autoClose: 5000,
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Unauthorized error
                toast.error('Unauthorized. Please check your credentials.', {
                    position: 'top-right',
                    autoClose: 5000,
                });
            } else {
                // Other errors
                toast.error('Login failed', {
                    position: 'top-right',
                    autoClose: 5000,
                });
            }
        }
    };
    return (
        <div className="container-fluid display-flex body m-0 p-0">
            <div className="row">
                <div className="col-lg-6 m-0 col-sm-12 col6-BG ">
                    <div className='inventoryBox'>
                        <div className="center">
                            {/* <img src={Logo} className="centrlogo" alt="Logo" /> */}
                            {/* <img src={YourLogo} className="centrlogo" style={{ height: "40px" }} alt="Logo" /> */}
                            <img src={BinLogo}  style={{height:'42px'}} className="centrlogo" alt="Logo" />
                            {/* <img src={BlueLogo} className="centrlogo" alt="Logo" /> */}
                        </div>
                        <div className="textin pt-2 center" >Point of Sale</div>
                    </div>
                </div>
                <div className="col-lg-6 m-0 p-5 col-sm-12">
                    <div className="center pt-5">
                        {/* <img src={YourLogo} className="centrlogo" style={{ height: "70px" }} alt="Logo" /> */}
                        {/* <img src={Logo} className="logo_size" alt="Logo" /> */}
                        <img src={BinLogo} className="logo_size" alt="Logo" />
                        {/* <img src={BlueLogo} className="logo_size" alt="Logo" /> */}
                    </div>
                    <div className="p-lg-5 p-sm-0 p-md-0 brderbox m-5">
                        <form className="">
                            <div className='lgintx center'>Login into your account</div>
                            <div className="form-group p-3">
                                <label className="pb-2 labels" htmlFor="emailOrPhone">
                                    Email or phone number
                                </label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control p-2"
                                        id="emailOrPhone"
                                        name="emailOrPhone"
                                        value={formData.emailOrPhone}
                                        onChange={handleChange}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <IoMailOutline />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group p-3">
                                <label className="pb-2 labels" htmlFor="password">
                                    Password
                                </label>
                                <div className="input-group">
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        className="form-control p-2"
                                        id="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            {showPassword ? (
                                                <FaEyeSlash onClick={togglePasswordVisibility} />
                                            ) : (
                                                <FaEye onClick={togglePasswordVisibility} />
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="form-group p-3">
                                <label className="pb-2 labels" htmlFor="password">
                                    Company
                                </label>
                                <Select
                                    value={formData?.Company}
                                    onChange={(selectedOption) => handleCompanyChange(selectedOption)}
                                    options={companies?.map((comp) => ({ value: comp, label: comp?.companyName }))}
                                />
                            </div> */}
                        </form>

                        <div className=" mt-5">
                            <div className="form-group p-3 d-flex justify-content-center mt-3 ">
                                <button
                                    onClick={handleLogin}
                                    className="btn Button_Normal center p-2 w-100"
                                >
                                    Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InventoryLogin;
