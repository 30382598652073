// store.js
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import userReducer from "./reducers/index";
import CurrencyReducer from "./reducers/currencyReducer";
import cartReducer from "./reducers/cartReducer";
import tableReducer from "./reducers/tableReducer";
import orderEditReducer from "./reducers/orderEditReducer";
import ComboReducer from "./reducers/comboReducer";
import employeReducer from "./reducers/employeReducer";
import rowsReducer from "./reducers/rowReducer";
import comparisonReducer from "./reducers/comparisonReducers";
import addOnsReducer from "./reducers/addOnReducer";
import rightReducer from "./reducers/rightReduser";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userAuth', 'Currency', 'cart', 'table', 'orderEdit', 'comboCart', 'addons', 'employeData', 'rows', 'comparison', 'userRights']
};

const appReducer = combineReducers({
  userAuth: userReducer,
  Currency: CurrencyReducer,
  cart: cartReducer,
  table: tableReducer,
  orderEdit: orderEditReducer,
  comboCart: ComboReducer,
  addons: addOnsReducer,
  employeData: employeReducer,
  rows: rowsReducer,
  comparison: comparisonReducer,
  userRights: rightReducer
});

const persistedReducer = persistReducer(persistConfig, appReducer);

export const configureReduxStore = () => {
  const store = configureStore({
    reducer: persistedReducer,
  });

  const persistor = persistStore(store);

  return { store, persistor };
};
