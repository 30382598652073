import React, { useState } from "react";
import './setting.css'
import Sidebox from "./SideBox";
import ApiService from "../../../services/apiService";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import userManagement from "../../../services/userManagement";

const PasswordReset = () => {
    const token = localStorage.getItem("Token");
    let user = userManagement.getUserName(token);

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        currentPassword: "",
        newPassword: "",
        email: user,
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };
    const UpdatePassword = async () => {
        setLoading(true)
        try {
            if (!formData?.email) toast.error("Please Enter Email")
            else if (!formData?.currentPassword) toast.error("Please Enter Current Password")
            else if (!formData?.newPassword) toast.error("Please Enter New Password")
            else {
                let body = {
                    email: formData?.email,
                    currentPassword: formData?.currentPassword,
                    newPassword: formData?.newPassword
                }
                const apiService = new ApiService();
                let res = await apiService.post('UPDATE_PASSWORD', body);

                if (res?.data?.statusCode == 200) {
                    toast?.success("Password Successfully Changed")
                    setLoading(false)
                    setFormData({
                        email: " ",
                        currentPassword: " ",
                        newPassword: " "
                    });
                } else if (res?.data?.statusCode === 400) {
                    toast?.error("Incorrect Username/Password")
                    setLoading(false)
                }
                setLoading(false)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error('Error posting data:', error);
        }
    };
    return (
        <div className='container'>
            <div className='pading-space pt-5'>
                <div className="pt-5 pb-5">
                    <div className="row">
                        <div className="col-3 pt-5"><Sidebox /></div>
                        <div className="col-9  px-5">
                            <div className="pb-4"><span className="settingtext">Change Your Password ?</span> </div>
                            <div className=''>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <label htmlFor="validationCustom01" className="form-label">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="validationCustom01"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                </div>
                                <div className='row py-3'>
                                    <div className='col-lg-6'>
                                        <label htmlFor="validationCustom02" className="form-label">
                                            Current password
                                        </label>
                                        <div className="input-group">
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                className="form-control"
                                                id="validationCustom02"
                                                min={0}
                                                name="currentPassword"
                                                value={formData.currentPassword}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <button className="btn btn-outline-secondary" type="button" onClick={togglePasswordVisibility}>
                                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                                            </button>
                                        </div>
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                </div>
                                <div className='row py-3'>
                                    <div className='col-lg-6'>
                                        <label htmlFor="validationCustom02" className="form-label">
                                            New password
                                        </label>
                                        <div className="input-group">
                                            <input
                                                type={showNewPassword ? "text" : "password"}
                                                className="form-control"
                                                id="validationCustom02"
                                                min={0}
                                                name="newPassword"
                                                value={formData.newPassword}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <button className="btn btn-outline-secondary" type="button" onClick={toggleNewPasswordVisibility}>
                                                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                                            </button>
                                        </div>
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-5 start">
                                    {loading ? (
                                        <div className="addItem-btn w-25">
                                            <ClipLoader loading={loading} size={30} color="#fff" />
                                        </div>
                                    ) : (
                                        <button className='addItem-btn w-25' onClick={() => UpdatePassword()}>Save</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        </div>
    );
}
export default PasswordReset;