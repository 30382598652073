import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./reports.css"
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { RiSkipLeftFill, RiSkipRightFill } from "react-icons/ri";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";
import { SlRefresh } from "react-icons/sl";
import { HiOutlinePrinter } from "react-icons/hi2";
import { PiDownloadSimple } from "react-icons/pi";
import moment from 'moment';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { BsFileEarmarkPdf, BsFiletypeCsv } from "react-icons/bs";
import { LiaFileExcel } from "react-icons/lia";

const GrnReports = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [StockType, setStockType] = useState();
    const [PoList, setPOlist] = useState();
    const [grnList, setGrnList] = useState();
    const [pageSize, setPageSize] = useState(20);
    const [Items, setItems] = useState([]);
    const [pagination, setPagination] = useState();
    const [toDate, setToDate] = useState();
    const [fromDate, setFromDate] = useState();
    const [dateFilter, setdateFilter] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [SupplierList, setSuppliers] = useState();

    const StatusFilter = [
        { value: '0', label: 'Pending' },
        { value: '1', label: 'Aproved' },
        { value: '2', label: 'Hold' },
        { value: '3', label: 'Rejected' },
    ];
    const gridRef = useRef();
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const [formData, setFormData] = useState({
        withoutPo: false,
        StockType: [{ label: 'Select Stock Type', value: '0' }],
        Status: [{ label: 'Filter By', value: '' }],
        GrnNo: [{ label: 'GRN No', value: '' }],
        PONo: [{ label: 'PO No', value: '' }],
        ItemNo: [{ label: 'Item No', value: '' }],
        ItemDescription: [{ label: 'Item Description', value: '' }],
        costcenter: '',
        SupplierName: [{ label: 'Select Supplier', value: '' }],
        SupplierId: [{ label: 'Select Supplier', value: '' }],
    });

    const handleClearFilters = () => {
        setFormData({
            withoutPo: false,
            StockType: [{ label: 'Select Stock Type', value: '0' }],
            Status: [{ label: 'Filter By', value: '' }],
            GrnNo: [{ label: 'GRN No', value: '' }],
            PONo: [{ label: 'PO No', value: '' }],
            ItemNo: [{ label: 'Item No', value: '' }],
            ItemDescription: [{ label: 'Item Description', value: '' }],
            costcenter: '',
            SupplierName: [{ label: 'Select Supplier', value: '' }],
            SupplierId: [{ label: 'Select Supplier Id', value: '' }],
        });
        setFromDate("")
        setToDate(" ")
        setdateFilter(false)
    };
    useEffect(() => {
        if (formData || pageSize) {
            getItems();
        }
        if (dateFilter === true) {
            getItems();
        } else {
            return
        }
    }, [pageSize, formData, dateFilter, toDate, fromDate]);
    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                getItems()
                const apiService = new ApiService();
                let param = `?isActive=true`
                const stockType = await apiService.getApiParamater("GET_STOCK_TYPE", param);
                const requsition = await apiService.getApiParamater("GET_PO", param);
                const grn = await apiService.getApiParamater("GET_GRN", param);
                const Items = await apiService.getApiParamater("GET_ITEM", param);
                const suppliers = await apiService.get('GET_SUPPLIER');
                const activeSupplier = suppliers?.data?.result.filter(res => res.exp4 === "1");
                setGrnList(grn.data.result)
                setSuppliers(activeSupplier);
                setStockType(stockType?.data?.result);
                setItems(Items?.data?.result);
                setPOlist(requsition?.data.result)
            } catch (error) {
                console.error('Error fetching dropdown data:', error);
            }
        };
        fetchDropdownData();
    }, []);
    const dateFormatter = (params) => {
        const date = moment.utc(params.value).local();
        return date.format("DD-MM-YYYY");
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    }
    const handleSupplierChange = (selectedOption) => {
        setFormData({ ...formData, SupplierName: selectedOption });
    }
    const handleSupplierIdChange = (selectedOption) => {
        setFormData({
            ...formData,
            SupplierId: { value: selectedOption.value, label: selectedOption.label },
            SupplierName: { value: selectedOption.value, label: selectedOption.value?.supplierName }
        });
    }
    const getItems = async (pageChange) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('GRNNo', formData.GrnNo.label || "");
            params.append('Status', formData.Status.value || "");
            params.append('ItemDescription', formData.ItemDescription.label || "");
            params.append('StockType', formData.StockType?.value?.stockTypeCode || "");
            params.append('POrderNo', formData.PONo.label || "");
            params.append('VendorId', formData.SupplierId.label || "");
            params.append('VendorName', formData.SupplierName.label || "");
            params.append('CostCenter3', formData.costcenter || "");
            params.append('ItemNo', formData.ItemNo.label || "");
            let newPageNo = pagination?.currentPage;

            if (pageChange === "next") {
                newPageNo += 1;
            } else if (pageChange === "prev") {
                newPageNo -= 1;
            } else if (pageChange === "skipNext") {
                newPageNo += 10;
            } else if (pageChange === "skipPrev") {
                newPageNo -= 10;
            }
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            if (dateFilter === true) {
                params.append('FromDate', fromDate || "");
                params.append('ToDate', toDate || "");
            }
            const res = await apiService.getApiParamater('GET_GRN_REPORT', `?${params.toString()}`);
            const modifiedData = res?.data?.result?.map((item, index) => ({
                ...item,
                recordId: `GRN-24-${item?.recordId?.toString().padStart(4, '0')}`
            }));
            setRowData(modifiedData || [])
            setPagination(res.data.paginationData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const Comparison = [
        { headerName: 'GRN No', field: 'grnNo', filter: true, floatingFilter: true, width: 140 },
        {
            headerName: 'Invoice Date', field: 'invoiceDate', filter: true, floatingFilter: true, width: 140, filter: 'agDateColumnFilter',
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Invoice No', field: 'invoiceNo', filter: true, floatingFilter: true, width: 130 },
        { headerName: 'Invoice Ref', field: 'invoiceRef', filter: true, floatingFilter: true, width: 130 },
        {
            headerName: 'PO No', field: 'pOrderNo', filter: true, floatingFilter: true, width: 110,
        },
        { headerName: 'Vendor Name', field: 'vendorName', filter: true, floatingFilter: true, width: 170 },
        {
            headerName: 'Delivery Date', field: 'delivery_Date', filter: true, floatingFilter: true, width: 140, filter: 'agDateColumnFilter',
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Currency Code', field: 'currencyCode', filter: true, floatingFilter: true, width: 150 },
        { headerName: 'Exchange Rate', field: 'exchangeRate', filter: true, floatingFilter: true, width: 150 },
        { headerName: 'Amount', field: 'amount', filter: true, floatingFilter: true, width: 110 },
        {
            headerName: 'Status', field: 'status', filter: true, floatingFilter: true, width: 180,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 2 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 3 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }
        },
    ];
    const handlePageSizeChange = (event) => {
        const newSize = Number(event.target.value);
        setPageSize(newSize);
    };
    const onBtnCSVExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: 'GrnReport.csv'
        });
    }, []);

    const onBtExcelExport = useCallback(() => {
        gridRef.current.api.exportDataAsExcel();
    }, []);

    const onBtPDFExport = useCallback(() => {
        const doc = new jsPDF();
        const rowData = [];
        const columnHeaders = [];
        const comparisonFields = [
            'recordId',
            'requestor',
            'requisitionDate',
            'costCenter1',
            'status',
            'itemCode',
            'itemDescription',
            'uom',
            'qty',
        ];
        const translateStatus = (status) => {
            switch (status) {
                case 1:
                    return 'Approved';
                case 0:
                    return 'Pending';
                case 2:
                    return 'Hold';
                case 3:
                    return 'Rejected';
                default:
                    return status;
            }
        };

        comparisonFields.forEach(field => {
            const header = Comparison.find(col => col.field === field).headerName;
            columnHeaders.push(header);
        });

        gridRef.current.api.forEachNode((node) => {
            const row = [];
            comparisonFields.forEach(field => {
                let value = node.data[field];
                if (field === 'status') {
                    value = translateStatus(value);
                }
                row.push(value);
            });
            rowData.push(row);
        });

        const poppinsFont = 'data:font/ttf;base64,AAEAAAARAQAABAAw7HA...';
        doc.addFileToVFS("Poppins-Regular.ttf", poppinsFont);
        doc.addFont("Poppins-Regular.ttf", "Poppins", "normal");
        doc.setFont("Poppins");
        doc.setFontSize(18);
        doc.setTextColor(40, 40, 40);
        doc.text("Purchase Requsition Report", 65, 12);
        doc.text("__________________________", 60, 15);
        doc.autoTable({
            head: [columnHeaders],
            body: rowData,
            styles: {
                fontSize: 6,
            },
            headStyles: {
                fillColor: [209, 126, 6],
                textColor: [255, 255, 255],
                fontSize: 6,
            },
            columnStyles: {
                0: { cellWidth: 20 },
                1: { cellWidth: 20 },
                2: { cellWidth: 20 },
                3: { cellWidth: 20 },
                4: { cellWidth: 15 },
                5: { cellWidth: 20 },
                6: { cellWidth: 20 },
                7: { cellWidth: 15 },
                8: { cellWidth: 20 },
            },
            startY: 20
        });

        doc.save('GrnReport.pdf');
    }, []);
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleFromDate = (e) => {
        setFromDate(e.target.value);
    };
    const handleToDate = (e) => {
        setToDate(e.target.value);
    };
    const handleDateFilterSelection = (e) => {
        setdateFilter(e);
    };
    const handleStockChange = (selectedOption) => {
        setFormData({ ...formData, StockType: selectedOption });
    }
    const handleGrnChange = (selectedOption) => {
        setFormData({ ...formData, GrnNo: selectedOption });
    }
    const handlePOChange = (selectedOption) => {
        setFormData({ ...formData, PONo: selectedOption });
    }
    const handleItemChange = (selectedOption) => {
        setFormData({ ...formData, ItemNo: selectedOption });
    }
    const handleDescriptionChange = (selectedOption) => {
        setFormData({ ...formData, ItemDescription: selectedOption });
    }
    const handleStatusFilter = (selectedOption) => {
        setFormData({ ...formData, Status: selectedOption });
    }
    return (
        <div className='p-5'>
            <div className='col pb-3 start'>
                <h4 className='start'>
                    GRN Report
                </h4>
            </div>
            <div className='pb-4'>
                <div className='d-flex gap-2'>
                    <div className='col-2'>
                        <Select
                            value={formData.SupplierId}
                            onChange={(selectedOption) => handleSupplierIdChange(selectedOption)}
                            options={SupplierList?.map((supl) => ({ value: supl, label: supl?.record_Id }))}
                        />
                    </div>
                    <div className='col-2'>
                        <Select
                            value={formData.SupplierName}
                            onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                            options={SupplierList?.map((sup) => ({ value: sup, label: sup?.supplierName }))}
                        />
                    </div>
                    <div className='col-2'>
                        <Select
                            value={formData.StockType}
                            onChange={(selectedOption) => handleStockChange(selectedOption)}
                            options={StockType?.map((Stock) => ({ value: Stock, label: Stock?.stockTypeName }))}
                        />
                    </div>
                    <div className='col-2'>
                        <Select
                            value={formData.Status}
                            options={StatusFilter}
                            onChange={handleStatusFilter}
                        />
                    </div>
                    <div className='col d-flex gap-2 jutify-content-between'>
                        <div >
                            <input
                                type='date'
                                className="form-control"
                                value={fromDate}
                                name="fromDate"
                                disabled={!dateFilter}
                                onChange={handleFromDate}
                                placeholder='From  Date '
                            />
                        </div>
                        <div className=''>
                            <input
                                type='date'
                                disabled={!dateFilter}
                                className="form-control"
                                placeholder='To  Date '
                                value={toDate}
                                name="toDate"
                                onChange={handleToDate}
                            />
                        </div>
                        <div className='gap-2 center'>
                            <input className="form-check-input"
                                type="checkbox"
                                id="dateFilter" style={{ width: "26px", height: "26px" }}
                                name="dateFilter"
                                checked={dateFilter}
                                onChange={(e) => handleDateFilterSelection(e.target.checked)} />
                            <label className=''>Date Filter</label>
                        </div>
                    </div>
                </div>
                <div className='d-flex gap-2 mt-2'>

                    <div className='col'>
                        <Select
                            value={formData.GrnNo}
                            onChange={(selectedOption) => handleGrnChange(selectedOption)}
                            options={grnList?.map((rec) => ({ value: rec, label: `${rec?.grnNo}` }))}
                        />
                    </div>
                    <div className='col'>
                        <Select
                            value={formData.PONo}
                            onChange={(selectedOption) => handlePOChange(selectedOption)}
                            options={PoList?.map((rec) => ({ value: rec, label: `${rec?.pOrderNo}` }))}
                        />
                    </div>
                    <div className='col'>
                        <Select
                            value={formData.ItemNo}
                            onChange={(selectedOption) => handleItemChange(selectedOption)}
                            options={Items?.map((rec) => ({ value: rec, label: rec?.itemNo }))}
                        />
                    </div>
                    <div className='col'>
                        <Select
                            value={formData.ItemDescription}
                            onChange={(selectedOption) => handleDescriptionChange(selectedOption)}
                            options={Items?.map((rec) => ({ value: rec, label: rec?.itemDescription }))}
                        />
                    </div>
                    <div className='col'>
                        <input
                            type='text'
                            className="form-control"
                            id="validationCustom02"
                            value={formData.costcenter}
                            name="costcenter"
                            onChange={handleInputChange}
                            placeholder='cost center 3'
                        />
                    </div>
                </div>
            </div>
            <div className='pageBodr'>
                <div className='row'>
                    <div className='col-6 d-flex gap-5'>
                        <div className='arrows d-flex gap-5'>
                            {pagination?.totalPages < 10 ?
                                <div className='center'><RiSkipLeftFill size={30} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><RiSkipLeftFill size={30} onClick={() => getItems("skipPrev")} /></div>
                            }
                            {pagination?.currentPage === 1 ?
                                <div className='center'><FaAngleLeft size={25} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><FaAngleLeft size={25} onClick={() => getItems("prev")} /></div>
                            }
                            <div className='d-flex gap-3 center'>
                                <div className=''>{pagination?.currentPage}</div>
                                <div className=''>of</div>
                                <div className=''>{pagination?.totalPages}</div>
                            </div>
                            {pagination?.currentPage === pagination?.totalPages ?
                                <div className='center'><FaAngleRight size={25} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><FaAngleRight size={25} onClick={() => getItems("next")} /></div>
                            }
                            {pagination?.totalPages > 10 ?
                                <div className='center'><RiSkipRightFill size={30} onClick={() => getItems("skipNext")} /></div> :
                                <div className='center'><RiSkipRightFill size={30} style={{ color: "#ABA3A3" }} /></div>
                            }
                        </div>
                        <div className="gap-4 d-flex">
                            <div className='center'>Page Size</div>
                            <div>
                                <select
                                    className="form-select pagclr"
                                    aria-label="Default select example"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                >
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 '>
                        <div className='end  d-flex gap-4'>
                            <div className='center' onClick={handleClearFilters}><SlRefresh size={30} className='repIcon' /></div>
                            <div className='center'>
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                                        <PiDownloadSimple size={30} className='repIcon' />
                                    </DropdownToggle>
                                    <DropdownMenu className='mt-3'>
                                        <DropdownItem onClick={onBtnCSVExport} className='gap-3 d-flex center'><BsFiletypeCsv /><div>Export CSV</div></DropdownItem>
                                        {/* <DropdownItem onClick={onBtExcelExport} className='gap-3 d-flex center'><LiaFileExcel/><div>Export Excel</div></DropdownItem> */}
                                        <DropdownItem onClick={onBtPDFExport} className='gap-3 d-flex center'><BsFileEarmarkPdf /><div>Export PDF</div></DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <div className='center'><HiOutlinePrinter size={30} className='repIcon' /></div>
                            <div className='center'><TfiEmail size={28} className='repIcon' /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='brdor'>
                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        rowSelection={'single'}
                        columnDefs={Comparison}
                        suppressExcelExport={true}
                        rowHeight={50}
                        ref={gridRef}
                    />
                </div>
            </div>
        </div>
    );
};
export default GrnReports; 