import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useState } from 'react';
import moment from 'moment/moment';
import "../dashboard.css"

const LatestOrder = ({ orders }) => {
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("MMM Do YYYY");
    };
    const mapOrderStatus = (orderStatus) => {
        switch (orderStatus) {
            case 0:
                return "New Order";
            case 1:
                return "Accepted";
            case 2:
                return "Preparing";
            case 3:
                return "Ready For Delivery";
            case 4:
                return "Ready For Pick up";
            case 5:
                return "Out For Delivery";
            case 6:
                return "Delivered";
            case 7:
                return "Cancelled";
            case 8:
                return "On-Hold";
            default:
                return "Unknown Order Status";
        }
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const mapOrderSource = (orderSource) => {
        switch (orderSource) {
            case 0:
                return "Website";
            case 1:
                return "Mobile App";
            case 2:
                return "Phone";
            case 3:
                return "In Person";
            case 4:
                return "Third Party Delivery";
            case 5:
                return "Cashier";
            case 6:
                return "Waiter";
            default:
                return "Unknown Order Source";
        }
    };
    const transformedOrders = orders.map(order => ({
        ...order,
        id: `ORD-${order.id}`,
        orderAmount: Number(order.orderAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }));
    const orderItemsRenderer = (params) => {
        const orderItems = params?.data?.orderItems;
        const itemNames = orderItems?.map((item) => item?.itemName).join(', ');
        return itemNames;
    };
    const [colDefs, setColDefs] = useState([
        { field: "id", minWidth: 50 },
        {
            field: "orderDate",
            valueFormatter: dateFormatter, // Custom value formatter for date
            comparator: dateComparator, // Custom comparator for date filtering
        },
        { field: "customerName" },
        {
            field: "orderSource",
            cellRenderer: (params) => mapOrderSource(params.value),
        },
        {
            field: "orderAmount",
            valueFormatter: (params) => params.value.toLocaleString(),
        },
        {
            field: "orderStatus",
            cellRenderer: (params) => mapOrderStatus(params.value),
            cellClassRules: {
                'custom-cooking': (params) => params.value === '0',
                'custom-delivery': (params) => params.value === '1',
                'custom-delivered': (params) => params.value === '2',
                'custom-ReadyForDelivery': (params) => params.value === '3',
                'custom-canceled': (params) => params.value === '4',
            },
        },
    ]);
    return (
        <div className='brdor  mt-3' style={{ }}>

        <div className="ag-theme-alpine" style={{ height: 389 }}>
            <AgGridReact
                rowData={transformedOrders}
                columnDefs={colDefs}
                enableFilter={false}
                floatingFilter={false}
                frameworkComponents={{ orderItemsRenderer }}

            />
        </div>
        </div>
    );
}
export default LatestOrder;