import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/apiService';
import Select from 'react-select';
import "./reports.css"
import { RiSkipLeftFill, RiSkipRightFill } from "react-icons/ri";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";
import { SlRefresh } from "react-icons/sl";
import { HiOutlinePrinter } from "react-icons/hi2";
import { PiDownloadSimple } from "react-icons/pi";
import moment from 'moment';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { BsFileEarmarkPdf, BsFiletypeCsv } from "react-icons/bs";
import { LiaFileExcel } from "react-icons/lia";

const PurchaseReturnReport = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [StockType, setStockType] = useState();
    const [grnList, setGrnList] = useState();
    const [pageSize, setPageSize] = useState(20);
    const [Items, setItems] = useState([]);
    const [pagination, setPagination] = useState();
    const [toDate, setToDate] = useState();
    const [fromDate, setFromDate] = useState();
    const [dateFilter, setdateFilter] = useState(false);
    const [SupplierList, setSuppliers] = useState();
    const gridRef = useRef();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const [formData, setFormData] = useState({
        Status: [{ label: 'Filter By', value: '' }],
        PR: [{ label: 'Select PR', value: 'Select Category' }],
        PONo: [{ label: 'Select Purchase Order', value: 'Select Category' }],
        GrnNo: [{ label: 'Select GRN', value: 'Select Sub Category' }],
        StockType: [{ label: 'Select Stock Type', value: 'Select Sub Category' }],
        VendorName: [{ label: 'Select Supplier', value: 'Select Sub Category' }],
        VendorId: [{ label: 'Select Supplier Id', value: 'Select Sub Category' }],
    });
    const StatusFilter = [
        { value: '0', label: 'Pending' },
        { value: '1', label: 'Aproved' },
        { value: '2', label: 'Hold' },
        { value: '3', label: 'Rejected' },
    ];
    let navigate = useNavigate();
    const staticOptions = [];

    useEffect(() => {
        getItems();
    }, [formData, pageSize]);
    useEffect(() => {
        getItems()
        const fetchDropdownData = async () => {
            try {
                const apiService = new ApiService();
                let param = `?isActive=true`
                const grn = await apiService.getApiParamater("GET_GRN", param);
                const Items = await apiService.getApiParamater("GET_P_RETURN", param);
                Items?.data?.result.sort((a, b) => b.recordId - a.recordId);
                grn?.data?.result.sort((a, b) => b.recordId - a.recordId);
                const suppliers = await apiService.get('GET_SUPPLIER');
                const activeSupplier = suppliers?.data?.result.filter(res => res.exp4 === "1");
                setGrnList(grn.data.result)
                setSuppliers(activeSupplier);
                setItems(Items?.data?.result);
            } catch (error) {
                console.error('Error fetching dropdown data:', error);
            }
        };
        fetchDropdownData();
    }, []);
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const dateFormatter = (params) => {
        const date = moment.utc(params.value).local();
        return date.format("DD-MM-YYYY");
    };
    const getItems = async (pageChange) => {
        try {
            debugger
            const apiService = new ApiService();
            const params = new URLSearchParams({
                Status: formData.Status.value || "",
                VendorName: formData.VendorName?.label || "",
                PRNo: formData.PR?.value?.prNo || "",
                GRNNo: formData.GrnNo?.label || "",
            });
            let newPageNo = pagination?.currentPage;
            if (pageChange === "next") {
                newPageNo += 1;
            } else if (pageChange === "prev") {
                newPageNo -= 1;
            } else if (pageChange === "skipNext") {
                newPageNo += 10;
            } else if (pageChange === "skipPrev") {
                newPageNo -= 10;
            }

            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            debugger
            const res = await apiService.getApiParamater('GET_PURCHASE_RETURN', `?${params.toString()}`);
            setRowData(res?.data?.result?.reverse() || []);
            setPagination(res.data.paginationData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const Comparison = [
        {
            headerName: 'PR NO', field: 'prNo', filter: true, floatingFilter: true, minWidth: 110
        },
        {
            headerName: 'GRN No ', field: 'grnNo', filter: true, floatingFilter: true, minWidth: 110
        },
        {
            headerName: 'Doc Date', field: 'delivery_Date', filter: true, floatingFilter: true, minWidth: 110,
            valueFormatter: dateFormatter,
            comparator: dateComparator
        },
        { headerName: 'Ref No', field: 'subCategory', filter: true, floatingFilter: true, minWidth: 110 },
        { headerName: 'Vendor Name', field: 'stockType', filter: true, floatingFilter: true, minWidth: 110 },
        {
            headerName: 'Status', field: 'status', filter: true, floatingFilter: true, width: 110,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<div>Pending</div>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<div>Approved</div>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<div>Hold</div>);
                }
                else if (params.value === 3 || params.value === null) {
                    return (<div>Rejected</div>);
                } else {
                    return params.value;
                }
            }
        },
        { headerName: 'Item Code', field: 'itemCode', filter: true, floatingFilter: true, minWidth: 110 },
        { headerName: 'Item Description', field: 'itemDescription', filter: true, floatingFilter: true, minWidth: 150 },
        { headerName: 'UOM', field: 'uom', filter: true, floatingFilter: true, minWidth: 110 },
        { headerName: 'QTY', field: 'qty', filter: true, floatingFilter: true, minWidth: 110 },
        { headerName: 'Unit Price', field: 'unitPrice', filter: true, floatingFilter: true, minWidth: 110 },
        { headerName: 'Vat Dec', field: 'vatDesc', filter: true, floatingFilter: true, minWidth: 110 },
        { headerName: 'Vat %', field: 'vatPerc', filter: true, floatingFilter: true, minWidth: 110 },
        { headerName: 'Vat Amount', field: 'vatAmount', filter: true, floatingFilter: true, minWidth: 110 },
        { headerName: 'Amount', field: 'amount', filter: true, floatingFilter: true, minWidth: 110 },
    ];
    const handleGrnChange = (selectedOption) => {
        setFormData({ ...formData, GrnNo: selectedOption });
    }
    const handlePRChange = (selectedOption) => {
        setFormData({ ...formData, PR: selectedOption });
    }
    const handleStatusFilter = (selectedOption) => {
        setFormData({ ...formData, Status: selectedOption });
    }
    const onBtnCSVExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: 'itemsReport.csv'
        });
    }, []);
    const onBtExcelExport = useCallback(() => {
        gridRef.current.api.exportDataAsExcel({
            fileName: 'itemsReport.xlsx'
        });
    }, []);
    const onBtPDFExport = useCallback(() => {
        const doc = new jsPDF();
        const rowData = [];
        const columnHeaders = [];
        const comparisonFields = ['prNo', 'grnNo', 'itemDescription', 'category', 'subCategory', 'stockType', 'unitMeasurement', 'warehouseLocation', 'purchasePrice'];

        Comparison.forEach(col => {
            columnHeaders.push(col.headerName);
        });

        gridRef.current.api.forEachNode((node) => {
            const row = [];
            comparisonFields.forEach(field => {
                row.push(node.data[field]);
            });
            rowData.push(row);
        });

        const poppinsFont = 'data:font/ttf;base64,AAEAAAARAQAABAAw7HA...';
        doc.addFileToVFS("Poppins-Regular.ttf", poppinsFont);
        doc.addFont("Poppins-Regular.ttf", "Poppins", "normal");
        doc.setFont("Poppins");
        doc.setFontSize(18);
        doc.setTextColor(40, 40, 40);
        doc.text("Item Master Report", 75, 12);
        doc.text("__________________________", 60, 15);
        doc.setLineWidth(2);
        doc.autoTable({
            head: [columnHeaders],
            body: rowData,
            styles: {
                fontSize: 6,
            },
            headStyles: {
                fillColor: [209, 126, 6],
                textColor: [255, 255, 255],
                fontSize: 6,
            },
            columnStyles: {
                0: { cellWidth: 19 },
                1: { cellWidth: 19 },
                2: { cellWidth: 19 },
                3: { cellWidth: 19 },
                4: { cellWidth: 19 },
                5: { cellWidth: 19 },
                6: { cellWidth: 19 },
                7: { cellWidth: 19 },
                8: { cellWidth: 15 },
                9: { cellWidth: 15 },
                10: { cellWidth: 15 }
            },
            startY: 20,
            theme: 'striped'
        });
        doc.save('itemsReport.pdf');
    }, []);
    const handlePageSizeChange = (event) => {
        const newSize = Number(event.target.value);
        setPageSize(newSize);
        gridRef.current.api.paginationSetPageSize(newSize);
    };
    const handleClearFilters = () => {
        setFormData({
            Status: [{ label: 'Filter By', value: '' }],
            PR: [{ label: 'Select PR', value: 'Select Category' }],
            PONo: [{ label: 'Select Purchase Order', value: 'Select Category' }],
            GrnNo: [{ label: 'Select GRN', value: 'Select Sub Category' }],
            VendorId: [{ label: 'Select Supplier Id', value: 'Select Sub Category' }],
            StockType: [{ label: 'Select Stock Type', value: 'Select Sub Category' }],
            VendorName: [{ label: 'Select Supplier', value: 'Select Sub Category' }],
        });
    };
    const handleSupplierChange = (selectedOption) => {
        setFormData({
            ...formData,
            VendorId: { value: selectedOption.value, label: selectedOption.value.record_Id },
            VendorName: { value: selectedOption.value, label: selectedOption.value?.supplierName }
        });
    }
    const handleSupplierIdChange = (selectedOption) => {
        setFormData({
            ...formData,
            VendorId: { value: selectedOption.value, label: selectedOption.label },
            VendorName: { value: selectedOption.value, label: selectedOption.value?.supplierName }
        });
    }
    return (
        <div className='p-5'>
            <div className='col'>
                <h4 className='py-3'>
                    Purchase Return Report
                </h4>
            </div>
            <div className=' justify-content-between d-flex pb-4 gap-3'>
                <div className='col'>
                    <Select
                        value={formData.VendorId}
                        onChange={(selectedOption) => handleSupplierIdChange(selectedOption)}
                        options={SupplierList?.map((sup) => ({ value: sup, label: sup?.record_Id }))}
                    />
                </div>
                <div className='col'>
                    <Select
                        value={formData.VendorName}
                        onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                        options={SupplierList?.map((sup) => ({ value: sup, label: sup?.supplierName }))}
                    />
                </div>
                <div className='col'>
                    <Select
                        value={formData.GrnNo}
                        onChange={(selectedOption) => handleGrnChange(selectedOption)}
                        options={grnList?.map((rec) => ({ value: rec, label: `${rec?.grnNo}` }))}
                    />
                </div>
                <div className='col'>
                    <Select
                        value={formData.Status}
                        options={StatusFilter}
                        onChange={handleStatusFilter}
                    />
                </div>
                <div className='col'>
                    <Select
                        value={formData.PR}
                        onChange={(selectedOption) => handlePRChange(selectedOption)}
                        options={Items?.map((rec) => ({ value: rec, label: `${rec?.prNo}` }))}
                    />
                </div>
            </div>
            <div className='pageBodr'>
                <div className='row'>
                    <div className='col-6 d-flex gap-5'>
                        <div className='arrows d-flex gap-5'>
                            {pagination?.totalPages < 10 ?
                                <div className='center'><RiSkipLeftFill size={30} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><RiSkipLeftFill size={30} onClick={() => getItems("skipPrev")} /></div>
                            }
                            {pagination?.currentPage === 1 ?
                                <div className='center'><FaAngleLeft size={25} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><FaAngleLeft size={25} onClick={() => getItems("prev")} /></div>
                            }
                            <div className='d-flex gap-3 center'>
                                <div className=''>{pagination?.currentPage}</div>
                                <div className=''>of</div>
                                <div className=''>{pagination?.totalPages}</div>
                            </div>
                            {pagination?.currentPage === pagination?.totalPages ?
                                <div className='center'><FaAngleRight size={25} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><FaAngleRight size={25} onClick={() => getItems("next")} /></div>
                            }
                            {pagination?.totalPages > 10 ?
                                <div className='center'><RiSkipRightFill size={30} onClick={() => getItems("skipNext")} /></div> :
                                <div className='center'><RiSkipRightFill size={30} style={{ color: "#ABA3A3" }} /></div>
                            }
                        </div>
                        <div className="gap-4 d-flex">
                            <div className='center'>Page Size</div>
                            <div>
                                <select
                                    className="form-select pagclr"
                                    aria-label="Default select example"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                >
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 '>
                        <div className='end arrows d-flex gap-4'>
                            <div className='center' onClick={handleClearFilters}><SlRefresh size={30} style={{ color: "5B93FF" }} /></div>
                            <div className='center'>
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                                        <PiDownloadSimple size={30} style={{ color: "5B93FF" }} />
                                    </DropdownToggle>
                                    <DropdownMenu className='mt-3'>
                                        <DropdownItem onClick={onBtnCSVExport} className='gap-3 d-flex center'><BsFiletypeCsv /><div>Export CSV</div></DropdownItem>
                                        {/* <DropdownItem onClick={onBtExcelExport} className='gap-3 d-flex center'><LiaFileExcel/><div>Export Excel</div></DropdownItem> */}
                                        <DropdownItem onClick={onBtPDFExport} className='gap-3 d-flex center'><BsFileEarmarkPdf /><div>Export PDF</div></DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <div className='center'><HiOutlinePrinter size={30} style={{ color: "5B93FF" }} /></div>
                            <div className='center'><TfiEmail size={28} style={{ color: "5B93FF" }} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='brdor'>
                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        rowSelection={'single'}
                        columnDefs={Comparison}
                        ref={gridRef}
                        suppressExcelExport={true}
                    />
                </div>
            </div>
        </div>
    );
};
export default PurchaseReturnReport; 