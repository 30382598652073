import { React, useState, useEffect } from 'react'
import ImageUpload from '../menus/addItems/imagUpload';
import "./master.css"
import ApiService from '../../../services/apiService';
import userManagement from '../../../services/userManagement';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { ReactComponent as Delete } from '../../../assets/images/svgIcons/Delete.svg';
import { BiEdit } from 'react-icons/bi';

const AddSubCategory = () => {
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [glAccount, setGlAccount] = useState([]);


    useEffect(() => {
        GetCategory()
        GetSubCategory()
        GetAccount()
    }, []);
    const [formData, setFormData] = useState({
        subcategory: "",
        category: "",
        glAccountCode: "",
        glAccountName: "",
    });
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const CreateSubCategory = async () => {
        let userId = userManagement.getUserId();
        if (!formData?.category) { toast.warning("Please Select Category") }
        else if (!formData?.subcategory) { toast.warning("Please Enter SubCategory Name") }
        else {
            try {
                let body = {
                    name: formData?.subcategory,
                    categoryId: formData?.category,
                    loggedInUserId: userId,
                    active: true,
                    glAccountCode: formData?.glAccountCode,
                    glAccountName: formData?.glAccountName,

                }
                const apiService = new ApiService();
                let res = await apiService.post('CREATE_SUBCATEGORY', body);

                if (res?.data?.statusCode == 201) {
                    toast?.success("Sub-Category Created Successfully")
                    GetSubCategory();
                    window.location.reload();
                }
                else if (res?.data?.statusCode == 208) {
                    toast?.warning(res?.data?.message);
                }
            } catch (error) {
                console.error('Error fetching category data:', error);
            }
        }
    };

    const GetCategory = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get('GET_CATEGORY');
            const filteredcategory = res?.data?.result?.filter(item => item?.active === true);
            setCategories(filteredcategory);
        } catch (error) {
            console.error('Error fetching category data:', error);
        }
    };
    const GetSubCategory = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get('GET_SUBCATEGORY');

            setSubCategories(res?.data?.result);
        } catch (error) {
            console.error('Error fetching Sub-category data:', error);
        }
    };
    const GetAccount = async () => {
        try {
            const apiService = new ApiService();
            let param = `?AccountType=Cash`
            let res = await apiService.get('GET_GL_ACCOUNT');

            setGlAccount(res?.data?.result);
        } catch (error) {
            console.error('Error fetching GL ACCOUNT data:', error);
        }
    };
    const handleCategoryChange = (selectedOption) => {
        const selectedCategory = selectedOption.value;
debugger
        if (selectedCategory?.glAccountName && selectedCategory?.glAccountCode) {
            setFormData({
                ...formData,
                category: selectedCategory.id,
                glAccountCode: selectedCategory.glAccountCode,
                glAccountName: selectedCategory.glAccountName,
            });
        } else {
            setFormData({
                ...formData,
                category: selectedCategory.id,
                glAccountCode: '',
                glAccountName: '',
            });
        }
    };
    const handleGLAccounts = (selectedOption) => {
        debugger
        setFormData({
            ...formData, glAccountCode: selectedOption?.value?.accountCode,
            glAccountName: selectedOption?.value?.accountName
        });

    }
    const deleteSubCat = async (id) => {
        try {
            const apiService = new ApiService();
            let body = {
                id: parseInt(id)
            }
            let res = await apiService.delete("DELETE_SUBCATEGORY", { data: body });
            if (res?.data?.statusCode === 204) {
                toast.success("SubCategory Deleted Successfully");
                GetSubCategory();
            }
        } catch (error) {
            console.error('Error deleting SubCategory:', error);
        }
    };
    const handleUpdatesubCategory = async (subCategory, newActiveStatus) => {
        try {
            const apiService = new ApiService();
            const catData = {
                id: subCategory?.id,
                categoryId: subCategory?.categoryId,
                name: subCategory?.name,
                loggedInUserId: subCategory?.loggedInUserId,
                active: newActiveStatus,
            };
            const res = await apiService.put(`UPDATE_SUBCATEGORY`, catData);
            if (res?.data?.statusCode === 200) {
                toast.success("SubCategory updated successfully!");
                GetSubCategory();
            } else {
                toast.error("Failed to update SubCategory. Please try again!");
            }
        } catch (error) {
            console.error('Error updating SubCategory:', error);
        }
    };

    const handleSwitchChange = async (subCategory, newActiveStatus) => {
        try {
            await handleUpdatesubCategory(subCategory, newActiveStatus);
        } catch (error) {
            console.error('Error updating SubCategory:', error);
        }
    };

    return (
        <div className='p-5 container'>
            <div className='catlist'>
                <div className='py-3 row '>
                    <div className="col">
                        <label htmlFor="validationCustom04" className="form-label">
                            Select category *
                        </label>
                        <Select styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                color: state.isFocused ? '#000 ' : '#000',
                                ':hover': {
                                    backgroundColor: '#F5F5F5',
                                    color: '#000 ',
                                },
                            }),
                        }}
                            defaultValue={formData.category}
                            onChange={handleCategoryChange}
                            options={categories?.map((category) => ({ value: category, label: category.name }))}
                        />
                        <div className="valid-feedback">Looks good!</div>
                    </div>
                    <div className="col">
                        <label htmlFor="validationCustom01" className="form-label">Type  Subcategory *</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom01"
                            name="subcategory"
                            value={formData.subcategory}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div className="col">
                        <label htmlFor="validationCustom04" className="form-label">
                            Select Gl Account *
                        </label>
                        <Select
                            styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                    color: state.isFocused ? '#000 ' : '#000',
                                    ':hover': {
                                        backgroundColor: '#F5F5F5',
                                        color: '#000 ',
                                    },
                                }),
                            }}
                            value={glAccount?.find(gl => gl.accountName === formData.glAccountName) ?
                                { value: glAccount?.find(gl => gl.accountName === formData.glAccountName), label: formData.glAccountName }
                                : null}
                            onChange={handleGLAccounts}
                            options={glAccount?.map((gl) => ({ value: gl, label: gl.accountName }))}
                        />
                        <div className="valid-feedback">Looks good!</div>
                    </div>
                </div>
                <div className='row  pt-3 '>
                    <div className=' end'><button className='addItem-btn' onClick={() => CreateSubCategory()}>Save</button></div>
                </div>
            </div>
            <div className="col-lg-4 mb-3 mt-5 pt-3">
                <input
                    type="Search"
                    className="form-control"
                    id="validationCustom01"
                    placeholder='Search subcategory'
                    // name="subcategory"
                    // value={formData.subcategory}
                    // onChange={handleInputChange}
                    required
                />
                <div className="valid-feedback">
                    Looks good!
                </div>
            </div>
            <div className='catlist p-5 table-responsive'>
                <table class="table table-striped mt-3">
                    <thead className=''>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Category</th>
                            <th scope="col">Subcategories</th>
                            <th scope="col">Account Name</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subCategories?.length === 0 ? (
                            <tr>
                                <td colSpan="3" className="text-center">No SubCategory found</td>
                            </tr>
                        ) : (
                            subCategories?.map((item, index) => (
                                <tr key={index}>
                                    <th scope='row'>{index + 1}</th>
                                    <td>{item.categoryName}</td>
                                    <td>{item?.name}</td>
                                    <td>{item?.glAccountName}</td>

                                    <td>
                                        <div className="d-flex text-center">
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id={`flexSwitchCheck${item.id}`}
                                                    checked={item.active}
                                                    onChange={(e) => handleSwitchChange(item, e.target.checked)}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AddSubCategory