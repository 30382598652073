import React, { useEffect, useState, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { FaEdit, FaEye } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/apiService';
import { MdOutlineEditOff } from "react-icons/md";

const BranchGrid = ({ fetchBranchData, rowData ,canEditMaster}) => {
    let navigate = useNavigate();

    useEffect(() => {
        fetchBranchData();
    }, []);
    const handleEdit = (data) => {
        navigate("/inventory/Master/Branch", { state: data })
    };
    const [colDefs, setColDefs] = useState([
        { headerName: "Branch Code", field: "branchCode", filter: true, floatingFilter: true, minWidth: 420 },
        { headerName: "Branch Name", field: "branchName", filter: true, floatingFilter: true, minWidth: 420 },
        { headerName: "Company Name", field: "companyId", filter: true, floatingFilter: true, minWidth: 415 },
        {
            headerName: "Action", field: "record_Id", filter: true, floatingFilter: true, minWidth: 320,
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                canEditMaster: canEditMaster,
                handleEdit: handleEdit,
                clicked: function (field, isActive) {
                    updateActiveStatus(field, isActive);
                },
            },
        },
    ])
    const updateActiveStatus = async (id, isActive) => {
        try {
            const apiService = new ApiService();
            let body = {
                recordId: id,
                isActive: isActive
            }
            const res = await apiService.put(`UPDATE_BRANCH`, body);
            if (res?.data?.statusCode === 201) {
                console.log('Updated successfully');
                fetchBranchData();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };

    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };
    return (
        <div className='brdor'>
            <div className="ag-theme-quartz" style={{ height: 500}}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={colDefs}
                    enableFilter={true}
                    floatingFilter={true}
                    pagination={false}
                    frameworkComponents={frameworkComponents}
                    suppressRowClickSelection={true}
                />
            </div>
        </div>
    )
}
class BtnCellRenderer extends Component {

    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this); // Bind edit button handler
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data); // Pass rowData to handleEdit function
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value, !this.props.data.isActive); // Toggle isActive value
    }

    render() {
        const { isActive } = this.props.data; // Extract isActive from rowData
        const { canEditMaster } = this.props;
        return (
            <div className='d-flex gap-3'>
                {canEditMaster === true ?
                    <div onClick={this.btnEditClickedHandler}><FaEdit size={25} /></div> :
                    <div><MdOutlineEditOff size={25} /></div>
                }
                {canEditMaster === true ?
                    <div className="form-check form-switch center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive}
                            onClick={this.btnClickedHandler}
                        />
                    </div>
                    :
                    <div className="form-check form-switch center">
                        <input disabled
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive}
                        />
                    </div>
                }
            </div>
            // <button onClick={this.btnClickedHandler} className="gridbtn">Delete</button>
        )
    }
}
export default BranchGrid;