import React, { useState, useEffect } from 'react';
import "../../../../Customer/Menu/NavBar/navBar.css";
import ApiService from '../../../../services/apiService';
import "../menuItemcard.css";
import { useNavigate } from 'react-router';
import "./catNav.css";

const CategoryNavBar = () => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [categorymenu, setCategoryMenu] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(null);

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const navigate = useNavigate();

    useEffect(() => {
        GetCategory();
    }, []);

    const GetCategory = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get('GET_CATEGORY');
            const filteredcategory = res?.data?.result?.filter(item => item?.active === true);

            setCategories(filteredcategory);
        } catch (error) {
            console.error('Error fetching category data:', error);
        }
    };

    const handleCategoryClick = async (selectedCategory) => {
        try {
            const apiService = new ApiService();
            let parameters = `?CategoryId=${selectedCategory}`;
            let res = await apiService.getApiParamater('GET_MENU', parameters);
            if (res?.data?.statusCode === 200) {
                setCategoryMenu(res?.data?.result);
                navigate('/admin/menu', { state: { categorymenu: res?.data?.result } });
            } else {
                console.error('Error fetching menu by category:', res?.data?.message);
            }
        } catch (error) {
            console.error('Error fetching menu by category:', error);
        }
    };

    const handleSubCategoryClick = async (selectedSubCat) => {
        try {
            const apiService = new ApiService();
            let parameters = `?SubCategoryId=${selectedSubCat}`;
            let res = await apiService.getApiParamater('GET_MENU', parameters);
            if (res?.data?.statusCode === 200) {
                setCategoryMenu(res?.data?.result);
                navigate('/admin/menu', { state: { categorymenu: res?.data?.result } });
            } else {
                console.error('Error fetching menu by category:', res?.data?.message);
            }
        } catch (error) {
            console.error('Error fetching menu by category:', error);
        }
    };

    const handlemenu = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get('GET_MENU');
            if (res?.data?.statusCode === 200) {
                setCategoryMenu(res?.data?.result);
                navigate('/admin/menu', { state: { categorymenu: res?.data?.result } });
            } else {
                console.error('Error fetching menu by category:', res?.data?.message);
            }
        } catch (error) {
            console.error('Error fetching menu by category:', error);
        }
        setSubCategories([]);
    };

    const GetSubCategory = async (categoryId) => {
        try {
            const apiService = new ApiService();
            let parameters = `?CategoryId=${categoryId}`;
            let res = await apiService.getApiParamater('GET_SUBCATEGORY', parameters);
            setSubCategories(res?.data?.result);
        } catch (error) {
            console.error('Error fetching subcategory data:', error);
        }
    };

    const handleCategoryToggle = (categoryId) => {
        if (isDropdownOpen === categoryId) {
            setIsDropdownOpen(null);
        } else {
            setIsDropdownOpen(categoryId);
            GetSubCategory(categoryId);
        }
    };

    const handleDocumentClick = (event) => {
        if (!event.target.closest('.nav-item')) {
            setIsDropdownOpen(null);
        }
    };

    return (
        <div className="maoin-BG">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <ul className="nav nav-pills overslid d-flex justify-content-between" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link ${!selectedCategory ? 'active' : ''}`}
                                    onClick={handlemenu}
                                    data-bs-toggle="pill"
                                    type="button"
                                    role="tab"
                                >
                                    All Items
                                </button>
                            </li>
                            {categories?.map((category, key) => (
                                <li className="nav-item" role="presentation" key={key}>
                                    <button
                                        className={`nav-link ${selectedCategory === category.id ? 'active' : ''}`}
                                        onClick={() => handleCategoryToggle(category.id)}
                                        id={`pills-${category.name}-tab`}
                                        data-bs-toggle="pill"
                                        data-bs-target={`#pills-${category.name.replace(/\s+/g, '-')}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`pills-${category.name}`}
                                        aria-selected={selectedCategory === category.id ? 'true' : 'false'}
                                    >
                                        {category.name}
                                    </button>
                                    {isDropdownOpen === category.id && (
                                        <div className="subcategories-dropdown">
                                            {subCategories?.map((subCategory, subKey) => (
                                                <div className='subcategories-dropdown-div' key={subKey}
                                                    onClick={() => handleSubCategoryClick(subCategory.id)}>{subCategory.name}</div>
                                            ))}
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryNavBar;
