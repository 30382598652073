import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import MessageGrid from '../purchaseRequisition/messageGrid';
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthorizationPurchaseReturn from './authorisation';
import ItemGridPurchaseReturn from './itemGrid';
import ApiService2 from '../../../POS/services/apiService';
import { useSelector } from 'react-redux';
import { getUserName } from '../../../Redux/selecters';
import { FaSave } from "react-icons/fa";
import { RiRefreshLine } from "react-icons/ri";

const CreatePurchaseReturn = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const apiService = new ApiService();
    const [messageData, setMessageData] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [ItemData, setItemData] = useState([]);
    const apiService2 = new ApiService2()
    const [POData, setPOData] = useState([]);
    const [usersDetails, setUser] = useState([]);
    const [Department, setDepartment] = useState([]);
    const [WareHouse, setWareHouse] = useState([]);
    const [SelectedWareHouse, setSelectedWareHouse] = useState([]);
    const [PurchaseOrder, setPurchaseOrder] = useState([]);
    const [SelectedPO, setSelectedPO] = useState([]);
    const [SelectedGrn, setSelectedGrn] = useState();
    const [SelectedSupplier, setSelectedSupplier] = useState([]);
    const [SelectedSupplierId, setSelectedSupplierID] = useState([]);
    const [GrnStatus, setGrnStatus] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [ReturnNo, setReturnNo] = useState();
    const location = useLocation();
    const [Userdata, setUserdata] = useState();
    const navigate = useNavigate();
    const [SupplierTab, setSupplierTab] = useState(false);
    const [PurchaseTab, setPurchaseTab] = useState(true);
    const [itemTab, setItemTab] = useState(true);
    const userName = useSelector(getUserName);
    const item = location.state;
    const currentDatetimeUTC = new Date().toISOString().slice(0, 16);
    const formatDateToDatetimeLocal = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    };
    useEffect(() => {
        fetchData()
        getApprovalUser()
        getCurrency()
    }, [])
    useEffect(() => {
        const item = location.state;
        const selectedGrn = PurchaseOrder?.find(dep => dep.grnNo === item?.grnNo)
        if (selectedGrn) {
            setSelectedPO({ label: selectedGrn?.grnNo, value: selectedGrn })
            setGrnStatus(selectedGrn.status)
            setSelectedSupplier({ label: selectedGrn.vendorName, value: selectedGrn })
            setSelectedSupplierID({ label: selectedGrn.vendorId, value: selectedGrn })
            setSelectedGrn(item?.pOrderNo)
            fetchGRNData({ label: `GRN-24-0${selectedGrn?.recordId}`, value: selectedGrn })
            setFormData({
                PRNo: item?.recordId,
                deliveryDate: formatDateToDatetimeLocal(item?.delivery_Date) || getCurrentLocalDatetime(),
                prNo: item?.prNo,
                InvoiceNo: item?.invoiceNo,
                location: item?.warehouse,
                PurchaseOrderDate: formatDateToDatetimeLocal(selectedGrn.docDate) || getCurrentLocalDatetime(),
                remarks: item?.remarks,
                currencyCode: item?.currencyCode,
            });
        }
    }, [location?.state, PurchaseOrder]);
    const fetchData = async () => {
        const department = await apiService.get("GET_DEPARTMENT_MASTER");
        setDepartment(department?.data?.result);
        const WareHouse = await apiService.get("GET_WAREHOUSE_MASTER");
        setWareHouse(WareHouse?.data?.result);
        const grn = await apiService.get("GET_GRN");
        const approvedPO = grn.data.result.filter(data => data.status === 1);
        setPurchaseOrder(approvedPO.reverse());
    }
    const getApprovalUser = async () => {
        try {
            const apiService = new ApiService();
            let docParam = `?TypeOfDoc=PR&DocId=PR`
            let DocId = await apiService.getApiParamater('GET_DOC_ID', docParam)
            setReturnNo(DocId?.data.result[0].docId);
            const res = await apiService2.get('GET_USERS');
            const selecteduser = res?.data?.result?.find(dep => dep.name === userName)
            setUserdata(selecteduser)
            let param = `?Type=${"PR"}&Company=${selecteduser.company}&Department=IT`;
            const response = await apiService.getApiParamater('GET_APPROVAL_USER_LIST', param);
            setUser(response.data.result);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')

    };
    const handleRequisitionData = (data) => {
        setItemData(data);
        console.log(data, 'reqData')
    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    };
    const [formData, setFormData] = useState({
        PRNo: "",
        prNo: "",
        grnStatus: "",
        remarks: "",
        InvoiceNo: "",
        refNo: "",
        InvoiceDate: getCurrentLocalDatetime(),
        deliveryDate: getCurrentLocalDatetime(),
        pOrderNo: "",
        TaxInvoice: "",
        Property: "",
        Department: "",
        Outlet: "",
        Currency: "",
        exchangeRate: "",
        deliveryPoint: "",
        PurchaseOrderDate: getCurrentLocalDatetime(),
        contactPerson: "",
        contatNo: "",
        taskCode: "",
        email: '',
        subcatname: "",
        location: ''
    });
    const handlePoChange = (selectedOption) => {
        setSelectedPO(selectedOption);
        setGrnStatus(selectedOption.value.status)
        setSelectedSupplier({ label: selectedOption.value.vendorName, value: selectedOption?.value })
        setSelectedSupplierID({ label: selectedOption.value.vendorId, value: selectedOption?.value })
        setSelectedGrn(selectedOption?.value?.pOrderNo)
        setFormData({ ...formData, PurchaseOrderDate: formatDateToDatetimeLocal(selectedOption.value.modifiedOn) });
        const selectedLocation = WareHouse?.find(dep => dep.description === selectedOption.value.warehouse)
        if (selectedLocation) {
            setSelectedWareHouse({ label: selectedLocation.description, value: selectedLocation })
        }
        // setPOData(selectedOption.value.grnDetails)
        fetchGRNData(selectedOption)
    };
    const fetchGRNData = async (selectedOption) => {
        let param = `?GRNNo=${selectedOption?.label}`
        const PO = await apiService.getApiParamater("GET_GRN_P_RETURN", param);
        if (PO.data.statusCode === 204) {
            toast.error(`No more Items in ${selectedOption?.label}`)
        } else {
            setPOData(PO.data.result)
        }
    }
    const handleLocationChange = (selectedOption) => {
        setSelectedWareHouse(selectedOption)
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'PurchaseOrderDate' || name === 'deliveryDate') {
            const dateParts = e.target.value.split("-");
            if (dateParts[0].length > 4) {
                return;
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const CreatePR = async () => {
        if (ItemData.length === 0) {
            toast.warning("Please add items");
            return;
        }
        if (!SelectedWareHouse) {
            toast.warning("Select Location")
            return
        }
        try {
            const currentDatetimeUTC = new Date().toISOString();
            const apiService = new ApiService()
            const totalAmount = ItemData.reduce((sum, row) => sum + (row?.qty * row.unitPrice || 0), 0);
            const baseAmount = ItemData.reduce((sum, row) => sum + (row?.balqty * row.unitPrice || 0), 0);
            const purchaseReturnDetailDtos = ItemData.map((item, index) => ({
                budgetCode: "string",
                batchNo: "string",
                expDate: formData?.deliveryDate,
                srno: index + 1,
                itemCode: item?.ItemNo,
                itemDescription: item.itemDescription,
                uom: item.uom,
                qty: parseInt(item.qty || "0"),
                baseUOM: item?.uom,
                baseQty: parseInt(item.balqty),
                specification: item.specification,
                convValue: parseInt(item?.baseQty),
                unitPrice: parseFloat(item.unitPrice),
                amount: parseFloat(item.amount),
                baseAmount: parseFloat(item.amount),
                convToInvUOM: parseFloat(item.conValue),
                vatPerc: parseFloat(item.vat),
                vatAmount: parseFloat(item.vatAmount),
                vatPerc: item?.vat,
                vatDesc: item?.vatDec,
                vatUnitPrice: item.vatAmount / item.qty,
                vatBaseAmount: item.vatAmount,
                prodDate: currentDatetimeUTC,
                isActive: true,
                psrno: index + 1,
                expDate: item.expDate,
            }));
            const userData = {
                prNo: ReturnNo,
                grnNo: SelectedPO?.value.grnNo,
                status: 0,
                invoiceRef: formData.InvoiceNo,
                pOrderNo: SelectedGrn,
                delivery_Date: formData?.deliveryDate,
                vendorId: SelectedSupplierId.label,
                vendorName: SelectedSupplier?.label,
                remarks: formData?.remarks,
                isActive: true,
                purchaseReturnDetailDtos: purchaseReturnDetailDtos,
                invoiceNo: formData.InvoiceNo,
                invoiceDate: SelectedPO?.value.InvoiceDate,
                currencyCode: SelectedCurrency.label,
                exchangeRate: SelectedCurrency?.value.conversionRate,
                baseAmount: totalAmount,
                amount: totalAmount,
                warehouse: SelectedWareHouse.label,
            };

            const response = await apiService.post("CREATE_P_RETURN", userData);
            if (response?.data?.statusCode === 201) {
                const userData2 = {
                    docType: "PR",
                    docId: ReturnNo,
                    department: "IT",
                    company: "PI",
                };
                const docBody = {
                    docId: "PR",
                    typeOfDoc: "PR",
                };
                const response2 = await apiService.post("CREATE_DOC_AUTHORIZATION", userData2);
                const docRes = await apiService.put("UPDATE_DOC_ID", docBody);
                toast.success(`${ReturnNo} Created successfully`);
                navigate("/inventory/PurchaseReturn")
            } else {
                toast.error(response.data.message)
            }
        } catch (error) {
            console.error("Error Creating PR:", error);
        }
    };
    const UpdatePR = async () => {
        if (ItemData.length === 0) {
            toast.warning("Please add items");
            return;
        }
        try {
            const currentDatetimeUTC = new Date().toISOString();
            const apiService = new ApiService()
            const totalAmount = ItemData.reduce((sum, row) => sum + (row?.qty * row.unitPrice || 0), 0);
            const baseAmount = ItemData.reduce((sum, row) => sum + (row?.balqty * row.unitPrice || 0), 0);
            const extractPO = (requisitionNo) => {
                return requisitionNo.replace('PO-24-', '').replace(/^0+/, '');
            };
            let cleanedRequisitionNo = extractPO(SelectedGrn);
            const purchaseReturnDetailDtos = ItemData.map((item, index) => ({
                budgetCode: "string",
                batchNo: "string",
                expDate: formData?.deliveryDate,
                srno: index + 1,
                itemCode: item?.ItemNo,
                itemDescription: item.itemDescription,
                uom: item.uom,
                qty: parseInt(item.qty || "0"),
                baseUOM: item?.uom,
                baseQty: parseInt(item.balqty),
                specification: item.specification,
                convValue: parseInt(item?.baseQty),
                unitPrice: parseInt(item.unitPrice),
                amount: parseInt(item.qty * item.unitPrice),
                baseAmount: parseInt(item.balqty * item.unitPrice),
                convToInvUOM: parseInt(item.baseQty),
                prodDate: currentDatetimeUTC,
                isActive: true
            }));

            const userData = {
                prNo: formData.prNo,
                grnNo: SelectedPO?.value.recordId.toString(),
                invoiceRef: formData.InvoiceNo,
                pOrderNo: cleanedRequisitionNo,
                delivery_Date: formData?.deliveryDate,
                vendorId: SelectedSupplierId.label,
                vendorName: SelectedSupplier?.label,
                baseAmount: baseAmount,
                remarks: formData?.remarks,
                isActive: true,
                purchaseReturnDetailDtos: purchaseReturnDetailDtos,
                invoiceNo: formData.InvoiceNo,
                invoiceDate: SelectedPO?.value.InvoiceDate,
                currencyCode: SelectedCurrency.label,
                exchangeRate: SelectedCurrency?.value.conversionRate,
                amount: totalAmount,
                warehouse: formData.location,
            };

            const response = await apiService.put("UPDATE_P_RETURN", userData);
            if (response?.data?.statusCode === 201) {
                navigate("/inventory/PurchaseReturn")
            }
        } catch (error) {
            console.error("Error Creating PR:", error);
        }
    };
    const getCurrency = async () => {
        let param = `?isActive=true`
        const apiService = new ApiService()
        let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
        setCurrency(res?.data?.result)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        setFormData({ ...formData, exchangeRate: filteredDenominations.conversionRate });
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
    }
    return (
        <div className='px-4 pt-3'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Purchase Return</h5>
                <div className='d-flex justify-content-end gap-3'>
                    <div>
                        {location.state ? (
                            <button className='mainbtn' onClick={UpdatePR}>Update</button>
                        ) : (
                            <button className='mainbtn' type="submit" onClick={CreatePR}>
                                <FaSave className='center mt-1 mx-2'/><div>Save</div>
                            </button>
                        )}
                    </div>
                    <button className='mainbtnRed'><RiRefreshLine className='center mt-1 mx-2'/><div>Reset</div></button>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setPurchaseTab(!PurchaseTab)}>
                    <div className='h6 pt-1'>GRN Details</div>
                </div>
                <div className={`slide-content ${PurchaseTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group justify-content-between row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="ReturnNo" className="form-label  pt-1 start">Purchase Return No</label>
                                <input
                                    className="form-Input w-70"
                                    value={ReturnNo}
                                    name='ReturnNo'
                                    disabled
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="catcode" className="form-label  pt-1 start">Date </label>
                                <input className='form-Input w-70' type='datetime-local' min={currentDatetimeUTC}
                                    name="deliveryDate"
                                    value={formData.deliveryDate}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">Location</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={SelectedWareHouse}
                                    onChange={handleLocationChange}
                                    options={WareHouse?.map((sup) => ({ value: sup, label: sup?.description }))}
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">GRN No *</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={SelectedPO}
                                    options={PurchaseOrder?.map((po) => ({ value: po, label: po?.grnNo }))}
                                    onChange={(selectedOption) => handlePoChange(selectedOption)}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="InvoiceNo" className="form-label  pt-1 start">Invoice Reg No</label>
                                <input
                                    type="number"
                                    className="form-Input w-70"
                                    id="InvoiceNo"
                                    value={formData.InvoiceNo}
                                    onChange={handleInputChange}
                                    name="InvoiceNo"
                                    onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="InvoiceDate" className="form-label  pt-1 start">Ref NO</label>
                                <input
                                    type="number"
                                    className="form-Input w-70"
                                    id="refNo"
                                    value={formData.refNo}
                                    onChange={handleInputChange}
                                    name="refNo"
                                    onWheel={(e) => e.target.blur()}
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="PurchaseOrderDate" className="form-label  pt-1 start">GRN Date</label>
                                <input
                                    type="datetime-local"
                                    className="form-Input w-70"
                                    id="PurchaseOrderDate"
                                    value={formData.PurchaseOrderDate}
                                    onChange={handleInputChange}
                                    name="PurchaseOrderDate"
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="pOrderNo" className="form-label  pt-1 start">POrder No</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="pOrderNo"
                                    value={SelectedGrn}
                                    name="pOrderNo"
                                    readOnly
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="remarks" className="form-label  pt-1 start">Remarks</label>
                                <input
                                    name="remarks"
                                    type='text'
                                    className="form-Input w-70"
                                    id="remarks"
                                    value={formData.remarks}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>Supplier Details</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="PropertyGroup" className="form-label  pt-1 start">Supplier</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={SelectedSupplier}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">Supplier ID</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={SelectedSupplierId}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Property" className="form-label  pt-1 start">Currency</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    isSearchable={false}
                                    value={SelectedCurrency}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="EXRate" className="form-label  pt-1 start">EX Rate</label>
                                <input
                                    type="number"
                                    className="form-Input w-70"
                                    disabled
                                    onWheel={(e) => e.target.blur()}
                                    id="exchangeRate"
                                    value={formData.exchangeRate}
                                    onChange={handleInputChange}
                                    name="exchangeRate"
                                />
                            </div>
                            <div className='col-1'></div>
                            <div className='col-2 brderbox center'>
                                <a href=''>Attachment</a>
                            </div>
                            <div className='col'></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`cost-center-section`} >
                <div className="mt-3 cursor-pointer slide-header">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Message
                        </div>
                    </div>
                </div>
            </div>
            <div className={`slide-content open`}>
                <div className={`grid-1 border-box2 p-3`}>
                    {gridVisibility[0] && (
                        <ItemGridPurchaseReturn onRequisitionData={handleRequisitionData} GrnItemData={POData} item={item} SelectedPO={SelectedPO} />
                    )}
                    {gridVisibility[1] && (
                        <AuthorizationPurchaseReturn handleAuthorization={handleAuthorization}
                            usersDetails={usersDetails}
                            setUser={setUser}
                            itemData={POData} />
                    )}
                    {gridVisibility[2] && (
                        <div className="grid-3 border">
                            <MessageGrid handleMessage={handleMessage} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CreatePurchaseReturn