
import React, { useEffect, useState } from 'react';
import './report.css';
import { Modal, Button } from 'react-bootstrap';
import ApiService from '../../../services/apiService';
import userManagement from '../../../services/userManagement';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const ReceiptModal = ({ isVisible, onClose }) => {
    const [receiptData, setData] = useState([]);

    useEffect(() => {
        GetReports();
    }, []);

    const handlePrint = () => {
        const printContents = document.getElementById('receipt-content').innerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload(); // Reload the page to restore original content
    };

    const handleSaveAsPDF = () => {
        const receipt = document.getElementById('receipt-content');
        html2canvas(receipt, { scale: 2 }).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('receipt.pdf');
        });
    };

    const GetReports = async () => {
        try {
            let date = new Date();
            let formattedDate = date.toISOString().split('T')[0];

            const apiService = new ApiService();
            let token = localStorage.getItem('Token');
            let UserId = userManagement.getUserId(token);
            let param = `?UserId=${UserId}&ShiftOpeningDate=${formattedDate}`;

            let res = await apiService.getApiParamater('GET_ORDER_XZREPORTS', param);
            setData(res?.data?.result);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Modal show={isVisible} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>X Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="receipt-container" id="receipt-content">
                    <div className="receipt-header">
                        <div className="receipt-logo">Logo</div>
                        <div className="receipt-date">{new Date().toLocaleDateString('en-GB')}</div>
                    </div>

                    <div className="section-title">SALES AND TAXES SUMMARY</div>
                    <hr />
                    <div className="receipt-row">
                        <span>Total Net Sales</span>
                        <span>{receiptData?.salesAndTaxSummery?.netSale?.toFixed(2) || '0.00'}</span>
                    </div>
                    <div className="receipt-row">
                        <span>Tax</span>
                        <span>{receiptData?.salesAndTaxSummery?.tax?.toFixed(2) || '0.00'}</span>
                    </div>
                    <div className="receipt-row">
                        <span>Total Sales</span>
                        <span>{receiptData?.salesAndTaxSummery?.totalSale?.toFixed(2) || '0.00'}</span>
                    </div>

                    <div className="section-title">SALES CATEGORIES</div>
                    <hr />
                    <div className="receipt-row header">
                        <span>Category</span>
                        <span>Qty</span>
                        <span>Net Sale</span>
                    </div>
                    {receiptData?.salesCategories?.map((item, index) => (
                        <div className="receipt-row" key={index}>
                            <span>{item?.category}</span>
                            <span>{item?.qty}</span>
                            <span>{item?.netSale?.toFixed(2)}</span>
                        </div>
                    ))}
                    <div className="receipt-row total">
                        <span>Total Net Sales</span>
                        <span>{receiptData?.salesCategories?.reduce((total, item) => total + item?.netSale, 0)?.toFixed(2) || '0.00'}</span>
                    </div>

                    <div className="section-title">PAYMENT DETAILS</div>
                    <hr />
                    {receiptData?.paymentDetails?.map((payment, index) => (
                        <div className="receipt-row" key={index}>
                            <span>{payment?.paymentMethod}</span>
                            <span>{payment?.totalAmount?.toFixed(2)}</span>
                        </div>
                    ))}
                    <div className="receipt-row total">
                        <span>Total Payments</span>
                        <span>{receiptData?.paymentDetails?.reduce((total, item) => total + item?.totalAmount, 0)?.toFixed(2) || '0.00'}</span>
                    </div>

                    <div className="section-title">RECEIPT DETAILS</div>
                    <hr />
                    <div className="receipt-row">
                        <span>Total Receipts</span>
                        <span>{receiptData?.reciptDetails?.totalReceipts || '0'}</span>
                    </div>
                    <div className="receipt-row">
                        <span>Duplicate Receipts</span>
                        <span>{receiptData?.reciptDetails?.duplicateReceipts || '0'}</span>
                    </div>
                    <div className="receipt-row">
                        <span>Invoice Sale</span>
                        <span>{receiptData?.reciptDetails?.invoiceSale?.toFixed(2) || '0.00'}</span>
                    </div>

                    <div className="section-title">CASH PAY IN/OUT</div>
                    <hr />
                    <div className="receipt-row">
                        <span>Pay In</span>
                        <span>{receiptData?.cashPayInPayOut?.payIn?.toFixed(2) || '0.00'}</span>
                    </div>
                    <div className="receipt-row">
                        <span>Pay Out</span>
                        <span>{receiptData?.cashPayInPayOut?.payOut?.toFixed(2) || '0.00'}</span>
                    </div>

                    <div className="section-title">DISCOUNTS DETAILS</div>
                    <hr />
                    {receiptData?.discountDetails?.map((discount, index) => (
                        <div className="receipt-row" key={index}>
                            <span>{discount?.discountName}</span>
                            <span>{discount?.discountAmount?.toFixed(2)}</span>
                        </div>
                    ))}

                    {/* Add more sections as necessary */}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleSaveAsPDF}>
                    Save as PDF
                </Button>
                <Button variant="primary" onClick={handlePrint}>
                    Print
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReceiptModal;
