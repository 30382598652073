import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { BiEdit } from 'react-icons/bi';
import ApiService from '../../../../services/apiService';
import "../master.css"; // Import CSS file for better structure

const UserRoleTable = (data) => {
    const [isActive, setIsActive] = useState(false);
    
    const toggleClaimStatus = async () => {
        try {
            const body = {
                rolesDtoInfo: {
                    id: data?.data?.id,
                    active: isActive
                }
            }
            const apiService = new ApiService();
            const res = await apiService.post(`UPDATE_ROLE`, body);
            if (res?.data?.statusCode === 200) {
                toast.success(`Claim ${isActive ? 'activated' : 'deactivated'} successfully`);
            }
        } catch (error) {
            console.error('Error toggling claim status:', error);
        }
    };
    const editUserRole = async (roleId) => {
        try {
            const body = {
                id: roleId,
            };
            const apiService = new ApiService();
            const res = await apiService.put(`UPDATE_ROLE`, body);

            if (res?.data?.statusCode === 200) {
                toast.success("Role updated successfully");
            }
        } catch (error) {
            console.error('Error updating role:', error);
        }
    };
    const handleSwitchChange = () => {
        setIsActive(!isActive);
        toggleClaimStatus()
    };
    return (
        <tr>
            <td>{data?.data?.name}</td>
            <td>
                <div className='action-icons d-flex gap-4 align-items-center'>
                    <BiEdit size={25} onClick={() => editUserRole(data.data?.id)} className='edit-icon' />
                    <div className="d-flex text-center gap-3">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={data?.data?.isActive}
                                onChange={handleSwitchChange}
                            />
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default UserRoleTable;
