// SidebarLayout.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import SideBar from './sideBar';
import "./sideBar.css"

const SidebarLayout = ({ children }) => {
  const location = useLocation();
  const isSidebarEnabled = location.pathname.startsWith('/home') || location.pathname.startsWith('/admin'); 

  return (
    <div className="sidebar-layout">
      <div className={`sidebar ${isSidebarEnabled ? 'fixed-left' : ''}`}>
        {isSidebarEnabled && <SideBar currentPath={location.pathname} />}
      </div>
      <main className="POS-main-content">{children}</main>
    </div>
  );
};

export default SidebarLayout;
