import React from "react";
import "../../assets/styles/homeStyle";
import "./pagination.css";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
const Pagination = ({ pagination, getItems }) => {
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const totalPageNumbers = Math.min(pagination?.totalPages, 4);
        let startPage = Math.max(pagination?.currentPage - Math.floor(totalPageNumbers / 2), 1);
        let endPage = startPage + totalPageNumbers - 1;

        if (endPage > pagination?.totalPages) {
            endPage = pagination?.totalPages;
            startPage = endPage - totalPageNumbers + 1;
            if (startPage < 1) {
                startPage = 1;
            }
        }

        // First Page
        if (startPage > 1) {
            pageNumbers.push(
                <div key={1} className={`page ${pagination?.currentPage === 1 ? "active" : ""}`} onClick={() => getItems(1)}>
                    1
                </div>
            );
            if (startPage > 2) {
                pageNumbers.push(<div key="ellipsis1" className="ellipsis">...</div>);
            }
        }

        // Middle Pages
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <div key={i} className={`page ${pagination?.currentPage === i ? "active" : ""}`} onClick={() => getItems(i)}>
                    {i}
                </div>
            );
        }

        // Last Page
        if (endPage < pagination?.totalPages) {
            if (endPage < pagination?.totalPages - 1) {
                pageNumbers.push(<div key="ellipsis2" className="ellipsis">...</div>);
            }
            pageNumbers.push(
                <div key={pagination?.totalPages} className={`page ${pagination?.currentPage === pagination?.totalPages ? "active" : ""}`} onClick={() => getItems(pagination?.totalPages)}>
                    {pagination?.totalPages}
                </div>
            );
        }

        return pageNumbers;
    };

    return (
        <div className='paginationbrdr'>
            <div className='pagination-container row'>
                <div className='col-8'>
                    <div className='pagination-controls gap-3 w-50'>
                        <button className={`prev ${pagination?.currentPage === 1 ? "disabled" : ""}`} onClick={() => getItems(pagination?.currentPage - 1)} disabled={pagination?.currentPage === 1}>
                            <FaChevronLeft />
                        </button>
                        <div className='page-numbers gap-3'>
                            {renderPageNumbers()}
                        </div>
                        <button className={`next ${pagination?.currentPage === pagination?.totalPages ? "disabled" : ""}`} onClick={() => getItems(pagination?.currentPage + 1)} disabled={pagination?.currentPage === pagination?.totalPages}>
                            <FaChevronRight />
                        </button>
                    </div>
                    {/* <div className='page-size'>
                    <span>Page Size</span>
                    <select className="form-select" value={pageSize} onChange={handlePageSizeChange}>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div> */}
                </div>
                <div className="col-4 end">
                    Total : {pagination?.totalRecords}
                </div>
            </div>
        </div>
    );
};

export default Pagination;