import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import ApiService2 from '../../../POS/services/apiService';
import { toast } from 'react-toastify';
import userManagement from '../../../POS/services/userManagement';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import ItemlistGrid from './itemsGrid';
import IssueAuthorization from './issueAuthorization';
import IssueMessageGrid from './IssueMessage';
import { ClipLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { getUserName } from '../../../Redux/selecters';

const IssueView = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const apiService = new ApiService();
    const [messageData, setMessageData] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [department, setDepartment] = useState([]);
    const [Location, setLocation] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [requisitionData, setRequisitionData] = useState([]);
    const [requisitionList, setRequisitionList] = useState([]);
    const [usersDetails, setUser] = useState([]);
    const [StockType, setStockType] = useState();
    const [userData, setUserdata] = useState([]);
    const [SelectedIssue, setSelectedIssue] = useState();
    const [loader, setLoader] = useState(false);
    const [itemNo, setItemNo] = useState();
    const userName = useSelector(getUserName);
    let navigate = useNavigate();
    const currentDatetimeUTC = new Date().toISOString();
    const location = useLocation();
    const itemData = location.state;

    const todayDate = new Date().toISOString().substr(0, 10);
    const [formData, setFormData] = useState({
        requisitionNo: "",
        itemType: "",
        quatotionNo: "",
        remarks: "",
        requestor: "",
        date: todayDate,
        requisitionDate: todayDate,
        deliveryDate: todayDate,
        currencyCode: "",
        exchangeRate: "",
        department: [],
        costCenter1: [],
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        description: "",
        fromLocation: [],
        toLocation: [],
        contactPerson: "",
        contatNo: "",
        taskCode: "",
        subcatname: "",
        refNo: "",
        StockType: ""
    });

    useEffect(() => {
        getUser()
        getLocation()
        getApprovalUser()
    }, [])
    useEffect(() => {
        const item = location.state;
        debugger
        if (item.docno) {
            const fetchData = async (item) => {
                const apiService = new ApiService();
                const res = await apiService.get('GET_ITEM_ISSUE');
                const selectedIssue = res.data.result?.find(itm => itm?.issueNo === item.docno)
                const selectedLocation = Location?.find(dep => dep?.description === selectedIssue?.warehouse)
                const selectedtoLocation = Location?.find(dep => dep?.description === selectedIssue?.divisionCode)
                debugger
                setSelectedIssue(selectedIssue)
                setFormData({
                    recordId: selectedIssue?.recordId,
                    requisitionDate: selectedIssue?.requisitionDate || todayDate,
                    date: selectedIssue.issueDate,
                    deliveryDate: selectedIssue?.deliveryDate ? selectedIssue.deliveryDate.substr(0, 10) : todayDate,
                    requisitionNo: selectedIssue?.requisitionNo,
                    description: selectedIssue?.description,
                    refNo: selectedIssue?.issueRefNo,
                    remarks: selectedIssue?.remarks,
                    requestor: selectedIssue?.requestor,
                    currencyCode: selectedIssue?.currencyCode,
                    costCenter2: selectedIssue?.costCenter2,
                    costCenter3: selectedIssue?.costCenter3,
                    costCenter4: selectedIssue?.costCenter4,
                    deliveryPoint: selectedIssue?.deliveryPoint,
                    contactPerson: selectedIssue?.contactPerson,
                    contatNo: selectedIssue?.contatNo,
                    taskCode: selectedIssue?.projectCode,
                    location: selectedIssue?.location,
                    department: selectedIssue.department,
                    fromLocation: { label: selectedLocation?.description, value: selectedLocation },
                    toLocation: { label: selectedtoLocation?.description, value: selectedtoLocation }
                });
            }
            fetchData(item)
        } else {
            const selectedLocation = Location?.find(dep => dep?.description === item?.warehouse)
            const selectedtoLocation = Location?.find(dep => dep?.description === item?.divisionCode)
            setFormData({
                recordId: item?.recordId,
                requisitionDate: item?.requisitionDate || todayDate,
                date: item.issueDate,
                deliveryDate: item?.deliveryDate ? item.deliveryDate.substr(0, 10) : todayDate,
                requisitionNo: item?.requisitionNo,
                description: item?.description,
                refNo: item?.issueRefNo,
                remarks: item?.remarks,
                requestor: item?.requestor,
                currencyCode: item?.currencyCode,
                costCenter2: item?.costCenter2,
                costCenter3: item?.costCenter3,
                costCenter4: item?.costCenter4,
                deliveryPoint: item?.deliveryPoint,
                contactPerson: item?.contactPerson,
                contatNo: item?.contatNo,
                taskCode: item?.projectCode,
                location: item?.location,
                department: item.department,
                fromLocation: { label: selectedLocation?.description, value: selectedLocation },
                toLocation: { label: selectedtoLocation?.description, value: selectedtoLocation }
            });
        }
        setItemNo(item.issueNo || item.docno)
    }, [location?.state, department, Location]);
    const getApprovalUser = async () => {
        try {
            let param = `?DocType=Requisition`;
            const response = await apiService.getApiParamater('GET_APPROVAL_USER', param);
            if (response?.data?.result && response.data.result.length > 0) {
                setUser(response.data.result); // Only set the first record
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getLocation = async () => {
        try {
            let param = `?isActive=true`
            let reqParam = `?ReqType=${"Store Request"}&Status=1`
            const CurRes = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
            const res = await apiService.getApiParamater('GET_WAREHOUSE_MASTER', param)
            const stockType = await apiService.getApiParamater("GET_STOCK_TYPE", param);
            const depRes = await apiService.getApiParamater('GET_DEPARTMENT_MASTER', param)
            const ReqRes = await apiService.getApiParamater('GET_PR', reqParam);
            setDepartment(depRes?.data?.result)
            setStockType(stockType?.data?.result);
            setRequisitionList(ReqRes.data.result)
            setLocation(res?.data?.result)
            const filteredDenominations = CurRes?.data?.result?.find(currency => currency?.isDefault === true);
            if (filteredDenominations) {
                setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
                setFormData({ ...formData, exchangeRate: filteredDenominations?.conversionRate });
            }
        } catch (error) {
            console.log(error)
        }
    }
    const getUser = async () => {
        try {
            const token = localStorage.getItem('Token');
            let userId = userManagement.getUserId(token)
            let Id = `?id=${userId}`
            const apiService2 = new ApiService2()
            let res = await apiService2.getApiParamater('GET_USERS', Id)
            setUserdata(res?.data?.result)
        } catch (error) {
            console.log(error)
        }

    }
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')
    };
    const handleRequisitionData = (data) => {
        setRequisitionData(data);
        console.log(data, 'reqData')
    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    };
    return (
        <div className='p-5'>
            <div className='h4'>Issue Items</div>
            <div className='brderbox p-lg-3 mb-5'>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="itemNo" className="form-label">Issue Note No</label>
                        <input className="form-control" disabled value={itemNo} name="itemNo" />
                    </div>
                    <div className="col">
                        <label className="form-label">Currency</label>
                        <Select isDisabled value={SelectedCurrency} />
                    </div>
                    <div className="col">
                        <label className="form-label">Exchange Rate</label>
                        <input disabled value={formData.exchangeRate} className="form-control" />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="subcatname" className="form-label">Requisition No</label>
                        <input disabled className="form-control" value={formData.requisitionNo} />
                    </div>
                    <div className="col">
                        <label htmlFor={formData.department} className="form-label">Department</label>
                        <input disabled className="form-control" value={formData.department} />
                    </div>
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Property Group</label>
                        <Select isDisabled />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="catcode" className="form-label">Date </label>
                        <input className='form-control' type='date' disabled defaultValue={new Date().toISOString().substr(0, 10)} />
                    </div>
                    <div className="col">
                        <label htmlFor="description" className="form-label">Description</label>
                        <input disabled value={formData.description} className="form-control" />
                    </div>
                    <div className="col">
                        <label htmlFor="costCenter1" className="form-label">Property</label>
                        <Select isDisabled value={formData.costCenter1} />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">From Location </label>
                        <Select isDisabled value={formData.fromLocation} />
                    </div>
                    <div className="col">
                        <label htmlFor="contactPerson" className="form-label">Requester</label>
                        <input disabled value={formData.contactPerson} className="form-control" />
                    </div>
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Department</label>
                        <Select isDisabled value={formData.department} />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">To Location</label>
                        <Select isDisabled value={formData.toLocation} />
                    </div>
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Issued By</label>
                        <Select isDisabled value={{ label: userName, value: userName }} />
                    </div>
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Outlets</label>
                        <Select isDisabled />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="refNo" className="form-label">Ref No</label>
                        <input disabled className="form-control" value={formData.refNo} />
                    </div>
                    <div className="col">
                        <label htmlFor="catcode" className="form-label">Request Date </label>
                        <input className='form-control' value={formData.requisitionDate} type='date' disabled defaultValue={new Date().toISOString().substr(0, 10)} />
                    </div>
                    <div className="col">
                        <label htmlFor="catcode" className="form-label">Delivery Date </label>
                        <input className='form-control' type='date' disabled defaultValue={new Date().toISOString().substr(0, 10)} />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Stock Type</label>
                        <Select isDisabled value={formData.StockType} />
                    </div>
                    <div className="col">
                        <label htmlFor="remarks" className="form-label">Remarks</label>
                        <textarea disabled className="form-control" value={formData.remarks} />
                    </div>
                    {itemData?.eye === true ?
                        <div className=' col-lg-4 mt-4 border p-3'><a href='' disabled>Attachment</a></div> :
                        <div className=' col-lg-4 mt-4 border p-3'><a href=''>Attachment</a></div>}
                </div>
            </div>
            <div className="topnav2 maon-BG ">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        <div className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`} onClick={() => handleTabClick(0)}>
                            Requisition
                        </div>
                        <div className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`} onClick={() => handleTabClick(1)}>
                            Authorization
                        </div>
                        <div className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`} onClick={() => handleTabClick(2)}>
                            Message
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-1 brderbox2 p-4 mb-3">
                {gridVisibility[0] && (
                    <ItemlistGrid onRequisitionData={handleRequisitionData} itemData={itemData} formData={formData} SelectedIssue={SelectedIssue}/>
                )}
                {gridVisibility[1] && (
                    <IssueAuthorization handleAuthorization={handleAuthorization} usersDetails={usersDetails} setUser={setUser} itemData={itemData} />
                )}
                {gridVisibility[2] && (
                    <div className="grid-3 border"><IssueMessageGrid handleMessage={handleMessage} /></div>
                )}
            </div>
        </div>
    )
}

export default IssueView