import React from 'react'
import { useState } from 'react'
import CostumerHeader from '../Header/header'
import SearchNav from '../Menu/searchNav'
import CartSlider from './imgSlider'
import "./cart.css"
import { AiOutlinePlus } from "react-icons/ai"
import { RiSubtractFill } from "react-icons/ri"
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom'
const AddtoCart = ({Currency}) => {

    let navigate = useNavigate();
    const [itemCount, setItemCount] = useState(0);

    const addItem = () => {
        setItemCount(itemCount + 1);
    };
    const subtractItem = () => {
        if (itemCount > 0) {
            setItemCount(itemCount - 1);
        }
    };
    const handlePayment = () => {
      navigate('/costumer/menu/Cart/Payment');
    }
    return (
        <div className=''>
            <CostumerHeader />
            <div className='container-fluid m-0 p-0 pt-5 px-5' >
                <SearchNav />
                <div className="px-lg-5 py-5">
                    <div className="row py-lg-3 px-lg-5">
                        <div className="col-lg-4 mbl-display">
                            <CartSlider />
                        </div>
                        <div className="col-lg-8 px-lg-5">
                            <div className="CartName">Tikka Meat Pizza</div>
                            <div className="cartPrice pt-2">RS: 1200</div>
                            <div className="py-5">
                                <div className="form-check d-flex justify-content-between pb-3">
                                    <div className="gap-3 d-flex">
                                        <input className="form-check-input" type="radio" name="flexRadioDisabled" id="PanPizza" checked
                                            style={{ width: "26.81px", height: "26.81px" }} />
                                        <label className="form-check-label checkItem" for="PanPizza">
                                            Pan Pizza
                                        </label>
                                    </div>
                                    <div className="checkItem">{Currency.currency.name} 100</div>
                                </div>
                                <div className="lineBreak"></div>
                                <div className="form-check d-flex justify-content-between py-3">
                                    <div className="gap-3 d-flex">
                                        <input className="form-check-input" type="radio" name="flexRadioDisabled" id="RegularPizza" checked
                                            style={{ width: "26.81px", height: "26.81px" }} />
                                        <label className="form-check-label checkItem" for="RegularPizza">
                                            Regular Pizza
                                        </label>
                                    </div>
                                    <div className="checkItem">{Currency.currency.name} 100</div>
                                </div>
                                <div className="lineBreak"></div>
                                <div className="form-check d-flex justify-content-between py-3">
                                    <div className="gap-3 d-flex">
                                        <input className="form-check-input" type="radio" name="flexRadioDisabled" id="LargePizza" checked
                                            style={{ width: "26.81px", height: "26.81px" }} />
                                        <label className="form-check-label checkItem" for="LargePizza">
                                            Large Pizza
                                        </label>
                                    </div>
                                    <div className="checkItem">{Currency.currency.name} 100</div>
                                </div>
                                <div className="lineBreak"></div>
                                <div className="form-check d-flex justify-content-between py-3">
                                    <div className="gap-3 d-flex">
                                        <input className="form-check-input "
                                            type="radio" name="flexRadioDisabled"
                                            id="JumboPizza" checked
                                            style={{ width: "26.81px", height: "26.81px" }} />
                                        <label className="form-check-label checkItem" for="JumboPizza">
                                            Jumbo Pizza
                                        </label>
                                    </div>
                                    <div className="checkItem">{Currency.currency.name} 100</div>
                                </div>
                                <div className="lineBreak"></div>
                            </div>
                            <div className="form-group row ">
                                <label className="special pb-3">Special Instructions</label>
                                <br />
                                <textarea rows="4" cols="100" className="textarea" name="Description" form="usrform"
                                    placeHolder="Enter the instructions about this item if any."></textarea>
                            </div>
                            <div className='row'>
                                <div className='d-flex col-lg-3  pt-3'>
                                    <button onClick={addItem} className='addItem'><AiOutlinePlus /></button>
                                    <h2 className='px-3'>{itemCount}</h2>
                                    <button onClick={subtractItem} className='SubItem'><RiSubtractFill /></button>
                                </div>
                                <div className='col-lg-9  pt-3'>
                                    <button onClick={handlePayment} className='w-100 AddBtn'> Checkout - Rs:1000</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(AddtoCart);