import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import "../priceComp.css"
import QuotationGrid from '../../purchaseRequisition/quotationList';
import ApiService from '../../../services/apiService';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RxCross2 } from "react-icons/rx";
import moment from 'moment';
import { FaAngleLeft } from "react-icons/fa6";
import { ClipLoader } from 'react-spinners';
import { FaSave } from "react-icons/fa";

const PriceCompView = () => {
    const [aprovaluser, setaprovaluser] = useState([]);
    const location = useLocation();
    const CompareData = location.state;
    const [suppliersTotals, setSuppliersTotals] = useState({});
    const [total, setTotal] = useState(0);
    const [quotationData, setQuotationData] = useState([]);
    const [suppliers, setSupplier] = useState([]);
    let navigate = useNavigate();
    const [payments, setPayments] = useState([]);
    const [rows, setRows] = useState([]);
    const [formData, setFormData] = useState({
        QuoteNo: "",
        RequisitionNo: "",
        remarks: "",
        costCenter1: "",
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        quotation: [
            { refNo: "", paymentTerms: "", supplier: "", remarks: "" },
            { refNo: "", paymentTerms: "", supplier: "", remarks: "" }
        ]
    });
    const [RequisitionNo, setRequisitionNo] = useState();
    const [QuotationNo, setQuotationNo] = useState();
    const [status, setStatus] = useState();
    const apiService = new ApiService()
    const [SupplierTab, setSupplierTab] = useState(true);
    const [CostTab, setCostTab] = useState(false);
    useEffect(() => {
        getApprovalUser()
        fetchSuplier()
        fetchPayment()
    }, []);
    const fetchSuplier = async () => {
        try {
            const apiService = new ApiService();
            const response = await apiService.get('GET_SUPPLIER');
            const activeData = response?.data?.result.filter(res => res.isActive === true);
            setSupplier(activeData);
        } catch (error) {
            console.error("Error fetching Currency data:", error);
        }
    };
    const fetchPayment = async () => {
        try {
            const apiService = new ApiService();
            const response = await apiService.get('GET_EPAYMENT');
            // const activeData = response?.data?.result.filter(res => res.isActive === true);
            setPayments(response?.data?.result);
        } catch (error) {
            console.error("Error fetching Currency data:", error);
        }
    };
    useEffect(() => {
        debugger
        if (CompareData.doctype === "Price Comparison") {
            const fetchCompareData = async () => {
                try {
                    const pcresponse = await apiService.get('GET_PC');
                    debugger
                    const selectedPc = pcresponse.data.result?.find(rec => rec.pcNo === CompareData.docno)
                    setRequisitionNo(selectedPc?.requisitionNo);
                    setQuotationNo(CompareData?.docno)
                    const response = await apiService.get('GET_PR');
                    const data = selectedPc;
                    const selectedPR = response.data.result?.find(rec => rec.requisitionNo === data.requisitionNo)
                    if (selectedPR?.prDetails) {
                        const formattedRows = selectedPR.prDetails.map(detail => ({
                            srno: detail.srno,
                            ItemNo: detail.itemCode,
                            recordId: detail.itemCode,
                            comparisonStatus: data.status,
                            itemDescription: detail.itemDescription,
                            Specs: detail.specification,
                            qty: detail.qty,
                            uom: detail.uom,
                            baseQty: detail.baseQty,
                            baseAmount: detail.baseAmount,
                            amount: detail.amount,
                            deliveryDate: detail.deliveryDate,
                            bol: '',
                            quotations: [{
                                itemCode: detail.itemCode,
                                qty: detail.qty,
                                unitPrice: "",
                                amount: "",
                                supplier: "",
                                paymentTerms: ""
                            },
                            {
                                itemCode: detail.itemCode,
                                qty: detail.qty,
                                unitPrice: "",
                                amount: "",
                                supplier: "",
                                paymentTerms: ""
                            }]
                        }));
                        setRows(formattedRows);
                    }
                    setQuotationData(data)
                    const transformedRows = data.priceComparisonDetails.map(item => {
                        const quotations = [];
                        for (let i = 1; i <= 6; i++) {
                            let supplier = item[`supp${i}`].trim();
                            if (item[`price${i}`] && item[`price${i}`] !== 0 && supplier !== "string") {
                                quotations.push({
                                    itemCode: item.itemCode,
                                    qty: item.qty,
                                    selected: item[`selected${i}`],
                                    unitPrice: item[`price${i}`],
                                    amount: item.qty * item[`price${i}`],
                                    supplier: supplier,
                                    paymentTerms: item[`paymentTerms${i}`],
                                    refNo: item[`ref${i}`],
                                    remarks: item[`remarks${i}`]
                                });
                            }
                        }
                        return {
                            srno: item.srno,
                            ItemNo: item.itemCode,
                            itemDescription: item.itemDescription,
                            Specs: item.specification,
                            qty: item.qty,
                            uom: item.uom,
                            baseQty: item.qty,
                            baseAmount: item.qty * item.price1,
                            amount: item.qty * item.price1,
                            Delivery: "",
                            bol: '',
                            quotations: quotations
                        };
                    });
                    debugger
                    const transformedFormData = {
                        QuoteNo: data.quoteNo,
                        RequisitionNo: data.requisitionNo,
                        remarks: "",
                        costCenter1: selectedPc.costCenter1,
                        costCenter2: selectedPc.costCenter2,
                        costCenter3: selectedPc.costCenter3,
                        costCenter4: selectedPc.costCenter4,
                        quotation: []
                    };
                    const selectedQuotationsMap = new Map();
                    transformedRows.forEach(row => {
                        row.quotations.forEach(quote => {
                            const key = `${quote.itemCode}-${quote.supplier.trim()}`;
                            if (!selectedQuotationsMap.has(key) || quote.selected === 1) {
                                selectedQuotationsMap.set(key, quote);
                            }
                        });
                    });
                    let quotations = Array.from(selectedQuotationsMap.values());
                    const supplierSet = new Set();
                    const filteredQuotations = [];
                    quotations.forEach(quote => {
                        if (!supplierSet.has(quote.supplier.trim())) {
                            supplierSet.add(quote.supplier.trim());
                            filteredQuotations.push(quote);
                        }
                    });
                    transformedFormData.quotation = filteredQuotations;
                    setFormData(transformedFormData);
                    setRows(transformedRows);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
            fetchCompareData();
        } else if (CompareData.doctype === "Comparison") {
            const fetchCompareData = async () => {
                try {
                    const pcresponse = await apiService.get('GET_PC');
                    const selectedPc = pcresponse.data.result?.find(rec => rec.pcNo === CompareData.pcNo)
                    setRequisitionNo(CompareData?.requisitionNo);
                    setQuotationNo(CompareData.pcNo)
                    let param = `?RequisitionNo=${selectedPc?.requisitionNo}`;
                    const response = await apiService.getApiParamater('GET_PR', param);
                    const data = selectedPc;
                    const selectedPR = response.data.result?.find(rec => rec.recordId === parseInt(data.requisitionNo))
                    if (selectedPR?.prDetails) {
                        const formattedRows = selectedPR.prDetails.map(detail => ({
                            srno: detail.srno,
                            ItemNo: detail.itemCode,
                            recordId: detail.itemCode,
                            comparisonStatus: data.status,
                            itemDescription: detail.itemDescription,
                            Specs: detail.specification,
                            qty: detail.qty,
                            uom: detail.uom,
                            baseQty: detail.baseQty,
                            baseAmount: detail.baseAmount,
                            amount: detail.amount,
                            deliveryDate: detail.deliveryDate,
                            bol: '',
                            quotations: [{
                                itemCode: detail.itemCode,
                                qty: detail.qty,
                                unitPrice: "",
                                amount: "",
                                supplier: "",
                                paymentTerms: ""
                            },
                            {
                                itemCode: detail.itemCode,
                                qty: detail.qty,
                                unitPrice: "",
                                amount: "",
                                supplier: "",
                                paymentTerms: ""
                            }]
                        }));
                        setRows(formattedRows);
                    }
                    setQuotationData(data)
                    const transformedRows = data.priceComparisonDetails.map(item => {
                        const quotations = [];
                        for (let i = 1; i <= 6; i++) {
                            let supplier = item[`supp${i}`].trim();
                            if (item[`price${i}`] && item[`price${i}`] !== 0 && supplier !== "string") {
                                quotations.push({
                                    itemCode: item.itemCode,
                                    qty: item.qty,
                                    selected: item[`selected${i}`],
                                    unitPrice: item[`price${i}`],
                                    amount: item.qty * item[`price${i}`],
                                    supplier: supplier,
                                    paymentTerms: item[`paymentTerms${i}`],
                                    refNo: item[`ref${i}`],
                                    remarks: item[`remarks${i}`]
                                });
                            }
                        }
                        return {
                            srno: item.srno,
                            ItemNo: item.itemCode,
                            itemDescription: item.itemDescription,
                            Specs: item.specification,
                            qty: item.qty,
                            uom: item.uom,
                            baseQty: item.qty,
                            baseAmount: item.qty * item.price1,
                            amount: item.qty * item.price1,
                            Delivery: "",
                            bol: '',
                            quotations: quotations
                        };
                    });
                    const transformedFormData = {
                        QuoteNo: data.quoteNo,
                        RequisitionNo: data.requisitionNo,
                        remarks: "",
                        costCenter1: data.costCenter1,
                        costCenter2: data.costCenter2,
                        costCenter3: data.costCenter3,
                        costCenter4: data.costCenter4,
                        quotation: []
                    };
                    const selectedQuotationsMap = new Map();
                    transformedRows.forEach(row => {
                        row.quotations.forEach(quote => {
                            const key = `${quote.itemCode}-${quote.supplier.trim()}`;
                            if (!selectedQuotationsMap.has(key) || quote.selected === 1) {
                                selectedQuotationsMap.set(key, quote);
                            }
                        });
                    });
                    let quotations = Array.from(selectedQuotationsMap.values());
                    const supplierSet = new Set();
                    const filteredQuotations = [];
                    quotations.forEach(quote => {
                        if (!supplierSet.has(quote.supplier.trim())) {
                            supplierSet.add(quote.supplier.trim());
                            filteredQuotations.push(quote);
                        }
                    });
                    transformedFormData.quotation = filteredQuotations;
                    setFormData(transformedFormData);
                    setRows(transformedRows);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
            fetchCompareData();
        } else if (CompareData.doctype === "UpdateComparison") {
            const fetchCompareData = async () => {
                try {
                    const pcresponse = await apiService.get('GET_PC');
                    const selectedPc = pcresponse.data.result?.find(rec => rec.pcNo === CompareData.pcNo)
                    setRequisitionNo(CompareData?.requisitionNo);
                    setQuotationNo(CompareData.pcNo)
                    let param = `?RequisitionNo=${selectedPc?.requisitionNo}`;
                    const response = await apiService.getApiParamater('GET_PR', param);
                    const data = selectedPc;
                    const selectedPR = response.data.result?.find(rec => rec.recordId === parseInt(data.requisitionNo))
                    if (selectedPR?.prDetails) {
                        const formattedRows = selectedPR.prDetails.map(detail => ({
                            srno: detail.srno,
                            ItemNo: detail.itemCode,
                            recordId: detail.itemCode,
                            comparisonStatus: data.status,
                            itemDescription: detail.itemDescription,
                            Specs: detail.specification,
                            qty: detail.qty,
                            uom: detail.uom,
                            baseQty: detail.baseQty,
                            baseAmount: detail.baseAmount,
                            amount: detail.amount,
                            deliveryDate: detail.deliveryDate,
                            bol: '',
                            quotations: [{
                                itemCode: detail.itemCode,
                                qty: detail.qty,
                                unitPrice: "",
                                amount: "",
                                supplier: "",
                                paymentTerms: ""
                            },
                            {
                                itemCode: detail.itemCode,
                                qty: detail.qty,
                                unitPrice: "",
                                amount: "",
                                supplier: "",
                                paymentTerms: ""
                            }]
                        }));
                        setRows(formattedRows);
                    }
                    setQuotationData(data)
                    const transformedRows = data.priceComparisonDetails.map(item => {
                        const quotations = [];
                        for (let i = 1; i <= 6; i++) {
                            let supplier = item[`supp${i}`].trim();
                            if (item[`price${i}`] && item[`price${i}`] !== 0 && supplier !== "string") {
                                quotations.push({
                                    itemCode: item.itemCode,
                                    qty: item.qty,
                                    selected: item[`selected${i}`],
                                    unitPrice: item[`price${i}`],
                                    amount: item.qty * item[`price${i}`],
                                    supplier: supplier,
                                    paymentTerms: item[`paymentTerms${i}`],
                                    refNo: item[`ref${i}`],
                                    remarks: item[`remarks${i}`]
                                });
                            }
                        }
                        return {
                            srno: item.srno,
                            ItemNo: item.itemCode,
                            itemDescription: item.itemDescription,
                            Specs: item.specification,
                            qty: item.qty,
                            uom: item.uom,
                            baseQty: item.qty,
                            baseAmount: item.qty * item.price1,
                            amount: item.qty * item.price1,
                            Delivery: "",
                            bol: '',
                            quotations: quotations
                        };
                    });
                    const transformedFormData = {
                        QuoteNo: data.quoteNo,
                        RequisitionNo: data.requisitionNo,
                        remarks: "",
                        costCenter1: data.costCenter1,
                        costCenter2: data.costCenter2,
                        costCenter3: data.costCenter3,
                        costCenter4: data.costCenter4,
                        quotation: []
                    };
                    const selectedQuotationsMap = new Map();
                    transformedRows.forEach(row => {
                        row.quotations.forEach(quote => {
                            const key = `${quote.itemCode}-${quote.supplier.trim()}`;
                            if (!selectedQuotationsMap.has(key) || quote.selected === 1) {
                                selectedQuotationsMap.set(key, quote);
                            }
                        });
                    });
                    let quotations = Array.from(selectedQuotationsMap.values());
                    const supplierSet = new Set();
                    const filteredQuotations = [];
                    quotations.forEach(quote => {
                        if (!supplierSet.has(quote.supplier.trim())) {
                            supplierSet.add(quote.supplier.trim());
                            filteredQuotations.push(quote);
                        }
                    });
                    transformedFormData.quotation = filteredQuotations;
                    setFormData(transformedFormData);
                    setRows(transformedRows);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
            fetchCompareData();
        } else {
            const fetchCompareData = async () => {
                try {
                    const pcresponse = await apiService.get('GET_QUOTATION');
                    const selectedPc = pcresponse.data.result?.find(rec => rec.quoteNo === CompareData.docno)
                    setRequisitionNo(selectedPc?.requisitionNo);
                    setQuotationNo(CompareData?.docno)
                    const response = await apiService.get('GET_PR');
                    const data = selectedPc;
                    const selectedPR = response.data.result?.find(rec => rec.recordId === parseInt(data.requisitionNo))
                    if (selectedPR?.prDetails) {
                        const formattedRows = selectedPR.prDetails.map(detail => ({
                            srno: detail.srno,
                            ItemNo: detail.itemCode,
                            recordId: detail.itemCode,
                            comparisonStatus: data.status,
                            itemDescription: detail.itemDescription,
                            Specs: detail.specification,
                            qty: detail.qty,
                            uom: detail.uom,
                            baseQty: detail.baseQty,
                            baseAmount: detail.baseAmount,
                            amount: detail.amount,
                            deliveryDate: detail.deliveryDate,
                            bol: '',
                            quotations: [{
                                itemCode: detail.itemCode,
                                qty: detail.qty,
                                unitPrice: "",
                                amount: "",
                                supplier: "",
                                paymentTerms: ""
                            },
                            {
                                itemCode: detail.itemCode,
                                qty: detail.qty,
                                unitPrice: "",
                                amount: "",
                                supplier: "",
                                paymentTerms: ""
                            }]
                        }));
                        setRows(formattedRows);
                    }
                    setQuotationData(data)
                    const transformedRows = data.tempQuotationDetails.map(item => {
                        const quotations = [];
                        for (let i = 1; i <= 6; i++) {
                            let supplier = item[`supp${i}`].trim();
                            if (item[`price${i}`] && item[`price${i}`] !== 0 && supplier !== "string") {
                                quotations.push({
                                    itemCode: item.itemCode,
                                    qty: item.qty,
                                    selected: item[`selected${i}`],
                                    unitPrice: item[`price${i}`],
                                    amount: item.qty * item[`price${i}`],
                                    supplier: supplier,
                                    paymentTerms: item[`paymentTerms${i}`],
                                    refNo: item[`ref${i}`],
                                    remarks: item[`remarks${i}`]
                                });
                            }
                        }
                        return {
                            srno: item.srno,
                            ItemNo: item.itemCode,
                            itemDescription: item.itemDescription,
                            Specs: item.specification,
                            qty: item.qty,
                            uom: item.uom,
                            baseQty: item.qty,
                            baseAmount: item.qty * item.price1,
                            amount: item.qty * item.price1,
                            Delivery: "",
                            bol: '',
                            quotations: quotations
                        };
                    });
                    const transformedFormData = {
                        QuoteNo: data.quoteNo,
                        RequisitionNo: data.requisitionNo,
                        remarks: "",
                        costCenter1: data.costCenter1,
                        costCenter2: data.costCenter2,
                        costCenter3: data.costCenter3,
                        costCenter4: data.costCenter4,
                        quotation: []
                    };
                    const selectedQuotationsMap = new Map();
                    transformedRows.forEach(row => {
                        row.quotations.forEach(quote => {
                            const key = `${quote.itemCode}-${quote.supplier.trim()}`;
                            if (!selectedQuotationsMap.has(key) || quote.selected === 1) {
                                selectedQuotationsMap.set(key, quote);
                            }
                        });
                    });
                    let quotations = Array.from(selectedQuotationsMap.values());
                    const supplierSet = new Set();
                    const filteredQuotations = [];
                    quotations.forEach(quote => {
                        if (!supplierSet.has(quote.supplier.trim())) {
                            supplierSet.add(quote.supplier.trim());
                            filteredQuotations.push(quote);
                        }
                    });
                    transformedFormData.quotation = filteredQuotations;
                    setFormData(transformedFormData);
                    setRows(transformedRows);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
            fetchCompareData();
        }
    }, [location.state, CompareData]);
    const calculateSubtotals = () => {
        const totals = {};
        rows.forEach(row => {
            row.quotations.forEach(quotation => {
                if (quotation.selected === 1) {
                    if (!totals[quotation.supplier]) {
                        totals[quotation.supplier] = 0;
                    }
                    totals[quotation.supplier] += quotation.amount;
                }
            });
        });
        setSuppliersTotals(totals);
    };
    const calculateTotal = () => {
        let sum = 0;
        Object.values(suppliersTotals).forEach(subtotal => {
            sum += subtotal;
        });
        setTotal(sum);
    };
    useEffect(() => {
        calculateSubtotals();
    }, [rows]);
    useEffect(() => {
        calculateTotal();
    }, [suppliersTotals]);
    useEffect(() => {
        const item = location.state;
        if (item?.prDetails) {
            const formattedRows = item.prDetails.map(detail => ({
                srno: detail.srno,
                ItemNo: detail.itemCode,
                recordId: detail.itemCode,
                comparisonStatus: item.comparisonStatus,
                itemDescription: detail.itemDescription,
                Specs: detail.specification,
                qty: detail.qty,
                uom: detail.uom,
                baseQty: detail.baseQty,
                baseAmount: detail.baseAmount,
                amount: detail.amount,
                deliveryDate: detail.deliveryDate,
                bol: '',
                quotations: [{
                    itemCode: detail.itemCode,
                    qty: detail.qty,
                    unitPrice: "",
                    amount: "",
                    supplier: "",
                    paymentTerms: ""
                }]
            }));
            setRows(formattedRows);
        }
    }, [location.state]);
    const getApprovalUser = async () => {
        try {
            const apiService = new ApiService();
            let param = `?DocType=Quotation`;
            const response = await apiService.getApiParamater('GET_APPROVAL_USER', param);
            if (response?.data?.result && response.data.result.length > 0) {
                setaprovaluser(response.data.result);
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const supplierTotals = {};
    rows?.forEach(row => {
        row.quotations.forEach(quotation => {
            const supplierName = quotation.supplier;
            if (!supplierTotals[supplierName]) {
                supplierTotals[supplierName] = { qty: 0, amount: 0 };
            }
            supplierTotals[supplierName].qty += quotation.qty;
            supplierTotals[supplierName].amount += quotation.amount;
        });
    });
    const handleBackClick = () => {
        window.history.back();
    }
    const handleCheckboxChange = (rowIndex, quotationIndex, checked) => {
        const updatedRows = [...rows];
        updatedRows[rowIndex].quotations.forEach((quotation, index) => {
            quotation.selected = 0;
        });
        if (checked) {
            updatedRows[rowIndex].quotations[quotationIndex].selected = 1;
        }
        setRows(updatedRows);
    };
    const UpdateComaprison = async () => {
        try {
            const currentDatetimeUTC = new Date().toISOString();
            const maxQuotations = 6;
            if (formData.quotation[0].supplier.length === 0) {
                toast.error("Please select suppliers before creating the quotation.");
                return;
            }
            const itemDetails = rows?.map((item, index) => {
                const itembody = {
                    srno: index + 1,
                    itemCode: item.ItemNo,
                    itemDescription: item.itemDescription,
                    uom: item.uom,
                    qty: item.qty,
                }
                item?.quotations.forEach((quotation, index) => {
                    if (index >= maxQuotations) {
                        toast.error(`Quotation limit exceeded for item ${item.ItemNo}. Maximum allowed is ${maxQuotations}.`);
                        return;
                    }
                    const suffix = index + 1;
                    itembody[`supp${suffix}`] = quotation.supplier;
                    itembody[`ref${suffix}`] = quotation?.refNo;
                    itembody[`remarks${suffix}`] = quotation?.remarks;
                    itembody[`paymentTerms${suffix}`] = quotation.paymentTerms;
                    itembody[`price${suffix}`] = quotation.unitPrice;
                    itembody[`selected${suffix}`] = quotation.selected;
                });
                for (let i = item.quotations.length + 1; i <= maxQuotations; i++) {
                    itembody[`supp${i}`] = "string";
                    itembody[`ref${i}`] = "string";
                    itembody[`remarks${i}`] = "string";
                    itembody[`paymentTerms${i}`] = "string";
                    itembody[`price${i}`] = 0;
                    itembody[`selected${i}`] = 0;
                }
                return itembody;
            });
            const requestBody = {
                pcid: CompareData.pcid,
                pcDate: new Date().toISOString(),
                pcNo: CompareData?.pcNo,
                requisitionNo: CompareData?.requisitionNo,
                quoteNo: CompareData?.quoteNo,
                costCenter1: CompareData.costCenter1,
                costCenter2: CompareData.costCenter2,
                costCenter3: CompareData.costCenter3,
                costCenter4: CompareData.costCenter4,
                priceComparisonDetails: itemDetails
            };
            const response = await apiService.put("UPDATE_PC", requestBody);
            if (response?.data?.statusCode === 201) {
                toast.success(` ${QuotationNo} Updated successfully`);
                // dispatch(clearComparison());
                navigate("/inventory/PriceComparison/List");
            }
        } catch (error) {
            console.error("Error Creating Comparison:", error);
        }
    };
    return (
        <div className='p-4'>
            <div className='d-flex gap-3 end'>
                {CompareData.doctype === "UpdateComparison" ?
                    <button className='mainbtn ' type="submit" onClick={UpdateComaprison}>
                        <FaSave className='center mt-1 mx-2' /><div>Save</div>
                    </button> : " "
                }
                <div className=''>
                    <button onClick={handleBackClick} className='mainbtn'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>Comparison Details</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className='row'>
                            <div className='col-5'>
                                <div className="d-flex justify-content-between">
                                    <label htmlFor="QuoteNo" className="form-label  pt-2 start">PC No</label>
                                    <input className="form-Input w-70" value={QuotationNo ? `${QuotationNo}` : " "} disabled />
                                </div>
                                <div className="py-2 d-flex justify-content-between">
                                    <label htmlFor="RequisitionNo" className="form-label  pt-2 start">Requisition No</label>
                                    <input disabled className="form-Input w-70" value={RequisitionNo} />
                                </div>
                                <div className="d-flex justify-content-between">
                                    <label htmlFor="PropertyGroup" className="form-label  pt-2 start">Property Group</label>
                                    <input className="form-Input w-70" value={CompareData.costCenter1 || formData.costCenter1} />
                                </div>
                                <div className="py-2 d-flex justify-content-between">
                                    <label htmlFor="Property" className="form-label  pt-2 start">Property</label>
                                    <input className="form-Input w-70" value={CompareData.costCenter2 || formData.costCenter2} />
                                </div>
                                <div className="d-flex justify-content-between">
                                    <label htmlFor="Department" className="form-label  pt-2 start">Department</label>
                                    <input className="form-Input w-70" value={CompareData.costCenter3 || formData.costCenter3} />
                                </div>
                                <div className="pt-2 d-flex justify-content-between">
                                    <label htmlFor="Outlet" className="form-label  pt-2 start">Outlet</label>
                                    <input className="form-Input w-70" value={CompareData.costCenter4 || formData.costCenter4} />
                                </div>
                            </div>
                            <div className='col-7'>
                                <div className='d-flex gap-3'>
                                    <div className='brderbox px-3 center'><a href='' disabled style={{ cursor: "not-allowed" }}>Attachment</a></div>
                                    <div className=""><button className="mainbtn" disabled style={{ cursor: "not-allowed" }}>Add More</button></div>
                                </div>
                                <div className="d-flex mt-2 gap-3 ovrflo">
                                    {formData?.quotation?.map((quotation, index) => (
                                        <div key={index} className="col-6 quotationbox py-3 px-2">
                                            <div className='d-flex justify-content-between pb-1'>
                                                <label className="form-label">Supplier</label>
                                                <RxCross2 />
                                            </div>
                                            <div className="">
                                                <Select isDisabled theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 5,
                                                    padding: 9
                                                })}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                    className="p-0 w-100 "
                                                    value={quotation?.supplier ? { value: quotation?.supplier, label: quotation?.supplier } : { label: "Select Supplier", value: "Select Supplier" }}
                                                />
                                            </div>
                                            <div className="my-2">
                                                <Select isDisabled theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 5,
                                                    padding: 9
                                                })}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                    className="p-0 w-100 "
                                                    value={quotation?.paymentTerms ? { value: quotation?.paymentTerms, label: quotation?.paymentTerms } : { label: "Select Payment Term", value: "Select Supplier" }}
                                                />
                                            </div>
                                            <div className=''>
                                                <input
                                                    type="number" onWheel={(e) => e.target.blur()}
                                                    className="form-Input w-100"
                                                    id={`refNo${index}`}
                                                    value={quotation.refNo}
                                                    placeholder="Quotation Ref No"
                                                    disabled
                                                />
                                            </div>
                                            <div className=" mt-2">
                                                <input
                                                    className="form-Input w-100"
                                                    id={`remarks${index}`} disabled
                                                    placeholder="Remarks"
                                                    value={quotation.remarks}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setCostTab(!CostTab)}>
                    <div className='h6 pt-1'>Items Details</div>
                </div>
                <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                    <div className="border-box2">
                        <div className="grid-1">
                            <div className='table-container mb-5' style={{ overflowX: 'auto', height: "auto" }}>
                                <table>
                                    <thead className='dynoTable' >
                                        <tr>
                                            <th>Sr no</th>
                                            <th>Item No</th>
                                            <th>Description</th>
                                            <th>Item Specs</th>
                                            <th>UOM</th>
                                            <th>Delivery Date</th>
                                            {rows[0]?.quotations.map((_, index) => (
                                                <React.Fragment key={index}>
                                                    <th></th>
                                                    <th>Qty</th>
                                                    <th>Unit Price</th>
                                                    <th>Amount</th>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </thead>
                                    {!rows ?
                                        <div>
                                            <ClipLoader size={50} color='#6E5199' />
                                        </div> :
                                        <tbody>
                                            {rows?.map((row, index) => (
                                                <tr key={index}>
                                                    <td className='m-0 p-0'><input style={{ width: "58px" }} className='p-1' type="number" disabled value={index + 1} /></td>
                                                    <td className='m-0 p-0'><input style={{ width: "78px" }} className='p-1' type="text" disabled value={row.ItemNo} /></td>
                                                    <td className='m-0 p-0'><input className='p-1' type="text" disabled value={row.itemDescription} /></td>
                                                    <td className='m-0 p-0'><input style={{ width: "101px" }} className='p-1' type="text" disabled value={row.Specs} /></td>
                                                    <td className='m-0 p-0'><input style={{ width: "90px" }} className='p-1' type="text" disabled value={row.uom} /></td>
                                                    <td className='m-0 p-0'><input style={{ width: "118px" }} className='p-1' disabled value={moment(CompareData?.deliveryDate).format('L')} /></td>
                                                    {row?.quotations.map((q, quotationIndex) => (
                                                        <React.Fragment key={quotationIndex}>
                                                            <td>
                                                                <div className='center'>
                                                                    {CompareData.doctype === "UpdateComparison" ?
                                                                        <input
                                                                            className='px-2 form-check-input'
                                                                            type="checkbox"
                                                                            style={{ width: "20px", height: "20px" }}
                                                                            checked={q.selected === 1}
                                                                            onChange={(e) => handleCheckboxChange(index, quotationIndex, e.target.checked)}
                                                                        /> :
                                                                        <input
                                                                            className='px-2 form-check-input'
                                                                            checked={q.selected === 1}
                                                                            type="checkbox" disabled
                                                                            style={{ width: "20px", height: "20px" }}
                                                                        />}
                                                                </div>
                                                            </td>
                                                            <td><input style={{ width: "55px" }} className='p-1' type="text" disabled value={q.qty} /></td>
                                                            <td><input style={{ width: "88px" }} className='p-1' type="text" value={q.unitPrice} disabled /></td>
                                                            <td><input style={{ width: "88px" }} className='p-1' type="text" disabled value={q.amount} /></td>
                                                        </React.Fragment>
                                                    ))}
                                                </tr>
                                            ))}
                                            <tr>
                                                <td colspan="6" className='m-0 p-0'><input className='p-1 w-100' disabled value={"Total"} /></td>
                                                {Object.keys(supplierTotals).map((supplier, index) => (
                                                    <React.Fragment key={index}>
                                                        <td><input style={{ width: "35px" }} className='p-1' type="text" disabled value={""} /></td>
                                                        <td><input style={{ width: "55px" }} className='p-1' type="text" disabled value={supplierTotals[supplier].qty} /></td>
                                                        <td><input style={{ width: "88px" }} className='p-1' type="text" disabled value={"--"} /></td>
                                                        <td><input style={{ width: "88px" }} className='p-1' type="text" disabled value={supplierTotals[supplier].amount || "0"} /></td>
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td colspan="6" className='m-0 p-0'><input className='p-1 w-100' disabled value={"Supplier Total"} /></td>
                                                {Object.keys(supplierTotals).map((supplier, index) => (
                                                    <React.Fragment key={index}>
                                                        <td><input style={{ width: "35px" }} className='p-1' type="text" disabled value={""} /></td>
                                                        <td><input style={{ width: "55px" }} className='p-1' type="text" disabled value={""} /></td>
                                                        <td><input style={{ width: "88px" }} className='p-1' type="text" disabled value={""} /></td>
                                                        <td><input style={{ width: "88px" }} className='p-1' type="text" disabled value={suppliersTotals[supplier] || "0"} /></td>
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                        </tbody>
                                    }
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PriceCompView