import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useState } from 'react';
import moment from 'moment/moment';

const CostumerGrid = ({ costumer }) => {

    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("MMM Do YYYY");
    }
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    }
    const [colDefs, setColDefs] = useState([
        { field: "name", filter: true, floatingFilter: true },
        {
            field: "dateOfBirth",
            filter: true,
            floatingFilter: true,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { field: "email", filter: true, floatingFilter: true },
        { field: "mobileNumber", filter: true, floatingFilter: true },
        { field: "gender", filter: true, floatingFilter: true },
        { field: "identityCardNumber", filter: true, floatingFilter: true },
        { field: "address1", filter: true, floatingFilter: true },
        { field: "address2", filter: true, floatingFilter: true },
        { field: "address3", filter: true, floatingFilter: true },
        { field: "area", filter: true, floatingFilter: true },
        { field: "customerTaxId", filter: true, floatingFilter: true },
        { field: "faxNumber", filter: true, floatingFilter: true },
        { field: "phoneNumber", filter: true, floatingFilter: true },
        { field: "branchCode", filter: true, floatingFilter: true }
    ])

    return (
        <div className="ag-theme-quartz" style={{ height: 600, backgroundColor: "#6E5199" }}>
            <AgGridReact
                rowData={costumer}
                columnDefs={colDefs}
                getRowStyle={(params) => {
                    if (params.node.rowIndex % 2 === 0) {
                        return { background: '#F0EDF5' };
                    } else {
                        return { background: '#fff' };
                    }
                }}
                suppressRowClickSelection={true}
            />
        </div>
    )
}
export default CostumerGrid;