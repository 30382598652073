import React, { useEffect, useState } from 'react';
import CostumerHeader from '../Header/header';
import "../Menu/costumer.css";
import CostumerBanner from '../Banners/banner';
import ApiService from '../../services/apiService';
import { ReactComponent as Cart } from '../../assets/images/svgIcons/shopping-cart-add.svg';
import { useDispatch } from 'react-redux';
import { getCartData, getComboData } from '../../../Redux/selecters';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { ReactComponent as Location } from '../../assets/images/svgIcons/Location.svg';
import ComboCard from './comboCard';
import { clearComboCart } from '../../../Redux/actions/comboAction';
import AddBucket from '../Bucket/addBucket';

const CustomerCombo = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    const [menu, setMenu] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const cartItems = useSelector(getComboData);
    const menuItems = useSelector(getCartData);
    const navigate = useNavigate()
    const thirdParty = () => {
        navigate('/customer/thirdparty');
    }
    const isThirdPartyRoute = location.pathname === '/customer/thirdparty';

    useEffect(() => {
        GetMenu();
    }, [searchTerm]);

    useEffect(() => {
        GetMenu();
    }, []);
    useEffect(() => {
        if (location.state && location.state.categorymenu) {
            setMenu(location.state.categorymenu);
        }
    }, [location]);

    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);

    const removeFromCart = (index) => {
        const newCartItems = [...cartItems];
        newCartItems.splice(index, 1);
    };

    const updateQuantity = (index, newQuantity) => {
        const updatedCartItems = [...cartItems];
        updatedCartItems[index].quantity = newQuantity;
    };

    const GetMenu = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get("GET_COMBO");
            const filteredsub = res.data.result.filter(item => item?.active === true);
            setMenu(filteredsub);
        } catch (error) {
            console.error('Error fetching menu data:', error);
        }
    };

    return (
        <div className="">
            <CostumerHeader />
            <div className='container-fluid m-0 p-0 pt-5 px-5' >

                <div className="pt-5 d-flex row">
                    <div className='col-lg-11'>
                        <div className='d-flex justify-content-between inline'>
                            <input type='Search'
                                className='form-control  w-25'
                                id='validationCustom01'
                                placeholder='Search'
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)} />
                            {isThirdPartyRoute ? null :
                                <div className='center'>
                                    <div className='btn btn-primary' onClick={() => thirdParty()}>Third Party</div>
                                </div>}
                            <div className='d-flex justify-content-end pad-t flx-strt-mbl'>
                                <div className='locbox'>
                                    <h3 className='Delivr'>Delivering to</h3>
                                    <h4 className='delivrloc'>Satellite Town D-Block</h4>
                                </div>
                                <div className='locationCombo'>
                                    <Location />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-lg-1'>
                        <button class="cartBtn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">
                            <Cart />
                            {cartItems?.length + menuItems?.length}
                        </button>
                    </div>
                </div>
            </div>
            <div className='px-5 pt-5 p-0'>
                <CostumerBanner />
            </div>
            <div className="px-5">
                <div className="row">
                    <div className={'col-12'}>
                        <div className="grid">
                            {menu?.map((val, key) => (
                                <ComboCard key={key} val={val} />
                            ))}
                        </div>
                    </div>

                    <div class="offcanvas offcanvas-end " data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                        <div class="offcanvas-header">
                            <h5 class="offcanvas-title" id="offcanvasScrollingLabel">Your Cart Items</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div>
                            <AddBucket cartItems={cartItems} removeFromCart={removeFromCart} updateQuantity={updateQuantity} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerCombo