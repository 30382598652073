import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ApiService from '../../services/apiService';
import InvoiceItemList from './invoiceItems';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthorizationInvoice from './invoiceAuthorization';
import moment from 'moment';
import InvoiceGrnTable from './invoiceView/GrnTable';
import { FaAngleLeft } from "react-icons/fa6";

const InvoiceView = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const apiService = new ApiService();
    const [messageData, setMessageData] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [ItemData, setItemData] = useState([]);
    const [GrnItemData, setGrnItems] = useState([]);
    const [grnData, setGrnData] = useState([]);
    const [selectedGrn, setSelectedGrn] = useState();
    const [selectedInvoice, setSelectedInvoice] = useState();
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [POData, setPOData] = useState([]);
    const [usersDetails, setUser] = useState([]);
    const [PurchaseOrder, setPurchaseOrder] = useState([]);
    const [SelectedPO, setSelectedPO] = useState([]);
    const currentDatetimeUTC = new Date().toISOString();
    const [SelectedSupplier, setSelectedSupplier] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        fetchData()
        getCurrency()
        getApprovalUser()
    }, [])
    useEffect(() => {
        const item = location.state;
        const getPR = async () => {
            try {
                const response = await apiService.get('GET_INVOICE');
                const selectedInv = response.data.result?.find(rec => rec.invoiceRegNo === item.docno)
                setSelectedInvoice(selectedInv)
                setFormData({
                    InvoiceNO: item.docno,
                    PropertyGroup: selectedInv.costCenter1,
                    remarks: "",
                    InvoiceDate: selectedInv.invoiceDate,
                    Property: selectedInv.costCenter2,
                    deliveryDate: selectedInv.docDate,
                    PaymentTerm: selectedInv.terms,
                    POType: "",
                    SuppInvoiceNo: selectedInv.invoiceNo,
                    Department: selectedInv.department,
                    Outlet: selectedInv.costCenter3,
                    DueDate: selectedInv.dueDate,
                    TaxInvoice: "",
                    CostCentre5: selectedInv.costCenter5,
                    Narration: "",
                });
                const selectedPO = PurchaseOrder?.find(po => po?.pOrderNo === selectedInv?.pOrderNo);
                if (selectedPO) {
                    setSelectedPO({ label: selectedInv?.pOrderNo, value: selectedPO })
                    setSelectedSupplier({ label: selectedPO.vendorName, value: selectedPO })
                }
                
                // const selectedSuplier = suppliers?.find(sup => sup?.supplierName === selectedPr?.vendorName);
                // if (selectedSuplier) {
                //     setSupplierData({ label: selectedSuplier?.supplierName, value: selectedSuplier })
                //     setSupplierID({ label: selectedSuplier?.record_Id, value: selectedSuplier })
                //     setSupplierContact(selectedSuplier?.phoneNo1)
                // }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        getPR()
    }, [location.state, PurchaseOrder]);

    const fetchData = async () => {
        const PurchaseOrder = await apiService.get("GET_PO");
        const approvedPO = PurchaseOrder?.data?.result?.filter(data => data.status === 1);
        setPurchaseOrder(approvedPO);
    }
    const fetchItemsData = async (grnList) => {
        const selectedgrn = grnList?.filter(grn => grn.checked === 1)
        const items = await apiService.get("GET_GRN");
        const grn = items.data.result?.filter(grnList => grnList?.grnNo === selectedgrn[0]?.grnNo)
        debugger
        setGrnItems(grn);
        setSelectedGrn(grn[0])
        setFormData({
            recordId: grn[0]?.grnNo,
            requisitionDate: grn[0]?.requisitionDate || currentDatetimeUTC,
            deliveryDate: grn[0]?.delivery_Date,
            InvoiceDate: grn[0].invoiceDate,
            SuppInvoiceNo: grn[0]?.invoiceNo,
            TaxInvoice: grn[0]?.contractNo,
            remarks: grn[0]?.remarks,
            requestor: grn[0]?.requestor,
            currencyCode: grn[0]?.currencyCode,
            costCenter2: grn[0]?.costCenter2,
            costCenter3: grn[0]?.costCenter3,
            costCenter4: grn[0]?.costCenter4,
            deliveryPoint: grn[0]?.deliveryPoint,
            contactPerson: grn[0]?.contactPerson,
            contatNo: grn[0]?.contatNo,
            taskCode: grn[0]?.projectCode,
            location: grn[0]?.location,
        });
    }
    const getApprovalUser = async () => {
        try {
            let param = `?DocType=Invoice`;
            const apiService = new ApiService();
            const response = await apiService.getApiParamater('GET_APPROVAL_USER', param);
            if (response?.data?.result && response.data.result.length > 0) {
                setUser(response.data.result);
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getCurrency = async () => {
        let param = `?isActive=true`
        const apiService = new ApiService()
        let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
        setCurrency(res?.data?.result)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        setFormData({ ...formData, exchangeRate: filteredDenominations.conversionRate });
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
    }
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')

    };
    const handleRequisitionData = (data) => {
        setItemData(data);
        console.log(data, 'reqData')
    };
    const GrnData = (data) => {
        setGrnData(data);
        debugger
        fetchItemsData(data)
        console.log(data, 'grnData')
    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')

    };
    const [formData, setFormData] = useState({
        InvoiceNO: "",
        PropertyGroup: "",
        remarks: "",
        InvoiceDate: "",
        Property: "",
        deliveryDate: "",
        PaymentTerm: "",
        POType: "",
        SuppInvoiceNo: "",
        Department: "",
        Outlet: "",
        Currency: "",
        exchangeRate: "",
        DueDate: "",
        TaxInvoice: "",
        CostCentre5: "",
        Narration: "",
    });
    const handleBackClick = () => {
        window.history.back();
    }
    return (
        <div className='p-5'>
            <div className='d-flex justify-content-between my-2'>
                <div className='h4'>Supplier Invoice</div>
                <div onClick={handleBackClick} className='mainbtn  flex-end  gap-3'>
                    <FaAngleLeft size={25} className='center' />
                    <div>Back</div>
                </div>
            </div>
            <div className='brderbox p-lg-3 mb-5'>
                <div className="form-group justify-content-between row pb-3">
                    <div className="col">
                        <label htmlFor="InvoiceNO" className="form-label">Invoice No</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.InvoiceNO}
                            name="InvoiceNO"
                            disabled
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="deliveryDate" className="form-label">Date </label>
                        <input className='form-control' type='datetime-Local'
                            name="deliveryDate"
                            value={formData.deliveryDate} disabled
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="PropertyGroup" className="form-label">Property Group</label>
                        <input
                            type="number"
                            className="form-control"
                            value={formData.PropertyGroup} disabled
                            name="PropertyGroup"
                        />
                    </div>
                </div>
                {/* Dropdowns for other fields */}
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Purchase Order No *</label>
                        <Select
                            value={SelectedPO} isDisabled
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="InvoiceDate" className="form-label">Supp. Invoice Date</label>
                        <input
                            type="datetime-Local"
                            className="form-control"
                            value={formData.InvoiceDate} disabled
                            name="InvoiceDate"
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="Property" className="form-label">Property</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.Property} disabled
                            name="Property"
                        />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Vendor Name</label>
                        <Select
                            value={SelectedSupplier} isDisabled
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="PaymentTerm" className="form-label">Payment Term</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.PaymentTerm} disabled
                            name="PaymentTerm"
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="Department" className="form-label">Department</label>
                        <input
                            type="number"
                            className="form-control"
                            value={formData.Department} disabled
                            name="Department"
                        />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="POType" className="form-label">PO Type</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.POType} disabled
                            name="POType"
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="SuppInvoiceNo" className="form-label">Supp. Invoice No</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.SuppInvoiceNo} disabled
                            name="SuppInvoiceNo"
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="Outlet" className="form-label">Outlet</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.Outlet} disabled
                            name="Outlet"
                        />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="DueDate" className="form-label">Due Date</label>
                        <input
                            type="datetime-Local"
                            className="form-control"
                            value={formData.DueDate} disabled
                            name="DueDate"
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="TaxInvoice" className="form-label">Tax Invoice No ( KRA )</label>
                        <input
                            type="number"
                            className="form-control"
                            value={formData.TaxInvoice} disabled
                            name="TaxInvoice"
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="CostCentre5" className="form-label">Cost Centre 5</label>
                        <input
                            type="number"
                            className="form-control"
                            value={formData.CostCentre5} disabled
                            name="CostCentre5"
                        />
                    </div>
                </div>
                <div className="form-group row pb-3">
                    <div className="col-4 d-flex gap-3">
                        <div className="col">
                            <label htmlFor="Currency" className="form-label">Currency</label>
                            <Select isDisabled
                                value={SelectedCurrency}
                                className="Currency-dropdown mbl-dropdown"
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="EXRate" className="form-label">EX Rate</label>
                            <input
                                type="number"
                                className="form-control"
                                disabled
                                value={formData.exchangeRate}
                                name="exchangeRate"
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <label htmlFor="Narration" className="form-label">Narration</label>
                        <input
                            type="number"
                            className="form-control"
                            value={formData.Narration} disabled
                            name="Narration"
                        />
                    </div>
                </div>

            </div>
            <div className="grid-1 maon-BG mb-3">
                <InvoiceGrnTable GrnData={GrnData} SelectedPO={SelectedPO.value} selectedInvoice={selectedInvoice} />
            </div>
            <div className="topnav2 maon-BG ">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Message
                        </div>
                    </div>

                </div>
            </div>
            <div className="grid-1 brderbox2 p-4 mb-3">
                {gridVisibility[0] && (
                    <InvoiceItemList onRequisitionData={handleRequisitionData} GrnItemData={GrnItemData[0]?.grnDetails} />
                )}
                {gridVisibility[1] && (
                    <AuthorizationInvoice handleAuthorization={handleAuthorization}
                        usersDetails={usersDetails}
                        setUser={setUser}
                        itemData={POData} />
                )}
                {gridVisibility[2] && (
                    <div className="grid-3 border">
                        {/* <MessageGrid handleMessage={handleMessage} /> */}
                    </div>
                )}
            </div>
        </div>
    )
}

export default InvoiceView