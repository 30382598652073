import React from 'react';
import PropTypes from 'prop-types';
import P1 from '../../../assets/images/P1.png';
import DeleteModal from './deleteModal';
import "./menuItemTable.css"
import { connect } from "react-redux";
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

const MenuItemtable = ({ itemPic = P1, price, id, name, itemCategory, reviews, imageUrl, val, Currency }) => {

    return (
        <>
            <tr>
                <td className='d-inline-flex gap-3'>
                    <div><img src={val?.imageUrl === '' ? itemPic :
                        IMAGE_URL + val?.imageUrl} className='menu-card-image' />
                    </div>
                    <div className="menu-card-details">
                        <h5>{name}</h5>
                        <div className="menu-card-rating">
                            <span>&#9733;&#9733;&#9733;&#9733;&#9733;</span>
                            <span>{reviews} Reviews</span>
                        </div>
                    </div>
                </td>
                <td>Qty</td>
                <td>{Currency.currency.name}  {price}</td>
                <td>{id}%</td>
                <td>{Currency.currency.name}  {price - id}</td>
                <td>
                    <div className=' d-flex gap-3'>
                        <button className='edit-button'>
                            Edit
                        </button>
                        <button className='delete-button'>
                            Delete
                        </button>
                    </div>
                </td>
            </tr>
        </>
    )
}
const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(MenuItemtable);