import React from "react";
import { Link } from 'react-router-dom';
import './supplier.css'

const SupplierNavBox = ({ id }) => {

    return (
        <div className="py-lg-3">
            <div className="topnav maon-BG ">
                <div className="container">
                    <div className="nav nav-pills overslid d-flex justify-content-between">
                        <Link to="/Inventory/Supplier_Master/list" className={`dashitems sidebar-text center  ${id === "list" ? "sideActive" : ""}`}>
                            Supplier List</Link>
                        <Link to="/Inventory/Supplier_Master/create" className={`dashitems sidebar-text center ${id === "create" ? "sideActive" : ""}`}>
                            Details</Link>
                        <Link to="/Inventory/Supplier_Master/account" className={`dashitems sidebar-text center ${id == "account" ? "sideActive" : ""}`}>
                            GL Account</Link>
                        <Link to="/Inventory/Supplier_Master/additional" className={`dashitems sidebar-text center ${id == "additional" ? "sideActive" : ""}`}>
                            Additional Contact</Link>
                        <Link to="/Inventory/Supplier_Master/bank" className={`dashitems sidebar-text center ${id == "bank" ? "sideActive" : ""}`}>
                            Bank Details</Link>
                        <Link to="/Inventory/Supplier_Master/attachment"  className={`dashitems sidebar-text center ${id == "attachment" ? "sideActive" : ""}`}>
                            Attachment</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SupplierNavBox; 