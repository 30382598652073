import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ApiService from '../../../../services/apiService';
import ImageUpload from './imagUpload';
import P1 from '../../../../../POS/assets/images/P1.png';
import "../../../../Customer/Menu/NavBar/navBar.css";
import "../menuItemcard.css";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const CreateCombo = ({ Currency }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedVariant, setSelectedVariant] = useState(null);
    const [comboItems, setComboItems] = useState([]);
    const [menu, setMenu] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [tax, setTax] = useState([]);
    const [comboDeals, setComboDeals] = useState([]);

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        price: '',
        discount: '',
        active: true,
        imageUrl: '',
        comboItems: [],
        loggedInUserId: 'string',
        images: [],
    });

    useEffect(() => {
        getComboDeals();
        GetTax();
        fetchMenu();
    }, []);

    const GetTax = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService.get('GET_TAX');
            setTax(res?.data?.result);
        } catch (error) {
            console.error('Error fetching tax data:', error);
        }
    };

    const getComboDeals = async () => {
        try {
            const apiService = new ApiService();
            const response = await apiService.get('GET_COMBO');
            setComboDeals(response?.data?.result.reverse());
        } catch (error) {
            console.error('Error fetching combo deals:', error);
        }
    };

    const fetchMenu = async () => {
        try {
            const apiService = new ApiService();
            const response = await apiService.get('GET_MENU');
            setMenu(response?.data?.result);
        } catch (error) {
            console.error('Error fetching menu:', error);
        }
    };

    const handleItemChange = (selectedOption) => {
        setSelectedItem(selectedOption);
        setSelectedVariant(null);
    };

    const handleVariantChange = (selectedOption) => {
        setSelectedVariant(selectedOption);
    };

    const handleAddComboItem = () => {
        if (selectedItem && selectedVariant) {
            const newComboItem = {
                itemId: selectedItem?.value.id,
                itemName: selectedItem?.label,
                variantId: selectedVariant?.value?.metaItemId,
                variantName: selectedVariant?.value?.metaItemName,
                price: selectedVariant?.value?.variantPrice,
                tax: selectedItem?.value?.tax,
                taxIncluded: selectedItem?.value?.taxIncluded,
            };
            setComboItems([...comboItems, newComboItem]);
            setSelectedItem(null);
            setSelectedVariant(null);
        }
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleImagesSelect = (images) => {
        setFormData({ ...formData, images: images });
    };

    const handleImageRemove = (index) => {
        const updatedImages = [...formData.images];
        updatedImages.splice(index, 1);
        setFormData({ ...formData, images: updatedImages });
    };

    useEffect(() => {
        let total = 0;
        comboItems.forEach(item => {
            total += item.price;
        });
        setTotalPrice(total);
    }, [comboItems]);


    const calculateTotalTaxPercentage = () => {
        let totalTaxPercentage = 0;
        comboItems.forEach(item => {
            if (!item.taxIncluded && item.tax) {
                totalTaxPercentage += item.tax;
            }
        });
        return totalTaxPercentage;
    };

    const calculateTotalTaxAmount = () => {
        let totalTaxAmount = 0;
        comboItems.forEach(item => {
            if (!item.taxIncluded && item.tax) {
                totalTaxAmount += (item.price * item.tax / 100);
            }
        });
        return totalTaxAmount;
    };

    const calculateFinalPrice = () => {
        const { discount } = formData;
        const subTotal = comboItems.reduce((acc, item) => acc + item.price, 0);
        const totalTaxAmount = calculateTotalTaxAmount();
        return (subTotal + totalTaxAmount - discount).toFixed(2);
    };

    const UploadImage = async () => {
        if (!formData?.images && !formData?.imageUrl) {
            toast.error("Upload Image");
        } else if (formData?.images) {
            try {
                const apiService = new ApiService();
                const formdata = new FormData();
                for (const image of formData?.images) {
                    if (image?.file) {
                        formdata.append('Images', image?.file);
                    }
                }
                const response = await apiService.postMultiPart("UPLOAD_IMG", formdata);

                if (response?.data?.statusCode === 201) {
                    const imgUrl = response?.data?.result;
                    handleCreateCombo(imgUrl);
                } else {
                    toast.error(response?.data?.msg);
                }
            } catch (error) {
                toast.error("Something went wrong with the image. Please try again!");
            }
        }
    };

    const handleCreateCombo = async (imgUrl) => {
        if (!formData?.discount) {
            toast.error("Add Discount");
        } else {
            try {
                const apiService = new ApiService();
                const comboData = {
                    name: formData.name,
                    description: formData.description,
                    price: calculateFinalPrice(),
                    tax: calculateTotalTaxAmount(),
                    discount: formData.discount,
                    active: true,
                    imageUrl: imgUrl,
                    comboItems: comboItems.map(item => ({
                        itemId: item.itemId,
                        variantId: item.variantId,
                    })),
                    loggedInUserId: formData.loggedInUserId,
                };
debugger
                const res = await apiService.post('CREATE_COMBO', comboData);
                if (res?.data?.statusCode === 201) {
                    setFormData({
                        name: '',
                        description: '',
                        price: '',
                        discount: '',
                        active: true,
                        imageUrl: '',
                        comboItems: [],
                        loggedInUserId: '',
                        images: [],
                    });
                    setComboItems([]);
                    toast.success("Combo created successfully!");
                    getComboDeals();
                } else {
                    toast.success("Something wrong with combo data. Try again!");
                }
            } catch (error) {
                console.error('Error creating combo:', error);
            }
        }
    };

    const handleUpdateCombo = async (combo, newActiveStatus) => {
        try {
            const apiService = new ApiService();
            const comboData = {
                id: combo.id,
                name: combo.name,
                description: combo.description,
                price: combo.price,
                tax: combo.tax,
                discount: combo.discount,
                active: newActiveStatus,
                imageUrl: combo.imageUrl,
                comboItems: combo?.comboItems,
                loggedInUserId: combo.loggedInUserId,
            };

            const res = await apiService.put(`UPDATE_COMBO`, comboData);
            if (res?.data?.statusCode === 200) {
                toast.success("Combo updated successfully!");
                getComboDeals();
            } else {
                toast.error("Failed to update combo. Please try again!");
            }
        } catch (error) {
            console.error('Error updating combo:', error);
        }
    };

    const handleSwitchChange = async (comboId, newActiveStatus) => {
        try {
            await handleUpdateCombo(comboId, newActiveStatus);
        } catch (error) {
            console.error('Error updating combo:', error);
        }
    };

    return (
        <div className="p-lg-5">
            <div className='row catlist rows gap-5 p-4'>
                <div className='col-lg-5 col6 pl-4 pr-4'>
                    <div className='mb-5'>
                        <label htmlFor="validationCustom05" className="form-label">Items</label>
                        <CreatableSelect
                            options={menu?.map((Items) => ({ value: Items, label: Items.name }))}
                            value={selectedItem}
                            onChange={handleItemChange}
                            placeholder="Select an Item"
                            className=''
                            required
                        />
                        <div className="valid-feedback">Looks good!</div>
                    </div>
                    {selectedItem && (
                        <div className='mb-5'>
                            <label htmlFor="validationCustom05" className="form-label">Variants</label>
                            <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                options={selectedItem.value.menuItemMeta?.filter(variant => variant.metaType === 4).map((variant) => ({ value: variant, label: variant.metaItemName }))}
                                value={selectedVariant}
                                onChange={handleVariantChange}
                                placeholder="Select a Variant"
                                className=''
                                required
                            />
                            <button onClick={handleAddComboItem} className='btn btn-primary mt-3'>Okay</button>
                            <div className="valid-feedback">Looks good!</div>
                        </div>
                    )}
                    <div className='mb-5'>
                        <label htmlFor="validationCustom01" className="form-label">Description</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom01"
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">Looks good!</div>
                    </div>
                    <div className='pt-3'>
                        <div className="table-container" style={{ overflowY: 'auto', maxHeight: '140px' }}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Item Name</th>
                                        <th scope="col">Variant Name</th>
                                        <th scope="col">Price</th>
                                        {/* <th scope="col">Tax</th> */}

                                    </tr>
                                </thead>
                                <tbody>
                                    {comboItems?.map((val, index) => (
                                        <tr key={index}>
                                            <td>{val.itemName}</td>
                                            <td>{val.variantName}</td>
                                            <td>{Currency.currency.name} {val.price}</td>
                                            {/* <td> {val.tax }%</td> */}

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='divider'></div>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td className='bold'>Items Total</td>
                                    <td>{Currency.currency.name} {totalPrice.toFixed(2,0)}</td>
                                </tr>
                                <tr>
                                    <td className='bold'>Total Tax</td>
                                    <td>{Currency.currency.name} {calculateTotalTaxAmount().toFixed(2,0)}</td>
                                </tr>
                                <tr>
                                    <td className='bold'> Total</td>
                                    <td>{Currency.currency.name} {calculateFinalPrice()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-between gap-2 mt-3">
                   
                        <div>
                            <label htmlFor="validationCustom03" className="form-label">Combo Discount</label>
                            <input
                                type="number"
                                className="form-control"
                                id="validationCustom03"
                                name="discount"
                                value={formData.discount}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col6 pl-4 pr-4'>
                    <div className='mb-5'>
                        <label htmlFor="validationCustom01" className="form-label">Combo Name</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom01"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">Looks good!</div>
                    </div>
                    <div className='pt-3'>
                        <ImageUpload onImagesSelect={handleImagesSelect} onImageRemove={handleImageRemove} formData={formData} />
                    </div>
                    <div className='row'>
                     
                        <div className='col-lg-7 end'>
                            <button onClick={UploadImage} className='addItem-btn p-3 w-50 mt-4 mb-3'>Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='brderbx p-3 my-5'>
                <div className='table-responsive' style={{ height: "470px" }}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Deal Name</th>
                                <th>Item Qty</th>
                                <th>Discount</th>
                                <th>Deal Price</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {comboDeals?.map((deal) => (
                                <tr key={deal?.id}>
                                    <td>
                                        <img src={deal?.imageUrl === '' ? P1 : IMAGE_URL + deal?.imageUrl} className='menu-card-image p-1' alt="combo" />
                                        {deal.name}
                                    </td>
                                    <td>{deal?.comboItems.length}</td>
                                    <td>{Currency.currency.name} {deal.discount}</td>
                                    <td>{Currency.currency.name} {deal.price}</td>
                                    <td>
                                        <div className="d-flex text-center">
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id={`flexSwitchCheck${deal.id}`}
                                                    checked={deal.active}
                                                    onChange={(e) => handleSwitchChange(deal, e.target.checked)}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(CreateCombo);
