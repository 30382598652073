import React, { useState, useEffect } from 'react';
import ApiService from '../../services/apiService';
import Logo from '../../Assets/images/yourlogo2.png';
import { useLocation } from 'react-router-dom';
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import "./print.css"
import { FiPrinter } from "react-icons/fi";
import { PiDownloadThin } from "react-icons/pi";
import moment from 'moment';
import { Watermark } from '@hirohe/react-watermark';
import { toWords } from 'number-to-words';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
const PurchaseOrder = ({ }) => {
    const location = useLocation();
    const CompareData = location.state;
    const [Items, setItems] = useState([]);
    const currentDatetimeUTC = new Date().toISOString();
    const [suppliers, setSupplier] = useState();
    const totalAmount = Items?.reduce((sum, row) => sum + (row?.amount || 0), 0);

    useEffect(() => {
        fetchSupplierData()
        setItems(CompareData?.poDetails)

    }, [location?.state]);
    const fetchSupplierData = async () => {
        try {
            const apiService = new ApiService()
            let param = `?SupplierName=${CompareData?.vendorName}`
            const response = await apiService.getApiParamater('GET_SUPPLIER', param);
            setSupplier(response?.data?.result[0]);
        } catch (error) {
            console.error("Error fetching Supplier data:", error);
        }
    };
    const handleBackClick = () => {
        window.history.back();
    };
    const handlePrintClick = async () => {
        const input = document.getElementById('mainboxdsn');
        if (input) {
            const canvas = await html2canvas(input, { scale: 2 });
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF("landscape");
            // pdf.addImage(imgData, "JPEG", 15, 40,180,180);
            pdf.addImage(imgData, 'PNG', 10, 10, 275, 190);
            pdf.save(`Purchase-Order-${CompareData?.pOrderNo}.pdf`);
        } else {
            console.error("Element not found: mainboxdsn");
        }
    };
    return (
        <div className='container p-lg-5 printBG'>
            <div className='d-flex justify-content-between py-5'>
                <div className='' onClick={handleBackClick} ><button className='prntbtn'> <MdKeyboardDoubleArrowLeft size={20} /> Back</button></div>
                <div className=''><button className='prntbtn'><IoMdMail size={20} /> Send To Mail</button></div>
                <div className=''><button className='prntbtn'> <FiPrinter size={20} /> Print</button></div>
                <div className=''><button className='downbtn' onClick={handlePrintClick}> <PiDownloadThin size={20} /></button></div>
            </div>
            <div className='mainboxdsn' id="mainboxdsn">
                <Watermark rotate={-39} textSize={70} gutter={200} textColor="#6E5199" text={CompareData?.status === 1 ? "" : "UNAPPROVED LPO DRAFT"}>
                    <div className=''>
                        <div className='printhead px-5'>
                            <div className='d-flex justify-content-between'>
                                <div className='center'>
                                    <img src={Logo} className="printlogo" alt="Logo" style={{ height: "30px" }} />
                                </div>
                                <div className='mainhead'>
                                    {CompareData?.status === 1 ?
                                        <u>LOCAL PURCHASE ORDER</u>
                                        :
                                        <u>UNAPPROVED LPO DRAFT</u>
                                    }
                                </div>
                                <div className='headtx'>
                                    <div className='py-1'>Order No: {CompareData?.pOrderNo}</div>
                                    <div className='pt-1'>Date: {moment(CompareData?.docDate).format('ll')}</div>
                                </div>
                            </div>
                        </div>
                        <div className='printboxIn p-4 d-flex gap-3'>
                            <div className='printsupbox w-50'>
                                <div className='d-flex justify-content-between'>
                                    <div>Supplier</div>
                                    <div>{suppliers?.supplierName}</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div>Address</div>
                                    <div>{suppliers?.address1}</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div>Attn</div>
                                    <div>---</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div>Phone</div>
                                    <div>{suppliers?.phoneNo1}</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div>Email</div>
                                    <div>{suppliers?.email1}</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div>Quote Ref</div>
                                    <div>---</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div>TRN</div>
                                    <div>{suppliers?.vatRegNo}</div>
                                </div>
                            </div>
                            <div className='printsupbox w-50'>
                                <div className='d-flex justify-content-between'>
                                    <div>Store Location</div>
                                    <div>{CompareData?.location || "---"}</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div>{CompareData?.status === 1 ?
                                        <div>Delivery Date</div>
                                        :
                                        <div>Expected Delivery Date</div>
                                    }</div>
                                    <div>{moment(CompareData?.deliveryDate).format('ll')}</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div>Cost Centre 1</div>
                                    <div>---</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div>Cost Centre 2</div>
                                    <div>---</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div>Contact Person</div>
                                    <div>{CompareData?.contactPerson || "---"}</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div>Contact No</div>
                                    <div>{CompareData?.contatNo || "---"}</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div>Req No  </div>
                                    <div>{CompareData?.requisitionNo || "---"}</div>
                                </div>
                            </div>
                        </div>
                        <div className='printhead2 px-4  mb-5'>
                            <table>
                                <thead className='dynoTable' style={{ backgroundColor: '#6E5199', color: '#fff' }}>
                                    <tr>
                                        <th>Sr no</th>
                                        <th>Item No</th>
                                        <th>Description</th>
                                        <th>Qty</th>
                                        <th>UOM</th>
                                        <th>Unit Price</th>
                                        <th>Discount</th>
                                        <th>VAT %</th>
                                        <th>VAT Amount</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Items?.map((row, index) => (
                                        <tr key={index}>
                                            <td className='m-0 p-0'><input style={{ width: "100px" }} className='p-2' type="text" value={index + 1} disabled /></td>
                                            <td className='m-0 p-0'>
                                                <td className='m-0 p-0'><input style={{ width: "110px" }} className='p-2' disabled value={row.itemCode} /></td>
                                            </td>
                                            <td className='m-0 p-0'>
                                                <td className='m-0 p-0'><input style={{ width: "140px" }} className='p-2' disabled value={row.itemDescription} /></td>
                                            </td>
                                            <td className='m-0 p-0'><input style={{ width: "80px" }} className='p-2' disabled value={row.qty} /></td>
                                            <td className='m-0 p-0'><input style={{ width: "150px" }} className='p-2' disabled value={row.uom} /></td>
                                            <td className='m-0 p-0'><input style={{ width: "120px" }} className='p-2' disabled value={row.baseAmount?.toFixed(2) || 0} /></td>
                                            <td className='m-0 p-0'><input style={{ width: "110px" }} className='p-2' type="text" value={row.sqtytock} disabled /></td>
                                            <td className='m-0 p-0'><input className='p-2' style={{ width: "110px" }} type="text" value={row.vatPerc} disabled /></td>
                                            <td className='m-0 p-0'><input className='p-2 w-100' type="text" value={row.vatAmount} disabled /></td>
                                            <td className='m-0 p-0'><input style={{ width: "100%" }} className='p-2' disabled value={(row.amount)?.toFixed(2) || 0} /></td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colspan="6" className='m-0 p-0'><input className='p-2 w-100' disabled value={"Total"} /></td>
                                        <td className='m-0 p-0'><input className='p-2' style={{ width: "110px" }} disabled value={totalAmount?.toFixed(2)} /></td>
                                        <td className='m-0 p-0'><input className='p-2' style={{ width: "110px" }} disabled value={"---"} /></td>
                                        <td className='m-0 p-0'><input className='p-2 w-100' disabled value={"---"} /></td>
                                        <td className='m-0 p-0'><input className='p-2 w-100' disabled value={"---"} /></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" className='m-0 p-0'><input className='p-2 w-100' disabled value={"Amount In Words"} /></td>
                                        <td colspan="6" className='m-0 p-0'><input className='p-2 w-100' disabled value={toWords(totalAmount)} /></td>
                                        <td colspan="2" className='m-0 p-0'><input className='p-2 w-100' disabled value={totalAmount?.toFixed(2)} /></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" className='m-0 p-0'><input className='p-2 w-100' disabled value={"Payment Terms"} /></td>
                                        <td colspan="6" className='m-0 p-0'><input className='p-2 w-100' disabled value={CompareData?.terms} /></td>
                                        <td colspan="2" className='m-0 p-0'><input className='p-2 w-100' disabled /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='p-5 row'>
                                <div className=' row d-flex justify-content-between'>
                                    <div className='col-3'>
                                        <div className='itmKIns center'>System Admin</div>
                                        <hr />
                                        <div className='itmKIns center'>Prepared On: {"  "} {moment(currentDatetimeUTC).format('D-MMM-YYYY')}</div>
                                    </div>
                                    <div className='col-3'>
                                        <div className='itmKIns center'>.</div>
                                        <hr />
                                        <div className='itmKIns center'>Signature</div>
                                    </div>
                                    <div className='col-3'>
                                        <div className='itmKIns center'>.</div>
                                        <hr />
                                        <div className='itmKIns center'>Signature</div>
                                    </div>
                                </div>
                                <div className='col-3 pt-4'>
                                </div>
                            </div>
                        </div>
                    </div>
                </Watermark>
            </div>
        </div>
    );
};

export default PurchaseOrder;
