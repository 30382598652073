import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';
import Select from 'react-select';
import BankGrid from './grid';
import { GetCountries, GetState, GetCity, GetLanguages, } from "react-country-state-city";
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import { hasPermission } from "../../services/roleService"
import Pagination from '../pagination/pagination';

const BankMaster = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [defaultSelection, setDefault] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [countriesList, setCountries] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();

    useEffect(() => {
        window.history.replaceState({}, '')
        const dep = location.state;
        setFormData({
            recordId: dep?.recordId,
            BankName: dep?.description || "",
            BankCode: dep?.descriptionBI || "",
        });
    }, [location?.state]);
    const [formData, setFormData] = useState({
        recordId: "",
        BankCode: "",
        BankName: "",
        Country: "",
        City: "",
    });
    useEffect(() => {
        GetCountries().then((result) => {
            setCountries(result);
        });
    }, []);
    useEffect(() => {
        GetState(selectedCountry?.value?.id).then((result) => {
            setStateList(result);
        });
    }, [selectedCountry]);
    useEffect(() => {
        GetCity(selectedCountry?.value?.id, selectedState?.value?.id).then((result) => {
            setCityList(result);
        });
    }, [selectedState]);

    useEffect(() => {
        fetchRoleData();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
        if (pageSize) {
            fetchRoleData();
        }
    }, [pageSize]);

    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    const canEditMaster = checkPermission('Bank Master', 'edit');
    const canCreateMaster = checkPermission('Bank Master', 'create');
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async () => {
        if (!formData.BankName) {
            if (!formData.BankName) { toast.warning("Enter Bank Name") }
            return;
        }
        try {
            const apiService = new ApiService()
            let data = {
                descriptionBI: formData?.BankCode,
                description: formData?.BankName,
                city: selectedCity.label,
                country: selectedCountry.label,
            }
            const response = await apiService.post('CREATE_BANK', data);
            if (response?.data?.statusCode === 201) {
                toast.success(response?.data?.message)
                fetchRoleData();
                setFormData({
                    BankCode: "",
                    BankName: "",
                });
                window.location.reload()
                window.history.replaceState({}, '')
            } else if (response?.data?.statusCode === 208) {
                toast?.warning(response?.data?.message)
                fetchRoleData();
            }
        } catch (error) {
            toast.error("Please try Again!")
        }
    }
    const updateFormData = async () => {
        try {
            const apiService = new ApiService();
            let body = {
                recordId: formData?.recordId,
                description: formData?.BankName,
                descriptionBI: formData?.BankCode,
                city: selectedCity.label,
                country: selectedCountry.label,
                isActive: true,
            }
            const res = await apiService.put(`UPDATE_BANK`, body);

            if (res?.data?.statusCode === 201) {
                fetchRoleData()
                toast.success('Form data updated successfully');
                setFormData({
                    BankCode: "",
                    BankName: "",
                });
                window.history.replaceState({}, '')
                window.location.reload()
            } else {
                console.error('Failed to update form data');
            }
        } catch (error) {
            console.error('Error updating form data:', error);
        }
    };
    const fetchRoleData = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService()
            const params = new URLSearchParams();
           params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const response = await apiService.getApiParamater('GET_BANK', `?${params.toString()}`);
            setPagination(response.data.paginationData);
            setRowData(response.data?.result);
        } catch (error) {
            console.error("Error fetching Currency data:", error);
        }
    };
    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        setSelectedCity(null);
    };
    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption);
        setSelectedCity(null);
    };
    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
    };
    
    return (
        <div className='px-4 pt-3'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Bank Master</h5>
                <div className='d-flex justify-content-end gap-3'>
                    <div className='col center'>
                        {location.state ? (
                            <button className='mainbtn ' onClick={updateFormData}>Update</button>
                        ) : (
                            <>{canCreateMaster === true ?
                                <button className='mainbtn ' type="submit" onClick={() => handleSubmit()}>
                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                                :
                                <button className='mainbtn ' type="submit" style={{ cursor: "not-allowed" }}>
                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                            } </>
                        )}
                    </div>
                    <button className='mainbtnRed'><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                </div>
            </div>
            <div className='py-3'>
                <div className='brderbox p-lg-3'>
                    <div className="row pb-3 d-flex justify-content-between">
                        <div className="col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Bank Code</label>
                            <input
                                type="text"
                                onWheel={(e) => e.target.blur()}
                                className="form-Input w-70"
                                disabled={canCreateMaster === true ? false : true}
                                id="validationCustom01"
                                name="BankCode"
                                value={formData.BankCode}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom02" className="form-label  pt-2 start">Bank Name</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom02"
                                disabled={canCreateMaster === true ? false : true}
                                name="BankName"
                                value={formData?.BankName}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-lg-4 d-flex justify-content-between ">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Country</label>
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                className="w-70 p-0"
                                value={selectedCountry}
                                isDisabled={canCreateMaster === true ? false : true}
                                onChange={handleCountryChange}
                                options={countriesList?.map(country => ({ value: country, label: country?.name }))}
                                />
                        </div>
                    </div>
                    <div className="form-group row  d-flex justify-content-between">
                        <div className="col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom19" className="form-label  pt-2 start">State</label>
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                className="w-70 p-0"
                                value={selectedState}
                                isDisabled={canCreateMaster === true ? false : true}
                                onChange={handleStateChange}
                                options={stateList ? stateList?.map(state => ({ value: state, label: state?.name })) : []}
                            />
                        </div>
                        <div className="col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom03" className="form-label  pt-2 start">City *</label>
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                className="w-70 p-0"
                                value={selectedCity}
                                isDisabled={canCreateMaster === true ? false : true}
                                onChange={handleCityChange}
                                options={cityList ? cityList?.map(city => ({ value: city, label: city?.name })) : []}
                            />
                        </div>
                        <div className='col-4'></div>
                    </div>
                </div>
            </div>
            <div className='pt-2'>
                <BankGrid fetchRoleData={fetchRoleData} rowData={rowData} canEditMaster={canEditMaster}/>
            </div>
            <Pagination pagination={pagination} getItems={fetchRoleData} />

        </div>
    )
}

export default BankMaster;
