import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { FaEdit, FaEye } from "react-icons/fa";

const DocumentGrid = ({ rowData, fetchCurrencyData, canEditItemMaster, }) => {
    let navigate = useNavigate();
    const [selectedRowData, setSelectedRowData] = useState(null);
    
    const handleEdit = (data) => {
        navigate("/inventory/Master/Department", { state: data })
    };
    const columnDefs = [
        { headerName: "Department Code", field: "description_BI", filter: true, floatingFilter: true, editable: true, minWidth: 500 },
        { headerName: "Department Name", field: "description", filter: true, floatingFilter: true, editable: true, minWidth: 500 },
        {
            headerName: 'Status', field: 'record_Id', cellRenderer: BtnCellRenderer, minWidth: 520,
            cellRendererParams: {
                handleEdit: handleEdit,
                canEditItemMaster: canEditItemMaster,
                clicked: function (field, isActive) {
                    updateActiveStatus(field, isActive);
                },
            },
        }
    ];
    const updateActiveStatus = async (id, isActive) => {
        try {

            const apiService = new ApiService();
            let body = {
                record_Id: id,
                isActive: isActive
            }
            const res = await apiService.put(`UPDATE_DEPARTMENT_MASTER`, body);
            if (res?.status === 200) {
                console.log('Updated successfully');
                // You may want to refresh the data after successful update
                fetchCurrencyData();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    const actionsRenderer = (props) => {
        return (
            <div>
                <Button variant="primary" onClick={() => handleEditRow(props.data)}>Edit</Button>
            </div>
        );
    };
    const handleEditRow = (rowData) => {
        setSelectedRowData(rowData);
    };
    return (
            <div className='brdor mt-3'>
                <div className="ag-theme-quartz" style={{ height: 500 }}>
                    <AgGridReact
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        rowSelection={'single'}
                        columnDefs={columnDefs}
                        pagination={false}
                        suppressExcelExport={true}
                        domLayout='autoHeight'
                        frameworkComponents={{ actionsRenderer }}
                        suppressRowClickSelection={true}
                    />
                </div>
            </div>
    );
};
class BtnCellRenderer extends Component {

    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this); // Bind edit button handler
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data); // Pass rowData to handleEdit function
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value, !this.props.data.isActive); // Toggle isActive value
    }

    render() {
        const { isActive } = this.props.data; // Extract isActive from rowData
        const { canEditItemMaster } = this.props;

        return (
            <div className='d-flex gap-3'>
                {canEditItemMaster && (
                    <div onClick={this.btnEditClickedHandler}><FaEdit size={25} /></div>
                )}
                {canEditItemMaster && (
                    <div className="form-check form-switch center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive} // Set checked attribute based on isActive value
                            onClick={this.btnClickedHandler}
                        />
                    </div>
                )}
            </div>
            // <button onClick={this.btnClickedHandler} className="gridbtn">Delete</button>
        )
    }
}
export default DocumentGrid;
