import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React from 'react';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import ApiService from '../../../services/apiService';
import { getUserId } from '../../../../Redux/selecters';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const RestaurantGrid = ({ branches, GetBranches }) => {
    const navigate = useNavigate();
    const userId = useSelector(getUserId);

    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("LT");
    };

    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };

    const handleUpdate = async (value, newActiveStatus) => {
        const apiService = new ApiService();
        if (branches) {
            let body = {
                restaurantId: parseInt(value?.restaurantId),
                isActive: newActiveStatus,
                id: parseInt(value?.id),
                loggedInUserId: userId,
                branchName: value?.branchName,
                contactNo: value?.contactNo,
                address: value?.address,
            };
            let res = await apiService.put("UPDATE_BRANCH", body);

            if (res?.data?.statusCode === 200 || res?.data?.statusCode === 201) {
                toast.success(res.data?.message);
                GetBranches();
            } else {
                toast.warning('Waiting for update');
            }
        }
    };

    const handleSwitchChange = async (id, newActiveStatus) => {
        try {
            await handleUpdate(id, newActiveStatus);
        } catch (error) {
            console.error('Error updating AddOn:', error);
        }
    };

    const handleEditClick = (branch) => {
        navigate('/admin/Restaurant/Branch/Create', { state: { branch } });
    };

    const truncateText = (text, length) => {
        if (text.length > length) {
            return text.substring(0, length) + '...';
        }
        return text;
    };

    return (
        <div className='brderbx py-3 px-5'>
            <div className='table-responsive' style={{ height: "470px" }}>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Branch OpenTime</th>
                            <th>Branch CloseTime</th>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Restaurant Name</th>
                            <th>Contact No</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {branches?.map((val) => (
                            <tr key={val?.id}>
                                <td>{moment(val?.branchOpenTime).format('LT')}</td>
                                <td>{moment(val?.branchCloseTime).format('LT')}</td>
                                <td>{truncateText(val.branchName, 15)}</td>
                                <td>{truncateText(val.address, 24)}</td>
                                <td>{val.restaurantName}</td>
                                <td>{val.contactNo}</td>
                                <td>
                                    <div className="d-flex text-center gap-4">
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id={`flexSwitchCheck${val.id}`}
                                                checked={val?.isActive}
                                                onChange={(e) => handleSwitchChange(val, e.target.checked)}
                                            />
                                        </div>
                                        <button onClick={() => handleEditClick(val)} className="btn btn-primary">Edit</button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RestaurantGrid;
