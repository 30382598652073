import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import { ClipLoader } from "react-spinners";
import "../report.css"
import { FaCalendarAlt } from "react-icons/fa";
import ThirdPieChart from './ThirdPieChart';

const ThirdPartySale = ({ Currency }) => {
    let navigate = useNavigate();
    const location = useLocation();
    const [Currencies, setCurrency] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('4');
    useEffect(() => {
    }, []);
    const handleFilterChange = async (selectedFilter) => {
        setSelectedFilter(selectedFilter?.value);
        console.log('Selected Option:', selectedFilter);
    };
    const ThirdFilter = [
        { value: '0', label: 'Day' },
        { value: '1', label: 'Week' },
        { value: '2', label: 'Month' },
        { value: '3', label: 'Year' },
        { value: '4', label: 'All Time' },
    ];
    const handlechart = () => {
        navigate('/admin/Reports/3rdParty')
    }

    const data = [
        { name: 'Group A', value: 400 },
        { name: 'Group B', value: 300 },
        { name: 'Group C', value: 300 },
        { name: 'Group D', value: 200 },
    ];
    return (
        <div className='p-lg-5'>
            <div className='px-lg-5'>
                <div className='d-flex justify-content-between' >
                    <div className='viebtn' onClick={() => handlechart()}>Reports View</div>
                    <div className='d-flex mbl-center gap-3'>
                        <div className='d-flex'>
                            <nav className="nav nav-pills weekfilter ">
                                {ThirdFilter.map((filter) => (
                                    <button
                                        key={filter.value}
                                        className={`nav-link ${selectedFilter === filter.value ? 'activecls' : ''}`}
                                        onClick={() => handleFilterChange(filter)}
                                    >
                                        {filter.label}
                                    </button>
                                ))}
                            </nav>
                        </div>
                        <div className='custom center gap-2'>Custom <FaCalendarAlt /></div>
                    </div>
                </div>
                <div className='mt-5 earn'>
                    <div className='p-4 earntxt'>Most Sold Items</div>
                    <ThirdPieChart data={data} />
                    <div className='d-flex justify-content-around'>
                        <div className=''>
                            <h6>Talabat</h6>$ 26,553
                        </div>
                        <div>
                            <h6>Food Noon</h6>$ 26,553
                        </div>
                        <div>
                            <h6>Careem</h6>$ 26,553
                        </div>
                        <div>
                            <h6>Uber Eats</h6>$ 26,553
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(ThirdPartySale);