import { React, useState, useEffect } from 'react'
import StockCard from './stockCard';
import { stock } from '../dummyData';
import ApiService from '../../../../services/apiService';
import LatestOrder from './stockGrid';
import "../dashboard.css"
const StockInformation = ({ val }) => {

    const [orders, setOrders] = useState([]);


    useEffect(() => {
        GetOrders();
    }, []);

    const GetOrders = async (page) => {
        const apiService = new ApiService();
        try {
            let res = await apiService.get("GET_ORDERS");
            let orders = res?.data?.result;
            let slicedOrders = orders.slice(-10); 
            setOrders(slicedOrders.reverse());
        } catch (error) {
            console.error("Error fetching customer data:", error);
        }
    };
    return (
        <div className='dashcard'>
            <div>
                <div className='chatrtHead'>Top Orders</div>
                {/* <div className='charthead2'>Lorem ipsum dolor sit amet, consectetur</div> */}
            </div>
            <div className="pt-3">
                {/* {orders?.slice(0, 10).map((val, key) => ( */}
                {/* // <StockCard key={key} val={val} /> */}
                <LatestOrder orders={orders} />
                {/* ))} */}
            </div>
        </div>
    );
};

export default StockInformation;
