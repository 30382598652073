import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import AuthorizationGrid from '../purchaseRequisition/authorization';
import MessageGrid from '../purchaseRequisition/messageGrid';
import ApiService from '../../services/apiService';
import { useLocation, useNavigate } from 'react-router-dom';
import RequisitionItemList from '../purchaseRequisition/viewpage/itemlist';
import { FaAngleLeft } from "react-icons/fa6";

const UserStoreView = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const apiService = new ApiService();
    const [messageData, setMessageData] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [requsitionData, setPR] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [usersDetails, setUser] = useState([]);
    let navigate = useNavigate();
    const currentDatetimeUTC = new Date().toISOString();
    const location = useLocation();
    const itemData = location.state;
    const formatDateToDatetimeLocal = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    };
    const [formData, setFormData] = useState({
        recordId: "",
        requisitionNo: "",
        itemType: "",
        quatotionNo: "",
        remarks: "",
        requestor: "",
        requisitionDate: getCurrentLocalDatetime(),
        deliveryDate: getCurrentLocalDatetime(),
        currencyCode: "",
        exchangeRate: "",
        department: [],
        costCenter1: [],
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        deliveryPoint: "",
        location: "",
        contactPerson: "",
        contatNo: "",
        taskCode: "",
        subcatname: "",
    });
    useEffect(() => {
        getCurrency()
        getApprovalUser()
    }, [])

    useEffect(() => {
        const item = location.state;
        const getPR = async () => {
            try {
                const response = await apiService.get('GET_PR');
                const selectedPr = response.data.result?.find(rec => rec.requisitionNo === item.docno)
                setPR(selectedPr)
                debugger
                setFormData({
                    recordId: item.docno,
                    requisitionDate: formatDateToDatetimeLocal(selectedPr?.requisitionDate) || currentDatetimeUTC,
                    deliveryDate: selectedPr?.deliveryDate ? formatDateToDatetimeLocal(selectedPr.deliveryDate) : getCurrentLocalDatetime,
                    requisitionNo: selectedPr?.requisitionNo,
                    itemType: selectedPr?.type,
                    quatotionNo: selectedPr?.contractNo,
                    remarks: selectedPr?.remarks,
                    requestor: selectedPr?.requestor,
                    currencyCode: selectedPr?.currencyCode,
                    costCenter2: selectedPr?.costCenter2,
                    costCenter3: selectedPr?.costCenter3,
                    costCenter4: selectedPr?.costCenter4,
                    deliveryPoint: selectedPr?.deliveryPoint,
                    contactPerson: selectedPr?.contactPerson,
                    contatNo: selectedPr?.contatNo,
                    taskCode: selectedPr?.projectCode,
                    location: selectedPr?.location,
                });

                setSelectedLocation({ label: selectedPr.approvalUser, value: selectedPr?.approvalUser })
                setSelectedDepartment({ label: selectedPr.department, value: selectedPr?.department })
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        getPR()
    }, [location?.state, itemData]);
    const getApprovalUser = async () => {
        try {
            let param = `?Type=${"Purchase Requisition"}&Company=PI&Department=IT`;
            const response = await apiService.getApiParamater('GET_APPROVAL_USER', param);
            if (response?.data?.result && response.data.result.length > 0) {
                setUser(response.data.result);
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    let param = `?isActive=true`
    const getCurrency = async () => {
        const apiService = new ApiService()
        let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
        setCurrency(res?.data?.result)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
    }
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')

    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    };
    const handleBackClick = () => {
        window.history.back();
    };
    return (
        <div className='p-5'>
            <div className='d-flex justify-content-between my-2'>
                <div className='h4'>User Store Request</div>
                <div onClick={handleBackClick} className='mainbtn  flex-end  gap-3'>
                    <FaAngleLeft size={25} className='center' />
                    <div>Back</div>
                </div>
            </div>
            <div className='brderbox p-lg-3 mb-5'>
                <div className="form-group justify-content-between row pb-3">
                    <div className="col">
                        <label htmlFor="ReqNo" className="form-label">Requisition No</label>
                        <input
                            className="form-control"
                            disabled
                            value={itemData.docno}
                            name="ReqNo"
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="deliveryDate" className="form-label">Delivery Date</label>
                        <input
                            type='datetime-local'
                            className='form-control' disabled
                            value={formData.deliveryDate}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Property Group</label>
                        <Select isDisabled />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="catcode" className="form-label">Date </label>
                        <input
                            className='form-control'
                            type='datetime-local' disabled value={formData.requisitionDate}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="deliveryPoint" className="form-label">Delivery Point</label>
                        <input disabled
                            className="form-control"
                            value={formData.deliveryPoint}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Property</label>
                        <Select isDisabled />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Currency</label>
                        <Select isDisabled value={SelectedCurrency} />
                    </div>
                    <div className="col">
                        <label htmlFor="location" className="form-label">Req- Office Location</label>
                        <input disabled value={formData.location} className="form-control" />
                    </div>
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Cost Center 3</label>
                        <Select isDisabled />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="exchangeRate" className="form-label">Exchange Rate</label>
                        <input disabled value={formData.exchangeRate} className="form-control" />
                    </div>
                    <div className="col">
                        <label htmlFor="reqName" className="form-label">Req- Name</label>
                        <input disabled value={formData.reqName} className="form-control" />
                    </div>
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Outlets</label>
                        <Select isDisabled />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Item Type</label>
                        <Select isDisabled />
                    </div>
                    <div className="col">
                        <label htmlFor="contatNo" className="form-label">Req- Contact No</label>
                        <input disabled className="form-control" value={formData.contatNo} />
                    </div>
                    <div className="col">
                        <label htmlFor="subcatname" className="form-label">Location</label>
                        <Select isDisabled value={selectedLocation} />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Stock Type</label>
                        <Select isDisabled />
                    </div>
                    <div className="col">
                        <label htmlFor="systemUser" className="form-label">System User</label>
                        <input disabled className="form-control" value={formData.systemUser} />
                    </div>
                    <div className="col">
                        <label htmlFor="subcatname" className="form-label">Remarks</label>
                        <input disabled
                            className="form-control"
                            value={formData.remarks}
                        />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Template</label>
                        <Select isDisabled />
                    </div>
                    <div className="col">
                        <>
                            <label htmlFor="selectedDepartment" className="form-label">Task Code</label>
                            <Select isDisabled value={selectedDepartment} />
                        </>
                    </div>
                    <div className="col-2">
                        <div className='start mt-4 brderbox p-3' disabled>
                            <a href='' disabled>
                                Attachment
                            </a>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className='start  mt-4 brderbox p-3' disabled>
                            <a href='' disabled>
                                Print
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="topnav2 maon-BG ">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Requisition
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Message
                        </div>
                    </div>

                </div>
            </div>
            <div className="grid-1 brderbox2 p-4 mb-3">
                {gridVisibility[0] && (
                    <RequisitionItemList itemData={requsitionData} selectedLocation={selectedLocation} />
                )}
                {gridVisibility[1] && (
                    <AuthorizationGrid
                        handleAuthorization={handleAuthorization}
                        usersDetails={usersDetails}
                        setUser={setUser}
                        itemData={formData}
                    />
                )}
                {gridVisibility[2] && (
                    <div className="grid-3 border">
                        <MessageGrid handleMessage={handleMessage} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default UserStoreView