import { useNavigate, useLocation } from 'react-router-dom';
import ApiService from '../../services/apiService';
import React, { useState, useEffect } from 'react';
import CategoryGridMaster from './categoryGrid';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Select from 'react-select';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUserId, getUserName } from '../../../Redux/selecters';
import { hasPermission } from "../../services/roleService"
import { ClipLoader } from 'react-spinners';
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
const CategoryMaster = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const [catCode, setCatCode] = useState()
    const userId = useSelector(getUserId);
    const userName = useSelector(getUserName);
    const [pagination, setPagination] = useState();
    const [pageSize, setPageSize] = useState(20);
    const [loader, setLoader] = useState(false);
    const [accounts, setAccounts] = useState();
    const [permissions, setPermissions] = useState([]);
    debugger
    useEffect(() => {
        fetchAccountData()
        fetchCategoryData();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
    }, []);
    useEffect(() => {
        fetchCategoryData();
    }, [pageSize]);
    const [formData, setFormData] = useState({
        recordId: "",
        catcod: "",
        Category: "",
        InventoryAccounts: [],
        grnAccount: [],
        wipAccount: [],
        invoiceAccount: [],
        adjustmentAccount: [],
        cgsAccount: [],
        issueAccount: [],
        wastageAccount: [],
    });
    useEffect(() => {
        window.history.replaceState({}, '')
        const item = location.state;
        setCatCode(item?.catCode)
        setFormData({
            recordId: item?.recordId || "",
            catcod: item?.catCode || "",
            Category: item?.catName || "",
        });
        const selectedInvntory = accounts?.find(inv => inv.accountName === item?.inventoryAccountCode)
        if (selectedInvntory) {
            setFormData(prevState => ({
                ...prevState,
                InventoryAccounts: { label: selectedInvntory.accountName, value: selectedInvntory.value }
            }));
        }
        const selectedAdjustmentAccount = accounts?.find(adj => adj.accountName === item?.adjustmentAccountCode)
        if (selectedAdjustmentAccount) {
            setFormData(prevState => ({
                ...prevState,
                adjustmentAccount: { label: selectedAdjustmentAccount.accountName, value: selectedAdjustmentAccount.value }
            }));
        }
        const selectedGrnAccountCode = accounts?.find(grn => grn.accountName === item?.grnAccountCode)
        if (selectedGrnAccountCode) {
            setFormData(prevState => ({
                ...prevState,
                grnAccount: { label: selectedGrnAccountCode.accountName, value: selectedGrnAccountCode.value }
            }));
        }
        const selectedissueAccount = accounts?.find(issue => issue.accountName === item?.issueAccountCode)
        if (selectedissueAccount) {
            setFormData(prevState => ({
                ...prevState,
                issueAccount: { label: selectedissueAccount.accountName, value: selectedissueAccount.value }
            }));
        }
        const selectedwastageAccount = accounts?.find(waste => waste.accountName === item?.wastageAccountCode)
        if (selectedwastageAccount) {
            setFormData(prevState => ({
                ...prevState,
                wastageAccount: { label: selectedwastageAccount.accountName, value: selectedwastageAccount.value }
            }));
        }
        const selectedcgsAccount = accounts?.find(cgs => cgs.accountName === item?.cgsAccountCode)
        if (selectedcgsAccount) {
            setFormData(prevState => ({
                ...prevState,
                cgsAccount: { label: selectedcgsAccount.accountName, value: selectedcgsAccount.value }
            }));
        }
        const selectedinvoiceAccountCode = accounts?.find(invoice => invoice.accountName === item?.invoiceAccountCode)
        if (selectedinvoiceAccountCode) {
            setFormData(prevState => ({
                ...prevState,
                invoiceAccount: { label: selectedinvoiceAccountCode.accountName, value: selectedinvoiceAccountCode.value }
            }));
        }
        const selectedwipAccount = accounts?.find(wip => wip.accountName === item?.workInProgressAccountCode)
        if (selectedwipAccount) {
            setFormData(prevState => ({
                ...prevState,
                wipAccount: { label: selectedwipAccount.accountName, value: selectedwipAccount.value }
            }));
        }
    }, [location?.state])
    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    const canEditItemMaster = checkPermission('Category Master', 'edit');
    const canCreateItemMaster = checkPermission('Category Master', 'create');
    const fetchAccountData = async (pageChange) => {
        try {
            const apiService = new ApiService()
            const response = await apiService.get('GET_ACCOUNTS');
            setAccounts(response.data.result)
        } catch (error) {
            console.error("Error fetching packing data:", error);
        }
    };
    const handleIssueAccountChange = (selectedOption) => {
        setFormData({ ...formData, issueAccount: selectedOption });
    }
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const handleGRNAccountChange = (selectedOption) => {
        setFormData({ ...formData, grnAccount: selectedOption });
    }
    const handleAdjustmentAccountChange = (selectedOption) => {
        setFormData({ ...formData, adjustmentAccount: selectedOption });
    }
    const handleWIPAccountChange = (selectedOption) => {
        setFormData({ ...formData, wipAccount: selectedOption });
    }
    const handleInvoiceAccountChange = (selectedOption) => {
        setFormData({ ...formData, invoiceAccount: selectedOption });
    }
    const handleCGSAccountChange = (selectedOption) => {
        setFormData({ ...formData, cgsAccount: selectedOption });
    }
    const handleWastageAccountChange = (selectedOption) => {
        setFormData({ ...formData, wastageAccount: selectedOption });
    }
    const handleInventoryAccountChange = (selectedOption) => {
        setFormData({ ...formData, InventoryAccounts: selectedOption });
    }
    const handleSubmit = async () => {
        setLoader(true)
        debugger
        if (!formData.Category) {
            if (!formData.Category) { toast.warning("Enter Category Name") }
            setLoader(false)
            return;
        }
        try {
            const apiService = new ApiService()
            let data = {
                recordId: 0,
                catCode: catCode,
                catName: formData?.Category,
                modifiedBy: userName,
                inventoryAccountCode: formData?.InventoryAccounts?.label,
                grnAccountCode: formData?.grnAccount?.label,
                invoiceAccountCode: formData?.invoiceAccount?.label,
                cgsAccountCode: formData?.cgsAccount?.label,
                adjustmentAccountCode: formData?.adjustmentAccount?.label,
                wastageAccountCode: formData?.wastageAccount?.label,
                workInProgressAccountCode: formData?.wipAccount?.label,
                issueAccountCode: formData?.issueAccount?.label,
                projectCode: formData?.recordId?.label,
                finishedGoodsAccountCode: formData?.recordId?.label
            }
            const response = await apiService.post('CREATE_UPDATE_CATEGORY', data);
            debugger
            if (response?.data?.statusCode === 201) {
                toast.success(response?.data?.message)
                setFormData({
                    recordId: " ",
                    catcod: " ",
                    Category: " ",
                    InventoryAccounts: [],
                    grnAccount: [],
                    wipAccount: [],
                    invoiceAccount: [],
                    adjustmentAccount: [],
                    cgsAccount: [],
                    issueAccount: [],
                    wastageAccount: [],
                });
                setCatCode()
                setLoader(false)
                fetchCategoryData();
            } else if (response?.data?.statusCode === 208) {
                toast?.warning(response?.data?.message)
                fetchCategoryData();
                setLoader(false)
            }
        } catch (error) {
            toast.error("Please try Again!")
            setLoader(false)
        }
    }
    const updateFormData = async () => {
        setLoader(true)
        try {
            const apiService = new ApiService();
            let body = {
                recordId: formData?.recordId,
                catCode: catCode,
                catName: formData?.Category,
                modifiedBy: userName,
                inventoryAccountCode: formData?.InventoryAccounts?.label,
                grnAccountCode: formData?.grnAccount?.label,
                invoiceAccountCode: formData?.invoiceAccount?.label,
                cgsAccountCode: formData?.cgsAccount?.label,
                adjustmentAccountCode: formData?.adjustmentAccount?.label,
                wastageAccountCode: formData?.wastageAccount?.label,
                workInProgressAccountCode: formData?.wipAccount?.label,
                issueAccountCode: formData?.issueAccount?.label,
                projectCode: formData?.recordId?.label,
                finishedGoodsAccountCode: formData?.recordId?.label
            }
            const res = await apiService.post(`CREATE_UPDATE_CATEGORY`, body);
            if (res?.data?.statusCode === 201) {
                toast.success(` ${catCode} updated successfully`);
                setFormData({
                    recordId: "",
                    catcod: "",
                    Category: "",
                    InventoryAccounts: [],
                    grnAccount: [],
                    wipAccount: [],
                    invoiceAccount: [],
                    adjustmentAccount: [],
                    cgsAccount: [],
                    issueAccount: [],
                    wastageAccount: [],
                })
                window.location.reload()
                window.history.replaceState({}, '')
                setLoader(false)
                fetchCategoryData()
            } else {
                console.error('Failed to update Category');
                setLoader(false)
            }
        } catch (error) {
            console.error('Error updating Category', error);
            setLoader(false)
        }
    }
    const fetchCategoryData = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService()
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const response = await apiService.getApiParamater('GET_CATEGORY', `?${params.toString()}`);
            const Code = await apiService.get('GET_CATEGORY_CODE');
            setCatCode(Code.data.catcode)
            const packingData = response?.data;
            setRowData(packingData);
            setPagination(response.data.paginationData)
        } catch (error) {
            console.error("Error fetching packing data:", error);
        }
    };
    const [rowData, setRowData] = useState([]);
    return (
        <div className='px-4 pt-3'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Category</h5>
                <div className='d-flex justify-content-end gap-3'>
                    <div className=''>
                        {location.state ? (
                            <>{loader === true ?
                                <button className='mainbtn '><ClipLoader size={30} color='#fff' /></button> :
                                <>
                                    {canEditItemMaster && (
                                        <button className='mainbtn ' onClick={updateFormData}>Update</button>
                                    )}
                                </>
                            }
                            </>
                        ) : (
                            <>{loader === true ?
                                <button className='mainbtn '><ClipLoader size={30} color='#fff' /></button> :
                                <>
                                    {canCreateItemMaster === true ?
                                        <button className='mainbtn ' onClick={handleSubmit}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Save</div></button> :
                                        <button className='mainbtn disabled' style={{ cursor: "not-allowed" }}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Save</div>
                                        </button>
                                    }
                                </>
                            }
                            </>
                        )}
                    </div>
                    <button className='mainbtnRed'><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                </div>
            </div>
            <div className='py-lg-2'>
                <div className='brderbox p-lg-3'>
                    <div className="form-group row d-flex justify-content-between pb-3">
                        <div className="col-md-3 col-lg-6 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Category Code</label>
                            <input
                                type="text"
                                disabled={canCreateItemMaster === true ? false : true}
                                className="form-Input w-75"
                                id="validationCustom01"
                                name="catCode"
                                value={catCode}
                                required
                            />
                        </div>
                        <div className="col-md-3 col-lg-6 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Category Name *</label>
                            <input
                                type="text"
                                className="form-Input w-75"
                                id="validationCustom01"
                                disabled={canCreateItemMaster === true ? false : true}
                                name="Category"
                                value={formData.Category}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group row d-flex justify-content-between pb-3">
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Inventory Acc</label>
                            {!location?.state ?
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    value={formData.InventoryAccounts}
                                    onChange={handleInventoryAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                /> :
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.InventoryAccounts}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleInventoryAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                />
                            }
                        </div>
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">GRN Acc</label>
                            {!location?.state ?
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.grnAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleGRNAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                /> :
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.grnAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleGRNAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                />
                            }
                        </div>
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Adjustment Acc</label>
                            {!location?.state ?
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.adjustmentAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleAdjustmentAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                /> :
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.adjustmentAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleAdjustmentAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                />
                            }
                        </div>
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Work In Progress</label>
                            {!location?.state ?
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.wipAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleWIPAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                /> :
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    value={formData.wipAccount}
                                    onChange={handleWIPAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                />
                            }
                        </div>
                    </div>
                    <div className="form-group row d-flex justify-content-between">
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Invoice Acc</label>
                            {!location?.state ?
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.invoiceAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleInvoiceAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                /> :
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.invoiceAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleInvoiceAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                />
                            }
                        </div>
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">CGS Acc</label>
                            {!location?.state ?
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.cgsAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleCGSAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                /> :
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.cgsAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleCGSAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                />
                            }
                        </div>
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Wastage Acc</label>
                            {!location?.state ?
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.wastageAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleWastageAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                /> :
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.wastageAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleWastageAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                />
                            }
                        </div>
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Issue Acc</label>
                            {!location?.state ?
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.issueAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleIssueAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                /> :
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.issueAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleIssueAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-2'>
                <CategoryGridMaster rowData={rowData?.result}
                    pageSize={pageSize}
                    fetchCategoryData={fetchCategoryData}
                    canEditItemMaster={canEditItemMaster} pagination={pagination} setPageSize={setPageSize} />
            </div>
        </div>
    )
}
export default CategoryMaster