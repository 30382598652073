import React, { useEffect, useState } from 'react';
import CostumerHeader from '../Header/header';
import "./costumer.css";
import CostumerNavBar from './NavBar/navBar';
import CostumerMenuCard from './Menu Card/menuCard';
import CostumerBanner from '../Banners/banner';
import SearchNav from './searchNav';
import Bucket from '../Bucket/addBucket';
import ApiService from '../../services/apiService';
import { useLocation } from 'react-router';
import { ReactComponent as Cart } from '../../assets/images/svgIcons/shopping-cart-add.svg';
import { useDispatch } from 'react-redux';
import { getCartData, getComboData } from '../../../Redux/selecters';
import { useSelector } from 'react-redux';

const CustomerMenu = () => {
    const location = useLocation();
    const dispatch = useDispatch()

    // const [cartItems, setCartItems] = useState([]);
    const [menu, setMenu] = useState([]);
    const cartItems = useSelector(getCartData);
    const menuItems = useSelector(getComboData);
    

    useEffect(() => {
        GetMenu();
    }, []);


    useEffect(() => {
        if (location.state && location.state.categorymenu) {
            setMenu(location.state.categorymenu);
        }
    }, [location]);

    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);

    const addToCart = (item) => {
        const existingItem = cartItems.find((cartItem) => cartItem?.id === item?.itemData?.id);
        if (existingItem) {
            const updatedCartItems = cartItems.map((cartItem) =>
                cartItem.id === item?.itemData?.id ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
            );
        } else {
            // If the item doesn't exist, add it to the cart
            // setCartItems([...cartItems, { ...item, quantity: 1 }]);
        }
    };

    const removeFromCart = (index) => {
        const newCartItems = [...cartItems];
        newCartItems.splice(index, 1);
        // setCartItems(newCartItems);
    };

    const updateQuantity = (index, newQuantity) => {
        const updatedCartItems = [...cartItems];
        updatedCartItems[index].quantity = newQuantity;
        // setCartItems(updatedCartItems);
    };

    const GetMenu = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get("GET_MENU");
            const filteredMenu = res.data.result.filter(item => item?.active === true);
            setMenu(filteredMenu);
        } catch (error) {
            console.error('Error fetching menu data:', error);
        }
    };

    return (
        <div className="">
            <CostumerHeader />
            <div className='container-fluid m-0 p-0 pt-5 px-5' >
                <SearchNav />
                <div className="pt-5 d-flex row">
                    <div className='col-lg-11'>
                        <CostumerNavBar />
                    </div>
                    <div className='col-lg-1'>
                        <button class="cartBtn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling"><Cart />
                            {cartItems?.length + menuItems?.length}
                        </button>
                    </div>
                </div>
            </div>
            {/* <div className='px-5 pt-5 p-0'>
                <CostumerBanner />
            </div> */}
            <div className="px-5">
                <div className="row">
                    <div className={'col-12'}>
                        <div className="grid">
                            {menu?.map((val, key) => (
                                <CostumerMenuCard key={key} val={val} addToCart={addToCart} />
                            ))}
                        </div>
                    </div>

                    <div class="offcanvas offcanvas-end " data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel"
                        style={{ overflow: "auto" }} >
                        <div class="offcanvas-header">
                            <h5 class="offcanvas-title" id="offcanvasScrollingLabel">Your Cart Items</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div>
                            <Bucket cartItems={cartItems} removeFromCart={removeFromCart} updateQuantity={updateQuantity} />
                        </div>
                    </div>
                </div>
            </div>
            {/* <SideBar /> */}
        </div>
    )
}

export default CustomerMenu