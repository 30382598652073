import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';
import BranchGrid from './grid';
import Select from "react-select";
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import { hasPermission } from "../../services/roleService"
import Pagination from '../pagination/pagination';

const BranchMaster = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [defaultSelection, setDefault] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [rowData, setRowData] = useState([]);

    const [formData, setFormData] = useState({
        BranchCode: "",
        BranchName: "",
        Company: []
    });
    useEffect(() => {
        window.history.replaceState({}, '')
        const dep = location.state;
        const selectedCompany = companies?.find(payment => payment?.record_id === parseInt(dep?.companyId));
        setFormData({
            recordId: dep?.record_Id,
            BranchName: dep?.branchName || "",
            BranchCode: dep?.branchCode || "",
            Company: { label: selectedCompany?.companyName, value: selectedCompany },
        });
    }, [location?.state, companies]);

    useEffect(() => {
        fetchBranchData();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
        if (pageSize) {
            fetchBranchData();
        }
    }, [pageSize]);

    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    const canEditMaster = checkPermission('Branch Master', 'edit');
    const canCreateMaster = checkPermission('Branch Master', 'create');

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleCompanyChange = (selectedOption) => {
        setFormData({ ...formData, Company: selectedOption });
    };
    const fetchBranchData = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService()
            const CompanyRes = await apiService.get('GET_COMPANY');
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const response = await apiService.getApiParamater('GET_BRANCH', `?${params.toString()}`);
            setPagination(response.data.paginationData);
            const CurrencyData = response.data?.result;
            setCompanies(CompanyRes.data.result);
            setRowData(CurrencyData);
        } catch (error) {
            console.error("Error fetching Currency data:", error);
        }
    };
    const handleSubmit = async () => {
        debugger
        if (!formData.Company || !formData.BranchName) {
            if (!formData.Company) { toast.warning("Enter Select Company") }
            if (!formData.BranchName) { toast.warning("Enter Branch Name") }
            return;
        }
        try {
            const apiService = new ApiService()
            let data = {
                branchCode: formData?.BranchCode,
                companyId: formData?.Company.value.companyId,
                isActive: true,
                branchName: formData?.BranchName,
                createdBy: formData?.InventoryAccounts?.label,
            }
            const response = await apiService.post('CREATE_BRANCH', data);

            if (response?.data?.statusCode === 201) {
                toast.success(response?.data?.message)
                fetchBranchData();
                setFormData({
                    BranchCode: "",
                    BranchName: "",
                    Company: []
                });
            } else if (response?.data?.statusCode === 208) {
                toast?.warning(response?.data?.message)
                fetchBranchData();
            }
        } catch (error) {
            toast.error("Please try Again!")
        }
    }
    const updateFormData = async () => {
        try {
            const apiService = new ApiService();
            let data = {
                record_Id: formData.recordId,
                branchCode: formData?.BranchCode,
                companyId: formData?.Company.value.companyId,
                isActive: true,
                branchName: formData?.BranchName,
                createdBy: formData?.InventoryAccounts?.label,
            }
            const res = await apiService.put(`UPDATE_BRANCH`, data);
            if (res?.data?.statusCode === 201) {
                toast.success(res?.data?.message)
                fetchBranchData();
                window.location.reload()
                window.history.replaceState({}, '')
                setFormData({
                    BranchCode: "",
                    BranchName: "",
                    Company: []
                });
            } else if (res?.data?.statusCode === 208) {
                toast?.warning(res?.data?.message)
                fetchBranchData();
            }
        } catch (error) {
            console.error('Error updating form data:', error);
        }
    };
    return (
        <div className='px-4 pt-3'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Branch Master</h5>
                <div className='d-flex justify-content-end gap-3'>
                    <div className='col center'>
                        {location.state ? (
                            <button className='mainbtn ' onClick={updateFormData}>Update</button>
                        ) : (
                            <>{canCreateMaster === true ?
                                <button className='mainbtn ' type="submit" onClick={() => handleSubmit()}>
                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                                :
                                <button className='mainbtn ' type="submit" style={{ cursor: "not-allowed" }}>
                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                            } </>
                        )}
                    </div>
                    <button className='mainbtnRed'><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                </div>
            </div>
            <div className='py-lg-3'>
                <div className='brderbox p-lg-3'>
                    <div className="form-group row">
                        <div className="col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Branch Code</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom01"
                                disabled={canCreateMaster === true ? false : true}
                                name="BranchCode"
                                value={formData.BranchCode}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom02" className="form-label  pt-2 start">Select Company</label>
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                isDisabled={canCreateMaster === true ? false : true}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                className="w-70 p-0"
                                value={formData?.Company}
                                onChange={(selectedOption) => handleCompanyChange(selectedOption)}
                                options={companies?.map((comp) => ({ value: comp, label: comp?.companyName }))}
                            />
                        </div>
                        <div className="col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom02" className="form-label  pt-2 start">Branch Name</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                disabled={canCreateMaster === true ? false : true}
                                id="validationCustom02"
                                name="BranchName"
                                value={formData?.BranchName}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-3'>
                <BranchGrid fetchBranchData={fetchBranchData} rowData={rowData} canEditMaster={canEditMaster} />
            </div>
            <Pagination pagination={pagination} getItems={fetchBranchData} />
        </div>
    )
}

export default BranchMaster;
