// import { v4 as uuidv4 } from 'uuid';
// import React, { useState } from 'react';

// const PaymentComponent = () => {
//   const [paymentStatus, setPaymentStatus] = useState(null);

//   const handleCashPayment = () => {
//     alert('Cash Payment Successful!');
//   };

//   const handleCardPayment = () => {
//     const paymentData = {
//       apiToken: "d0cef39e246de8a0cd06ef11902545f272c40920a9c47e17143a6525cd5275fe",
//       apiKey: "8293c2488dbfad5d362fbd6b4145ea31bb589bd0fac7caeeb47ce4a11b6c7ba7",
//       tranType: "SALE",
//       currency: "USD",
//       amount: 100,
//       shop: "Flocash E2E USD",
//       posId: "et_addis_ababa_tc_demo01",
//       preferredInstrument: "CARD",
//       messageId: uuidv4(),
//     };

//     initiatePayment(paymentData);
//   };

//   // const initiatePayment = (paymentData) => {
//   //   fetch("https://api.flocash.com/v1/payment", {
//   //     method: 'POST',
//   //     headers: {
//   //       'Content-Type': 'application/json',
//   //       'Authorization': `Bearer ${paymentData.apiToken}`
//   //     },
//   //     body: JSON.stringify({
//   //       messageId: paymentData.messageId,
//   //       posId: paymentData.posId,
//   //       currency: paymentData.currency,
//   //       tranType: paymentData.tranType,
//   //       requestAmount: paymentData.amount,
//   //       preferredInstrument: paymentData.preferredInstrument,
//   //       shop: paymentData.shop
//   //     })
//   //   })
//   //     .then(response => {
//   //       console.log(response); // Log the raw response for debugging
//   //       return response.json();
//   //     })
//   //     .then(data => {
//   //       if (data.tranStatus === 'APPROVED') {
//   //         setPaymentStatus('Transaction Approved!');
//   //       } else {
//   //         setPaymentStatus('Transaction Failed!');
//   //       }
//   //     })
//   //     .catch(error => {
//   //       console.error('Error:', error);
//   //       setPaymentStatus('Transaction Error!');
//   //     });
//   // };
//   const initiatePayment = async (paymentData) => {
//    await fetch("https://api.flocash.com/v1/payment", {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${paymentData.apiToken}`
//         },
//         body: JSON.stringify({
//             messageId: paymentData.messageId,
//             posId: paymentData.posId,
//             currency: paymentData.currency,
//             tranType: paymentData.tranType,
//             requestAmount: paymentData.amount,
//             preferredInstrument: paymentData.preferredInstrument,
//             shop: paymentData.shop
//         })
//     })
//     .then(response => {
//         console.log(response); // Log the raw response for debugging
//         if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//         }
//         return response.json();
//     })
//     .then(data => {
//         if (data.tranStatus === 'APPROVED') {
//             setPaymentStatus('Transaction Approved!');
//         } else {
//             setPaymentStatus('Transaction Failed!');
//         }
//     })
//     .catch(error => {
//         console.error('Error:', error);
//         setPaymentStatus('Transaction Error!');
//     });
// };

//   return (
//     <div>
//       <h1>Payment Demo</h1>
//       <button onClick={handleCashPayment}>Cash Payment</button>
//       <button onClick={handleCardPayment}>Card Payment</button>
//       {paymentStatus && <p>{paymentStatus}</p>}
//     </div>
//   );
// };

// export default PaymentComponent;
import React, { useEffect } from 'react';

const PaymentComponent = () => {
  
  const handleCashPayment = () => {
    alert('Cash Payment Successful!');
  };

  const handleCardPayment = () => {
    const paymentData = {
      merchant: "merchant@test.com",
      order_id: "12345678",
      amount: 10,
      currency: "USD",
      custom: "1234567890",
    };

    // Check if the Flocash SDK is loaded
    if (window.FlocashSDK) {
      window.FlocashSDK.processPayment(paymentData)
        .then(response => {
          console.log("Payment Response:", response);
          if (response.status === 'approved') {
            alert('Transaction Approved!');
          } else {
            alert('Transaction Failed!');
          }
        })
        .catch(error => {
          console.error("Payment Error:", error);
          alert('Transaction Error!');
        });
    } else {
      console.error("Flocash SDK not loaded.");
      alert('Payment SDK not available.');
    }
  };

  return (
    <div>
      <h1>Flocash Payment</h1>
      <button onClick={handleCashPayment}>Cash Payment</button>
      <button onClick={handleCardPayment}>Card Payment</button>
    </div>
  );
};

export default PaymentComponent;
