import React from 'react';
import { useEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { newInstance } from '@jsplumb/browser-ui';
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

const TableDragSection = ({ children, setInstance }) => {
    

    useEffect(() => {
        const jsPlumbInstance = newInstance({
            container: document.getElementById('container'),
            elementsDraggable: false,
        });
        setInstance(jsPlumbInstance);

        return () => {
        };
    }, [setInstance]);
    const backgroundImage = children && children[0]?.props?.state?.imageUrl
    ? `url(${IMAGE_URL + children[0].props.state.imageUrl})`
    : '';
    return (
        <div
            id="container"
            style={{
                backgroundImage: backgroundImage,
                backgroundSize: 'cover',
                width: '100%',
                height: '630px',
                position: 'relative'
            }}
        >
            {children}
        </div>
    );
};

export default TableDragSection;
