// service.js

import moment from "moment";

export function generateUniqueVoucherNumber() {
    const timestamp = Date.now().toString(); // Get current timestamp in milliseconds
    const randomPart = Math.floor(1000 + Math.random() * 9000).toString(); // Generate a random 4-digit number
    const uniqueNumber = timestamp.slice(-4) + randomPart; // Combine the last 4 digits of the timestamp with the random part

    return uniqueNumber.slice(-5); // Ensure it's 5 digits
}
// export async function printReceipt(val, formData, totalOrderAmount) {
//     const commands = generateReceiptCommands(val, formData, totalOrderAmount);
//     const device = await requestPrinter();
    
//     if (device) {
//         const encoder = new TextEncoder();
//         const data = encoder.encode(commands);

//         try {
//             await device.transferOut(1, data); // Endpoint number might need adjustment
//             await device.close();
//             console.log('Print successful');
//         } catch (error) {
//             console.error('Error printing receipt:', error);
//         }
//     }
// }

function generateReceiptCommands(val, formData, totalOrderAmount) {
    const ESC = '\x1B'; // ESC byte in hex notation
    const NEW_LINE = '\x0A'; // LF byte in hex notation

    let commands = `${ESC}@`; // Initialize printer

    commands += `${ESC}!0x38`; // Double height + width text
    commands += '*** Receipt ***' + NEW_LINE;
    commands += `${ESC}!0x00`; // Normal text

    commands += 'Ordered By: ' + formData.name + NEW_LINE;
    commands += 'Cashier #: ' + formData.cashierNumber + NEW_LINE;
    commands += 'Created at: ' + moment().format('YYYY-MM-DD HH:mm') + NEW_LINE;

    commands += 'Order Details' + NEW_LINE;
    commands += '------------------------------' + NEW_LINE;
    val.forEach((item) => {
        commands += `${item.itemData.id} ${item.quantity} ${item.itemData.name} ${item.itemData.price}` + NEW_LINE;
    });

    commands += '------------------------------' + NEW_LINE;
    commands += `SubTotal: ${totalOrderAmount}` + NEW_LINE;
    commands += `Total Amount: ${totalOrderAmount}` + NEW_LINE;

    commands += NEW_LINE + NEW_LINE + NEW_LINE; // Feed three lines
    commands += `${ESC}m`; // Cut paper

    return commands;
}
export async function requestPrinter1() {
    try {
        
        const device = await navigator.usb.requestDevice();
        await device.open();
        await device.selectConfiguration(1);
        await device.claimInterface(0);
        return device;
    } catch (error) {
        console.error('Error accessing USB device:', error);
    }
}
export async function requestPrinter() {
    try {
        const devices = await listUSBDevices();
        
        devices.forEach(device => {
            console.log('Device:', device);
            console.log('Vendor ID:', device.vendorId);
            console.log('Product ID:', device.productId);
            // You can access other properties of the USBDevice object as needed
        });
    } catch (error) {
        console.error('Error logging connected devices:', error);
    }
}

export async function listUSBDevices() {
    try {
        
        navigator.usb.onconnect = event => {
            
            // Handle USB device connection event
            console.log('USB device connected:', event.device);
        };
        const devices = await navigator.usb.getDevices();
        return devices;
    } catch (error) {
        
        console.error('Error listing USB devices:', error);
        return [];
    }
}



// Function to detect and print receipt
export function printReceipt() {
    let usb = "usb";

    // Find the printer device by vendor and product ID
    const printer = usb.findByIds(0x04B8, 0x0202);

    if (!printer) {
        console.error('Printer not found');
        return;
    }

    try {
        // Open the printer device
        printer.open();

        // Claim the interface
        const interface2 = printer.interface(0);
        interface2.claim();

        // Send ESC/POS commands to the printer to print the receipt
        const receiptData = generateReceiptData(); // Function to generate receipt data
        printer.controlTransfer(0x40, 0x80, 0x01, 0x02, Buffer.from(receiptData), (error) => {
            if (error) {
                console.error('Error sending data to printer:', error);
            } else {
                console.log('Receipt printed successfully');
            }

            // Release the interface and close the printer
            interface2.release(() => printer.close());
        });
    } catch (error) {
        console.error('Error printing receipt:', error);
    }
}

// Function to generate receipt data (ESC/POS commands)
export function generateReceiptData() {
    // Generate ESC/POS commands to format and print the receipt
    // Example:
    const commands = [
        '\x1B\x40', // Initialize printer
        '\x1B\x61\x01', // Center align text
        'Sample Receipt\n',
        '\x1B\x61\x00', // Left align text
        '-------------------------\n',
        'Item 1       $10.00\n',
        'Item 2       $15.00\n',
        '-------------------------\n',
        'Total:       $25.00\n',
        '-------------------------\n',
        '\x0A\x0A\x0A', // Feed lines
    ];
    return commands.join('');
}

