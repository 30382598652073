import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useRef, useState, Component } from 'react';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';


const EmployeeGrid = ({ employee, onRowSelect }) => {
    const gridRef = useRef();
    let navigate = useNavigate();

    const handleShow = (field) => {

        navigate(`/admin/employee/${field?.employee_Name}`, { state: { field } });
    }

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        if (selectedRows.length === 1) {
            const selectedRowData = selectedRows[0];
            // Call the function passed from the parent component with selected row data
            onRowSelect(selectedRowData);
        }
    };
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("MMM Do YY");
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const orderItemsRenderer = (params) => {
        const orderItems = params.data.orderItems;
        const itemNames = orderItems?.map((item) => item.itemName).join(', ');
        return itemNames;
    };

    const [colDefs, setColDefs] = useState([
        { field: "id", filter: true, floatingFilter: true, width: 100 },
        { field: "email", filter: true, floatingFilter: true },
        { field: "employee_Name", filter: true, floatingFilter: true, width: 200 },
        { headerName: 'Job ', field: "department", filter: true, floatingFilter: true, width: 245 },
        { headerName: 'Phone Number', field: "phoneNumber", filter: true, floatingFilter: true, width: 250 },
        { headerName: 'Address', field: "exp3", filter: true, floatingFilter: true, width: 250 },
        { field: "city", filter: true, floatingFilter: true },
        {
            headerName: 'Action',
            field: 'id', Width: 100,
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                clicked: function (params) {
                    handleShow(params)
                },
            },

        },
    ]);
    return (
        <div className="ag-theme-quartz" style={{ height: 550, backgroundColor: "#6E5199" }}>
            <AgGridReact
                ref={gridRef}
                rowData={employee}
                onSelectionChanged={onSelectionChanged}
                rowSelection="single"
                columnDefs={colDefs}
                frameworkComponents={{ orderItemsRenderer }}
                getRowStyle={(params) => {
                    if (params.node.rowIndex % 2 === 0) {
                        return { background: '#F0EDF5' };
                    } else {
                        return { background: '#fff' };
                    }
                }}
                suppressRowClickSelection={true}
            />
        </div>
    )
}
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { data, clicked } = this.props;
        clicked(data);
    }

    render() {
        return (
            <button onClick={this.btnClickedHandler} className="delbtn" style={{ height: "39px", padding: "0px" }}>View</button>
        )
    }
}
export default EmployeeGrid;