import React, { useState, useEffect } from 'react';
import CartSlider from '../Cart/imgSlider';
import "./modifier.css";
import { useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import { AddToCart } from '../../../Redux/actions/cartAction';

const ModifierModal = ({ CartValue, closeModal, addToCart, Currency }) => {
  const itemData = CartValue;
  const AdOns = CartValue?.menuItemMeta;
  const category = CartValue?.category;
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [selectedAdOn, setSelectedAdOn] = useState([]);
  const [selectedModifier, setSelectedModifier] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(null);

  const [specialInstructions, setSpecialInstructions] = useState('');

  useEffect(() => {
    // Find the first variant in the AdOns array
    const firstVariant = AdOns?.find(item => item.metaType === 4);
    // If a variant is found, select it by default
    if (firstVariant) {
      handleRadioChange(firstVariant);
    }
  }, [AdOns]);

  const handleSpecialInstructionsChange = (event) => {
    setSpecialInstructions(event.target.value);
  };

  const handleCheckboxChange = (item, type) => {
    if (type === 'AddsOn') {
      setSelectedAdOn((prevSelected) =>
        prevSelected.includes(item) ? prevSelected.filter((selected) => selected !== item) : [...prevSelected, item]
      );
    } else if (type === 'Modifier') {
      setSelectedModifier((prevSelected) =>
        prevSelected.includes(item) ? prevSelected.filter((selected) => selected !== item) : [...prevSelected, item]
      );
    }
  };
  const handleRadioChange = (item) => {
    setSelectedVariant({ ...item, itemIndex: itemData.itemIndex });
  };

  const handleATC = () => {
    const mergedData = {
      selectedAdOn,
      selectedModifier,
      selectedVariant,
      specialInstructions,
      itemData,
      quantity: 1,
    };

    dispatch((AddToCart(mergedData)))
    navigate('/customer/menu');
    closeModal(true);
  };

  // Group items by metaType
  const groupedItems = AdOns?.reduce((acc, item) => {
    acc[item.metaType] = acc[item.metaType] || [];
    acc[item.metaType].push(item);
    return acc;
  }, {});

  const calculateTotalAmount = () => {
    let totalAmount;

    if (selectedVariant) {
      // Use variant price if a variant is selected
      totalAmount = parseInt(selectedVariant?.variantPrice || 0)
      // + parseInt(selectedVariant?.metaItemTax || 0) - parseInt(selectedVariant?.metaItemDiscount || 0);
    } else {
      // Use item price if no variant is selected
      totalAmount = parseInt(CartValue?.price || 0)
      // - parseInt(CartValue?.discount || 0);
    }
    // AddsOn
    selectedAdOn.forEach((item) => {
      totalAmount += parseInt(item?.metaItemPrice || 0);
      // totalAmount += (parseInt(item?.metaItemTax || 0) + parseInt(item?.metaItemPrice || 0)) - parseInt(item?.metaItemDiscount || 0);
    });
    // Modifier
    selectedModifier.forEach((item) => {
      totalAmount += parseInt(item?.metaItemPrice || 0)
      // totalAmount += (parseInt(item?.metaItemTax || 0) + parseInt(item?.metaItemPrice || 0)) - parseInt(item?.metaItemDiscount || 0);
    });

    return totalAmount?.toLocaleString('en-US');
  }


  return (
    <div className=''>
      <div className='container-fluid m-0 p-0 pt-5 px-5'>
        <div className="row py-lg-3">
          <div className="col-lg-4">
            <CartSlider val={itemData} />
          </div>
          <div className="col-lg-8 px-lg-5">
            <div className="CartName">{itemData?.name}</div>
            <div className="cartPrice pt-2">{Currency.currency.name} {itemData?.price?.toLocaleString('en-US')}</div>
            <div className="py-lg-5">
              {Object.keys(groupedItems)?.map((metaType) => (
                <div key={metaType}>
                  <h5 className='mt-3'> {metaType == 2 ? ' Add On' : metaType == 3 ? "Modifiers" : 'Variants'}</h5>
                  {groupedItems[metaType]?.map((item) => (
                    <div key={item?.metaItemId}>
                      {metaType === '2' && (
                        <div className="form-check d-flex justify-content-between pb-3">
                          <div className="gap-3 d-flex">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={selectedAdOn.includes(item)}
                              style={{ width: "26.81px", height: "26.81px" }}
                              onChange={() => handleCheckboxChange(item, 'AddsOn')}
                            />
                            <label className="form-check-label checkItem" htmlFor={item.metaItemId}>
                              {item?.metaItemName}
                            </label>
                          </div>
                          <div className="checkItem">{Currency.currency.name} {parseInt(item?.metaItemPrice || 0)}</div>
                        </div>
                      )}
                      {metaType === '3' && (
                        <div className="form-check d-flex justify-content-between pb-3">
                          <div className="gap-3 d-flex">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={selectedModifier.includes(item)}
                              style={{ width: "26.81px", height: "26.81px" }}
                              onChange={() => handleCheckboxChange(item, 'Modifier')}
                            />
                            <label className="form-check-label checkItem" htmlFor={item.metaItemId}>
                              {item?.metaItemName}
                            </label>
                          </div>
                          <div className="checkItem">{Currency.currency.name} {parseInt(item?.metaItemPrice || 0)}</div>
                        </div>
                      )}
                      {metaType === '4' && (
                        <div className="form-check d-flex justify-content-between pb-3">
                          <div className="gap-3 d-flex">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`flexRadioDisabled_${metaType}`}
                              checked={selectedVariant?.metaItemId === item.metaItemId}
                              style={{ width: "26.81px", height: "26.81px" }}
                              onChange={() => handleRadioChange(item)}
                            />
                            <label className="form-check-label checkItem" htmlFor={item.metaItemId}>
                              {item?.metaItemName}
                            </label>
                          </div>
                          <div className="checkItem">{Currency.currency.name}  {item?.variantPrice || 0}</div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
              <div className="form-group">
                <label className="special pb-3">Special Instructions</label>
                <br />
                <textarea
                  rows="4"
                  cols="100"
                  className="textarea"
                  name="Description"
                  form="usrform"
                  placeholder="Enter the instructions about this item if any."
                  value={specialInstructions}
                  onChange={handleSpecialInstructionsChange}
                ></textarea>
              </div>
              <div className='row pt-lg-5'>
                <div className='col-lg-12'>
                  <button onClick={handleATC} className='w-100 AddBtn'>
                    Add to cart {Currency.currency.name}  {calculateTotalAmount()}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(ModifierModal); 