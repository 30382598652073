import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { BiEdit } from 'react-icons/bi';
import ApiService from '../../../../services/apiService';
import userManagement from '../../../../services/userManagement';
import "../master.css"; // Import CSS file for better structure
import UserClaimTable from './claimTable';

const UserClaim = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [checkedOptions, setCheckedOptions] = useState([]);
    const [editClaimId, setEditClaimId] = useState(null);
    let token = localStorage.getItem("Token")
    const [formData, setFormData] = useState({
        name: '',
        UserRoleList: [],
    });
    
    useEffect(() => {
        GetClaim();
    }, []);

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCheckboxChange = (e) => {
        const option = e.target.name;
        if (e.target.checked) {
            setCheckedOptions([...checkedOptions, option]);
        } else {
            setCheckedOptions(checkedOptions.filter(item => item !== option));
        }
    };

    const GetClaim = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService.get('GET_CLAIM');
            setFormData({ ...formData, UserRoleList: res?.data?.result });
        } catch (error) {
            console.error('Error fetching User Roles:', error);
        }
    };

    const createRole = async () => {
        try {
            const userId = userManagement.getUserId(token);
            const { name } = formData;

            if (!name) {
                toast.error("Please enter a role name");
                return;
            }

            const apiService = new ApiService();
            const createdAt = new Date().toISOString();
            const modifiedAt = new Date().toISOString();

            const firstClaimType = `${name}`;
            const firstBody = {
                claimType: 'module',
                claimValue: firstClaimType,
                createdBy: userId,
                modifiedBy: userId,
                createdAt,
                modifiedAt
            };
            await apiService.post('CREATE_CLAIM', firstBody);
            let res
            for (let option of checkedOptions) {
                const claimValue = `${firstClaimType}.${option}`;
                const body = {
                    claimType: 'crud',
                    claimValue,
                    createdBy: userId,
                    modifiedBy: userId,
                    createdAt,
                    modifiedAt
                };
                res = await apiService.post('CREATE_CLAIM', body);
            }

            toast.success(res?.data?.message);
            setFormData({ ...formData, name: '' });
            GetClaim();
        } catch (error) {
            console.error('Error creating Claims:', error);
        }
    };

    const HandleEditClaim = (claimId, claimValue) => {
        setEditClaimId(claimId);
        setFormData({ ...formData, name: claimValue });
        setCheckedOptions([]); // Clear checked options when editing
    };

    return (
        <div className='p-5 container'>
            <div className="user-shadow">
                <div className='row d-flex align-center p-5'>
                    <div className="col-4">
                        <label htmlFor="roleName" className="form-label">Claim Type *</label>
                        <input
                            type="text"
                            className="form-control"
                            id="roleName"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                            disabled={editClaimId !== null} // Disable input field during editing
                        />
                        <div className="valid-feedback">Looks good!</div>
                    </div>


                    <div className='offset-1 col-lg-2 d-flex align-items-end'>
                        {editClaimId !== null ? (
                            <div onClick={() => { }} className='addItem-btn save-btn disabled'>Save</div>
                        ) : (
                            <div onClick={createRole} className='addItem-btn save-btn'>Save</div>
                        )}
                    </div>
                </div>

                <div className='row'>
                    <div className='col-4 d-flex justify-content-around'>
                        <div className='form-check'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='addCheckbox'
                                name='add'
                                onChange={handleCheckboxChange}
                                disabled={editClaimId !== null} // Disable checkboxes during editing
                            />
                            <label className='form-check-label' htmlFor='addCheckbox'>
                                Add
                            </label>
                        </div>
                        <div className='form-check'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='queryCheckbox'
                                name='query'
                                onChange={handleCheckboxChange}
                                disabled={editClaimId !== null} // Disable checkboxes during editing
                            />
                            <label className='form-check-label' htmlFor='queryCheckbox'>
                                Query
                            </label>
                        </div>
                        <div className='form-check'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='updateCheckbox'
                                name='update'
                                onChange={handleCheckboxChange}
                                disabled={editClaimId !== null} // Disable checkboxes during editing
                            />
                            <label className='form-check-label' htmlFor='updateCheckbox'>
                                Update
                            </label>
                        </div>
                        <div className='form-check'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='deleteCheckbox'
                                name='delete'
                                onChange={handleCheckboxChange}
                                disabled={editClaimId !== null} // Disable checkboxes during editing
                            />
                            <label className='form-check-label' htmlFor='deleteCheckbox'>
                                Delete
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className='role-list-container mt-5 catlist'>
                <table className='table table-striped mt-3'>
                    <thead>
                        <tr>
                            <th scope='col'>Claim Value</th>
                            <th scope='col'>Claim Type</th>
                            <th scope='col'>Status</th>
                            <th scope='col'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {formData?.UserRoleList?.length === 0 ? (
                            <tr>
                                <td colSpan="4" className="text-center">No Claims found</td>
                            </tr>
                        ) : (
                            formData?.UserRoleList?.map((claim, index) => (
                                <UserClaimTable claim={claim}  index={index} HandleEditClaim={HandleEditClaim} GetClaim={GetClaim}/>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UserClaim;
