import React, { useEffect, useState } from 'react';
import ApiService from '../../../services/apiService';
import userManagement from '../../../services/userManagement';
import yourLogo from '../../../assets/images/yourlogo.png';
import './report.css';
import { Modal, Button } from 'react-bootstrap';

const ReceiptModal = ({ isVisible, onClose }) => {
    const [receiptData, setData] = useState([]);

    useEffect(() => {
        GetReports();
    }, []);

    const handlePrint = async () => {
        const printReceipt = async () => {
            const printerIp = 'http://192.168.18.123:9100'; 

            const escPosCommands = `
              Store Name\n
              Date: ${new Date().toLocaleDateString('en-GB')}\n
              ------------------------\n
              ${receiptData?.salesCategories?.map(item => `${item.category.padEnd(15)} ${item.netSale.toFixed(2).padStart(8)}\n`).join('')}
              ------------------------\n
              Total          ${receiptData?.salesAndTaxSummery?.totalSale?.toFixed(2) || '0.00'}\n
              ------------------------\n
              \n\n
              \x1b\x64\x02  // Feed paper and cut
            `;

            try {
                const response = await fetch(printerIp, {
                    method: 'POST',
                    body: escPosCommands,
                });

                if (response.ok) {
                    console.log('Print successful!');
                } else {
                    console.error('Failed to print.');
                }
            } catch (error) {
                console.error('Error printing:', error);
            }
        };

        // Call the print function
        printReceipt();
    };

    const GetReports = async () => {
        try {
            let date = new Date();

            // Format the date to YYYY-MM-DD
            let formattedDate = date.toISOString().split('T')[0];

            const apiService = new ApiService();
            let token = localStorage.getItem('Token');
            let UserId = userManagement.getUserId(token);
            let param = `?UserId=${UserId}&ShiftOpeningDate=${formattedDate}`;

            let res = await apiService.getApiParamater('GET_ORDER_XZREPORTS', param);
            setData(res?.data?.result);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Modal show={isVisible} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Z Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="receipt-container">
                    <div className="receipt-header">
                        <div className="receipt-logo">Logo</div>
                        <div className="receipt-date">{new Date().toLocaleDateString('en-GB')}</div>
                    </div>

                    <div className="section-title">SALES AND TAXES SUMMARY</div>
                    <hr />
                    <div className="receipt-row">
                        <span>Total Net Sales</span>
                        <span>{receiptData?.salesAndTaxSummery?.netSale?.toFixed(2) || '0.00'}</span>
                    </div>
                    <div className="receipt-row">
                        <span>Tax</span>
                        <span>{receiptData?.salesAndTaxSummery?.tax?.toFixed(2) || '0.00'}</span>
                    </div>
                    <div className="receipt-row">
                        <span>Total Sales</span>
                        <span>{receiptData?.salesAndTaxSummery?.totalSale?.toFixed(2) || '0.00'}</span>
                    </div>

                    <div className="section-title">SALES CATEGORIES</div>
                    <hr />
                    <div className="receipt-row header">
                        <span>Category</span>
                        <span>Qty</span>
                        <span>Net Sale</span>
                    </div>
                    {receiptData?.salesCategories?.map((item, index) => (
                        <div className="receipt-row" key={index}>
                            <span>{item?.category}</span>
                            <span>{item?.qty}</span>
                            <span>{item?.netSale?.toFixed(2)}</span>
                        </div>
                    ))}
                    <div className="receipt-row total">
                        <span>Total Net Sales</span>
                        <span>{receiptData?.salesCategories?.reduce((total, item) => total + item?.netSale, 0)?.toFixed(2) || '0.00'}</span>
                    </div>

                    <div className="section-title">PAYMENT DETAILS</div>
                    <hr />
                    {receiptData?.paymentDetails?.map((payment, index) => (
                        <div className="receipt-row" key={index}>
                            <span>{payment?.paymentMethod}</span>
                            <span>{payment?.totalAmount?.toFixed(2)}</span>
                        </div>
                    ))}
                    <div className="receipt-row total">
                        <span>Total Payments</span>
                        <span>{receiptData?.paymentDetails?.reduce((total, item) => total + item?.totalAmount, 0)?.toFixed(2) || '0.00'}</span>
                    </div>

                    <div className="section-title">RECEIPT DETAILS</div>
                    <hr />
                    <div className="receipt-row">
                        <span>Total Receipts</span>
                        <span>{receiptData?.reciptDetails?.totalReceipts || '0'}</span>
                    </div>
                    <div className="receipt-row">
                        <span>Duplicate Receipts</span>
                        <span>{receiptData?.reciptDetails?.duplicateReceipts || '0'}</span>
                    </div>
                    <div className="receipt-row">
                        <span>Invoice Sale</span>
                        <span>{receiptData?.reciptDetails?.invoiceSale?.toFixed(2) || '0.00'}</span>
                    </div>

                    <div className="section-title">CASH PAY IN/OUT</div>
                    <hr />
                    <div className="receipt-row">
                        <span>Pay In</span>
                        <span>{receiptData?.cashPayInPayOut?.payIn?.toFixed(2) || '0.00'}</span>
                    </div>
                    <div className="receipt-row">
                        <span>Pay Out</span>
                        <span>{receiptData?.cashPayInPayOut?.payOut?.toFixed(2) || '0.00'}</span>
                    </div>

                    <div className="section-title">DISCOUNTS DETAILS</div>
                    <hr />
                    {receiptData?.discountDetails?.map((discount, index) => (
                        <div className="receipt-row" key={index}>
                            <span>{discount?.discountName}</span>
                            <span>{discount?.discountAmount?.toFixed(2)}</span>
                        </div>
                    ))}

                    {/* Add more sections as necessary */}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handlePrint}>
                    Print
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReceiptModal;
