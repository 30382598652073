

import React from 'react';
import 'bootstrap'
import Banner1 from "../../assets/images/C-1.png"
import Banner2 from "../../assets/images/C-2.png"
import Banner3 from "../../assets/images/C-3.png"
import Banner4 from "../../assets/images/P1.png"
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

const CartSlider = ({ val }) => {
    let data = val


    return (
        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src={data?.imageUrl === null || "" ? Banner4 : IMAGE_URL + data?.imageUrl || Banner4} class="d-block w-100" />
                </div>
                <div class="carousel-item">
                    <img src={data?.imageUrl === null || "" ? Banner3 : IMAGE_URL + data?.imageUrl || Banner3} class="d-block w-100" />
                </div>
                <div class="carousel-item">
                    <img src={data?.imageUrl === null || "" ? Banner2 : IMAGE_URL + data?.imageUrl || Banner2} class="d-block w-100" />
                </div>
                <div class="carousel-item">
                    <img src={data?.imageUrl === null || "" ? Banner1 : IMAGE_URL + data?.imageUrl || Banner1} class="d-block w-100" />
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    );
};

export default CartSlider;
