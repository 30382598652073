import React, { useState, useEffect } from 'react';
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import "../../component/pages/Employees/employee.css"
import ImageUpload from '../../component/pages/menus/addItems/imagUpload';

const Dispute = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const [image, setImage] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImage(file); // Store the file directly instead of the URL
        }
    };

    const [formData, setFormData] = useState({
        id: '',
        senderName: "",
        JobTitle: "",
        branchName: "",
        branchId: "",
        branchManager: "",
        branchContactNu: "",
        note: "Active",
        images: [],
    });
    useEffect(() => {
        if (location?.state && location?.state?.val) {
            const { val } = location.state;

            setFormData({
                id: val?.id || "",
                senderName: val?.senderName || "",
                JobTitle: val?.JobTitle || "",
                branchName: val?.branchName || "",
                branchId: moment(val?.branchId).format("YYYY-MM-DD") || "" || "",
                branchManager: moment(val?.branchManager).format("YYYY-MM-DD") || "",
                branchContactNu: val?.city || "",
                note: val?.state || "Active",

            });
        }

    }, [location.state]);
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleAddCostumer = async (e) => {
        // e.preventDefault();
        // try {
        //     const formdata = new FormData();
        //     formdata.append('EmployeeInfo.UserName', formData.name);
        //     formdata.append('EmployeeInfo.FirstName', formData.name);
        //     formdata.append('EmployeeInfo.LastName', formData.name);
        //     formdata.append('EmployeeInfo.branchContactNu', formData.branchContactNu);
        //     // formdata.append('EmployeeDisplayPicture', image);
        //     for (const image of formData?.images) {
        //         if (image.file) {
        //             formdata.append('EmployeeInfo.EmployeeDisplayPicture', image?.file);
        //         }
        //     }
        //     formdata.append('EmployeeInfo.IdNumber', formData.JobTitle);
        //     formdata.append('EmployeeInfo.Gender', 1);
        //     formdata.append('EmployeeInfo.DateOfBirth', moment(formData.branchId).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
        //     formdata.append('EmployeeInfo.DateOfJoining', moment(formData.branchManager).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
        //     formdata.append('EmployeeInfo.Shift', 1);
        //     formdata.append('EmployeeInfo.note', formData.note);
        //     formdata.append('EmployeeInfo.Address1', formData.address);
        //     formdata.append('EmployeeInfo.JobTitle', formData.JobTitle);
        //     formdata.append('EmployeeInfo.BranchId', formData.BranchId);
        //     formdata.append('EmployeeInfo.LicenseNumber', formData.LicenseNumber);
        //     formdata.append('EmployeeInfo.ContactNumber', formData.phoneNumber);
        //     formdata.append('EmployeeInfo.Address2', formData.address);
        //     formdata.append('EmployeeInfo.Address3', formData.address);
        //     formdata.append('EmployeeInfo.Email', formData.email);
        //     formdata.append('EmployeeInfo.Roles', "Waiter");
        //     
        //     const apiService = new ApiService();
        //     let response = await apiService.postMultiPart("ADD_EMPLOYEE", formdata);
        //     
        //     if (response.data.statusCode === 201) {
        //         toast.success('Employee created/updated successfully');
        //         navigate(`/admin/employee`);

        //     } else {
        //         toast.warning('Waiting for menu update');
        //     }
        // } catch (error) {
        //     console.error('Error:', error);
        // }
    };
    // const handleUpdateCostumer = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const formdata = new FormData();
    //         formdata.append('Name', formData.name);
    //         formdata.append('branchContactNu', formData.branchContactNu);
    //         // formdata.append('EmployeeDisplayPicture', image);
    //         for (const image of formData?.images) {
    //             if (image.file) {
    //                 formdata.append('EmployeeDisplayPicture', image?.file);
    //             }
    //         }
    //         formdata.append('IdNumber', formData.JobTitle);
    //         formdata.append('id', formData.id);
    //         formdata.append('Gender', formData.branchName);
    //         formdata.append('DateOfBirth', moment(formData.branchId).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
    //         formdata.append('DateOfJoining', moment(formData.branchManager).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
    //         formdata.append('Shift', 1);
    //         formdata.append('note', formData.note);
    //         formdata.append('JobTitle', formData.JobTitle);
    //         formdata.append('BranchId', formData.BranchId);
    //         formdata.append('LicenseNumber', formData.LicenseNumber);
    //         formdata.append('ContactNumber', formData.phoneNumber);
    //         formdata.append('Address', formData.address);
    //         formdata.append('Email', formData.email);
    //         
    //         const apiService = new ApiService();
    //         let response = await apiService.putMultiPart("UPDATE_EMPLOYEE", formdata);
    //         
    //         if (response.data.statusCode === 204) {
    //             toast.success('Employee Updated successfully');
    //             navigate(`/admin/employee`);

    //         } else {
    //             toast.warning('Waiting for menu update');
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };
    const handleImagesSelect = (images) => {
        setFormData({ ...formData, images: images });
    };
    const handleImageRemove = (index) => {
        const updatedImages = [...formData.images];
        updatedImages.splice(index, 1);
        setFormData({ ...formData, images: updatedImages });
    };
    return (
        <div className='p-lg-5 container'>
            <form  >
                <div className=''>
                    <div>
                        <ImageUpload onImagesSelect={handleImagesSelect} onImageRemove={handleImageRemove} formData={formData} />
                    </div>
                    <div className='p-lg-5 '>
                        <div className="form-group row justify-content-between">
                            <div className="col-md-5 col-lg-5">
                                <label htmlFor="validationCustom01" className="form-label">Sender Name</label>
                                <input
                                    type="text"
                                    className="p-2 form-control"
                                    id="validationCustom01"
                                    name="senderName"
                                    value={formData.senderName}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col-md-5 col-lg-5">
                                <label htmlFor="validationCustom03" className="form-label">Job Title</label>
                                <input
                                    type="text"
                                    className="p-2 form-control"
                                    id="validationCustom03"
                                    name="Job Title"
                                    value={formData.JobTitle}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>

                        </div>
                        <div className="form-group row mt-3 justify-content-between">
                            <div className="col-md-5">
                                <label htmlFor="validationCustom02" className="form-label">Branch Name</label>
                                <input
                                    type="text"
                                    className="p-2 form-control"
                                    id="validationCustom02"
                                    name="Branch Name"
                                    value={formData?.branchName}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col-md-5">
                                <label htmlFor="validationCustom04" className="form-label">Branch ID</label>
                                <input
                                    type="text"
                                    className="p-2 form-control"
                                    id="validationCustom04"
                                    name="Branch ID"
                                    value={formData.branchId}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                        </div>
                        <div className="form-group row mt-3 justify-content-between">
                            <div className="col-md-5 col-lg-5">
                                <label htmlFor="validationCustom03" className="form-label">Branch Manager</label>
                                <input
                                    type="text"
                                    className="p-2 form-control"
                                    id="validationCustom03"
                                    name="branchManager"
                                    value={formData.branchManager}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col-md-5">
                                <label htmlFor="validationCustom04" className="form-label">Branch Contact No</label>
                                <input
                                    type="number"
                                    className="p-2 form-control"
                                    id="validationCustom04"
                                    name="branchContactNu"
                                    value={formData.branchContactNu}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                        </div>
                        <div className='form-group row mt-3 justify-content-between'>
                            <div className="col-md-5 col-lg-5">
                                <label htmlFor="validationCustom05" className="form-label">Note</label>
                                <textarea
                                    type="text"
                                    className="p-2 form-control"
                                    id="validationCustom05"
                                    name="note"
                                    value={formData.note}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='end'>
                                    <button className='addItem-btn' type="submit" onClick={handleAddCostumer}>Send to Admin</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </form>
        </div>
    )
}
export default Dispute
