import React, { useState } from 'react';
import Sider from "../../../assets/images/LoginSider.png";
import QLogo from "../../../assets/images/Logo.png";
import Logo from "../../../assets/images/pro360.png";
import google from "../../../assets/images/Google.png";
import facebook from "../../../assets/images/Facebook.png";
import "./auth.css";
import 'react-bootstrap';
import { Link } from 'react-router-dom';
import "bootstrap"
import 'bootstrap/dist/css/bootstrap.css';
import ApiService from '../../../services/apiService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';


const SignUp = () => {
    let navigate = useNavigate();
    const [formData, setFormData] = useState({
        userName: '',
        email: '',
        contactNumber: '',
        password: '',
        roles: [],
    });

    const handleChange = (event) => {
        const { name, value } = event.target;

        // Handle roles separately
        if (name === "roles") {
            const updatedRoles = formData.roles.includes(value) ? [] : [value];

            setFormData({
                ...formData,
                roles: updatedRoles,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };


    const handleSubmit = async () => {
        ;
        try {
            const apiService = new ApiService();
            let body = {
                userName: formData?.userName,
                email: formData?.email,
                password: formData?.password,
                roles: formData?.roles,
            };
            let response = await apiService.post("USER_REGISTER", body);

            if (response.data.statusCode === 201) {
                toast.success('Registration successful!', {
                    position: 'top-right',
                    autoClose: 3000,
                });

                navigate('/Login');
            }
        } catch (error) {
            if (error?.response?.status === 400) {
                // Handle 400 Bad Request errors
                const errorData = error.response.data;
                if (errorData && errorData.errors) {
                    for (const errorMessage of errorData.errors) {
                        toast.error(errorMessage, {
                            position: 'top-right',
                            autoClose: 5000,
                        });
                    }
                } else {
                    toast.error('Bad Request. Please check your input.', {
                        position: 'top-right',
                        autoClose: 5000,
                    });
                }
            } else {
                // Handle other errors
                toast.error(`${error.response.data.message}`, {
                    position: 'top-right',
                    autoClose: 3000,
                });
            }
        }
    };


    return (
        <div className='container-fluid display-flex body m-0 p-0'>
            <div className='row'>
                <div className='col-lg-6 m-0 col-sm-12'>
                    <img src={Sider} className='sider_login' alt="Login Sider" />
                </div>
                <div className='col-lg-6 m-0 pl-lg-5 pr-lg-5 p-sm-4 pb-lg-0 col-sm-12'>
                    <div className='row'>
                        <img src={Logo} className='logo_size' alt="Logo" />
                    </div>
                    <div className='p-lg-5 p-sm-0 p-md-0 justify-content-center'>
                        <div className=' login_head d-flex justify-content-center  p-lg-2 p-md-2'>
                            Welcome to Qfinity
                        </div>

                        <form className="justify-content-center d-flex flex-column align-items-center" >
                            <div className="form-group w-75  p-lg-2 p-md-2">
                                <label className="labels" htmlFor="userName">
                                    User Name
                                </label>
                                <input
                                    className="form-control p-2"
                                    id="userName"
                                    name="userName"
                                    value={formData.userName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group w-75  p-lg-2 p-md-2">
                                <label className="labels" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    className="form-control w-100 p-2"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group w-75  p-lg-2 p-md-2">
                                <label className="labels" htmlFor="contactNumber">
                                    Contact Number
                                </label>
                                <input
                                    type="number"
                                    className="form-control p-2"
                                    id="contactNumber"
                                    name="contactNumber"
                                    value={formData.contactNumber}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group w-75  p-lg-2 p-md-2">
                                <label className="labels" htmlFor="password">
                                    Password
                                </label>
                                <input
                                    type="password"
                                    className="form-control  p-2"
                                    id="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                            </div>
                            {/* Toggle buttons for roles */}
                            <div className="form-group w-75 p-lg-2 p-md-2">
                                <label className="labels" htmlFor="roles">
                                    Roles
                                </label>
                                <div>
                                    {['Admin', 'Cashier', 'Customer', 'Kitchen'].map((role) => (
                                        <React.Fragment key={role}>
                                            <input
                                                type="checkbox"
                                                name="roles"
                                                value={role}
                                                checked={formData.roles.includes(role)}
                                                onChange={handleChange}
                                                className="form-check-input"
                                            />
                                            <span className="mx-2">{role}</span>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                            {/* <button onClick={handleSubmit} className="btn Button_Normal center p-2">
                                Sign Up
                            </button> */}
                        </form>
                        <div className='align_center mt-5'>
                            <div className="form-group p-lg-2 p-md-2 mt-3 d-flex justify-content-center align_center">
                                <button onClick={handleSubmit} type="button" className="btn Button_Normal center p-2">Sign Up</button>
                            </div>
                            <p className='simple_mini m-0'>
                                Already have an account?
                                <Link to='/Login' className='orange_text'> Login</Link>
                            </p>
                        </div>
                        <div className='row p-4 align_center'>
                            <span className='head2'>
                                OR
                            </span>
                        </div>
                        {/* <div className='row align_center'>
                            <div className='col-lg-5'>
                                <div className='border_simple'>
                                    <a className='decor_n' href="URL_TO_GOOGLE_AUTH">
                                        <img src={google} className='icons' alt="Google" />
                                        <span className='Auth_Btn'>Log In with Google</span>
                                    </a>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='border_simple'>
                                    <a className='decor_n' href="URL_TO_FACEBOOK_AUTH">
                                        <img src={facebook} className='icons' alt="Facebook" />
                                        <span className='Auth_Btn'>Log In with Facebook</span>
                                    </a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
