import React, { useState, useEffect } from 'react';
import './table.css';
import { useNavigate } from 'react-router';
import { connect } from "react-redux";
import TableCard from './tableCard';
import ApiService from '../../../services/apiService';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TableDragSection from './tableDragSection';
import { useDispatch } from 'react-redux';
import { AddTable, clearTable } from '../../../../Redux/actions/tableAction';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { toast } from 'react-toastify';
import userManagement from '../../../services/userManagement';

const TableDrag = ({ Currency }) => {

    const [tables, setTable] = useState([]);
    const [selectedTable, setSelectedTable] = useState('');
    const [restaurantArea, setRestaurantArea] = useState();
    const [selectedFilter, setSelectedFilter] = useState('0');
    const [selectedArea, setSelectedArea] = useState();
    const [instance, setInstance] = useState();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [CartValue, setCartValue] = useState("");
    const [employeeData, setEmployeeData] = useState({});


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        name: '',
        selectedTable: '',
        mobile: '',
        bookingdate: ''
    });

    // const TableArea = [
    //     { value: '1', label: 'First Floor' },
    //     { value: '2', label: 'Second Floor' },
    //     { value: '3', label: 'Third Floor' },
    //     { value: '4', label: 'Fourth Floor' },
    //     { value: '5', label: 'Fifth Floor' },
    //     { value: '6', label: 'Sixth Floor' },
    //     { value: '7', label: 'Seventh Floor' },
    //     { value: '8', label: 'Eighth Floor' },
    //     { value: '9', label: 'Ninth Floor' },
    //     { value: '10', label: 'Tenth Floor' },
    //     { value: '11', label: 'Eleventh Floor' },
    //     { value: '12', label: 'Twelfth Floor' },
    //     { value: '13', label: 'Ground Floor' },
    //     { value: '14', label: 'Patio' },
    //     { value: '15', label: 'Terrace' },
    //     { value: '16', label: 'Private Room' }
    // ];
    const ThirdFilter = [
        { value: '1', label: 'Available' },
        { value: '3', label: 'Occupied' },
    ];
    const handleFilterChange = async (selectedFilter) => {
        setSelectedFilter(selectedFilter?.value);
    };
    const handleAreaChange = async (selectedFilter) => {
        setSelectedArea(selectedFilter?.value);
    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    useEffect(() => {
        GetEmployee()
        GetTablefilter();
    }, [selectedFilter, selectedArea]);
    const handleTableChange = (selectedOption) => {
        setFormData({ ...formData, selectedTable: selectedOption || selectedOption });
    };
    const GetTable = async () => {
        debugger
        if(selectedArea == null || selectedArea.length == 0){
            return toast.warning(`Please Select Area first`);
        }
        setSelectedFilter('')
        const apiService = new ApiService();
        let parameters = `?Area=${selectedFilter}`;
        const res = await apiService.getApiParamater('GET_TABLE', parameters);
        setTable(res?.data?.result);
    }
    const GetEmployee = async () => {
        const apiService = new ApiService();
        let token = localStorage.getItem('Token');
        let UserId = userManagement.getUserId(token)
        let parameters = `?UserId=${UserId}`;
        const res = await apiService.getApiParamater('GET_EMPLOYEE', parameters);
        setEmployeeData(res?.data?.result[0])
        fetchKitchenArea(res?.data?.result[0].restaurantId)
    }
    const fetchKitchenArea = async (restaurantId) => {
        try {
            const apiService = new ApiService();
            
            let parameters = `?ResturentId=${employeeData?.restaurantId || restaurantId}`;
            const response = await apiService.getApiParamater('GET_RESTAURANT_AREA', parameters);
            const updatedKitchen = response?.data?.result;
            setRestaurantArea(updatedKitchen);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };
    const GetTablefilter = async () => {
        try {
            const apiService = new ApiService();
            let parameters = `?Area=${selectedArea?.id}`;
            const res = await apiService.getApiParamater('GET_TABLE', parameters);
            setTable(res?.data?.result);
        } catch (error) {
            console.log(error)
        }

    }
    const handleSelectTable = (selectedTable) => {
        setSelectedTable(selectedTable);
        const tableWithSelectedChairs = { ...selectedTable, selectedChairs: selectedTable.selectedChairs?.length };
        dispatch(clearTable());
        setSelectedTable(tableWithSelectedChairs);
        dispatch(AddTable(tableWithSelectedChairs));
        navigate('/cashier/home');
    };
    const currentDatetime = new Date().toISOString();
    const handleUpdateTable = async () => {
        try {
            const datareq = {
                id: formData?.selectedTable?.value?.id,
                loggedInUserId: formData?.selectedTable?.value?.loggedInUserId,
                name: formData?.selectedTable?.value?.name,
                seatCapacity: formData?.selectedTable?.value?.seatCapacity,
                topPosition: formData?.selectedTable?.value?.topPosition,
                leftPosition: formData?.selectedTable?.value?.leftPosition,
                shape: formData?.selectedTable?.value?.shape,
                area: formData?.selectedTable?.value?.area,
                chirsFilledCount: formData?.selectedTable?.value?.chirsFilledCount,
                status: 2,
                active: formData?.selectedTable?.value?.active,
                reservedByName: formData?.name,
                reservedByPhone: formData?.mobile,
                reservedDateFrom: currentDatetime,
                reservedDateTo: moment(formData?.bookingdate).format("YYYY-MM-DD") || "" || "",
            }
            const apiService = new ApiService();
            let res = await apiService.put("UPDATE_TABLE", datareq);

            if (res?.data?.statusCode === 200) {
                handleClose()
                GetTable()
                toast.success("table Reserved Successfully")
            }

        } catch (error) {
            console.error('Error:', error);
        }
    }
    return (
        <div className=''>
            <div className=' d-flex justify-content-between'>
                <div className='col-8 d-flex gap-3' >
                    <div
                        className='col-3'
                    >

                        <label htmlFor="validationCustom0" className="form-label">Select Floor</label>
                        <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                            onChange={handleAreaChange}
                            options={restaurantArea?.map((sup) => ({ value: sup, label: sup?.name }))}
                        />
                    </div>

                </div>
                <div><button className='AddBtn' type="submit" onClick={() => handleShow()}>Reservation</button></div>
                <div className='d-inline-flex gap-3 end'>
                    <div className=''>
                        <nav className="nav nav-pills weekfilter ">
                            <li className="nav-item" role="presentation" >
                                <button
                                    className={`nav-link ${selectedFilter ? '' : 'activecls'}`}
                                    onClick={() => GetTable()}
                                    data-bs-toggle="pill"
                                    type="button"
                                    role="tab"
                                >
                                    All
                                </button>
                            </li>
                            {ThirdFilter?.map((filter) => (
                                <li className="nav-item" role="presentation" >
                                    <button
                                        key={filter.value}
                                        className={`nav-link ${selectedFilter === filter.value ? 'activecls' : ''}`}
                                        onClick={() => handleFilterChange(filter)}
                                        id={`pills-${filter.value}-tab`}
                                        data-bs-toggle="pill"
                                        data-bs-target={`#pills-${filter.value.replace(/\s+/g, '-')}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`pills-${filter.value}`}
                                        aria-selected={selectedFilter === filter.value ? 'true' : 'false'}
                                    >
                                        {filter.label}
                                    </button>
                                </li>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
            <div className='container p-5'>
                <DndProvider backend={HTML5Backend}>
                    {tables?.length <= 0 ? <div className=" mt-5  container">
                        <div className='d-flex justify-content-center'>
                            <div className="" role="status">
                                <h4 className=" silver">Please select floor to see table...</h4>
                            </div>

                        </div>
                    </div> :
                        <TableDragSection setInstance={setInstance} >
                            {tables?.map((box) => (
                                <TableCard
                                    key={box.id}
                                    instance={instance}
                                    id={box.id}
                                    left={box.leftPosition}
                                    top={box.topPosition}
                                    name={box?.name}
                                    val={box}
                                    status={box?.status}
                                    selectedTable={selectedTable}
                                    setSelectedTable={handleSelectTable}
                                    state={selectedArea}
                                />
                            ))}
                        </TableDragSection>
                    }
                </DndProvider>
            </div>
            <Modal show={show} onHide={handleClose} size="lg"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title className='center'>Table Reservation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='px-4'>
                        <div className='form-group row mt-3 justify-content-between'>
                            <div className="col-md-5 col-lg-5">
                                <label htmlFor="validationCustom01" className="form-label">Name</label>
                                <input
                                    type="text"
                                    className="p-2 form-control"
                                    id="validationCustom01"
                                    onChange={handleInputChange}
                                    name="name"
                                    value={formData.name}
                                    required
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col-md-5">
                                <label htmlFor="validationCustom01" className="form-label">Mobile No</label>
                                <input
                                    type="number"
                                    className="p-2 form-control"
                                    id="validationCustom01"
                                    name="mobile"
                                    onChange={handleInputChange}
                                    value={formData.mobile}
                                    required
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                        </div>
                        <div className='form-group row mt-3 justify-content-between'>
                            <div className="col-md-5 col-lg-5">
                                <label htmlFor="validationCustom03" className="form-label">Select Table</label>
                                <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    defaultValue={formData.selectedTable}
                                    onChange={handleTableChange}
                                    options={tables
                                        ?.filter(tab => tab?.status === 1)
                                        ?.map(tab => ({ value: tab, label: tab.name }))
                                    }
                                />
                            </div>
                            <div className="col-md-5">
                                <label htmlFor="validationCustom04" className="form-label">Schedule</label>
                                <input
                                    type="datetime-local"
                                    className="p-2 form-control"
                                    id="validationCustom05"
                                    name="bookingdate"
                                    min={currentDatetime.slice(0, -8)}
                                    onChange={handleInputChange}
                                    value={formData.bookingdate}
                                    required
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='center'>
                    <button className='saveButton' onClick={handleUpdateTable}>
                        Save 
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(TableDrag);
