import React, { useState, useEffect } from 'react';
import CookHeader from './kitchenHeader';
import Select from 'react-select';
import "./kitchen.css"
import { ReactComponent as Refresh } from '../assets/images/svgIcons/Refresh.svg';
import OrderSection from './dropZone';
import OrderCard from './Drag';
import ApiService from '../services/apiService';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createHubConnection } from '../services/signalRService';
import { toast } from 'react-toastify';
import { ReactComponent as List } from '../assets/images/svgIcons/list.svg';
import { useNavigate } from 'react-router-dom';

const KitchenDisplay = ({ }) => {
    const [selected, setSelected] = useState(null);
    const [orders, setOrders] = useState([]);
    const [draggedOrder, setDraggedOrder] = useState(null);
    const [isRinging, setIsRinging] = useState(false);
    const navigate = useNavigate()
    const [status, setstatus] = useState('');

    const filterValue = [
        { value: '', label: 'In Queue' },
        { value: '2', label: 'Preparing' },
        { value: '3', label: 'Ready For Delivery' },
        { value: '7', label: 'Canceled' },
    ];

    useEffect(() => {
        const handleReceiveMessage = async (user, message) => {
            toast.info(`${message}`);
            await GetOrders();
        };

        const hubConnection = createHubConnection(
            handleReceiveMessage,
        );

        return () => {
            hubConnection.stop();
        };
    }, [orders]);

    useEffect(() => {
        const hasAcceptedOrders = orders.some(order => order.orderStatus === OrderStatus.Accept);

        if (hasAcceptedOrders && !isRinging) {
            console.log('plaaayyy')
            playRingAudio();
        } else if (!hasAcceptedOrders && isRinging) {
            stopRingAudio();
        }
    }, [orders, isRinging]);

    const handleStatusChange = (selectedOption) => {
        setstatus(selectedOption?.value);
    }

    const PageReload = () => {
        window.location.reload(false);
    }

    useEffect(() => {
        GetOrders();
    }, [status]);

    const GetOrders = async () => {
        const apiService = new ApiService();
        let parameters = `?OrderStatus=${status}`;
        let res = await apiService.getApiParamater("GET_ORDERS", parameters);
        const mappedOrders = res?.data?.result?.map(order => ({
            ...order,
            orderStatus: mapApiStatusToEnum(order?.orderStatus),
        }));

        setOrders(mappedOrders.reverse());
    }

    const mapApiStatusToEnum = (apiStatus) => {
        switch (apiStatus) {
            case 1:
                return OrderStatus.InQueue;
            case 2:
                return OrderStatus.Preparing;
            case 3:
                return OrderStatus.Ready;
            case 7:
                return OrderStatus.Cancel;
            default:
                return OrderStatus.InQueue;
        }
    };
    const audio = new Audio(`https://www.soundjay.com/misc/sounds/bell-ringing-05.mp3`);

    const playRingAudio = () => {
        setIsRinging(true);
        audio.play();
        audio.loop = true;
    };

    const stopRingAudio = () => {
        setIsRinging(false);
        audio.pause();
        audio.currentTime = 0;
    };

    const handleDrop = async (orderId, newStatus) => {
        const statusMap = {
            [OrderStatus.Accept]: OrderStatus.Accept,
            [OrderStatus.Preparing]: OrderStatus.Preparing,
            [OrderStatus.Ready]: OrderStatus.Ready,
            [OrderStatus.Cancel]: OrderStatus.Cancel,
        };

        const validStatusKeys = Object.keys(OrderStatus);

        const updatedOrders = orders.map((order) =>
            order.id === orderId ? { ...order, orderStatus: validStatusKeys.includes(newStatus) ? OrderStatus[newStatus] : order.orderStatus } : order
        );

        setOrders(updatedOrders);
        setDraggedOrder(null);

        const draggedOrder = orders.find(order => order.id === orderId);

        // Create the new request body
        const body = {
            id: draggedOrder.id,
            orderType: draggedOrder.orderType,
            orderSource: draggedOrder.orderSource,
            orderStatus: validStatusKeys.includes(newStatus) ? OrderStatus[newStatus] : "",
            orderAmount: draggedOrder.orderAmount,
            calculatedTax: draggedOrder.calculatedTax,
            totalDiscount: draggedOrder.totalDiscount,
            amountCollected: draggedOrder.amountCollected,
            amountReturned: draggedOrder.amountReturned,
            customerType: draggedOrder.customerType,
            customerId: draggedOrder.customerId,
            customerName: draggedOrder.customerName,
            paymentStatus: draggedOrder.paymentStatus,
            invoiceNumber: draggedOrder.invoiceNumber,
            loggedInUserId: "string",  // Use the actual value or set to "string" as needed
        };

        const apiService = new ApiService();
        const response = await apiService.put('UPDATE_ORDER', body);
    };

    const handleHistory = () => {
        navigate('/Kitchen/History');
    };

    const columnStyles = {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: '16px',
    };

    const handleCancle = async (order) => {

        const body = {
            id: order.id,
            orderStatus: parseInt(7),
        };

        const apiService = new ApiService();
        const response = await apiService.put('UPDATE_ORDER', body);
        if (response && response.data && response.data.statusCode === 200) {
            toast.success("Order Canceled");
            GetOrders();
        } else {
            throw new Error("Failed to Canceled the order. Please try again.");
        }
        GetOrders()
    };
    const handleComplete = async (order) => {
        try {
            if (!order || order.paymentStatus !== 2) {
                toast.error(`Please Pay the Order ${order?.id} Amount`);
                return;
            }
            const body = {
                id: order.id,
                orderStatus: 6,
            };
            const apiService = new ApiService();
            const response = await apiService.put('UPDATE_ORDER', body);
            if (response && response.data && response.data.statusCode === 200) {
                toast.success("Order Completed");
                GetOrders();
            } else {
                throw new Error("Failed to complete the order. Please try again.");
            }
        } catch (error) {
            toast.error(error.message);
        }
    };
 
    return (
        <div className="">
            <div><CookHeader /></div>
            <div className='bghead d-flex justify-content-between px-lg-5' >
                <div className='d-flex gap-3'>
                    <div className='col-12'>
                        <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                            defaultValue={selected}
                            options={filterValue}
                            onChange={handleStatusChange}
                            value={filterValue.find(function (option) {
                                return option?.value === selected;
                            })}
                            className='reactselect'
                        />
                    </div>
                    <div className="">
                        <List className='filter' onClick={handleHistory} />
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className='refbtn' onClick={() => PageReload()}>
                        <Refresh />
                        Reload Orders
                    </div>
                </div>
            </div>
            <div>
                <DndProvider backend={HTML5Backend}>
                    <div>
                        <div style={columnStyles} className='p-lg-5'>
                            {Object.keys(OrderStatus).map((statusKey) => (
                                <OrderSection key={statusKey} status={statusKey} onDrop={handleDrop}>
                                    {orders
                                        .filter((order) => order.orderStatus === OrderStatus[statusKey])
                                        .map((order) => (
                                            <OrderCard key={order.id} order={order} onDrop={handleDrop} handleCancle={handleCancle} handleComplete={handleComplete} />
                                        ))}
                                </OrderSection>
                            ))}
                        </div>
                    </div>
                </DndProvider>
            </div>
        </div>
    );
};

export default KitchenDisplay;

export const OrderStatus = {
    InQueue: 1,
    Preparing: 2,
    Ready: 3,
    Cancel: 7,
};
