import React, { useRef, useState } from 'react';
import ApiService from '../../../services/apiService';
import { toast } from 'react-toastify';
import userManagement from '../../../services/userManagement';
import localStorage from 'redux-persist/es/storage';

const PasscodePopup = ({ onSubmit }) => {
    const [error, setError] = useState('');
    const [passcode, setPasscode] = useState(['', '', '', '']); // State to hold OTP values
    const refs = [useRef(), useRef(), useRef(), useRef()];
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            let Token = await localStorage.getItem('Token')
            
            let userId = userManagement.getUserId(Token)
            const otpString = passcode.join('');
            let body = {
                passcode: otpString,
                userId: userId
            }
            const apiService = new ApiService();
            const response = await apiService.post('VERIFY_PASS', body);

            if (response.data.statusCode === 200 || response.data.statusCode === 204) {
                onSubmit(true); // Passcode correct, allow navigation
            } else {
                toast.error(response.data.message)
            }
        } catch (error) {
            setError('Error authenticating passcode. Please try again later.');
            console.error('API error:', error);
        }
    };

    const handleOtpChange = (index, value) => {
        if (isNaN(value)) return; // Only allow numeric values
        const updatedOTP = [...passcode];
        updatedOTP[index] = value.slice(-1); // Update OTP value at the given index
        setPasscode(updatedOTP);

        if (value !== '') {
            // Move focus to the next input field if available
            if (index < 3) {
                refs[index + 1].current.focus();
            }
        }
    };

    return (
        <div style={styles.overlay}>
            <div style={styles.popup}>
                <h4>Enter 4 Digit Passcode</h4>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className='passbx'>
                        <div className='d-flex'>
                            {[0, 1, 2, 3].map(index => (
                                <input
                                    key={index}
                                    type="number"
                                    maxLength={1}
                                    value={passcode[index]}
                                    onChange={e => handleOtpChange(index, e.target.value)}
                                    ref={refs[index]}
                                    className="otpbx"
                                />
                            ))}
                        </div>
                    </div>
                    <button type="submit" className='addItem-btn w-100' style={styles.button}>Submit</button>
                </form>
            </div>
        </div>
    );
};

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    popup: {
        backgroundColor: 'white',
        padding: '100px',
        borderRadius: '8px',
        textAlign: 'center',
    },
    input: {
        marginBottom: '35px',
        padding: '12px',
        width: '100%',
    },
    button: {
        padding: '10px 20px',
    },
};

export default PasscodePopup;
