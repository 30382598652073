import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import Plus from "../../Assets/images/plusIcon.png"
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Edit } from '../../Assets/svg/edit.svg'
import { ReactComponent as Link } from '../../Assets/svg/Link.svg'
import { TbBallpenOff } from "react-icons/tb";
import { FaEdit } from "react-icons/fa";
import { PropagateLoader } from 'react-spinners';
import Pagination from '../pagination/pagination';

const MeasurementGrid = ({ refreshKey, fetchMeasurementData, canEditItemMaster }) => {

    const [selectedRowData, setSelectedRowData] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    useEffect(() => {
        getItems();
    }, [refreshKey]);
    useEffect(() => {
        if (pageSize) {
            getItems();
        }
    }, [pageSize]);

    const handleEdit = (data) => {
        navigate("/Inventory/Master/UnitMeasurement", { state: data })
    };
    const handleEditRow = (rowData) => {
        setSelectedRowData(rowData);
    };
    
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);

            const res = await apiService.getApiParamater('GET_EUNIT_MEASUREMENT', `?${params.toString()}`);
            setRowData(res?.data?.result || []);
            setPagination(res.data.paginationData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    };
    const updateActiveStatus = async (id, isActive) => {

        try {

            const apiService = new ApiService();
            let body = {
                recordId: id,
                isActive: isActive
            }
            const res = await apiService.put(`UPDATE_EUNIT_MEASUREMENT`, body);
            if (res?.status === 200) {
                console.log('Updated successfully');
                // You may want to refresh the data after successful update
                fetchMeasurementData();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    const columnDefs = [
        { headerName: 'UOM Number', field: 'recordId', filter: true, floatingFilter: true, editable: true, minWidth: 500 },
        { headerName: 'Code', field: 'type', filter: true, floatingFilter: true, editable: true, minWidth: 452 },
        { headerName: 'UOM', field: 'description', filter: true, floatingFilter: true, minWidth: 420, editable: true },
        {
            headerName: 'Active', field: 'recordId', cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                canEditItemMaster: canEditItemMaster,
                handleEdit: handleEdit,
                clicked: function (field, isActive) {
                    updateActiveStatus(field, isActive);
                },
            },
        }
    ];
    const actionsRenderer = (props) => {
        return (
            <div>
                <Button variant="primary" onClick={() => handleEditRow(props.data)}>Edit</Button>
            </div>
        );
    };

    return (
        <div className=''>
            {loading ? (
                <div className='center'>
                    <PropagateLoader color="#6E5199" size={15} />
                </div>
            ) : (
                <>
                    <div className='brdor  mt-3' style={{ height: 600 }}>
                        <div className="ag-theme-quartz" style={{ height: 600 }}>
                            <AgGridReact
                                rowData={rowData}
                                enableFilter={true}
                                floatingFilter={true}
                                rowSelection={'single'}
                                columnDefs={columnDefs}
                                pagination={false}
                                frameworkComponents={{ actionsRenderer }}
                            />
                        </div>
                    </div>
                    <Pagination setPageSize={setPageSize} pagination={pagination} getItems={getItems} pageSize={pageSize} />
                </>
            )}
        </div>
    );
};
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value, !this.props.data.isActive); // Toggle isActive value
    }
    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;

        handleEdit(data);
    }
    render() {
        const { isActive } = this.props.data; // Extract isActive from rowData
        const { canEditItemMaster } = this.props;

        return (
            <div className='d-flex gap-3'>
                {canEditItemMaster && (
                    <div onClick={this.btnEditClickedHandler}><FaEdit size={25} /></div>
                )}

                {canEditItemMaster && (
                    <div className="form-check form-switch center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive}
                            onClick={this.btnClickedHandler}
                        />
                    </div>
                )}
            </div>
        )
    }
}
export default MeasurementGrid;
