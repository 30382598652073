const initialState = {
    rows: [],
};

const comparisonReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_COMPARISON':
            return {
                ...state,
                rows: action.payload,
            };
        case 'ADD_COMPARISON':
            return {
                ...state,
                rows: [...state.rows, action.payload],
            };
        case 'REMOVE_COMPARISON_INDEX':
            return {
                ...state,
                rows: state.rows.filter((_, index) => index !== action.payload)
            };
        case 'REMOVE_COMPARISON':
            return {
                ...state,
                rows: state.rows.slice(0, -1),
            };
        case 'CLEAR_COMPARISON':
            return {
                ...state,
                rows: [{
                    srno: '',
                    ItemNo: '',
                    requisitionNo: '',
                    itemDescription: '',
                    branch: '',
                    qty: '',
                    uom: '',
                    baseQty: '',
                    baseAmount: '',
                    amount: '',
                    stock: '',
                    bol: ''
                }],
            };
        case 'UPDATE_COMPARISON':
            const { index, row } = action.payload;
            return {
                ...state,
                rows: state.rows.map((r, i) => i === index ? row : r),
            };
        default:
            return state;
    }
};

export default comparisonReducer;
