import axios from "axios";
import { endPoint } from "../Endpoints/endpoint";

const defaultHeaders = {
    // 'Accept': 'application/json',
    'Content-Type': 'application/json',
    "apiKey": "V+kBloEwqqVho1PINmQI0VJQwSBX2l/e",
};

let headersForm = {
    "Content-Type": "multipart/form-data",
}


class ApiService {
    constructor() {
        this.axiosInstance = axios.create({
            // baseURL: "http://qfinityinventoryapi.softaxusdemo.com", 
            baseURL: "https://dev.api.newcrm.freemyip.com",
            headers: defaultHeaders,
        });

        this.axiosInstance.interceptors.request.use(this.requestInterceptor);

        this.axiosInstance.interceptors.response.use(this.responseInterceptor, this.errorInterceptor);
    }

    requestInterceptor = (config) => {
        const token = this.getToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    };

    responseInterceptor = (response) => {

        return response;
    };

    errorInterceptor = async (error) => {
        if (error.response && error.response.status === 401) {
            const token = this.getToken();
            if (token) {
                await this.handleRefreshToken();
                const newToken = this.getToken();
                if (newToken) {
                    error.config.headers['Authorization'] = `Bearer ${newToken}`;
                    return axios.request(error.config);
                } else {
                    console.error('Token refresh failed');
                    return Promise.reject(error);
                }
            } else if (this.isLoginOrSignupRequest(error.config)) {
                return axios.request(error.config);
            } else {
                console.error('User not authenticated');
                return Promise.reject(new Error('Unauthorized'));
            }
        } else {
            return Promise.reject(error);
        }
    };

    isLoginOrSignupRequest(config) {
        const url = config.url;
        return url.includes('USER_LOGIN') || url.includes('USER_SIGNUP');
    }

    async handleRefreshToken() {

        try {
            const response = await this.axiosInstance.post(endPoint['REFRESH_TOKEN'], {
                token: this.getToken(),
                refreshToken: this.getRefreshToken(), 
            });

            if (response.status === 200) {
                this.setToken(response.data.result.token);
            }
        } catch (error) {
            console.error('Token refresh failed', error);
        }
    }

    getToken() {
        return localStorage.getItem('Token');
    }

    setToken(token) {
        localStorage.setItem('Token', token);
    }

    getRefreshToken() {
        return localStorage.getItem('RefreshToken');
    }

    setRefreshToken(refreshToken) {
        localStorage.setItem('RefreshToken', refreshToken);
    }

    async get(path) {
        return this.axiosInstance.get(endPoint[path]);
    }
    async getApiParamater(path, data) {
        return this.axiosInstance.get(endPoint[path] + data);
    }
    async post(path, data) {
        return this.axiosInstance.post(endPoint[path], data);
    }
    async postMultiPart(path, body) {
        let res = false;
        await axios.post(endPoint[path], body, headersForm)
            .then((response) => {
                res = response;
            })
            .catch((err) => {
                res = err?.response?.data?.message
            });
        return res;
    }
    async putMultiPart(path, body) {
        let res = false;
        await axios.put(endPoint[path], body, headersForm)
            .then((response) => {
                res = response;
            })
            .catch((err) => {
                res = err?.response?.data?.message
            });
        return res;
    }
    async put(path, data) {
        return this.axiosInstance.put(endPoint[path], data);
    }
    async delete(path, data) {
        return this.axiosInstance.delete(endPoint[path], data);
    }
    async deleteParameter(path, data) {
        return this.axiosInstance.delete(endPoint[path] + data);
    }
}

export default ApiService;

