import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/apiService';
import Select from 'react-select';
import "./reports.css"
import { RiSkipLeftFill, RiSkipRightFill } from "react-icons/ri";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";
import { SlRefresh } from "react-icons/sl";
import { HiOutlinePrinter } from "react-icons/hi2";
import { PiDownloadSimple } from "react-icons/pi";
import moment from 'moment';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { jsPDF } from 'jspdf';
import { BsFileEarmarkPdf, BsFiletypeCsv } from "react-icons/bs";
import { LiaFileExcel } from "react-icons/lia";
import 'jspdf-autotable';
const PurchaseOrderReports = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [StockType, setStockType] = useState();
    const [SupplierList, setSuppliers] = useState();
    const [PageNo, setPageNo] = useState();
    const [pageSize, setPageSize] = useState(20);
    const [Items, setItems] = useState([]);
    const [pagination, setPagination] = useState();
    const [toDate, setToDate] = useState();
    const [fromDate, setFromDate] = useState();
    const [dateFilter, setdateFilter] = useState(false);
    const currentDatetimeUTC = new Date().toISOString().slice(0, 16);
    const gridRef = useRef();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const StatusFilter = [
        { value: '0', label: 'Pending' },
        { value: '1', label: 'Aproved' },
        { value: '2', label: 'Hold' },
        { value: '3', label: 'Rejected' },
    ];
    const FilterStatus = [
        { value: 'Partially Delivered', label: 'Partial Delievered' },
        { value: 'Completely Delivered', label: 'Completely Delivered' },
        { value: 'Not Delivered', label: 'Not Delivered' }
    ];
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    let navigate = useNavigate();
    const staticOptions = [];
    const [formData, setFormData] = useState({
        startdate: "",
        endDate: "",
        PoNum: "",
        Filterby: [{ label: 'Select Delivery Status', value: '0' }],
        VendorName: [{ label: 'Select Supplier ', value: '0' }],
        StockType: [{ label: 'Select Stock Type', value: '0' }],
        Status: [{ label: 'Filter By', value: '' }],
        Cost1: [{ label: 'Select Stock Type', value: '0' }],
        Cost2: [{ label: 'Select Stock Type', value: '0' }],
        Cost3: [{ label: 'Select Stock Type', value: '0' }],
        Cost4: [{ label: 'Select Stock Type', value: '0' }],
        ItemType: [{ label: 'Select Stock Type', value: '0' }],
        Items: [{ label: 'Select Items', value: '0' }],
    });
    const handleClearFilters = () => {
        setFormData({
            startdate: "",
            endDate: "",
            PoNum: "",
            Filterby: [{ label: 'Select Delivery Status', value: '0' }],
            VendorName: [{ label: 'Select Supplier ', value: '0' }],
            StockType: [{ label: 'Select Stock Type', value: '0' }],
            Status: [{ label: 'Filter By', value: '' }],
            Cost1: [{ label: 'Select Stock Type', value: '0' }],
            Cost2: [{ label: 'Select Stock Type', value: '0' }],
            Cost3: [{ label: 'Select Stock Type', value: '0' }],
            Cost4: [{ label: 'Select Stock Type', value: '0' }],
            ItemType: [{ label: 'Select Stock Type', value: '0' }],
            Items: [{ label: 'Select Items', value: '0' }],
        });
        setFromDate("")
        setToDate(" ")
        setdateFilter(false)
    };

    useEffect(() => {
        if (formData || pageSize) {
            getItems();
        }
        if (dateFilter === true) {
            getItems();
        } else {
            return
        }
    }, [pageSize, formData, dateFilter, toDate, fromDate]);
    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const apiService = new ApiService();
                let param = `?isActive=true`
                const stockType = await apiService.getApiParamater("GET_STOCK_TYPE", param);
                const Items = await apiService.getApiParamater("GET_ITEM", param);
                const suppliers = await apiService.get('GET_SUPPLIER');
                const activeSupplier = suppliers?.data?.result.filter(res => res.exp4 === "1");
                setSuppliers(activeSupplier);
                setStockType(stockType?.data?.result);
                setItems(Items?.data?.result);
            } catch (error) {
                console.error('Error fetching dropdown data:', error);
            }
        };
        fetchDropdownData();
        getItems()
    }, []);
    const getItems = async (pageChange) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('VendorName', formData.VendorName.label || "");
            params.append('Status', formData.Status.value || "");
            params.append('Filter', formData.Filterby.value || "");
            params.append('ItemDescription', formData.Items?.label || "");
            params.append('CostCenter1', formData.Cost1?.label || "");
            params.append('CostCenter2', formData.Cost2?.label || "");
            params.append('CostCenter3', formData.Cost3?.label || "");
            params.append('CostCenter4', formData.Cost4?.label || "");
            params.append('StockType', formData.StockType?.value?.stockTypeCode || "");
            let newPageNo = pagination?.currentPage;

            if (pageChange === "next") {
                newPageNo += 1;
            } else if (pageChange === "prev") {
                newPageNo -= 1;
            } else if (pageChange === "skipNext") {
                newPageNo += 10;
            } else if (pageChange === "skipPrev") {
                newPageNo -= 10;
            }

            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            params.append('POrderNo', formData.PoNum || "");
            if (dateFilter === true) {
                params.append('FromDate', fromDate || "");
                params.append('ToDate', toDate || "");
            }

            const res = await apiService.getApiParamater('GET_PO_REPORT', `?${params.toString()}`);
            setRowData(res?.data?.result?.reverse() || []);
            setPagination(res.data.paginationData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const dateFormatter = (params) => {
        const date = moment.utc(params.value || currentDatetimeUTC).local();
        return date.format("DD-MM-YYYY");
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    }
    const Comparison = [
        { headerName: 'PO No', field: 'pOrderNo', filter: true, floatingFilter: true, width: 110 },
        {
            headerName: 'Requisition NO ', field: 'requisitionNo', filter: true, floatingFilter: true, width: 110,
        },
        {
            headerName: 'PO Date', field: 'docDate', filter: true, floatingFilter: true, width: 110, filter: 'agDateColumnFilter',
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Vendor', field: 'vendorName', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'Payment Term', field: 'terms', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'Department', field: 'department', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'Supplier Contact', field: 'attention', filter: true, floatingFilter: true, width: 110 },
        {
            headerName: 'Delivery Date', field: 'deliveryDate', filter: true, floatingFilter: true, width: 150, filter: 'agDateColumnFilter',
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Qout Ref', field: 'quotationRef', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'Curr Code', field: 'currencyCode', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'Exchange Rate', field: 'exchangeRate', filter: true, floatingFilter: true, width: 110 },
        {
            headerName: 'Status', field: 'status', filter: true, floatingFilter: true, width: 110,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<div>Pending</div>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<div>Approved</div>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<div>Hold</div>);
                }
                else if (params.value === 3 || params.value === null) {
                    return (<div>Rejected</div>);
                } else {
                    return params.value;
                }
            }
        },
        { headerName: 'Cost center 1', field: 'costCenter1', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'Cost center 2', field: 'costCenter2', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'Cost center 3', field: 'costCenter3', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'Cost center 4', field: 'costCenter4', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'Delivery Status', field: 'deliveryStatus', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'Item Code', field: 'itemCode', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'Item Description', field: 'itemDescription', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'UOM', field: 'uom', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'QTY', field: 'qty', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'Unit Price', field: 'unitPrice', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'Vat %', field: 'vatPerc', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'Vat Amount', field: 'vatAmount', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'Amount', field: 'amount', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'Net Amount', field: 'netAmount', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'GRN Qty', field: 'grnQty', filter: true, floatingFilter: true, width: 110 },

    ];
    const onBtnCSVExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: 'PurchaseOrderReport.csv'
        });
    }, []);

    const onBtExcelExport = useCallback(() => {
        gridRef.current.api.exportDataAsExcel({
            fileName: 'PurchaseOrderReport.xlsx'
        });
    }, []);

    const onBtPDFExport = useCallback(() => {
        const doc = new jsPDF();
        const rowData = [];
        const columnHeaders = [];
        const comparisonFields = [
            'recordId',
            'requisitionNo',
            'docDate',
            'vendorName',
            'terms',
            'status',
            'deliveryDate',
            'deliveryStatus',
            'itemCode',
            'itemDescription',
            'uom',
            'qty',
            'unitPrice',
        ];

        const translateStatus = (status) => {
            switch (status) {
                case 1:
                    return 'Approved';
                case 0:
                    return 'Pending';
                case 2:
                    return 'Hold';
                case 3:
                    return 'Rejected';
                default:
                    return status;
            }
        };

        comparisonFields.forEach(field => {
            const header = Comparison.find(col => col.field === field).headerName;
            columnHeaders.push(header);
        });

        gridRef.current.api.forEachNode((node) => {
            const row = [];
            comparisonFields.forEach(field => {
                let value = node.data[field];
                if (field === 'status') {
                    value = translateStatus(value);
                }
                row.push(value);
            });
            rowData.push(row);
        });

        const poppinsFont = 'data:font/ttf;base64,AAEAAAARAQAABAAw7HA...';
        doc.addFileToVFS("Poppins-Regular.ttf", poppinsFont);
        doc.addFont("Poppins-Regular.ttf", "Poppins", "normal");
        doc.setFont("Poppins");
        doc.setFontSize(18);
        doc.setTextColor(40, 40, 40);
        doc.text("Purchase Order Report", 72, 12);
        doc.text("__________________________", 60, 15);
        doc.autoTable({
            head: [columnHeaders],
            body: rowData,
            styles: {
                fontSize: 5,
            },
            headStyles: {
                fillColor: [209, 126, 6],
                textColor: [255, 255, 255],
                fontSize: 5,
            },
            columnStyles: {
                0: { cellWidth: 15 },
                1: { cellWidth: 15 },
                2: { cellWidth: 15 },
                3: { cellWidth: 15 },
                4: { cellWidth: 15 },
                5: { cellWidth: 15 },
                6: { cellWidth: 15 },
                7: { cellWidth: 20 },
                8: { cellWidth: 15 },
                9: { cellWidth: 15 },
                10: { cellWidth: 10 },
                11: { cellWidth: 10 },
                12: { cellWidth: 10 },
            },
            startY: 20
        });

        doc.save('PurchaseOrderReport.pdf');
    }, []);

    const handlePageSizeChange = (event) => {
        const newSize = Number(event.target.value);
        setPageSize(newSize);
    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleStockChange = (selectedOption) => {
        setFormData({ ...formData, StockType: selectedOption });
    }
    const handleSupplierChange = (selectedOption) => {
        setFormData({ ...formData, VendorName: selectedOption });
    }
    const handleItemChange = (selectedOption) => {
        setFormData({ ...formData, Items: selectedOption });
    }
    const handleFilterbyChange = (selectedOption) => {
        setFormData({ ...formData, Filterby: selectedOption });
    }
    const handleStatusFilter = (selectedOption) => {
        setFormData({ ...formData, Status: selectedOption });
    }
    const handleFromDate = (e) => {
        setFromDate(e.target.value);
    };
    const handleToDate = (e) => {
        setToDate(e.target.value);
    };
    const handleDateFilterSelection = (e) => {
        setdateFilter(e);
    };
    return (
        <div className='p-5'>
            <div className='col  start'>
                <h4 className='start'>
                    Purchase Order Report
                </h4>
            </div>
            <div className='pb-4'>
                {/* <div className='d-flex gap-3'>
                    <div className='col'>
                        <Select
                            defaultValue={{ label: 'Cost Center 1', value: 'Select Sub Category' }}
                            options={staticOptions}
                        />
                    </div>
                    <div className='col'>
                        <Select
                            defaultValue={{ label: 'Cost Center 2', value: 'Select Sub Category' }}
                            options={staticOptions}
                        />
                    </div>
                    <div className='col'>
                        <Select
                            options={staticOptions}
                            defaultValue={{ label: 'Cost Center 3', value: 'Select Stock Type' }}
                        />
                    </div>
                    <div className='col'>
                        <Select
                            options={staticOptions}
                            defaultValue={{ label: 'Cost Center 4', value: 'Select Stock Type' }}
                        />
                    </div>
                </div> */}
                <div className='d-flex gap-3 my-3'>

                    <div className='col'>
                        <input
                            type='number'
                            className="form-control"
                            placeholder='PO No'
                            value={formData.PoNum}
                            name="PoNum"
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='col'>
                        <Select
                            value={formData.Status}
                            options={StatusFilter}
                            onChange={handleStatusFilter}
                        />
                    </div>
                    <div className='col'>
                        <Select
                            defaultValue={{ label: 'Item type', value: 'Select Sub Category' }}
                            options={staticOptions}
                        />
                    </div>
                    <div className='col'>
                        <Select
                            value={formData.Items}
                            onChange={(selectedOption) => handleItemChange(selectedOption)}
                            options={Items?.map((itm) => ({ value: itm, label: itm?.itemDescription }))}
                        />
                    </div>
                </div>
                <div className='d-flex gap-3 mb-3'>
                    <div className='col'>
                        <Select
                            onChange={(selectedOption) => handleFilterbyChange(selectedOption)}
                            value={formData.Filterby}
                            options={FilterStatus}
                        />
                    </div>
                    <div className='col'>
                        <Select
                            value={formData.VendorName}
                            onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                            options={SupplierList?.map((sup) => ({ value: sup, label: sup?.supplierName }))}
                        />
                    </div>
                    <div className='col'>
                        <Select
                            value={formData.StockType}
                            onChange={(selectedOption) => handleStockChange(selectedOption)}
                            options={StockType?.map((Stock) => ({ value: Stock, label: Stock?.stockTypeName }))}
                        />
                    </div>
                    <div className='col-4 d-flex gap-3'>
                        <div >
                            <input
                                type='date'
                                className="form-control"
                                value={fromDate}
                                name="fromDate"
                                disabled={!dateFilter}
                                onChange={handleFromDate}
                                placeholder='From  Date '
                            />
                        </div>
                        <div className=''>
                            <input
                                type='date'
                                disabled={!dateFilter}
                                className="form-control"
                                placeholder='To  Date '
                                value={toDate}
                                name="toDate"
                                onChange={handleToDate}
                            />
                        </div>
                        <div className='gap-3 center'>
                            <input className="form-check-input"
                                type="checkbox"
                                id="dateFilter" style={{ width: "26px", height: "26px" }}
                                name="dateFilter"
                                checked={dateFilter}
                                onChange={(e) => handleDateFilterSelection(e.target.checked)} />
                            <label className=''>Date Filter</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className='pageBodr'>
                <div className='row'>
                    <div className='col-6 d-flex gap-5'>
                        <div className='arrows d-flex gap-5'>
                            {pagination?.totalPages < 10 ?
                                <div className='center'><RiSkipLeftFill size={30} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><RiSkipLeftFill size={30} onClick={() => getItems("skipPrev")} /></div>
                            }
                            {pagination?.currentPage === 1 ?
                                <div className='center'><FaAngleLeft size={25} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><FaAngleLeft size={25} onClick={() => getItems("prev")} /></div>
                            }
                            <div className='d-flex gap-3 center'>
                                <div className=''>{pagination?.currentPage || "0"}</div>
                                <div className=''>of</div>
                                <div className=''>{pagination?.totalPages || "0"}</div>
                            </div>
                            {pagination?.currentPage === pagination?.totalPages ?
                                <div className='center'><FaAngleRight size={25} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><FaAngleRight size={25} onClick={() => getItems("next")} /></div>
                            }
                            {pagination?.totalPages > 10 ?
                                <div className='center'><RiSkipRightFill size={30} onClick={() => getItems("skipNext")} /></div> :
                                <div className='center'><RiSkipRightFill size={30} style={{ color: "#ABA3A3" }} /></div>
                            }
                        </div>
                        <div className="gap-4 d-flex">
                            <div className='center'>Page Size</div>
                            <div>
                                <select
                                    className="form-select pagclr"
                                    aria-label="Default select example"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                >
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 '>
                        <div className='end arrows d-flex gap-4'>
                            <div className='center' onClick={handleClearFilters}><SlRefresh size={30} style={{ color: "5B93FF" }} /></div>
                            <div className='center'>
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                                        <PiDownloadSimple size={30} style={{ color: "5B93FF" }} />
                                    </DropdownToggle>
                                    <DropdownMenu className='mt-3'>
                                        <DropdownItem onClick={onBtnCSVExport} className='gap-3 d-flex center'><BsFiletypeCsv /><div>Export CSV</div></DropdownItem>
                                        {/* <DropdownItem onClick={onBtExcelExport} className='gap-3 d-flex center'><LiaFileExcel /><div>Export Excel</div></DropdownItem> */}
                                        <DropdownItem onClick={onBtPDFExport} className='gap-3 d-flex center'><BsFileEarmarkPdf /><div>Export PDF</div></DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <div className='center'><HiOutlinePrinter size={30} style={{ color: "5B93FF" }} /></div>
                            <div className='center'><TfiEmail size={28} style={{ color: "5B93FF" }} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='brdor'>
                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        rowSelection={'single'}
                        columnDefs={Comparison}
                        suppressExcelExport={true}
                        ref={gridRef}
                    />
                </div>
            </div>
        </div>
    );
};
export default PurchaseOrderReports; 