import React, { useState, useEffect } from 'react';
import "../../../../Customer/Menu/NavBar/navBar.css";
import "../menuItemcard.css";
import ApiService from '../../../../services/apiService';
import { toast } from 'react-toastify';
import ItemGrid from './grid/grid';
import Select from 'react-select';

const CreateModifiers = () => {
    const [menu, setMenu] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [tax, setTax] = useState([]);

    useEffect(() => {
        GetModifier();
        GetTax();
    }, [searchTerm]);

    const [formData, setFormData] = useState({
        name: '',
        price: '0',
        Tax: '0',
        discount: '0'
    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCreate = async (formData) => {
        try {
            const datareq = {
                name: formData?.name,
                price: parseInt(formData.price),
                vat: parseInt(0),
                discount: parseInt(formData.discount)
            };
            if (formData.name && formData.price) {
                const apiService = new ApiService();
                let res = await apiService.post("CREATE_MODIFIER", datareq);
                if (res?.data?.statusCode === 201) {
                    toast.success(res.data.message);
                    GetModifier();
                    setFormData({
                        name: '',
                        price: '0',

                    })
                } else {
                    toast.warning(res.data.message);
                }
            } else {
                toast.error("Fill in all the fields to create Modifier");
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const GetModifier = async () => {
        try {
            const apiService = new ApiService();
            let parameters = `?Name=${searchTerm}`;
            const res = await apiService.getApiParamater('GET_MODIFIER', parameters);
            setMenu(res.data.result);
        } catch (error) {
            console.error('Error fetching Variants data:', error);
        }
    };

    const GetTax = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get("GET_TAX");
            setTax(res.data.result);
        } catch (error) {
            console.error('Error fetching menu data:', error);
        }
    };

    const handleVATChange = (selectedOption) => {
        setFormData({ ...formData, Tax: selectedOption?.value || selectedOption });
    };

    return (
        <div className="p-lg-5 container">
            <div className="catlist p-5">
                <div className="form-group row justify-content-between align-content-center">
                    <div className="col-md-6">
                        <label htmlFor={`validationCustom01`} className="form-label">Name</label>
                        <input
                            type="text"
                            className="p-2 form-control w-75"
                            id={`avalidationCustom01`}
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="col-md-6">
                        <div className='gap-2 d-flex '>
                            <div className='w-75'>
                                <label htmlFor={`avalidationCustom02`} className="form-label">Price</label>
                                <input
                                    type="number"
                                    className="form-control "
                                    id={`avalidationCustom02`}
                                    name="price"
                                    value={formData.price}
                                    onChange={handleInputChange}
                                    required
                                    onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <div className='w-25'>
                                <label htmlFor="validationCustomPrice" className="form-label"></label>
                                <div className=" addItem-btn p-3  mt-1"
                                    onClick={() => handleCreate(formData)}
                                >
                                    Save
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row gap-5 pt-5 d-flex justify-content-between'>
                <div className='col-lg-3'>
                    <label htmlFor={`Search`} className="form-label">Modifier Search by name</label>
                    <input
                        type='Search'
                        className='form-control'
                        placeholder='Search'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                <div className=''>
                    <ItemGrid data={menu} GetModifier={GetModifier} />
                </div>
            </div>
        </div>
    );
};

export default CreateModifiers;
