import React, { useState, useEffect } from 'react';
import './table.css';
import { connect } from "react-redux";
import { useDrag } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { getTableData } from '../../../../Redux/selecters';
import Modal from 'react-bootstrap/Modal';

const TableCard = ({ val, selectedTable, setSelectedTable, id, left, top, name, status }) => {
    
    const dispatch = useDispatch();
    const tableData = useSelector(getTableData);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const isTableSelected = tableData.length > 0 && tableData[0].id === id;
    const [chairs, setChairs] = useState(Array.from({ length: val.seatCapacity }, (_, index) => index + 1));
    const [selectedChairs, setSelectedChairs] = useState([]);

    const [{ isDragging }, drag] = useDrag({
        type: 'TABLES',
        item: { id: val.id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const handleSelectChair = (chair) => {
        if (selectedChairs.includes(chair)) {
            setSelectedChairs(selectedChairs.filter(item => item !== chair));
        } else {
            setSelectedChairs([...selectedChairs, chair]);
        }
    };

    const handleSelectTable = () => {
        const tableWithSelectedChairs = { ...val, selectedChairs: selectedChairs };
        setSelectedTable(tableWithSelectedChairs);
    };

    const isChairFilled = (chairNumber) => {
        return val.chirsFilledCount >= chairNumber;
    };

    return (
        <div>
            <div id={id} className={`table-card ${status === 3 || status === 2 ? 'disabled' : ''}`}
                style={{
                    position: 'absolute',
                    left,
                    top,
                    opacity: isDragging ? 0.5 : 1,
                    cursor: status === 3 || status === 2 ? 'not-allowed' : 'pointer',
                    width: '200px',
                }}
                onClick={status !== 3 && status !== 2 ? () => handleShow() : undefined}
                ref={drag}
            >
                <div className="table-container">
                    {chairs.map((chair, index) => (
                        <div key={chair} className={`chair chair-${index + 1}`}>{chair}</div>
                    ))}
                    <div className={`tablebox ${val.shape === 1 ? 'Rectangle' : val.shape === 2 ? 'Circle' : val.shape === 3 ? 'Square' : 'Oval'} ${isTableSelected ? 'selected' : ''}`}>
                        {
                            status === 3
                                ? `Occupied ${val.name}`
                                : status === 2
                                    ? `Reserved ${val.name}`
                                    : (isTableSelected
                                        ? 'Selected'
                                        : name
                                    )
                        }
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title className='center'>Select Pax</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='px-4'>
                        <h6>Select Pax</h6>
                        <div className='d-flex gap-3 pt-3'>
                            {chairs.map((chair) => (
                                <div key={chair} className={`chairBX ${selectedChairs.includes(chair) ? 'selected' : ''} ${isChairFilled(chair) ? 'filled' : ''}`} onClick={() => handleSelectChair(chair)}>
                                    {chair}
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='center'>
                    <button className='saveButton' onClick={() => handleSelectTable()}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(TableCard);
