import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaEye } from "react-icons/fa";
import { MdOutlineEditOff } from "react-icons/md";

const CategoryGrid = ({ rowData, fetchStockData, canEditMaster }) => {

    let navigate = useNavigate();

    const handleEdit = (data) => {
        navigate("/Inventory/Master/Location/CategoryMaster", { state: data })
    };
    const columnDefs = [
        { headerName: 'Record ID', field: 'id', filter: true, floatingFilter: true, minWidth: 550 },

        { headerName: 'Category', field: 'description', filter: true, floatingFilter: true, minWidth: 550 },
        {
            headerName: 'Status', field: 'id', cellRenderer: BtnCellRenderer, minWidth: 460,
            cellRendererParams: {
                canEditMaster: canEditMaster,
                handleEdit: handleEdit,
                clicked: function (field, isActive) {
                    updateActiveStatus(field, isActive);
                },
            },
        }
    ];
    const updateActiveStatus = async (id, isActive) => {
        try {
            const apiService = new ApiService();
            let body = {
                id: id,
                isActive: isActive
            }
            const res = await apiService.put(`UPDATE_WAREHOUSE_CAT_MASTER`, body);
            if (res?.status === 200) {
                console.log('Updated successfully');
                fetchStockData();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    return (
        <div className='brdor' style={{ height: 550 }}>
            <div className="ag-theme-quartz" style={{ height: 550 }}>
                <AgGridReact
                    rowData={rowData}
                    enableFilter={true}
                    floatingFilter={true}
                    columnDefs={columnDefs}
                    suppressRowClickSelection={true}
                />
            </div>
        </div>
    );
};

class BtnCellRenderer extends Component {

    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this); // Bind edit button handler
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data); // Pass rowData to handleEdit function
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value, !this.props.data.isActive); // Toggle isActive value
    }

    render() {
        const { isActive } = this.props.data;
        const { canEditMaster } = this.props;
        return (
            <div className='d-flex gap-3'>
                {canEditMaster === true ?
                    <div onClick={this.btnEditClickedHandler}><FaEdit size={25} /></div> :
                    <div><MdOutlineEditOff size={25} /></div>
                }
                {canEditMaster === true ?
                    <div className="form-check form-switch center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive}
                            onClick={this.btnClickedHandler}
                        />
                    </div>
                    :
                    <div className="form-check form-switch center">
                        <input disabled
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive}
                        />
                    </div>
                }
            </div>
        )
    }
}
export default CategoryGrid;
