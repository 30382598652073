import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CartSlider from '../../Cart/imgSlider';
import "../../Menu/Menu Card/menuCard.css";
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import { AddToCart } from '../../../../Redux/actions/cartAction';
import ApiService from '../../../services/apiService';
import { AddComboToCart } from '../../../../Redux/actions/comboAction';

const ComboModal = ({ CartValue, Currency, closeModal }) => {
    const itemData = CartValue;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [menuItems, setMenuItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null); // Changed to null initially
    const [totalPrice, setTotalPrice] = useState(itemData.price);

    useEffect(() => {
        fetchMenuItems();
    }, []);

    const fetchMenuItems = async () => {
        try {
            const apiService = new ApiService();
            const itemsPromises = itemData.comboItems.map(async (comboItem) => {
                const res = await apiService.getApiParamater("GET_MENU", `?Id=${comboItem.itemId}`);
                return res.data.result;
            });
            const menuItemsData = await Promise.all(itemsPromises);
            const flattenedMenuItems = menuItemsData.flat();
            setMenuItems(flattenedMenuItems);
        } catch (error) {
            console.error('Error fetching menu data:', error);
        }
    };

    const handleCheckboxChange = (itemId, metaItemId, metaItemPrice) => {
        if (!selectedItems) {
            setSelectedItems({
                name: itemData.name,
                description: itemData.description,
                price: totalPrice,
                tax: itemData.tax,
                discount: itemData.discount,
                active: itemData.active,
                imageUrl: itemData.imageUrl,
                quantity: 1,
                comboItems: itemData.comboItems.map(comboItem => ({
                    itemId: comboItem.itemId,
                    metaItemIds: [],
                })),
                id: itemData.id,
                loggedInUserId: itemData.loggedInUserId
            });
            return;
        }

        const selectedItemIndex = selectedItems.comboItems.findIndex(item => item.itemId === itemId);
        const updatedItems = { ...selectedItems };

        if (!updatedItems.comboItems[selectedItemIndex].metaItemIds) {
            updatedItems.comboItems[selectedItemIndex].metaItemIds = [];
        }

        if (updatedItems.comboItems[selectedItemIndex].metaItemIds.includes(metaItemId)) {
            updatedItems.comboItems[selectedItemIndex].metaItemIds = updatedItems.comboItems[selectedItemIndex].metaItemIds.filter(id => id !== metaItemId);
            setTotalPrice(prevTotal => prevTotal - metaItemPrice);
        } else {
            updatedItems.comboItems[selectedItemIndex].metaItemIds.push(metaItemId);
            setTotalPrice(prevTotal => prevTotal + metaItemPrice);
        }
        setSelectedItems(updatedItems);
    };

    const handleATC = () => {
        if (!selectedItems) {
            setSelectedItems({
                name: itemData.name,
                description: itemData.description,
                price: totalPrice,
                tax: itemData.tax,
                discount: itemData.discount,
                active: itemData.active,
                imageUrl: itemData.imageUrl,
                quantity: 1,
                comboItems: itemData.comboItems.map(comboItem => ({
                    itemId: comboItem.itemId,
                    metaItemIds: [],
                })),
                id: itemData.id,
                loggedInUserId: itemData.loggedInUserId
            });
            return;
        }
        dispatch(AddComboToCart(selectedItems));
        navigate('/customer/menu/combo');
        closeModal(true);
    };

    return (
        <div className=''>
            <div className='container-fluid m-0 p-0 pt-5 px-5'>
                <div className="row py-lg-3">
                    <div className="col-lg-4">
                        <CartSlider val={itemData} />
                    </div>
                    <div className="col-lg-8 px-lg-5">
                        <div className="CartName">{itemData?.name}</div>
                        <div className="cartPrice pt-2">{Currency.currency.name} {totalPrice.toFixed(2)}</div>
                        {menuItems?.map(item => (
                            <div className="py-3" key={item?.id}>
                                <div className="form-check d-flex justify-content-between py-3">
                                    <label className="form-check-label checkItem2">
                                        {item?.name}
                                    </label>
                                    <div className="checkItem">{Currency.currency.name} {item?.price}</div>
                                </div>
                                {item?.menuItemMeta
                                    .filter(metaItem => metaItem.metaType === 3)
                                    .map(metaItem => (
                                        <div key={metaItem?.metaItemId} className="form-check d-flex justify-content-between pb-2">
                                            <div className="gap-3 d-flex">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    style={{ width: "26.81px", height: "26.81px" }}
                                                    checked={selectedItems && selectedItems.comboItems.some(selectedItem => selectedItem.itemId === item.id && selectedItem.metaItemIds.includes(metaItem.metaItemId))}
                                                    onChange={() => handleCheckboxChange(item.id, metaItem?.metaItemId, metaItem?.metaItemPrice)}
                                                />
                                                <label className="form-check-label ">
                                                    {metaItem?.metaItemName}
                                                </label>
                                            </div>
                                            <div className="checkItem">{Currency.currency.name} {metaItem?.metaItemPrice}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        ))}
                        {/* Add to Cart button */}
                        <div className='row pt-lg-5'>
                            <div className='col-lg-12'>
                                <button className='w-100 AddBtn' onClick={handleATC}>
                                    Add to cart ({Currency.currency.name} {totalPrice.toFixed(2)})
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(ComboModal);
