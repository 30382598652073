import React, { useEffect, useState, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Edit } from '../../Assets/svg/edit.svg'
import Button from 'react-bootstrap/Button';

const ApprovalUserGrid = ({ fetchApprovalData, rowData }) => {

    const [selectedRowData, setSelectedRowData] = useState(null);
    let navigate = useNavigate();

    const handleEdit = (data) => {
        navigate("/inventory/ApprovalUser", { state: data })
    };

    const [colDefs, setColDefs] = useState([
        { headerName: "Doc Type", field: "docType", filter: true, floatingFilter: true, minWidth: 310 },
        { headerName: "User No", field: "userNo", filter: true, floatingFilter: true, minWidth: 319 },
        { headerName: "User Level", field: "userLevel", filter: true, floatingFilter: true, minWidth: 340 },
        { headerName: "Department", field: "department", filter: true, floatingFilter: true, minWidth: 340 },
        {
            headerName: 'Status', field: 'rcid', cellRenderer: BtnCellRenderer, minWidth: 545,
            cellRendererParams: {
                handleEdit: handleEdit,
                clicked: function (field, active) {
                    updateActiveStatus(field, active);
                },
            },
        }
    ]);
    const updateActiveStatus = async (id, active) => {
        try {
            const apiService = new ApiService();
            let body = {
                rcid: id,
                active: active || 1,
            }
            const res = await apiService.put(`UPDATE_APPROVAL_USER`, body);
            if (res?.status === 200) {
                console.log('Updated successfully');
                fetchApprovalData();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    const actionsRenderer = (props) => {
        return (
            <div>
                <Button variant="primary" onClick={() => handleEditRow(props.data)}>Edit</Button>
            </div>
        );
    };
    const handleEditRow = (rowData) => {
        setSelectedRowData(rowData);
    };

    return (
        <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={{
                filter: true,
                floatingFilter: true,
                minWidth: 100,
            }}
            suppressRowClickSelection={true}
            pagination={false}
        />
    );
};
class BtnCellRenderer extends Component {

    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this); // Bind edit button handler
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data); // Pass rowData to handleEdit function
    }

    btnClickedHandler() {
        const { value, clicked, data } = this.props;
        const newActiveStatus = data.active === 1 ? 0 : 1;
        clicked(value, newActiveStatus);
    }

    render() {
        const { active } = this.props.data;
        return (
            <div className='d-flex gap-3'>
                <div onClick={this.btnEditClickedHandler}><Edit /></div>
                <div className="form-check form-switch center">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`flexSwitchCheck`}
                        checked={active === 1}
                        onClick={this.btnClickedHandler}
                    />
                </div>
            </div>
        )
    }
}
export default ApprovalUserGrid;
