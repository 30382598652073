import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import "../report.css"
import "./card.css"
import moment from 'moment';

const ReportCard = ({ data }) => {
    
    let navigate = useNavigate();
    const handlechart = () => {
        navigate('/admin/Reports/home')
    }
    const mapOrderType = (orderType) => {
        switch (orderType) {
            case 0:
                return "Dine-In";
            case 1:
                return "Take-Away";
            case 2:
                return "Delivery";
            case 3:
                return "Online Order";
            case 4:
                return "Drive through";
            case 5:
                return "Catering";
            case 6:
                return "Room Service";
            case 7:
                return "Pickup";
            case 8:
                return "Curbside Pickup";
            default:
                return "Unknown Order Type";
        }
    };
    const mapPaymentType = (orderType) => {
        switch (orderType) {
            case 0:
                return "Pending";
            case 1:
                return "Authorized";
            case 2:
                return "Paid";
            case 3:
                return "Refunded";
            case 4:
                return "Failed";
            default:
                return "Unknown Order Type";
        }
    };
    return (
        <div className='cardbx'>
            <div className='cardHead row'>
                <div className='col-4 start'>Item Name</div>
                <div className='col-4 center'>Qty</div>
                <div className='col-4 end'>Price</div>
            </div>
            <div className='px-2 flowover'>
                {data?.associtedOrder?.orderItems?.map((orderItem, itemIndex) => {
                    const metaItem = data.associtedOrder.orderItemsMeta.find(meta => meta.menuItemId === orderItem.itemId);
                    return (
                        <div key={itemIndex} className={``}>
                            <div className={` row my-2 `}>
                                <div className='col-4 start'>{orderItem?.menuItemName}</div>
                                <div className='col-4 center'>{orderItem?.quantity || "---"}</div>
                                <div className='col-4 end'>{metaItem ? metaItem.variantPrice : "---"}</div>
                            </div>
                            <div class="separators">
                                <hr />
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className='center pt-3'>
                <div className='cardHead d-flex justify-content-around w-75 '>
                    <div>Order Number {data?.orderId}</div>
                    <div>Date {moment(data?.paymentDate).format('DD-MMM, hh:mm a')}</div>
                </div>
            </div>
            <div className='d-flex gap-3 px-3 pt-3'>
                <div className='coscard w-50'>
                    <div className='cos'>Tax</div>
                    <div>{data?.associtedOrder?.calculatedTax || "0"}</div>
                </div>
                <div className='coscard w-50'>
                    <div className='cos'>Discount</div>
                    <div>{data?.associtedOrder?.discount || "0"}</div>
                </div>
            </div>
            <div className='d-flex gap-3 px-3 pt-2'>
                <div className='coscard w-50'>
                    <div className='cos'>Customer</div>
                    <div>{data?.associtedOrder?.customerName || "Guest"}</div>
                </div>
                <div className='coscard w-50'>
                    <div className='cos'>Price</div>
                    <div>{data?.associtedOrder?.orderAmount || "---"}</div>
                </div>
            </div>
            <div className='d-flex gap-3 px-3 py-2'>
                <div className='coscard w-50'>
                    <div className='cos'>Order type</div>
                    <div>{mapOrderType(data?.associtedOrder?.orderType) || "---"}</div>
                </div>
                <div className='coscard w-50'>
                    <div className='cos'>Payment type</div>
                    <div>{mapPaymentType(data?.associtedOrder?.paymentStatus) || "---"}</div>
                </div>
            </div>
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(ReportCard);