import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';
import Select from "react-select";
import RoleGrid from './Rolegrid';

const CreateUserRole = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [defaultSelection, setDefault] = useState(false);

    const [formData, setFormData] = useState({
        RoleCode: "",
        RoleName: "",
        conversionRate: "",
    });
    useEffect(() => {
        fetchRoleData();
    }, []); 
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const [rowData, setRowData] = useState([]);
    const fetchRoleData = async () => {
        try {
            const apiService = new ApiService()
            const response = await apiService.get('GET_CURRENCY_MASTER');
            const CurrencyData = response.data?.result;
            setRowData(CurrencyData);
        } catch (error) {
            console.error("Error fetching Currency data:", error);
        }
    };

    return (
        <div className='p-lg-5'>
            <h4>User Role</h4>
            <div className='py-lg-5'>
                <div className='brderbox p-lg-3'>
                    <div className="form-group row pb-3">
                        <div className="col-lg-4">
                            <label htmlFor="validationCustom01" className="form-label">User Role Code</label>
                            <input
                                type="number" onWheel={(e) => e.target.blur()}
                                className="form-control"
                                id="validationCustom01"
                                name="RoleCode"
                                value={formData.RoleCode}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <label htmlFor="validationCustom02" className="form-label">User Role Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                                name="RoleName"
                                value={formData?.RoleName}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div className='col-lg-2 center pt-3'>
                            <button className='mainbtn ' >Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-3'>
                <RoleGrid fetchRoleData={fetchRoleData} rowData={rowData}/>
            </div>
        </div>
    )
}

export default CreateUserRole;
