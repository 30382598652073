import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/apiService';
import Select from 'react-select';
import { ReactComponent as Link } from '../../Assets/svg/linkIcon.svg';
import { ReactComponent as Add } from '../../Assets/svg/Add.svg';
import { AiOutlinePrinter } from "react-icons/ai"
import moment from 'moment';
import { IoAddSharp } from "react-icons/io5";
import Pagination from '../pagination/pagination';
const InvoiceList = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const StatusFilter = [
        { value: 'All', label: 'All' },
        { value: '0', label: 'Pending' },
        { value: '1', label: 'Approved' },
        { value: '5', label: 'Hold' },
        { value: '2', label: 'Rejected' },
    ];
    const [formData, setFormData] = useState({
        Status: { label: 'All', value: '' },
    });
    const handleClearFilters = () => {
        setFormData({
            Status: [{ label: 'All', value: '' }],
        });
    };
    useEffect(() => {
        getItems();
    }, []);

    useEffect(() => {
        if (pageSize) {
            getItems();
        }
        if (formData) {
            getItems();
        }
    }, [pageSize, formData]);

    const handlePrint = (data) => {
        navigate(`/inventory/invoice/Print/${data?.invoiceNo}`, { state: data })
    }
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
           params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            debugger
            if (formData?.Status?.label === "All") {

            } else {
                params.append('Status', formData?.Status?.value);
            }
            const res = await apiService.getApiParamater('GET_INVOICE', `?${params.toString()}`);
            setPagination(res.data.paginationData)
            setRowData(res?.data?.result || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("MMM Do YYYY");
    };
    const Comparison = [
        {
            headerName: 'Invoice No', field: 'invoiceNo', filter: true, floatingFilter: true, width: 150,
        },
        {
            headerName: 'PO No', field: 'pOrderNo', filter: true, floatingFilter: true, width: 150,
        },
        {
            headerName: 'GRN No', field: 'grnNo', filter: true, floatingFilter: true, width: 150,
        },
        {
            headerName: 'Invoice Date', field: 'invoiceDate', filter: true, floatingFilter: true, width: 150,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Vendor Name', field: 'vendorName', filter: true, floatingFilter: true, width: 150 },
        { headerName: 'Property', field: 'costCenter1', filter: true, floatingFilter: true, width: 150 },
        { headerName: 'Property Group', field: 'remarks', filter: true, floatingFilter: true, width: 150 },
        {
            headerName: 'Due Date', field: 'dueDate', filter: true, floatingFilter: true, width: 140,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        {
            headerName: 'Status', field: 'status', filter: true, floatingFilter: true,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 2 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }, width: 160
        },
        {
            headerName: 'Action', field: 'id', cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                handlePrint: handlePrint,
                clicked: function (field) {
                    // handleShow(field)
                },
            },
        }
    ];
    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };
    const handlenew = () => {
        navigate('/inventory/invoice/Create')
    }
    const handleStatusFilter = (selectedOption) => {
        debugger
        if (selectedOption.label == "All") {
            handleClearFilters()
        } else {
            setFormData({ ...formData, Status: selectedOption });
        }
    }
    return (
        <div className='p-4'>
            <div className=' justify-content-between d-flex align-items-center pb-4'>
                <div className='mainInvHead'>Supplier Invoice</div>
                <div className='col-3'>
                    <Select
                        className="p-0"
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            padding: 9
                        })}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        value={formData.Status}
                        options={StatusFilter}
                        onChange={handleStatusFilter}
                    />
                </div>
                <div onClick={handlenew} className=' justify-content-evenly flex-end mainbtn  gap-3'>
                    <IoAddSharp color='#fff' className='center mt-1 mx-2' />
                    <div>Add New</div>
                </div>
            </div>
            <div className='brdor'>
                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        rowSelection={'single'}
                        columnDefs={Comparison}
                        pagination={false}
                        rowHeight={50}
                        suppressExcelExport={true}
                        suppressRowClickSelection={true}
                        frameworkComponents={{ frameworkComponents }}
                    />
                </div>
            </div>
            <Pagination pagination={pagination} getItems={getItems} />
        </div>
    );
};
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnPrintClickedHandler = this.btnPrintClickedHandler.bind(this);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }
    btnPrintClickedHandler() {
        const { data, handlePrint } = this.props;
        handlePrint(data);
    }
    render() {
        return (
            <div className='d-flex gap-3'>
                <div><AiOutlinePrinter onClick={this.btnPrintClickedHandler} size={30} /></div>
                <div><Link /></div>
                {/* <div><Edit /></div> */}
            </div>
        )
    }
}
export default InvoiceList; 