import React from 'react';
import './menuItemcard.css'
import 'bootstrap'
import P1 from '../../../assets/images/P1.png';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import ApiService from '../../../services/apiService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'
import { connect } from "react-redux";
import inactiveBox from "../../../assets/images/InActive.png"
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

const MenuItemCard = ({ itemPic = P1, item, Currency ,GetMenu}) => {

    const [show, setShow] = useState(false);
    let navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleview = () => {
        navigate(`/admin/menu/${item?.name}/${item?.id}`, { state: item });
    }
    const handleEdit = (item) => {
        debugger
        navigate(`/admin/master/Update/${item?.name}`, { state: { item } });
    }
    const DeleteItem = async () => {
        const apiService = new ApiService();
        let body = {
            id: parseInt(item?.id)
        }
        let res = await apiService.delete("DELETE_ITEM", { data: body });

        if (res?.data?.statusCode === 204 || res?.data?.statusCode === 200) {
            // Show success toast
            setShow(false)
            toast.success(res.data.message)
            GetMenu()
        }
        else {
            toast.warning(res.data.message);
        }
    }
    const truncatedAddress = item?.name?.length > 20 ? item?.name.substring(0, 20) + '...' : item?.name;
    const truncatedDescription = item?.description?.length > 20 ? item?.description.substring(0, 30) + '...' : item?.description;
    return (
        <>
            <div className="menu-item-card mt-5">
                <div className="menu-item-details justify-content-center IMG-BG">
                    <div className="ActiveTag">
                        {item?.active === true ? "Active Item" : "InActive Item"}
                    </div>
                    <div onClick={() => handleview(item)}>
                        <img src={item?.imageUrl === null || "" ? itemPic : IMAGE_URL + item?.imageUrl} className='item-pic' />
                    </div>
                </div>
                <div className="menu-item-details justify-content-center p-3">
                    <div className="item-name" title={item?.name}>{truncatedAddress || "--"}</div>
                    <div className="item-des mt-1" title={item?.description}>{truncatedDescription || "--"}</div>
                    <div className="item-price mt-1" title={item?.price}  >
                        {Currency.currency.name} {" "}
                        {item?.price?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }) || "0"}
                    </div>
                    <div className="actions p-1 gap-3 mt-3 d-flex ">
                        <div className='w-50'>
                            <button className="edit-button" onClick={() => handleEdit(item)}>
                                Edit
                            </button>
                        </div>
                        <div className='w-50'>
                            <button className="delete-button" onClick={handleShow}>
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you Sure you want to delete {item?.name}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => DeleteItem()}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(MenuItemCard);