import { React, useState, useEffect } from 'react'
import ImageUpload from '../../menus/addItems/imagUpload';
import "../master.css"
import { toast } from 'react-toastify';
import ApiService from '../../../../services/apiService';
import userManagement from '../../../../services/userManagement';
import Select from 'react-select';

const Image = process.env.REACT_APP_IMAGE_URL
const CreateMethod = () => {
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [glAccount, setGlAccount] = useState([]);

    useEffect(() => {
        GetMethods()
        GetAccount()

    }, []);
    const [formData, setFormData] = useState({
        paymentMethod: "",
        images: [],
        imageUrl: "",
        glAccountCode: "",
        glAccountName: "",
    });
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const handleGLAccounts = (selectedOption) => {
        debugger
        setFormData({
            ...formData, glAccountCode: selectedOption?.value?.accountCode,
            glAccountName: selectedOption?.value?.accountName
        });

    }
    const CreatepaymentMethod = async () => {

        try {
            if (!formData?.images && !formData?.imageUrl) {
                toast.error("Upload Image");
            } else if (formData?.images) {
                try {
                    const apiService = new ApiService();
                    const formdata = new FormData();
                    for (const image of formData?.images) {
                        if (image.file) {
                            formdata.append('Images', image?.file);
                        }
                    }
                    const response = await apiService.postMultiPart("UPLOAD_IMG", formdata);

                    if (response?.data?.statusCode === 201) {
                        setFormData({ ...formData, imageUrl: response?.data?.result });

                        handleSubmit(response?.data?.result)
                    }
                    else toast.error(response?.data?.msg)
                } catch (error) {
                    toast.error("Something went wrong with the image. Please try again!");
                }
            }
        } catch (error) {
            console.error('Error fetching paymentMethod :', error);
        }
    }
    const handleSubmit = async (imgurl) => {
        try {
            let UserId = userManagement.getUserId();
            if (imgurl) {
                let body = {
                    name: formData?.paymentMethod,
                    imageUrl: imgurl,
                    loggedInUserId: UserId,
                    glAccountName: formData?.glAccountName,
                    glAccountCode: formData?.glAccountCode,
                }

                const apiService = new ApiService();
                let res = await apiService.post('CREATE_PAYMENT_METHOD', body);
                if (res?.data?.statusCode == 201) {
                    toast?.success("paymentMethod Created Successfully")
                    setFormData({
                        paymentMethod: "",
                        images: [],
                        imageUrl: '',
                        glAccountCode: "",
                        glAccountName: "",
                    });
                    GetMethods();
                    window.location.reload(false);
                }
            }

        } catch (error) {
            console.error('Error fetching paymentMethod :', error);
        }
    }
    const GetAccount = async () => {
        try {
            const apiService = new ApiService();
            let param = `?AccountType=Cash`
            let res = await apiService.get('GET_GL_ACCOUNT');

            setGlAccount(res?.data?.result);
        } catch (error) {
            console.error('Error fetching GL ACCOUNT data:', error);
        }
    };
    const handleImagesSelect = (images) => {
        setFormData({ ...formData, images: images });
    }
    const handleImageRemove = (index) => {
        const updatedImages = [...formData.images];
        updatedImages.splice(index, 1);
        setFormData({ ...formData, images: updatedImages });
    }
    const GetMethods = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get('GET_PAYMENT_METHOD');
            setPaymentMethod(res?.data?.result);
        } catch (error) {
            console.error('Error fetching paymentMethod data:', error);
        }
    }
    const handleUpdatePayment = async (payment, newActiveStatus) => {
        try {
            const apiService = new ApiService();
            const catData = {
                id: payment?.id,
                name: payment?.name,
                loggedInUserId: payment?.loggedInUserId,
                active: newActiveStatus,
                imageUrl: payment?.imageUrl,
            };
            const res = await apiService.put(`UPDATE_PAYMENT_METHOD`, catData);
            if (res?.data?.statusCode === 200) {
                toast.success(`Payment ${payment?.name} updated successfully!`);
                GetMethods();
            } else {
                toast.error("Failed to update Payment. Please try again!");
            }
        } catch (error) {
            console.error('Error updating Payment:', error);
        }
    }
    const handleSwitchChange = async (payment, newActiveStatus) => {
        try {
            await handleUpdatePayment(payment, newActiveStatus);
        } catch (error) {
            console.error('Error updating Payment:', error);
        }
    }

    return (
        <div className='p-5 container'>
            <div className='catlist'>
                <div className='py-3 row'>
                    <div className="col-5 col6">
                        <label htmlFor="validationCustom01" className="form-label">Payment Method </label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom01"
                            name="paymentMethod"
                            value={formData.paymentMethod}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div className="col">
                        <label htmlFor="validationCustom04" className="form-label">
                            Select Gl Account *
                        </label>
                        <Select styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                color: state.isFocused ? '#000 ' : '#000',
                                ':hover': {
                                    backgroundColor: '#F5F5F5',
                                    color: '#000 ',
                                },
                            }),
                        }}
                            defaultValue={formData.glAccountName}
                            onChange={handleGLAccounts}
                            options={glAccount?.map((gl) => ({ value: gl, label: gl.accountName }))}
                        />
                        <div className="valid-feedback">Looks good!</div>
                    </div>

                </div>
                <div className='row center pt-3 d-flex'>
                    <div className='col'><ImageUpload onImagesSelect={handleImagesSelect} onImageRemove={handleImageRemove} formData={formData} /></div>
                    <div className='col d-flex align-self-end justify-content-center  pt-3'><button className='addItem-btn' onClick={() => CreatepaymentMethod()}>Save</button></div>
                </div>

            </div>
            <div className='catlist mt-5 table-responsive'>
                <table className='table table-striped mt-3'>
                    <thead>
                        <tr>
                            <th scope='col'>logo</th>
                            <th scope='col'>Payment Method</th>
                            <th scope='col'>Gl Account</th>
                            <th scope='col'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paymentMethod?.length === 0 ? (
                            <tr>
                                <td colSpan="3" className="text-center">No Methods there</td>
                            </tr>
                        ) : (
                            paymentMethod?.map((methods, index) => (
                                <tr key={index}>
                                    <td scope='row'><img src={Image + methods?.imageUrl} className='paymentlogo' /></td>
                                    <td className='pt-2'>{methods.name}</td>
                                    <td className='pt-2'>{methods.glAccountName?methods.glAccountName:'----'}</td>

                                    <td>
                                        <div className="d-flex text-center">
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id={`flexSwitchCheck${methods.id}`}
                                                    checked={methods.active}
                                                    onChange={(e) => handleSwitchChange(methods, e.target.checked)}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>

                </table>
            </div>
        </div>

    )
}

export default CreateMethod