import {ADD_COMBO_TO_CART,CLEAR_COMBO_CART,DOWNGRADE_COMBO_QUANTITY,REMOVE_COMBO_FROM_CART, UPDATE_COMBO_QUANTITY} from "./types"

export const AddComboToCart = (item) => ({
  type: ADD_COMBO_TO_CART,
  payload: item,
});

export const RemoveComboFromCart = (itemId, index) => ({
  type: REMOVE_COMBO_FROM_CART,
  payload: { itemId, index }, 
});

export const updateComboQuantity = (index, itemId, quantity) => ({
  type: UPDATE_COMBO_QUANTITY,
  payload: { index, itemId, quantity },
});

export const downgradeComboQuantity = (index, itemId, quantity) => ({
  type: DOWNGRADE_COMBO_QUANTITY,
  payload: { index, itemId, quantity },
});

export const clearComboCart = () => ({
  type: CLEAR_COMBO_CART,
});