import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CashierLayout from '../Cashier/components/cashierLayout'; // Import your layout component for Cashier
import CashierMenu from '../Cashier/pages/cashierMenu' // Import your Cashier routes

const CashierRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<CashierLayout>
        <CashierMenu/>
      </CashierLayout>} />
    </Routes>
  );
};

export default CashierRoutes;
