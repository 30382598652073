import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CategoryGridMaster from './categoryGrid';
import axios from 'axios'; // Import axios for API requests
import ApiService from '../../../INVENTORY/services/apiService';
import SubCategoryGridMaster from './subCategoryGrid';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { getUserName } from '../../../Redux/selecters';
import { hasPermission } from "../../services/roleService"
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";

const SubCategoryMaster = () => {
    const location = useLocation();

    const [categories, setCategories] = useState([]);
    const [subcategories, setSubCategories] = useState([]);
    const apiService = new ApiService();
    const roleAccess = JSON.parse(localStorage.getItem('RoleAccess'));
    const [loader, setLoader] = useState(false);
    const [catCode, setCatCode] = useState()
    const [pagination, setPagination] = useState();
    const userName = useSelector(getUserName);
    const [pageSize, setPageSize] = useState(20);
    const [permissions, setPermissions] = useState([]);
    const [accounts, setAccounts] = useState();
    const [formData, setFormData] = useState({
        recordId: "",
        subcatcode: "",
        subcatname: "",
        category: [],
        catcode: "",
        active: 0,
        adduser: "",
        InventoryAccounts: [],
        grnAccount: [],
        wipAccount: [],
        invoiceAccount: [],
        adjustmentAccount: [],
        cgsAccount: [],
        issueAccount: [],
        wastageAccount: [],

    });
    useEffect(() => {
        window.history.replaceState({}, '')
        const item = location.state;
        if (item) {
            const selectedInvntory = accounts?.find(inv => inv.accountName === item?.inventoryAccountCode)
            const selectedAdjustmentAccount = accounts?.find(adj => adj.accountName === item?.adjustmentAccountCode)
            const selectedGrnAccountCode = accounts?.find(grn => grn.accountName === item?.grnAccountCode)
            const selectedissueAccount = accounts?.find(issue => issue.accountName === item?.issueAccountCode)
            const selectedwastageAccount = accounts?.find(waste => waste.accountName === item?.wastageAccountCode)
            const selectedcgsAccount = accounts?.find(cgs => cgs.accountName === item?.cgsAccountCode)
            const selectedinvoiceAccountCode = accounts?.find(invoice => invoice.accountName === item?.invoiceAccountCode)
            const selectedwipAccount = accounts?.find(wip => wip.accountName === item?.workInProgressAccountCode)
            const selectedcategories = categories?.find(cat => cat.catCode === item?.catcode)
            setCatCode(item?.subcatcode)
            setFormData({
                recordId: item?.recordid || "",
                subcatname: item?.subcatname || "",
                InventoryAccounts: { label: selectedInvntory?.accountName, value: selectedInvntory },
                adjustmentAccount: { label: selectedAdjustmentAccount?.accountName, value: selectedAdjustmentAccount },
                grnAccount: { label: selectedGrnAccountCode?.accountName, value: selectedGrnAccountCode },
                issueAccount: { label: selectedissueAccount?.accountName, value: selectedissueAccount },
                wastageAccount: { label: selectedwastageAccount?.accountName, value: selectedwastageAccount },
                cgsAccount: { label: selectedcgsAccount?.accountName, value: selectedcgsAccount },
                invoiceAccount: { label: selectedinvoiceAccountCode?.accountName, value: selectedinvoiceAccountCode },
                wipAccount: { label: selectedwipAccount?.accountName, value: selectedwipAccount },
                category: { label: selectedcategories?.catName, value: selectedcategories }
            });
        }
    }, [location?.state, accounts, categories]);

    useEffect(() => {
        fetchCategories();
        fetchSubCategories()
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
    }, []);

    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    const canEditItemMaster = checkPermission('SubCategory Master', 'edit');
    const canCreateItemMaster = checkPermission('SubCategory Master', 'create');

    const handleIssueAccountChange = (selectedOption) => {
        setFormData({ ...formData, issueAccount: selectedOption });
    }
    const handleCategoryChange = (selectedOption) => {
        setFormData({ ...formData, category: selectedOption });
    }
    const handleWIPAccountChange = (selectedOption) => {
        setFormData({ ...formData, wipAccount: selectedOption });
    }
    const handleInvoiceAccountChange = (selectedOption) => {
        setFormData({ ...formData, invoiceAccount: selectedOption });
    }
    const handleAdjustmentAccountChange = (selectedOption) => {
        setFormData({ ...formData, adjustmentAccount: selectedOption });
    }
    const handleGRNAccountChange = (selectedOption) => {
        setFormData({ ...formData, grnAccount: selectedOption });
    }
    const handleCGSAccountChange = (selectedOption) => {
        setFormData({ ...formData, cgsAccount: selectedOption });
    }
    const handleWastageAccountChange = (selectedOption) => {
        setFormData({ ...formData, wastageAccount: selectedOption });
    }
    const handleInventoryAccountChange = (selectedOption) => {
        setFormData({ ...formData, InventoryAccounts: selectedOption });
    }
    const fetchCategories = async () => {
        try {
            let param = `?IsActive=${true}`
            const response = await apiService.getApiParamater('GET_CATEGORY', param);
            const accountResponse = await apiService.get('GET_ACCOUNTS');
            setAccounts(accountResponse.data.result)
            setCategories(response?.data?.result);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    }
    const fetchSubCategories = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService()
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const response = await apiService.getApiParamater('GET_SUB_CATEGORY', `?${params.toString()}`);
            const Code = await apiService.get('GET_SUB_CATEGORY_CODE');
            setCatCode(Code.data.subcatcode)
            setPagination(response.data.paginationData)
            setSubCategories(response?.data?.result.reverse());
        } catch (error) {
            console.error('Error fetching Sub categories:', error);
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true)
        debugger
        if (!formData.category || !formData?.subcatname) {
            if (!formData.category) { toast.warning("Enter Category Name") }
            if (!formData.subcatname) { toast.warning("Enter Sub Category Name") }
            setLoader(false)
            return;
        }
        try {
            let data = {
                recordid: 0,
                subcatcode: catCode,
                subcatname: formData?.subcatname,
                catCode: formData?.category?.value?.catCode,
                catName: formData?.category?.label,
                addUser: userName,
                inventoryAccountCode: formData?.InventoryAccounts?.label,
                grnAccountCode: formData?.grnAccount?.label,
                invoiceAccountCode: formData?.invoiceAccount?.label,
                cgsAccountCode: formData?.cgsAccount?.label,
                adjustmentAccountCode: formData?.adjustmentAccount?.label,
                wastageAccountCode: formData?.wastageAccount?.label,
                workInProgressAccountCode: formData?.wipAccount?.label,
                issueAccountCode: formData?.issueAccount?.label,
                isActive: true,
            }

            const response = await apiService.post('CREATE_UPDATE_SUB_CATEGORY', data);
            if (response?.data?.statusCode === 201 || response?.data?.statusCode === 200) {
                toast.success(response?.data?.message)
                fetchSubCategories()
                setFormData({
                    recordId: "",
                    subcatcode: "",
                    subcatname: "",
                    category: [],
                    catcode: "",
                    active: 0,
                    adduser: "",
                    InventoryAccounts: [],
                    grnAccount: [],
                    wipAccount: [],
                    invoiceAccount: [],
                    adjustmentAccount: [],
                    cgsAccount: [],
                    issueAccount: [],
                    wastageAccount: [],
                })
                window.location.reload()
                window.history.replaceState({}, '')
                setCatCode()
                setLoader(false)
            }
            else if (response?.data?.statusCode === 208) {
                toast.warning(response?.data?.message)
                setLoader(false)
            }

        } catch (error) {
            console.error('Error creating subcategory:', error);
            setLoader(false)
        }
    }
    const updateFormData = async () => {
        setLoader(true)
        try {
            const apiService = new ApiService();
            let body = {
                recordId: formData?.recordId,
                subcatcode: catCode,
                subcatname: formData?.subcatname,
                catCode: formData?.category?.value?.catCode,
                catName: formData?.category?.label,
                addUser: userName,
                inventoryAccountCode: formData?.InventoryAccounts?.label,
                grnAccountCode: formData?.grnAccount?.label,
                invoiceAccountCode: formData?.invoiceAccount?.label,
                cgsAccountCode: formData?.cgsAccount?.label,
                adjustmentAccountCode: formData?.adjustmentAccount?.label,
                wastageAccountCode: formData?.wastageAccount?.label,
                workInProgressAccountCode: formData?.wipAccount?.label,
                issueAccountCode: formData?.issueAccount?.label,
            }
            const res = await apiService.post(`CREATE_UPDATE_SUB_CATEGORY`, body);
            if (res?.data?.statusCode === 201) {
                toast.success(`${catCode} updated successfully`);
                setFormData({
                    recordId: "",
                    subcatcode: "",
                    subcatname: "",
                    category: [],
                    catcode: "",
                    active: 0,
                    adduser: "",
                    InventoryAccounts: [],
                    grnAccount: [],
                    wipAccount: [],
                    invoiceAccount: [],
                    adjustmentAccount: [],
                    cgsAccount: [],
                    issueAccount: [],
                    wastageAccount: [],
                })
                setCatCode()
                window.history.replaceState({}, '')
                window.location.reload()
                fetchCategories()
                fetchSubCategories()
                setLoader(false)
            } else {
                console.error('Failed to update form data');
                setLoader(false)
            }
        } catch (error) {
            console.error('Error updating form data:', error);
            setLoader(false)
        }
    }

    return (
        <div className='px-4 pt-3'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Sub Category</h5>
                <div className='d-flex justify-content-end gap-3'>
                    <div className=''>
                        {location.state ? (
                            <>{loader === true ?
                                <button className='mainbtn '><ClipLoader size={30} color='#fff' /></button> :
                                <>
                                    {canEditItemMaster && (
                                        <button className='mainbtn ' onClick={updateFormData}>Update</button>
                                    )}
                                </>
                            }
                            </>
                        ) : (
                            <>{loader === true ?
                                <button className='mainbtn '><ClipLoader size={30} color='#fff' /></button> :
                                <>
                                    {canCreateItemMaster === true ?
                                        <button className='mainbtn ' onClick={handleSubmit}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Save</div></button> :
                                        <button className='mainbtn disabled' style={{ cursor: "not-allowed" }}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Save</div>
                                        </button>
                                    }
                                </>
                            }
                            </>
                        )}
                    </div>
                    <button className='mainbtnRed'><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                </div>
            </div>
            <div className='py-lg-2'>
                <div className='brderbox p-lg-3'>
                    <div className="form-group row pb-3">
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="subcatcode" className="form-label  pt-2 start">Sub Category Code</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom01"
                                name="catCode"
                                disabled
                                value={catCode}
                                required
                            />
                        </div>
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="category" className="form-label  pt-2 start">Select Category *</label>
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                className="w-70 p-0"
                                onChange={(selectedOption) => handleCategoryChange(selectedOption)}
                                isDisabled={canCreateItemMaster === true ? false : true}
                                value={formData?.category}
                                options={categories?.map(category => ({ value: category, label: category.catName }))}
                            />
                        </div>
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="subcatname" className="form-label  pt-2 start">Sub Category Name *</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom01"
                                disabled={canCreateItemMaster === true ? false : true}
                                name="subcatname"
                                onChange={(e) => setFormData({ ...formData, subcatname: e.target.value })}
                                value={formData.subcatname}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group row justify-content-between pb-3">
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Inventory Account</label>
                            {!location?.state ?
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.InventoryAccounts}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleInventoryAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                /> :
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.InventoryAccounts}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleInventoryAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                />
                            }
                        </div>
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">GRN Account</label>
                            {!location?.state ?
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.grnAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleGRNAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                /> :
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.grnAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleGRNAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                />
                            }
                        </div>
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Adjustment Account</label>
                            {!location?.state ?
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.adjustmentAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleAdjustmentAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                /> :
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.adjustmentAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleAdjustmentAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                />
                            }
                        </div>
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Work In Progress</label>
                            {!location?.state ?
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.wipAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleWIPAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                /> :
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.wipAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleWIPAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                />
                            }
                        </div>
                    </div>
                    <div className="form-group row justify-content-between pb-3">
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Invoice Account</label>
                            {!location?.state ?
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.invoiceAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleInvoiceAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                /> :
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.invoiceAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleInvoiceAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                />
                            }
                        </div>
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">CGS Account</label>
                            {!location?.state ?
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.cgsAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleCGSAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                /> :
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.cgsAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleCGSAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                />
                            }
                        </div>
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Wastage Account</label>
                            {!location?.state ?
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.wastageAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleWastageAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                /> :
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.wastageAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleWastageAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                />
                            }
                        </div>
                        <div className="col-md-3 col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Issue Account</label>
                            {!location?.state ?
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.issueAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleIssueAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                /> :
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.issueAccount}
                                    isDisabled={canCreateItemMaster === true ? false : true}
                                    onChange={handleIssueAccountChange}
                                    options={accounts?.map((sup) => ({ value: sup, label: sup?.accountName }))}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-2'>
                <SubCategoryGridMaster rowData={subcategories} canEditItemMaster={canEditItemMaster}
                    fetchSubCategories={fetchSubCategories} pagination={pagination} setPageSize={setPageSize} />
            </div>
        </div>
    )
}

export default SubCategoryMaster;
