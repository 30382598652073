import React from "react";
import { Link, useLocation } from 'react-router-dom';
import './setting.css';
import './sideBox.css';
import { MdNotificationsNone } from "react-icons/md";
import { GrCurrency } from "react-icons/gr";
import { LuUser2 } from "react-icons/lu";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";

const Sidebox = () => {
    const location = useLocation();

    const isActive = (route) => location.pathname === route;

    return (
        <ProSidebar className="set-sidebar">
            <Menu iconShape="square">
                <MenuItem className={`dashitems sidebar-text ${isActive("/admin/Setting/Profile") ? "sideactive" : ""}`}>
                    <Link to="/admin/Setting/Profile">
                        <LuUser2 /> Profile
                    </Link>
                </MenuItem>

                <MenuItem className={`dashitems sidebar-text ${isActive("/admin/Setting/Notification") ? "sideactive" : ""}`}>
                    <Link to="/admin/Setting/Notification">
                        <MdNotificationsNone /> Notifications
                    </Link>
                </MenuItem>

                <MenuItem className={`dashitems sidebar-text ${isActive("/admin/Setting/Currency") ? "sideactive" : ""}`}>
                    <Link to="/admin/Setting/Currency">
                        <GrCurrency /> Currency
                    </Link>
                </MenuItem>
            </Menu>
        </ProSidebar>
    );
}

export default Sidebox;
