import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useState, Component } from 'react';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ApiService from '../../../../services/apiService';

const TableGrid = ({ data, GetTable }) => {


    const [show, setShow] = useState(false);
    const [id, setId] = useState();

    const handleClose = () => setShow(false);

    const mapTablePosition = (Position) => {
        switch (Position) {
            case 1:
                return "Center";
            case 2:
                return "Corner";
            default:
                return "Unknown Table Position";
        }
    }
    const mapTableShape = (Shape) => {
        switch (Shape) {
            case 1:
                return "Rectangle";
            case 2:
                return "Circle";
            case 3:
                return "Square";
            case 4:
                return "Oval";
            case 5:
                return "Hexagon";
            case 6:
                return "Triangle";
            case 7:
                return "Octagon";
            case 8:
                return "Pentagon";
            case 9:
                return "L Shaped";
            case 10:
                return "Kidney Shaped";
            case 11:
                return "CustomShape";
            default:
                return "Unknown Table Shape";
        }
    }
    const mapTableArea = (Area) => {
        switch (Area) {
            case 1:
                return "First Floor";
            case 2:
                return "Second Floor";
            case 3:
                return "Third Floor";
            case 4:
                return "Fourth Floor";
            case 5:
                return "Fifth Floor";
            case 6:
                return "Sixth Floor";
            case 7:
                return "Seventh Floor";
            case 8:
                return "Eighth Floor";
            case 9:
                return "Ninth Floor";
            case 10:
                return "Tenth Floor";
            case 11:
                return "Eleventh Floor";
            case 12:
                return "Twelfth Floor";
            case 13:
                return "Ground Floor";
            case 14:
                return "Patio";
            case 15:
                return "Terrace";
            case 16:
                return "Private Room";
            case 17:
                return "Custom Area";
            default:
                return "Unknown Table Area";
        }
    }
    const mapTableCapacity = (Capacity) => {
        switch (Capacity) {
            case 1:
                return "Single Seater";
            case 2:
                return "Double Seater";
            case 3:
                return "Triple Seater";
            case 4:
                return "Four Seater";
            case 5:
                return "Five Seater";
            case 6:
                return "Six Seater";
            case 7:
                return "Seven Seater";
            case 8:
                return "Eight Seater";
            case 9:
                return "Nine Seater";
            case 10:
                return "Ten Seater";
            case 11:
                return "Eleven Seater";
            case 12:
                return "Twelve Seater";
            case 13:
                return "Custom Seater";
            default:
                return "Unknown Table Capacity";
        }
    }
    const handleShow = (field) => {
        setId(field)
        setShow(true);
    }
    const handleDelete = async (id) => {

        const apiService = new ApiService();
        let body = {
            id: parseInt(id)
        }
        let res = await apiService.delete("DELETE_TABLE", { data: body });
        if (res?.data?.statusCode === 204) {
            setShow(false)
            toast.success("Successfully Deleted");
            GetTable()
        }
        else {
            toast.warning('Waiting for menu update');
        }
    }
    const [colDefs, setColDefs] = useState([
        { headerName: 'Table name', field: "name", filter: true, floatingFilter: true, minWidth: 200 },
        {
            headerName: 'Capacity', field: "seatCapacity", filter: true, floatingFilter: true, minWidth: 200,
            cellRenderer: (params) => mapTableCapacity(params.value),
            filterParams: {
                textCustomComparator: (filter, value, filterText) => {
                    const displayedText = mapTableCapacity(value);
                    return displayedText.toLowerCase().includes(filterText.toLowerCase());
                },
            },
        },
        {
            headerName: 'Position', field: "position", minWidth: 200,
            filter: true,
            floatingFilter: true,
            cellRenderer: (params) => mapTablePosition(params.value),
            filterParams: {
                textCustomComparator: (filter, value, filterText) => {
                    const displayedText = mapTablePosition(value);
                    return displayedText.toLowerCase().includes(filterText.toLowerCase());
                },
            },
        },
        {
            headerName: 'Table Shape', field: "shape", filter: true, floatingFilter: true, minWidth: 200,
            cellRenderer: (params) => mapTableShape(params.value),
            filterParams: {
                textCustomComparator: (filter, value, filterText) => {
                    const displayedText = mapTableShape(value);
                    return displayedText.toLowerCase().includes(filterText.toLowerCase());
                },
            },
        },
        {
            headerName: 'Area', field: "area", filter: true, floatingFilter: true, minWidth: 224,
            cellRenderer: (params) => mapTableArea(params.value),
            filterParams: {
                textCustomComparator: (filter, value, filterText) => {
                    const displayedText = mapTableArea(value);
                    return displayedText.toLowerCase().includes(filterText.toLowerCase());
                },
            },
        },
        {
            headerName: 'Action',
            field: 'id',
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                clicked: function (field) {
                    handleShow(field)
                },
            },
        },
    ])
    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    }
    return (
        <>
        <div className='brdor  mt-3' style={{ }}>
        
            <div className="ag-theme-alpine" style={{ height: 400, backgroundColor: "#6E5199" }}>
                <AgGridReact
                    rowData={data}
                    columnDefs={colDefs}
                    enableFilter={true}
                    floatingFilter={true}
                    rowSelection="multiple"
                    frameworkComponents={frameworkComponents}
                    pagination={true}
                    suppressRowClickSelection={true}
                />
            </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you Sure you want to delete</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => handleDelete(id)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }

    render() {
        return (
            <button onClick={this.btnClickedHandler} className="delbtn">Delete</button>
        )
    }
}
export default TableGrid;
