import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React, { useEffect, useState } from 'react';
import ApiService from '../../../../services/apiService';
import { useLocation, useNavigate } from 'react-router-dom';
import { TfiControlBackward } from "react-icons/tfi";
import { RiH5 } from 'react-icons/ri';

const CurrencyDetail = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [CashDenominations, setType0Denominations] = useState([]);
    const [CoinDenominations, setType1Denominations] = useState([]);
    const [FillsDenominations, setType2Denominations] = useState([]);


    useEffect(() => {
        if (state && state.currencyDenomination) {
            const type0 = state.currencyDenomination.filter(denomination => denomination.type === 0);
            const type1 = state.currencyDenomination.filter(denomination => denomination.type === 1);
            const type2 = state.currencyDenomination.filter(denomination => denomination.type === 2);

            setType0Denominations(type0);
            setType1Denominations(type1);
            setType2Denominations(type2);

        }
    }, [state]);

    const separateAndCountValues = (denominations) => {
        const denominationCounts = denominations.reduce((acc, curr) => {
            if (acc[curr.value]) {
                acc[curr.value]++;
            } else {
                acc[curr.value] = 1;
            }
            return acc;
        }, {});

        return Object.entries(denominationCounts).map(([value, count]) => ({ value: parseInt(value), count }));
    };

    const handleBackClick = () => {
        navigate(-1);
    }

    return (
        <div className="p-5">
            <div className='BackBtn mb-4' onClick={() => handleBackClick()}>
                <TfiControlBackward /> Back
            </div>
            <div className="row">
                {CashDenominations?.length === 0 ?
                    " " :
                    <div className='col-lg-4 col-sm-3'>
                        <h5>
                            Coin Denominations
                        </h5>
                        <div className='borderMain mt-3 p-4'>
                            {separateAndCountValues(CashDenominations)?.map((item, index) => (
                                <div className='d-flex justify-content-between p-2 mb-3 innerBorder' key={index}>
                                    <div>{item.value} </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }
                {CoinDenominations?.length === 0 ? " " :
                    <div className='col-lg-4 col-sm-3'>
                        <h5>
                            Note Denominations
                        </h5>
                        <div className='borderMain mt-3 p-4'>
                            {separateAndCountValues(CoinDenominations)?.map((item, index) => (
                                <div className='d-flex justify-content-between p-2 mb-3 innerBorder' key={index}>
                                    <div>{item.value} </div>
                                </div>
                            ))}
                        </div>
                    </div>

                }
                {FillsDenominations?.length === 0 ? " " :
                    <div className='col-lg-4 col-sm-3'>
                        <h5>
                            Fills Denominations
                        </h5>
                        <div className='borderMain mt-3 p-4'>
                            {separateAndCountValues(FillsDenominations)?.map((item, index) => (
                                <div className='d-flex justify-content-between p-2 mb-3 innerBorder' key={index}>
                                    <div>{item.value} </div>
                                </div>
                            ))}
                        </div>
                    </div>

                }
            </div>
        </div>
    );
};

export default CurrencyDetail;
