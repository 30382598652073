import React, { useState, useRef, useEffect } from 'react';
import UploadIcon from "../../../../assets/images/svgIcons/UploadIcon.svg";
import "./imageUpload.css"
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const ImageUpload = ({ onImagesSelect, onImageRemove, formData, canCreateMaster=true }) => {
  const [images, setImages] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
debugger
  useEffect(() => {
    if (formData?.images) {
      setImages(formData?.images.map(img => ({
        ...img,
        url: img.url || IMAGE_URL + img.name
      })));
    }
  }, [formData]);

  const selectFiles = () => {
    fileInputRef.current.click();
  };

  const deleteImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
    onImageRemove(index);
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const onDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const onDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    handleFiles(files);
  };

  const onFileSelect = (event) => {
    const files = event.target.files;
    handleFiles(files);
  };

  const handleFiles = (files) => {
    const selectedImages = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split('/')[0] !== 'image') continue;
      const image = {
        name: files[i].name,
        url: URL.createObjectURL(files[i]),
        file: files[i],
      };
      selectedImages.push(image);
    }
    setImages(selectedImages);  // Replacing with new images
    onImagesSelect(selectedImages);
  };

  return (
    <div className='imgUploadBox'>
      <div className="drag-area p-4 justify-content-center"
        disabled={canCreateMaster === true ? false : true}
        onClick={selectFiles}
        style={{ cursor: canCreateMaster === true ? "pointer" : "not-allowed" }}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}>
        <div className=' justify-content-center d-flex'>
          <img src={UploadIcon} alt="Upload" style={{height:"50px"}}/>
        </div>
        {isDragging ? (
          <div className="select justify-content-center d-flex">Drag & Drop </div>
        ) : (
          <span className='justify-content-center d-flex'>Drag & Drop</span>
        )}
        <input
          name="file"
          type="file"
          className="file"
          multiple
          disabled={canCreateMaster === true ? false : true}
          ref={fileInputRef}
          onChange={onFileSelect}
        />
      </div>
      <div className="container">
        {images.map((image, index) => (
          <div key={index} className="d-flex">
            <div className='image'>
              <img src={image.url} alt={image.name} />
            </div>
            <div>
              {canCreateMaster === true ?
                <button className='btn btn-primary' onClick={() => deleteImage(index)}>Remove</button> :
                <button className='btn btn-primary' style={{ cursor: "not-allowed" }}>Remove</button>
              }
            </div>
          </div>
        ))}
        {formData?.imageUrl && (
          <div className="d-flex">
            <div className='image'>
              <img
                src={IMAGE_URL+`${formData?.imageUrl}`} alt="Existing" />
            </div>
            <div>
              {canCreateMaster === true ?
                <button className='btn btn-primary' onClick={() => onImageRemove(null)}>Remove</button> :
                <button className='btn btn-primary' style={{ cursor: "not-allowed" }}>Remove</button>
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;