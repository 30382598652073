import { React, useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import ApiService from '../../../../services/apiService';
import ImageUpload from '../../menus/addItems/imagUpload';
import { useSelector } from 'react-redux';
import { getUserId } from '../../../../../Redux/selecters';
import Select from 'react-select';
import RestaurentMasterCard from './restaurentCard';

const AreaMaster = () => {
    const userId = useSelector(getUserId);
    const [restaurantList, setRestaurantList] = useState([]);
    const [restaurantArea, setRestaurantArea] = useState([]);

    useEffect(() => {
        fetchKitchen()
        fetchKitchenArea()
    }, []);
    const [formData, setFormData] = useState({
        restaurant: '',
        area: "",
        images: [],
        imageUrl: ""
    });
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleRestaurantChange = (selectedOption) => {
        setFormData({ ...formData, restaurant: selectedOption || selectedOption });
    };
    const CreateCategory = async () => {

        try {
            if (!formData?.images && !formData?.imageUrl) {
                toast.error("Upload Image");
            } else if (formData?.images) {
                try {
                    const apiService = new ApiService();
                    const formdata = new FormData();
                    for (const image of formData?.images) {
                        if (image.file) {
                            formdata.append('Images', image?.file);
                        }
                    }
                    const response = await apiService.postMultiPart("UPLOAD_IMG", formdata);

                    if (response?.data?.statusCode === 201) {
                        setFormData({ ...formData, imageUrl: response?.data?.result });

                        handleSubmit(response?.data?.result)
                    }
                    else toast.error(response?.data?.msg)
                } catch (error) {
                    toast.error("Something went wrong with the image. Please try again!");
                }
            }
        } catch (error) {
            console.error('Error fetching area :', error);
        }
    };
    const fetchKitchen = async () => {
        try {
            const apiService = new ApiService();
            const response = await apiService.get('GET_RESTAURANT');
            const updatedKitchen = response?.data?.result;
            setRestaurantList(updatedKitchen);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };
    const fetchKitchenArea = async () => {
        try {
            const apiService = new ApiService();
            const response = await apiService.get('GET_RESTAURANT_AREA');
            const updatedKitchen = response?.data?.result;
            setRestaurantArea(updatedKitchen);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };
    const handleSubmit = async (imgurl) => {
        try {
            if (imgurl) {
                let body = {
                    resturentId: formData?.restaurant?.value?.id,
                    loggedInUserId: userId,
                    name: formData?.area,
                    imageUrl: imgurl,
                    active: true,
                }
                const apiService = new ApiService();
                
                let res = await apiService.post('CREATE_RESTAURANT_AREA', body);
                if (res?.data?.statusCode == 201) {
                    toast?.success("area Created Successfully")
                    setFormData({
                        restaurant: '',
                        area: "",
                        images: [],
                        imageUrl: ""
                    });
                    fetchKitchen()
                    fetchKitchenArea()
                }
            }

        } catch (error) {
            console.error('Error fetching area :', error);
        }
    };
    const handleImagesSelect = (images) => {
        setFormData({ ...formData, images: images });
    }
    const handleImageRemove = (index) => {
        const updatedImages = [...formData.images];
        updatedImages.splice(index, 1);
        setFormData({ ...formData, images: updatedImages });
    }
    return (
        <div className='p-5 container'>
            <div className='catlist'>

            <div className=' pt-3 '>
                <ImageUpload onImagesSelect={handleImagesSelect} onImageRemove={handleImageRemove} formData={formData} />
            </div>
            <div className=' py-3 row '>
                <div className="col-5">
                    <label htmlFor="validationCustom01" className="form-label">Area Name </label>
                    <input
                        type="text"
                        className="p-2 form-control"
                        id="validationCustom01"
                        name="area"
                        value={formData.area}
                        onChange={handleInputChange}
                        required
                    />
                    <div className="valid-feedback">
                        Looks good!
                    </div>
                </div>
                <div className="col-5">
                    <label htmlFor="validationCustom04" className="form-label texthead">Restaurant</label>
                    <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                        defaultValue={formData.restaurant}
                        onChange={handleRestaurantChange}
                        options={restaurantList?.map((restaurant) => ({ value: restaurant, label: restaurant.name }))}
                    />
                    <div className="valid-feedback">
                        Looks good!
                    </div>
                </div>
                <div className='col-2 d-flex align-self-end col6  pt-3'>
                    <button className='addItem-btn end' onClick={() => CreateCategory()}>Save</button>
                </div>
            </div>
            </div>

            <div className='catlist mt-5'>
                <div className="row">
                    {restaurantArea?.map((item) => (
                        <RestaurentMasterCard
                            key={item.id}
                            val={item}
                            fetchKitchenArea={fetchKitchenArea}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AreaMaster