import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import ApiServicePOS from '../../../POS/services/apiService';

const AddUser = ({ onUserId }) => {
    const { state } = useLocation();
    const apiService = new ApiService();
    const apiServicePos = new ApiServicePOS();
    let navigate = useNavigate();
    const location = useLocation();
    const [Roles, setRoles] = useState([]);
    const [department, setDepartment] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [branches, setBranches] = useState([]);
    const [suppliertype, setSuppliertype] = useState([]);
    const [Active, setActive] = useState(false);
    useEffect(() => {
        getRoles();
        if (state && state.employee_Name) {
            const [first, ...rest] = state.employee_Name.split(' ');
            setFormData(prevState => ({
                ...prevState,
                firstName: first,
                lastName: rest.join(' '),
            }));
        }
    }, [state]);
    const getRoles = async () => {
        try {
            const res = await apiServicePos.get('GET_ROLE');
            const CompanyRes = await apiService.get('GET_COMPANY');
            const DepRes = await apiService.get('GET_DEPARTMENT_MASTER');
            setRoles(res.data.result);
            setCompanies(CompanyRes.data.result);
            setDepartment(DepRes.data.result);
        } catch (error) {
            console.error('Error fetching User Roles:', error);
        }
    };
    const [formData, setFormData] = useState({
        id: state?.id || '',
        fullName: "",
        Phoneno: "",
        MobileNo: "",
        NameBI: "",
        department: [],
        Branch: [],
        userRole: [],
        Company: [],
        Email: "",
        Name: "",
        Designation: "",
        password: "",
        EmployeeID: "",
        userName: ""
    });
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const createUser = async () => {
        try {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{10,}$/;
            if (!formData?.userName) {
                toast.error("Please Enter UserName");
            } else if (!formData?.Email || !emailRegex.test(formData.Email)) {
                toast.error("Please Enter a valid Email");
            } else if (!formData?.password || !passwordRegex.test(formData.password)) {
                toast.error("Password must be at least 10 characters long, have at least one non-alphanumeric character, one digit ('0'-'9'), and one uppercase letter ('A'-'Z').");
            } else if (!formData?.userRole.label) {
                toast.error("Please Select Role");
            } else if (!formData?.fullName) {
                toast.error("Please Enter FullName");
            } else if (!formData?.Phoneno) {
                toast.error("Please Enter Phone No");
            } else if (!formData?.Company.label) {
                toast.error("Please Select Company");
            } else if (!formData?.Branch.label) {
                toast.error("Please Select Branch");
            } else if (!formData?.department.label) {
                toast.error("Please Select Department");
            } else {
                const userData = {
                    userName: formData.userName,
                    email: formData.Email,
                    password: formData.password,
                    roles: formData.userRole.label,
                    phone: formData.Phoneno,
                    user_Name: formData.userName,
                    user_Fullname: formData.fullName,
                    user_Fullname_BI: formData.NameBI,
                    phoneNo: formData.Phoneno,
                    mobileNo: formData.MobileNo,
                    department: formData.department.value.description_BI,
                    role: formData.userRole.label,
                    // company: formData.Company.label,
                    company: "PI",
                    branch: formData.Branch.label,
                    status: Active === true ? "active" : "Inactive",
                };
                debugger
                const response = await apiServicePos.post("USER_REGISTER", userData);
                if (response?.data?.statusCode === 201) {
                    toast.success("User Created successfully");
                    setFormData({
                        id: state?.id || '',
                        fullName: "",
                        Phoneno: "",
                        MobileNo: "",
                        NameBI: "",
                        department: [],
                        Branch: [],
                        userRole: [],
                        Company: [],
                        Email: "",
                        Name: "",
                        Designation: "",
                        password: "",
                        EmployeeID: "",
                        userName: ""
                    });
                    navigate("/Inventory/UserMaster/rights");
                }
            }
        } catch (error) {
            console.error("Error Creating User:", error);
            toast.error(error.response.data.message);
            toast.error(error?.response?.data?.errors);
        }
    };
    const handleActiveSelection = (e) => {
        setActive(e);
    };
    const handleDepChange = (selectedOption) => {
        setFormData({ ...formData, department: selectedOption });
    };
    const handleBranchChange = (selectedOption) => {
        setFormData({ ...formData, Branch: selectedOption });
    };
    const handleRoleChange = (selectedOption) => {
        setFormData({ ...formData, userRole: selectedOption });
    };
    const handleCompanyChange = (selectedOption) => {
        getBranches(selectedOption)
        setFormData({ ...formData, Company: selectedOption });
    };
    const getBranches = async (selectedOption) => {
        try {
            debugger
            let param = `?CompanyId=${selectedOption.value.companyId}`
            const CompanyRes = await apiService.getApiParamater('GET_BRANCH', param);
            setBranches(CompanyRes.data.result);
        } catch (error) {
            console.error('Error fetching Branch:', error);
        }
    };
    return (
        <div className='container'>
            <div className=''>
                <div className=''>
                    <h5>User Detail</h5>
                    <div className='brderbox p-lg-5'>
                        <div className="form-group row justify-content-between ">
                            <div className="col">
                                <label htmlFor="validationCustom01" className="form-label">Full Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom01"
                                    name="fullName"
                                    value={formData?.fullName}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="validationCustom2" className="form-label">Name BI</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom2"
                                    name="NameBI"
                                    value={formData?.NameBI}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="validationCustom03" className="form-label">Mobile No</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-control"
                                    id="validationCustom03"
                                    name="MobileNo"
                                    value={formData?.MobileNo}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="validationCustom03" className="form-label">Phone no </label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-control"
                                    id="validationCustom03"
                                    name="Phoneno"
                                    value={formData?.Phoneno}
                                    onChange={handleInputChange}
                                />
                            </div>

                        </div>
                        <div className="form-group row justify-content-between my-4">
                            <div className="col">
                                <label htmlFor="validationCustom01" className="form-label">Department</label>
                                <Select
                                    value={formData?.department}
                                    onChange={(selectedOption) => handleDepChange(selectedOption)}
                                    options={department?.map((dep) => ({ value: dep, label: dep?.description }))}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="validationCustom01" className="form-label">Company</label>
                                <Select
                                    value={formData?.Company}
                                    onChange={(selectedOption) => handleCompanyChange(selectedOption)}
                                    options={companies?.map((comp) => ({ value: comp, label: comp?.companyName }))}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="validationCustom01" className="form-label">Branch</label>
                                <Select
                                    value={formData?.Branch}
                                    onChange={(selectedOption) => handleBranchChange(selectedOption)}
                                    options={branches?.map((sup) => ({ value: sup, label: sup?.branchName }))}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="validationCustom01" className="form-label">User Role</label>
                                <Select
                                    value={formData?.userRole}
                                    onChange={(selectedOption) => handleRoleChange(selectedOption)}
                                    options={Roles?.map((role) => ({ value: role, label: role?.name }))}
                                />
                            </div>
                            {/* <div className="col">
                                <label htmlFor="validationCustom2" className="form-label">Designation</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom2"
                                    name="Designation"
                                    value={formData?.Designation}
                                    onChange={handleInputChange}
                                />
                            </div> */}

                            {/* <div className="col">
                                <label htmlFor="validationCustom03" className="form-label">Employee ID</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom03"
                                    name="EmployeeID"
                                    value={formData?.EmployeeID}
                                    onChange={handleInputChange}
                                />
                            </div> */}
                        </div>
                        <div className="form-group row justify-content-between ">
                            <div className="col">
                                <label htmlFor="validationCustom01" className="form-label">User Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom01"
                                    name="userName"
                                    value={formData?.userName}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="validationCustom03" className="form-label">Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="validationCustom03"
                                    name="Email"
                                    value={formData?.Email}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="validationCustom01" className="form-label">Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="validationCustom01"
                                    name="password"
                                    value={formData?.password}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='col d-flex justify-content-around mt-2'>
                                <div className='d-flex gap-3 pt-2 center'>
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="Active" style={{ width: "26px", height: "26px" }}
                                        name="Active"
                                        checked={Active}
                                        onChange={(e) => handleActiveSelection(e.target.checked)}
                                    />
                                    <label className="form-check-label checkItem center" htmlFor="Active">
                                        Active
                                    </label>
                                </div>
                                <div className='mt-4'>
                                    <button className='mainbtn ' onClick={createUser} >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default AddUser