import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useState, Component } from 'react';
import ApiService from '../../../../../services/apiService';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ItemGrid = ({ data, addson, GetAddsOn, GetModifier }) => {

    const [show, setShow] = useState(false);
    const [id, setId] = useState();
    const [selectedActive, setSelectedActive] = useState();


    const handleClose = () => setShow(false);
    const handleShow = (field) => {
        setId(field)
        setShow(true);
    }

    const handleUpdate = async (id, newActiveStatus) => {

        const apiService = new ApiService();
        if (addson) {
            
            let body = {
                active: newActiveStatus,
                id: parseInt(id?.id)
            };
            let res = await apiService.put("UPDATE_ADDONS", body);
            if (res?.data?.statusCode === 200) {
                setShow(false);
                toast.success("Successfully Updated");
                GetAddsOn();
            } else {
                toast.warning('Waiting for update');
            }
        }
        else if (data) {
            let body = {
                active: newActiveStatus,
                id: parseInt(id?.id)
            }
            let res = await apiService.put("UPDATE_MODIFIER", body);
            if (res?.data?.statusCode === 200) {
                setShow(false)
                GetModifier()
                toast.success("Successfully Updated");
            }
            else {
                toast.warning('Waiting for Response');
            }
        }

    };
    const [colDefs, setColDefs] = useState([
        { field: "name", filter: true, floatingFilter: true, minWidth: 518 },
        {
            field: "price",
            filter: true,
            floatingFilter: true,
            valueFormatter: (params) => params?.value?.toLocaleString(),
            minWidth: 500
        },
        {
            headerName: 'Action',
            field: 'id', minWidth: 267,
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                clicked: function (field) {
                    handleUpdate(field)
                },
            },

        },
    ]);

    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };
    const handleSwitchChange = async (id, newActiveStatus) => {
        try {
            
            await handleUpdate(id, newActiveStatus);
        } catch (error) {
            console.error('Error updating AddOn:', error);
        }
    };
    return (
        <>
            {/* <div className="ag-theme-alpine" style={{ height: 400, backgroundColor: "#6E5199" }}>
                <AgGridReact 
                    rowData={data || addson}
                    columnDefs={colDefs}
                    enableFilter={true}
                    floatingFilter={true}
                    rowSelection="multiple"
                    frameworkComponents={frameworkComponents}
                    pagination={true}
                    suppressRowClickSelection={true}
                />
            </div> */}
            <div className='brderbx py-3 px-5'>
                <div className='table-responsive' style={{ height: "470px" }}>
                    <table className="table" >
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Price</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {addson?.map((val) => (
                                <tr key={val?.id}>
                                    <td>{val?.name}</td>
                                    <td>{val.price}</td>
                                    <td>
                                        <div className="d-flex text-center">
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id={`flexSwitchCheck${val.id}`}
                                                    checked={val?.active}
                                                    onChange={(e) => handleSwitchChange(val, e.target.checked)}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {data?.map((val) => (
                                <tr key={val?.id}>
                                    <td>{val?.name}</td>
                                    <td>{val.price}</td>
                                    <td>
                                        <div className="d-flex text-center">
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id={`flexSwitchCheck${val.id}`}
                                                    checked={val?.active}
                                                    onChange={(e) => handleSwitchChange(val, e.target.checked)}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;

        clicked(value);
    }

    render() {
        return (
            <div className="form-check form-switch">
                <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id={`flexSwitchCheck`}
                    checked={this.props}
                    // onChange={(e) => btnClickedHandler(val, e.target.checked)}
                    onChange={this.btnClickedHandler}
                />
            </div>
            // <button onClick={this.btnClickedHandler} className="delbtn">Delete</button>
        )
    }
}
export default ItemGrid;
