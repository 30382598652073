import { React, useState, useEffect } from 'react'
import AdminHeader from '../header/adminHeader'
import { ReactComponent as Add } from '../../../assets/images/svgIcons/Add_round_white.svg';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import EmployeeList from './employeeList';
import { useDispatch } from 'react-redux';
import {setEmployeeData} from '../../../../Redux/actions/employeActions'
import { shiftEnums } from '../../../../Enums/config';

const Employee = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState(null);
    const [SelecteData, setSelecteData] = useState(null);
    const AddCostumer = () => {
        navigate('/admin/Addemployee');
    }
    const selectedData = (rowData) => {
        setSelecteData(rowData)
    }

    const AddUserRole = () => {
        dispatch(setEmployeeData(SelecteData));
        navigate(`/admin/master/userMaster/create`, { state: SelecteData });
    }
    
    return (
        <div className='container-fluid '>
            <div className='p-lg-5'>
                <div className='bghead d-flex justify-content-between px-lg-5' >
                    <div className='col-3'>
                        <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={shiftEnums}
                        />
                    </div>
                    <div className="d-flex justify-content-end gap-3">
                        <div className='refbtn' onClick={AddUserRole}>
                            <Add /> Add user Role
                        </div>
                        <div className='refbtn' onClick={AddCostumer}>
                            <Add /> Add Employee
                        </div>
                    </div>
                </div>
                <div className=''>
                    <EmployeeList selectedRow={selectedData} />
                </div>

            </div>
        </div>
    )
}
export default Employee