import { React, useState, useEffect } from 'react'
import ImageUpload from '../menus/addItems/imagUpload';
import "./master.css"
import ApiService from '../../../services/apiService';
import userManagement from '../../../services/userManagement';
import { toast } from 'react-toastify';
import CategoryMasterCard from './categoryCard/categorycard';
import Select from 'react-select';

const AddCategory = () => {
    const [categories, setCategories] = useState([]);
    const [glAccount, setGlAccount] = useState([]);

    useEffect(() => {
        GetCategory()
        GetAccount()
    }, []);
    const [formData, setFormData] = useState({
        category: "",
        images: [],
        imageUrl: "",
        glAccountCode: "",
        glAccountName: "",
    });
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleGLAccounts = (selectedOption) => {
        debugger
        setFormData({
            ...formData, glAccountCode: selectedOption?.value?.accountCode,
            glAccountName: selectedOption?.value?.accountName
        });

    }
    const CreateCategory = async () => {

        try {
            if (!formData?.images && !formData?.imageUrl) {
                toast.error("Upload Image");
            } else if (formData?.images) {
                try {
                    const apiService = new ApiService();
                    const formdata = new FormData();

                    // Loop over the images and append them to formdata (log each step)
                    for (const image of formData?.images) {
                        if (image.file) {
                            console.log("Appending image:", image.file); // Log the file
                            formdata.append('Images', image.file);
                        }
                    }

                    // Optional: Inspect the FormData content before sending it
                    for (let [key, value] of formdata.entries()) {
                        console.log(`${key}:`, value);
                    }

                    // Make the API call
                    const response = await apiService.postMultiPart("UPLOAD_IMG", formdata);

                    // Handle the response
                    if (response?.data?.statusCode === 201) {
                        // Update formData and call handleSubmit with the new image URL
                        setFormData({ ...formData, imageUrl: response?.data?.result });
                        handleSubmit(response?.data?.result);
                    } else {
                        toast.error(response?.data?.msg || "An error occurred during image upload.");
                    }
                } catch (error) {
                    // Handle specific errors during image upload process
                    toast.error("Something went wrong with the image. Please try again!");
                }
            }
        } catch (error) {
            // Catch any other errors that occur
            console.error('Error fetching category:', error);
            toast.error("An unexpected error occurred. Please try again.");
        }



    };

    const handleSubmit = async (imgurl) => {
        try {
            let UserId = userManagement.getUserId();
            if (imgurl) {
                let body = {
                    name: formData?.category,
                    imageUrl: imgurl,
                    loggedInUserId: UserId,
                    active: true,
                    glAccountName: formData?.glAccountName,
                    glAccountCode: formData?.glAccountCode,
                }
                debugger
                if (formData?.glAccountName && formData?.glAccountCode) {

                    const apiService = new ApiService();
                    let res = await apiService.post('CREATE_CATEGORY', body);
                    if (res?.data?.statusCode == 201) {
                        toast?.success("Category Created Successfully")
                        setFormData({
                            category: "",
                            images: [],
                            imageUrl: '',
                            glAccountCode: "",
                            glAccountName: "",
                        });
                        GetCategory();
                    }
                }
                else {
                    toast.error("Please select GL Account");
                }

            }

        } catch (error) {
            console.error('Error fetching category :', error);
        }
    };
    const handleImagesSelect = (images) => {
        setFormData({ ...formData, images: images });
    }
    const handleImageRemove = (index) => {
        const updatedImages = [...formData.images];
        updatedImages.splice(index, 1);
        setFormData({ ...formData, images: updatedImages });
    }
    const GetCategory = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get('GET_CATEGORY');
            // const filteredcategory = res?.data?.result?.filter(item => item?.active === true);
            setCategories(res?.data?.result);
        } catch (error) {
            console.error('Error fetching category data:', error);
        }
    };
    const GetAccount = async () => {
        try {
            const apiService = new ApiService();
            let param = `?AccountType=Cash`
            let res = await apiService.get('GET_GL_ACCOUNT');
            setGlAccount(res?.data?.result);
        } catch (error) {
            console.error('Error fetching GL ACCOUNT data:', error);
        }
    };
    return (
        <div className='p-5 container'>
            <div className='catlist'>
                <div className='py-3 row '>
                    <div className="col">
                        <label htmlFor="validationCustom01" className="form-label">Item Category Name </label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom01"
                            name="category"
                            value={formData.category}
                            placeholder='Enter Item Category name'
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div className="col">
                        <label htmlFor="validationCustom04" className="form-label">
                            Select Gl Account *
                        </label>
                        <Select styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                color: state.isFocused ? '#000 ' : '#000',
                                ':hover': {
                                    backgroundColor: '#F5F5F5',
                                    color: '#000 ',
                                },
                            }),
                        }}
                            defaultValue={formData.glAccountName}
                            onChange={handleGLAccounts}
                            options={glAccount?.map((gl) => ({ value: gl, label: gl.accountName }))}
                        />
                        <div className="valid-feedback">Looks good!</div>
                    </div>
                </div>
                <div className='row center py-3 '>
                    <div className='col-10 col6'><ImageUpload onImagesSelect={handleImagesSelect} onImageRemove={handleImageRemove} formData={formData} /></div>
                    <div className='col-2 d-flex align-self-end col6  pt-3'><button className='addItem-btn' onClick={() => CreateCategory()}>Save</button></div>
                </div>
            </div>
            <div className='catlist mt-5'>
                <div className="row">
                    {categories?.map((item) => (
                        <CategoryMasterCard
                            key={item.id}
                            val={item}
                            GetCategory={GetCategory}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AddCategory