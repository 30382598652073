import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import moment from 'moment';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import { hasPermission } from "../../services/roleService"

const GlAccount = ({ onUserId }) => {
    const { state } = useLocation();
    const apiService = new ApiService();
    const correctOTP = "123456";
    let navigate = useNavigate();
    const location = useLocation();
    const [otp, setOTP] = useState(['', '', '', '']); // State to hold OTP values
    const refs = [useRef(), useRef(), useRef(), useRef()];
    const [searchTerm, setSearchTerm] = useState();
    const [Roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState();
    const [Credit, setCredit] = useState(false);
    const [glAccount, setGlAccount] = useState(true);
    const [Retention, setRetention] = useState(false);
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        getRoles();
        if (state && state.employee_Name) {
            const [first, ...rest] = state.employee_Name.split(' ');
            setFormData(prevState => ({
                ...prevState,
                firstName: first,
                lastName: rest.join(' '),
            }));
        }
    }, [state]);

    useEffect(() => {
        // Set form data with state data if available
        setFormData(prevState => ({
            ...prevState,
            userName: state?.employee_Name || "",
            email: state?.email || "",
            role: state?.roleId || "",
            branch: state?.branchId || "",
            dateOfBirth: state?.exp2 ? moment(state.exp2).format('YYYY-MM-DD') : "",
            gender: state?.exp3 || "",
        }));
    }, [state]);

    useEffect(() => {
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
    }, []);

    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    const canEditMaster = checkPermission('Supplier', 'edit');
    const canCreateMaster = checkPermission('Supplier', 'create');

    const getRoles = async () => {
        try {
            const apiService = new ApiService();
            const parameters = `?Name=${searchTerm}`;
            const res = await apiService.getApiParamater('GET_ROLE', parameters);
            setRoles(res.data.result);
        } catch (error) {
            console.error('Error fetching User Roles:', error);
        }
    };

    const [formData, setFormData] = useState({
        id: state?.id || '',
        SubGroup: "",
        ChequeName: "",
        Phoneno: "",
        MobileNo: "",
        SupplierType: "",
        VATRegNo: "",
        Fax: "",
        Email: "",
        Name: "",
        Address: "",
        Web: "",
        Discount: "",
        Address1: "",
        Address2: "",
        CreditDays: "",
        CreditLimit: "",
        SubAccount: "",
        AccountName: "",
        AccountCode: "",
    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const createUser = async () => {
        try {
            if (!formData?.SupplierID || !formData?.ChequeName || !formData?.Email) {
                toast.error("Please fill in all required fields.");
                return;
            } else if (!formData?.CreditLimit) { toast.warning("please Enter Credit Limit") }
            else if (!formData?.CreditDays) { toast.warning("please Enter Credit Days") }
            else {
                const userData = {
                    supplier_ID: formData?.SupplierID,
                    supplierType: formData?.SupplierType,
                    supplierName: formData?.Name,
                    accountCode: formData?.AccountCode,
                    accountName: formData?.AccountName,
                    address1: formData?.Address1,
                    address2: formData?.Address2,
                    city: formData?.Address,
                    country: formData?.Address,
                    phoneNo1: formData?.Phoneno,
                    faxNo1: formData?.Fax,
                    mobileNo1: formData?.MobileNo,
                    email1: formData?.Email,
                    web: formData?.Web,
                    contactPerson1: formData?.Name,
                    cP1ContactNo1: formData?.Name,
                    chequePrintName: formData?.ChequeName,
                    creditLimit: formData?.CreditLimit,
                    creditDays: formData?.CreditDays,
                    vatRegNo: formData?.VATRegNo,
                };
                const response = await apiService.post("CREATE_SUPPLIER", userData);
                if (response?.data?.statusCode === 201) {
                    toast.success("Supplier Created successfully");
                    setFormData({
                        id: state?.id || '',
                        SupplierID: "",
                        ChequeName: "",
                        Phoneno: "",
                        MobileNo: "",
                        SupplierType: "",
                        VATRegNo: "",
                        Fax: "",
                        Email: "",
                        Name: "",
                        Address: "",
                        Web: "",
                        Discount: "",
                        Address1: "",
                        Address2: "",
                        CreditDays: "",
                        CreditLimit: "",
                        SubAccount: "",
                        AccountName: "",
                        AccountCode: "",
                    });
                }
            }
        } catch (error) {
            console.error("Error Creating Supplier:", error);
            toast.error("Error Creating Supplier. Please try again later.");
        }
    };
    const handleCreditSelection = (e) => {
        setCredit(e);
    };
    return (
        <div className='container'>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setGlAccount(!glAccount)}>
                    <div className='h6 pt-1'>GL Account</div>
                </div>
                <div className={`slide-content ${glAccount ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row  pb-4">
                            <div className="col-md-4 col-lg-4 d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Sub Group</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    isDisabled={canCreateMaster === true ? false : true}
                                    className="w-70 p-0"
                                    value={formData?.SubGroup}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className=" col-md-4 col-lg-4 d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Account Code</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="AccountName"
                                    value={formData?.AccountName}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-md-4 col-lg-4 d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Account Name</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="AccountName"
                                    value={formData?.AccountName}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row d-flex justify-content-between">
                            <div className="col-md-2 col-lg-4">
                                <div className='d-flex gap-3 pt-2 d-flex'>
                                    <input className="form-input"
                                        type="checkbox"
                                        id="ledger" style={{ width: "20px", height: "20px" }}
                                        name="ledger"
                                        checked={Credit}
                                        onChange={(e) => handleCreditSelection(e.target.checked)}
                                    />
                                    <label className="form-label checkItem center" htmlFor="ledger">
                                        Create ledger
                                    </label>
                                </div>
                            </div>
                            <div className='col-2 brderbox center'><a href=''>Pick Existing Account</a></div>
                            <div className='col-md-4 col-lg-4 d-flex justify-content-end gap-3'>
                                <div className=''>
                                    <>{canCreateMaster === true ?
                                        <button className='mainbtn ' type="submit">
                                            <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                                        :
                                        <button className='mainbtn ' type="submit" style={{ cursor: "not-allowed" }}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                                    } </>
                                </div>
                                <div className=''>
                                    <button className='mainbtnRed'><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setRetention(!Retention)}>
                    <div className='h6 pt-1'>Retention Account</div>
                </div>
                <div className={`slide-content ${Retention ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row  pb-4">
                            <div className="col-md-4 col-lg-4 d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Account Name</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="AccountName"
                                    value={formData?.AccountName}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Sub Group</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData?.SubGroup}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-md-4 col-lg-4 d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Account Code</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="AccountName"
                                    value={formData?.AccountName}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row d-flex justify-content-between">
                            <div className="col-md-2 col-lg-4">
                                <div className='d-flex gap-3 pt-2 d-flex'>
                                    <input className="form-input"
                                        type="checkbox"
                                        id="ledger" style={{ width: "20px", height: "20px" }}
                                        name="ledger"
                                        checked={Credit}
                                        onChange={(e) => handleCreditSelection(e.target.checked)}
                                    />
                                    <label className="form-label checkItem center" htmlFor="ledger">
                                        Create ledger
                                    </label>
                                </div>
                            </div>
                            <div className='col-2 brderbox center'><a href=''>Pick Existing Account</a></div>
                            <div className='col-md-4 col-lg-4 d-flex justify-content-end gap-3'>
                                <div className=''>
                                    <>{canCreateMaster === true ?
                                        <button className='mainbtn ' type="submit">
                                            <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                                        :
                                        <button className='mainbtn ' type="submit" style={{ cursor: "not-allowed" }}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                                    } </>
                                </div>
                                <div className=''>
                                    <button className='mainbtnRed'><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GlAccount