const BASEURL = process.env.REACT_APP_BACKEND_URL
const IDENTITY_BASEURL = process.env.REACT_APP_BACKEND_IDENTITY_URL
const qa_Url = 'https://posapi.pro360.freemyip.com'
export const endPoint = {

    USER_REGISTER: `${IDENTITY_BASEURL}/api/Account/Register`,
    USER_LOGIN: `${IDENTITY_BASEURL}/api/Account/Login`,
    REFRESH_TOKEN: `${IDENTITY_BASEURL}/api/Account/Refresh`,
    GET_USERS: `${IDENTITY_BASEURL}/api/Account/Get`,
    VERIFY_PASS: `${IDENTITY_BASEURL}/api/Account/VerifyPassCode`,
    UPDATE_PASSWORD: `${IDENTITY_BASEURL}/api/Account/ChangePassword`,
    CREATE_PAYMENT_INTENT: `${BASEURL}/api/payment/create-payment-intent`,
    // Menu Item
    CREATE_BULK_MENU: `${BASEURL}/api/MenuItem/CreateInBulk`,
    CREATE_MENU: `${BASEURL}/api/MenuItem/Create`,
    GET_MENU: `${BASEURL}/api/MenuItem/Get`,
    DELETE_ITEM: `${BASEURL}/api/MenuItem/Delete`,
    UPLOAD_IMG: `${BASEURL}/api/MenuItem/UploadImages`,
    UPDATE_MENU: `${BASEURL}/api/MenuItem/Update`,
    // Category
    // GET_MENU_BY_CATEGORY: `${BASEURL}/api/MenuItem/GetListByCategoryId`,
    CREATE_CATEGORY: `${BASEURL}/api/Category/Create`,
    GET_CATEGORY: `${BASEURL}/api/Category/Get`,
    DELETE_CATEGORY: `${BASEURL}/api/Category/Delete`,
    UPDATE_CATEGORY: `${BASEURL}/api/Category/Update`,
    // Dashboard
    ORDER_SUMMERY: `${BASEURL}/api/Dashboard/GetOrderSummery`,
    DASH_THIRD_COUNT: `${BASEURL}/api/Dashboard/GetDashboardCount`,
    DASH_THIRD_CHART: `${BASEURL}/api/Dashboard/GetOrderCharts`,
    DASH_REVENUE_COUNT: `${BASEURL}/api/Dashboard/GetDashboardSummery`,
    // Costumer
    GET_COSTUMER: `${qa_Url}/api/Customer/GetList`,
    GET_COSTUMER_ID: `${qa_Url}/api/Customer/GetById`,
    ADD_COSTUMER: `${qa_Url}/api/Customer/Register`,
    DELETE_COSTUMER: `${qa_Url}/api/Customer/Delete`,
    UPDATE_COSTUMER: `${qa_Url}/api/Customer/Update`,
    //Employees
    GET_EMPLOYEE: `${IDENTITY_BASEURL}/api/Employee/GetList`,
    GET_EMPLOYEE_ID: `${IDENTITY_BASEURL}/api/Employee/GetById`,
    ADD_EMPLOYEE: `${IDENTITY_BASEURL}/api/Employee/Create`,
    DELETE_EMPLOYEE: `${IDENTITY_BASEURL}/api/Employee/Delete`,
    UPDATE_EMPLOYEE: `${IDENTITY_BASEURL}/api/Employee/Update`,
    // AddOns
    CREATE_ADDONS: `${BASEURL}/api/AddOn/Create`,
    DELETE_ADDONS: `${BASEURL}/api/AddOn/Delete`,
    GET_ADDONS: `${BASEURL}/api/AddOn/Get`,
    UPDATE_ADDONS: `${BASEURL}/api/AddOn/Update`,
    //Counters
    CREATE_COUNTER: `${BASEURL}/api/Counter/Create`,
    DELETE_COUNTER: `${BASEURL}/api/Counter/Delete`,
    GET_COUNTER: `${BASEURL}/api/Counter/Get`,
    UPDATE_COUNTER: `${BASEURL}/api/Counter/Update`,
    // Modifier
    CREATE_MODIFIER: `${BASEURL}/api/Modifier/Create`,
    DELETE_MODIFIER: `${BASEURL}/api/Modifier/Delete`,
    GET_MODIFIER: `${BASEURL}/api/Modifier/Get`,
    UPDATE_MODIFIER: `${BASEURL}/api/Modifier/Update`,

    // orders
    CREATE_ORDER: `${BASEURL}/api/Order/Create`,
    GET_ORDERS: `${BASEURL}/api/Order/Get`,
    UPDATE_ORDER: `${BASEURL}/api/Order/Update`,
    UPDATE_ORDER_META: `${BASEURL}/api/Order/UpdateOrderMeta`,

    GET_ORDERS_PAGINATION: `${BASEURL}/api/Order/GetPaginatedList`,
    // VARIANTS
    CREATE_VARIANTS: `${BASEURL}/api/Variant/Create`,
    DELETE_VARIANTS: `${BASEURL}/api/Variant/Delete`,
    GET_VARIANTS: `${BASEURL}/api/Variant/Get`,
    UPDATE_VARIANTS: `${BASEURL}/api/Variant/Update`,
    // SubCategory
    CREATE_SUBCATEGORY: `${BASEURL}/api/SubCategory/Create`,
    DELETE_SUBCATEGORY: `${BASEURL}/api/SubCategory/Delete`,
    GET_SUBCATEGORY: `${BASEURL}/api/SubCategory/Get`,
    UPDATE_SUBCATEGORY: `${BASEURL}/api/SubCategory/Update`,
    // Tax Management
    CREATE_TAX: `${BASEURL}/api/Tax/Create`,
    DELETE_TAX: `${BASEURL}/api/Tax/Delete`,
    GET_TAX: `${BASEURL}/api/Tax/Get`,
    UPDATE_TAX: `${BASEURL}/api/Tax/Update`,
    //Combo Management
    CREATE_COMBO: `${BASEURL}/api/Combo/Create`,
    DELETE_COMBO: `${BASEURL}/api/Combo/Delete`,
    GET_COMBO: `${BASEURL}/api/Combo/Get`,
    UPDATE_COMBO: `${BASEURL}/api/Combo/Update`,
    // Table Managment
    CREATE_TABLE: `${BASEURL}/api/Table/Create`,
    DELETE_TABLE: `${BASEURL}/api/Table/Delete`,
    GET_TABLE: `${BASEURL}/api/Table/Get`,
    UPDATE_TABLE: `${BASEURL}/api/Table/Update`,
    // Kitchen Master 
    CREATE_KITCHEN: `${BASEURL}/api/Kitchen/Create`,
    DELETE_KITCHEN: `${BASEURL}/api/Kitchen/Delete`,
    GET_KITCHEN: `${BASEURL}/api/Kitchen/Get`,
    UPDATE_KITCHEN: `${BASEURL}/api/Kitchen/Update`,
    // Currency Master 
    CREATE_CURRENCY: `${BASEURL}/api/Currency/Create`,
    DELETE_CURRENCY: `${BASEURL}/api/Currency/Delete`,
    GET_CURRENCY: `${BASEURL}/api/Currency/Get`,
    UPDATE_CURRENCY: `${BASEURL}/api/Currency/Update`,
    // Cash Opening
    CREATE_CASH: `${BASEURL}/api/Cash/Create`,
    DELETE_CASH: `${BASEURL}/api/Cash/Delete`,
    GET_CASH: `${BASEURL}/api/Cash/Get`,
    UPDATE_CASH: `${BASEURL}/api/Cash/Update`,
    // Transations
    CREATE_PAYMENT: `${BASEURL}/api/Payment/Create`,
    DELETE_PAYMENT: `${BASEURL}/api/Payment/Delete`,
    GET_PAYMENT: `${BASEURL}/api/Payment/Get`,
    UPDATE_PAYMENT: `${BASEURL}/api/Payment/Update`,
    // Payments Methods
    CREATE_PAYMENT_METHOD: `${BASEURL}/api/PaymentMethods/Create`,
    DELETE_PAYMENT_METHOD: `${BASEURL}/api/PaymentMethods/Delete`,
    GET_PAYMENT_METHOD: `${BASEURL}/api/PaymentMethods/Get`,
    UPDATE_PAYMENT_METHOD: `${BASEURL}/api/PaymentMethods/Update`,
    // ROLE Methods
    CREATE_ROLE: `${IDENTITY_BASEURL}/api/Roles/Create`,
    DELETE_ROLE: `${IDENTITY_BASEURL}/api/Roles/Delete`,
    GET_ROLE: `${IDENTITY_BASEURL}/api/Roles/GetRolesList`,
    GET_ROLE_BY_ID: `${IDENTITY_BASEURL}/api/Roles/GetRoleById`,
    UPDATE_ROLE: `${IDENTITY_BASEURL}/api/Roles/Update`,
    // Claim Methods
    CREATE_CLAIM: `${IDENTITY_BASEURL}/api/Claims/Create`,
    DELETE_CLAIM: `${IDENTITY_BASEURL}/api/Claims/Delete`,
    GET_CLAIM: `${IDENTITY_BASEURL}/api/Claims/GetAllClaims`,
    UPDATE_CLAIM: `${IDENTITY_BASEURL}/api/Claims/Update`,
    // User CLAIM Methods
    CREATE_USER_CLAIM: `${IDENTITY_BASEURL}/api/UsersClaim/Create`,
    DELETE_USER_CLAIM: `${IDENTITY_BASEURL}/api/UsersClaim/Delete`,
    GET_USER_CLAIM: `${IDENTITY_BASEURL}/api/UsersClaim/GetList`,
    UPDATE_USER_CLAIM: `${IDENTITY_BASEURL}/api/UsersClaim/Update`,
    // ROLE CLAIM Methods
    CREATE_ROLE_CLAIM: `${BASEURL}/api/RoleClaim/Create`,
    DELETE_ROLE_CLAIM: `${BASEURL}/api/RoleClaim/Delete`,
    GET_ROLE_CLAIM: `${BASEURL}/api/RoleClaim/GetRoleById`,
    UPDATE_ROLE_CLAIM: `${BASEURL}/api/RoleClaim/Update`,
    // Restaurant
    CREATE_RESTAURANT: `${BASEURL}/api/Restaurant/Create`,
    DELETE_RESTAURANT: `${BASEURL}/api/Restaurant/Delete`,
    GET_RESTAURANT: `${BASEURL}/api/Restaurant/Get`,
    UPDATE_RESTAURANT: `${BASEURL}/api/Restaurant/Update`,
    // Restaurant Area
    CREATE_RESTAURANT_AREA: `${BASEURL}/api/ResturentArea/Create`,
    DELETE_RESTAURANT_AREA: `${BASEURL}/api/ResturentArea/Delete`,
    GET_RESTAURANT_AREA: `${BASEURL}/api/ResturentArea/Get`,
    UPDATE_RESTAURANT_AREA: `${BASEURL}/api/ResturentArea/Update`,
    // Branches
    CREATE_BRANCH: `${BASEURL}/api/Branches/Create`,
    DELETE_BRANCH: `${BASEURL}/api/Branches/Delete`,
    GET_BRANCH: `${BASEURL}/api/Branches/Get`,
    UPDATE_BRANCH: `${BASEURL}/api/Branches/Update`,
    // Branches
    CREATE_DISCOUNT: `${BASEURL}/api/Discount/Create`,
    DELETE_DISCOUNT: `${BASEURL}/api/Discount/Delete`,
    GET_DISCOUNT: `${BASEURL}/api/Discount/Get`,
    UPDATE_DISCOUNT: `${BASEURL}/api/Discount/Update`,
    // Promotion
    CREATE_PROMOTION: `${BASEURL}/api/Promotion/Create`,
    DELETE_PROMOTION: `${BASEURL}/api/Promotion/Delete`,
    GET_PROMOTION: `${BASEURL}/api/Promotion/Get`,
    UPDATE_PROMOTION: `${BASEURL}/api/Promotion/Update`,
    UPDATE_PROMOTION_DETAIL: `${BASEURL}/api/Promotion/UpdatePromotionDetails`,
    // Reports
    GET_SALE_REPORTS: `${BASEURL}/api/Reports/SalesReport`,
    GET_ITEM_SALE_REPORTS: `${BASEURL}/api/Reports/ItemSalesReport`,
    GET_ORDER_REPORTS: `${BASEURL}/api/Reports/OrderReport`,
    GET_ORDER_XZREPORTS: `${BASEURL}/api/Reports/XandZReports`,
    GET_TOTAL_SALE: `${BASEURL}/api/Reports/TotalSalesReport`,
    GET_TAX_REPORT: `${BASEURL}/api/Reports/OrderWiseTaxReport`,
    GET_CASHINOUT_REPORT: `${BASEURL}/api/Reports/CashInOutReport`,
    GET_TABLE_REPORT: `${BASEURL}/api/Reports/TableReport`,
    GET_DELIVERY_REPORT: `${BASEURL}/api/Reports/DeliveryReport`,

    // DeliveryPlatforms Methods
    CREATE_DELIVERY: `${BASEURL}/api/DeliveryPlatforms/Create`,
    DELETE_DELIVERY: `${BASEURL}/api/DeliveryPlatforms/Delete`,
    GET_DELIVERY: `${BASEURL}/api/DeliveryPlatforms/Get`,
    UPDATE_DELIVERY: `${BASEURL}/api/DeliveryPlatforms/Update`,

    //Save Card Information
    CREATE_CARD_DETAIL: `${BASEURL}/api/Card/Create`,
    DELETE_CARD_DETAIL: `${BASEURL}/api/Card/Delete`,
    GET_CARD_DETAIL: `${BASEURL}/api/Card/Get`,
    UPDATE_CARD_DETAIL: `${BASEURL}/api/Card/Update`,
    //GL ACCOUNTS 
    GET_GL_ACCOUNT: `${BASEURL}/api/Account/Get`,

    //Customer REWARDS 
    GET_REWARDS: `${BASEURL}/api/CustomerRewards/Get`,
    CREATE_REWARDS: `${BASEURL}/api/CustomerRewards/Create`,
    Update_REWARDS: `${BASEURL}/api/CustomerRewards/Update`,
    DELETE_REWARDS: `${BASEURL}/api/CustomerRewards/Delete`,




}
