import React, { useState, useEffect } from 'react';

const InvoiceItemList = ({ onRequisitionData, GrnItemData }) => {
    const [rows, setRows] = useState([{
        requisitionNo: '',
        srno: '',
        recordId: '',
        ItemNo: '',
        itemDescription: '',
        specification: '',
        qty: '',
        uom: '',
        baseQty: '',
        baseAmount: '',
        unitPrice: '',
        amount: '',
        discount: '',
        vat: '',
        vatAmount: ''
    }]);
    const totalAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
    useEffect(() => {
        getItems();
    }, [GrnItemData]);
    const getItems = async () => {
        try {
            debugger
            const formattedRows = GrnItemData?.map(detail => {
                return {
                    grnNo: detail.grnNo,
                    ItemNo: detail.itemCode,
                    itemDescription: detail.itemDescription,
                    qty: detail.qty,
                    uom: detail.uom,
                    baseAmount: detail.price,
                    specification: detail.specification,
                    unitPrice: detail.unitPrice,
                    amount: detail?.amount,
                    stock: detail?.convToInvUOM,
                    vat: detail.vatPerc,
                    vatAmount: detail?.vatAmount
                };
            }).filter(row => row !== null);

            if (formattedRows.length > 0) {
                setRows(formattedRows);
                onRequisitionData(formattedRows);
            }
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    return (
        <div>
            <div className='table-container p-2 mb-5' style={{ overflowX: 'auto', height: "400px" }}>
                <table>
                    <thead className='dynoTable'>
                        <tr>
                            <th>Sr no</th>
                            <th>Item No</th>
                            <th>Description</th>
                            <th>Item Specs</th>
                            <th>Qty</th>
                            <th>Packing UOM</th>
                            <th>Inventory Qty</th>
                            <th>Price</th>
                            <th>Discount</th>
                            <th>Unit Price</th>
                            <th>VAT %</th>
                            <th>Extra Amount</th>
                            <th>VAT Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(rows) && rows.map((row, index) => (
                            <tr key={index}>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={index + 1} style={{ width: "80px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.ItemNo} style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.itemDescription} style={{ width: "150px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.specification} style={{ width: "150px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.qty} style={{ width: "80px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.uom} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.stock} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.amount} style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={0} style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.unitPrice} style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.vat + " %"} style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={0} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.vatAmount} style={{ width: "120px" }} /></td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </div>
            <div className='d-flex justify-content-center boxs'>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Invoiced </div>
                    <div className='field'>{totalAmount || "---"}</div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceItemList;
