import React from 'react';
import { useDrop } from 'react-dnd';
import "./kitchen.css"
const OrderSection = ({ status, onDrop, children }) => {
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: 'ORDER',
        drop: (item) => onDrop(item.id, status),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const isActive = canDrop && isOver;

    return (
        <div ref={drop} className=''>
            <div className={`m-4 status-${status === 'Preparing' ? 'Pre' : status === 'Ready' ? 'ready' : status === 'Cancel' ? 'cancel' : 'contain'}`}>
                {status}
            </div>
            <div className='section-contain '>
                <div className='pt-4'>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default OrderSection;
