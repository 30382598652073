import React, { useState, useRef, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/apiService';
import { FaEdit, FaEye } from "react-icons/fa";
import Pagination from '../pagination/pagination';
const SubCategoryGridMaster = ({ rowData, canEditItemMaster, fetchSubCategories, pagination, setPageSize, pageSize }) => {

    const gridRef = useRef();
    const [selectedRowData, setSelectedRowData] = useState(null);
    let navigate = useNavigate();
    const handleEdit = (data) => {
        navigate("/inventory/Master/SubCategory", { state: data })
    };
    const columnDefs = [
        { headerName: 'Code', field: 'subcatcode', filter: true, floatingFilter: true, width: 90 },
        { headerName: 'SubCategory Name', field: 'subcatname', filter: true, floatingFilter: true, width: 180 },
        { headerName: 'Category Code ', field: 'catcode', filter: true, floatingFilter: true, width: 150 },
        { headerName: 'Category Name ', field: 'catName', filter: true, floatingFilter: true, width: 150 },
        { headerName: 'Inventory ACC ', field: 'inventoryAccountCode', filter: true, floatingFilter: true, width: 150 },
        { headerName: 'GRN ACC ', field: 'grnAccountCode', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'Adjustment ACC ', field: 'adjustmentAccountCode', filter: true, floatingFilter: true, width: 160 },
        { headerName: 'Work in progress ', field: 'workInProgressAccountCode', filter: true, floatingFilter: true, width: 160 },
        { headerName: 'CGS ACC', field: 'cgsAccountCode', filter: true, floatingFilter: true, width: 110 },
        { headerName: 'Invoice ACC', field: 'invoiceAccountCode', filter: true, floatingFilter: true, width: 130 },
        { headerName: 'Wastage ACC', field: 'wastageAccountCode', filter: true, floatingFilter: true, width: 140 },
        { headerName: 'Issue ACC', field: 'issueAccountCode', filter: true, floatingFilter: true, width: 110 },
        {
            headerName: 'Actions', field: 'recordid', cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                canEditItemMaster: canEditItemMaster,
                handleEdit: handleEdit,
                clicked: function (field, isActive) {
                    updateActiveStatus(field, isActive);
                },
            }, width: 150
        }
    ];
    const actionsRenderer = (props) => {
        return (
            <div>
                <Button variant="primary" onClick={() => handleEditRow(props.data)}>Edit</Button>
            </div>
        );
    };
    const updateActiveStatus = async (id, isActive) => {
        try {

            const apiService = new ApiService();
            let body = {
                recordId: id,
                isActive: isActive
            }
            const res = await apiService.put(`UPDATE_SUB_CATEGORY`, body);
            if (res?.data?.statusCode === 201) {
                console.log('Updated successfully');
                fetchSubCategories();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    const handleEditRow = (rowData) => {
        setSelectedRowData(rowData);
    };
    return (
        <div>
            <div className='brdor mt-3' style={{ height: 500 }}>
                <div className="ag-theme-quartz" style={{ height: 500 }}>
                    <AgGridReact
                        rowData={rowData}
                        ref={gridRef}
                        enableFilter={true}
                        floatingFilter={true}
                        rowSelection={'single'}
                        columnDefs={columnDefs}
                        pagination={false}
                        suppressRowClickSelection={true}
                        frameworkComponents={{ actionsRenderer }}
                    />
                </div>
            </div>
            <Pagination pagination={pagination} getItems={fetchSubCategories} />
        </div>
    );
};

class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value, !this.props.data.isActive); // Toggle isActive value
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data); // Pass rowData to handleEdit function
    }

    render() {
        const { isActive } = this.props.data;
        const { canEditItemMaster } = this.props;

        return (
            <div className='d-flex gap-3'>
                {canEditItemMaster && (
                    <div onClick={this.btnEditClickedHandler}><FaEdit size={25} /></div>
                )}
                {canEditItemMaster && (
                    <div className="form-check form-switch center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive}
                            onClick={this.btnClickedHandler}
                        />
                    </div>
                )}
            </div>
        )
    }
}
export default SubCategoryGridMaster;