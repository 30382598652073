import React, { useState } from 'react';
import Cake from '../assets/images/CakeImg.png';
import './itemCard.css';
import Modal from 'react-modal';
import CartModal from '../components/cartPopup';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import ComboPopup from './comboCart/comboPopup';
import { addAddon } from '../../../Redux/actions/addOnAction';
import { useDispatch } from 'react-redux';

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const ItemCard = ({ val, addToCart, Currency, combo, addsOns }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [CartValue, setCartValue] = useState("");
  let navigate = useNavigate();
  let dispatch = new useDispatch();


  const openModal = (item) => {
    if (addsOns) {
      // Directly add addon to cart and bypass the modal
      dispatch(addAddon(item))
    } else {
      // Open modal for non-addon items
      setCartValue(item);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const truncatedAddress = val?.name?.length > 20 ? val?.name.substring(0, 25) + '...' : val?.name;
  const truncatedDescription = val?.description?.length > 20 ? val?.description.substring(0, 25) + '...' : val?.description;

  return (
    <div className='py-3'>
      <div className='cashcard'>
        <div className='row'>
          <div className='col-lg-3'>
            <img className='itm_Img' src={!val?.imageUrl ? IMAGE_URL + val?.image || Cake : IMAGE_URL + val?.imageUrl} alt={val?.name} />
          </div>
          <div className='col-lg-9'>
            <div className="card-Head" title={val?.name}>{truncatedAddress || "--"}</div>
            <div className='card-subHead' title={val?.description}>{truncatedDescription}</div>
            <div className='card-Prize'>{Currency.currency.name} {val?.price?.toLocaleString('en-US')}</div>
            <div className='d-flex justify-content-end'>
              <div className='AddCart-btn center p-2' onClick={() => openModal(val)}>
                Add to cart
              </div>
            </div>
          </div>
        </div>
        {/* Render the CartModal as a modal */}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel='Cart Modal'
        >
          {(!combo || combo?.length === 0 && addsOns.length === 0) ? (
            <CartModal CartValue={CartValue} closeModal={closeModal} addToCart={addToCart} />
          ) : (
            <ComboPopup CartValue={CartValue} closeModal={closeModal} />
          )}
          {/* <button onClick={closeModal}>Close</button> */}
        </Modal>
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(ItemCard);
