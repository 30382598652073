import {
    ADD_TABLE,
    CLEAR_TABLE,
} from '../actions/types';

const initialState = {
    items: [],
};

const tableReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TABLE:
            return {
                ...state,
                items: [...state.items, action.payload],
            };

        case CLEAR_TABLE:
            return {
                ...state,
                items: [],
            };

        default:
            return state;
    }
};

export default tableReducer;
