import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import { FaEdit } from "react-icons/fa";
import { IoAddSharp } from "react-icons/io5";
import { hasPermission } from "../../services/roleService"
import Pagination from '../pagination/pagination';
const Listing = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState([]);
    let navigate = useNavigate();
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const roleAccess = JSON.parse(localStorage.getItem('RoleAccess'));
    const selectedRole = roleAccess?.find(acc => acc.description === "Item Master");
    useEffect(() => {
        if (pageSize) {
            getItems();
        }
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
    }, [pageSize]);

    const handleEdit = (data) => {
        navigate("/Inventory/Master/Item/Create", { state: data });
    };
    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    const canEditItemMaster = checkPermission('Item Master', 'edit');
    const canCreateItemMaster = checkPermission('Item Master', 'create');
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);

            const res = await apiService.getApiParamater('GET_ITEM', `?${params.toString()}`);
            setRowData(res?.data?.result || []);
            setPagination(res.data.paginationData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    };
    const columnDefs = [
        { headerName: 'itemNo', field: 'itemNo', filter: true, floatingFilter: true, width: 100 },
        { headerName: 'Item Description', field: 'itemDescription', filter: true, floatingFilter: true, width: 180 },
        { headerName: 'Packing UOM', field: 'baseUnit', filter: true, floatingFilter: true, width: 180 },
        { headerName: 'Inventory UOM', field: 'unitMeasurement', filter: true, floatingFilter: true, width: 180 },
        { headerName: 'Packing Type', field: 'packingType', filter: true, floatingFilter: true, width: 180 },
        { headerName: 'Conversion Rate', field: 'convToInvUOM', filter: true, floatingFilter: true },
        { headerName: 'Category', field: 'category', filter: true, floatingFilter: true },
        { headerName: 'Sub Category', field: 'subCategory', filter: true, floatingFilter: true },
        { headerName: 'Supplier', field: 'supplier', filter: true, floatingFilter: true },
        { headerName: 'Purchase Price', field: 'purchasePrice', filter: true, floatingFilter: true },
        {
            headerName: 'Actions', field: 'recordId', cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                role: selectedRole,
                canEdit: canEditItemMaster,
                handleEdit: handleEdit,
                clicked: function (field, exp9) {
                    handleToggle(field, exp9);
                },
            }, width: 132
        }
    ];
    const updateActiveStatus = async (id, isActive) => {
        try {
            const apiService = new ApiService();
            let body = {
                recordId: id,
                exp9: isActive ? "1" : "0"
            };
            const res = await apiService.put(`UPDATE_ITEM`, body);
            if (res?.status === 200) {
                console.log('Updated successfully');
                getItems();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    const handlenew = () => {
        navigate('/Inventory/Master/Item/Create');
    };
    const handleToggle = (recordId, newExp9Value) => {
        updateActiveStatus(recordId, newExp9Value === "1");
    };

    return (
        <div className='px-4 pt-3'>
            {loading ? (
                <div className='center'>
                    <PropagateLoader color="#6E5199" size={15} />
                </div>
            ) : (
                <>
                    <div className='justify-content-between d-flex align-items-center'>
                        <h5 className='col mainInvHead'>Item Master List</h5>
                        {canCreateItemMaster && (
                            <div onClick={handlenew} className='mainbtn flex-end gap-3'>
                                <IoAddSharp color='#fff' className='center mt-1 mx-2' />
                                <div className='center'>Add New</div>
                            </div>
                        )}
                    </div>
                    <div className='brdor mt-2'>
                        <div className="ag-theme-quartz" style={{ height: 700 }}>
                            <AgGridReact
                                rowData={rowData}
                                enableFilter={true}
                                floatingFilter={true}
                                rowSelection={'single'}
                                columnDefs={columnDefs}
                                pagination={false}
                                suppressExcelExport={true}
                                suppressRowClickSelection={true}
                            />
                        </div>
                    </div>
                    <div className=''>
                        <Pagination setPageSize={setPageSize} pagination={pagination} getItems={getItems} pageSize={pageSize} />
                    </div>
                </>
            )}
        </div>
    );
};


class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked, data } = this.props;
        const newExp9Value = data.exp9 === "1" ? "0" : "1";
        clicked(value, newExp9Value);
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data);
    }

    render() {
        const { exp9 } = this.props.data;
        const { canEdit } = this.props;
        return (
            <div className='d-flex gap-3'>
                {canEdit && (
                    <div onClick={this.btnEditClickedHandler}><FaEdit size={25} /></div>
                )}
                {canEdit && (
                    <div className="form-check form-switch center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={exp9 === "1"}
                            onChange={this.btnClickedHandler}
                        />
                    </div>
                )}
            </div>
        );
    }
}


export default Listing;
