import Button from 'react-bootstrap/Button';
import { React, useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import ApiService from '../../../services/apiService';
import Modal from 'react-bootstrap/Modal';
import "./customer.css"
import CostumerGrid from './CustomerGrid/grid';
import POSPagination from '../pagination/pagination';
const CostumerList = () => {

    const [show, setShow] = useState(false);
    const [CostValue, setDelCost] = useState("");
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState();
    const [pageSize, setPageSize] = useState(20);
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setDelCost(e)
        setShow(true);
    }
    useEffect(() => {
        GetCostumer();
    }, []);

    const DeleteCostumer = async () => {
        const apiService = new ApiService();
        let paramaters = `${CostValue?.id}`;
        let res = await apiService.delete("DELETE_COSTUMER", paramaters);
        if (res?.data?.statusCode === 204) {
            setShow(false)
            window.location.reload(false);
            toast.success("MenuItem Successfully Deleted");
        }
        else {
            toast.warning('Waiting for menu update');
        }
    }
    const GetCostumer = async (newPageNo = pagination?.currentPage) => {
        const apiService = new ApiService();
        try {
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const res = await apiService.getApiParamater('GET_COSTUMER', `?${params.toString()}`);
            setData(res?.data?.result);
            setPagination(res.data.paginationData);
        } catch (error) {
            console.error("Error fetching customer data:", error);
        }
    };
    return (
        <div>
            <div className='mt-4 POsgridshad p-0'>
                <CostumerGrid costumer={data} />
                <POSPagination setPageSize={setPageSize} pagination={pagination} getItems={GetCostumer} />
            </div>
            <Modal show={show} onHide={handleClose} value={CostValue}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Costumer</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you Sure you want to delete {CostValue?.name}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                    <Button variant="primary" onClick={() => DeleteCostumer(CostValue)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default CostumerList