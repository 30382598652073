import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { FaEdit, FaEye } from "react-icons/fa";
import { MdOutlineEditOff } from "react-icons/md";

const StockGrid = ({ rowData, getItems, loading, canEditMaster }) => {

    let navigate = useNavigate();
    const [selectedRowData, setSelectedRowData] = useState(null);
    const handleEdit = (data) => {
        navigate("/inventory/Master/Stock", { state: data })
    };
    const columnDefs = [
        { headerName: "Code", field: "stockTypeCode", filter: true, floatingFilter: true, editable: true, minWidth: 525 },
        { headerName: "Stock Name", field: "stockTypeName", filter: true, floatingFilter: true, minWidth: 525, editable: true },
        {
            headerName: 'Status', field: 'recordid', cellRenderer: BtnCellRenderer, minWidth: 510,
            cellRendererParams: {
                canEditMaster: canEditMaster,
                handleEdit: handleEdit,
                clicked: function (field, isActive) {
                    updateActiveStatus(field, isActive);
                },
            },
        }
    ];
    const updateActiveStatus = async (id, isActive) => {
        try {
            const apiService = new ApiService();
            let body = {
                recordid: id,
                isActive: isActive
            }
            const res = await apiService.put(`UPDATE_STOCK_TYPE`, body);
            if (res?.status === 200) {
                console.log('Updated successfully');
                getItems();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    const actionsRenderer = (props) => {
        return (
            <div>
                <Button variant="primary" onClick={() => handleEditRow(props.data)}>Edit</Button>
            </div>
        );
    };
    const handleEditRow = (rowData) => {
        setSelectedRowData(rowData);
    };
    return (
        <div className=''>

            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div className='brdor mt-3' style={{ height: 600 }}>
                        <div className="ag-theme-quartz" style={{ height: 600 }}>
                            <AgGridReact
                                rowData={rowData}
                                enableFilter={true}
                                floatingFilter={true}
                                rowSelection={'single'}
                                columnDefs={columnDefs}
                                pagination={false}
                                suppressExcelExport={true}
                                suppressRowClickSelection={true}
                                frameworkComponents={{ actionsRenderer }}
                            />
                        </div>
                    </div>
                    
                </>
            )}
        </div>
    );
};

class BtnCellRenderer extends Component {

    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data)
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value, !this.props.data.isActive);
    }

    render() {
        const { canEditMaster } = this.props;
        const { isActive } = this.props.data;
        return (
            <div className='d-flex gap-3'>
                {canEditMaster === true ?
                    <div onClick={this.btnEditClickedHandler}><FaEdit size={25} /></div> :
                    <div><MdOutlineEditOff size={25} /></div>
                }
                {canEditMaster === true ?
                    <div className="form-check form-switch center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive}
                            onClick={this.btnClickedHandler}
                        />
                    </div>
                    :
                    <div className="form-check form-switch center">
                        <input disabled
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default StockGrid;
