import CustomPieChart from '../../charts/pieChart';
import arrow from '../../../../assets/images/ArrowRight.png';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import ApiService from '../../../../services/apiService';
const OrderSummery = ({ val }) => {

    // const [Summary, setSelectedOption] = useState();
    const [orderSumary, SetorderSum] = useState('');
    // const SummaryFilter = [
    //     { value: '0', label: 'Day' },
    //     { value: '1', label: 'Week' },
    //     { value: '2', label: 'Month' },
    //     { value: '3', label: 'Year' },
    //     { value: '4', label: 'All Time' },
    // ];

    useEffect(() => {
        getSumData(val);
    }, [val]);

    const getSumData = async (val) => {
        try {
            const apiService = new ApiService();
            let paramaters = `?GetBy=${val}`;
            let res = await apiService.getApiParamater("ORDER_SUMMERY", paramaters);

            SetorderSum(res?.data?.result);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div className='dashcard'>
            <div className="row ">
                <div className="col">
                    <div className="Summary-label">Order Summary</div>
                </div>
            </div>
            <div className='bg-main-count p-2 mt-4 '>
                <div className=' d-flex gap-4'>
                    <div className='bg-counts'>
                        <div className='count-itself '>
                            {orderSumary?.orderInQueueCount || "0"}
                        </div>
                    </div>
                    <div className='ord-in-que center'>
                        Order in  Process
                    </div>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-4'>
                    <div className='summary-head'> {orderSumary?.onDeliveryCount || "0"}</div>
                    <div className='summary-para'> On Delivery</div>
                </div>
                <div className='col-lg-4'>
                    <div className='summary-head'> {orderSumary?.deliveredCount || "0"}</div>
                    <div className='summary-para'> Delivered</div>
                </div>
                <div className='col-lg-4'>
                    <div className='summary-head'> {orderSumary?.canceledCount || "0"}</div>
                    <div className='summary-para'> Canceled</div>
                </div>
            </div>
            <div className='row'>
                <div className='col center'>
                    <CustomPieChart val={orderSumary} />
                </div>
                <div className='col pt-5 mt-5'>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="dlvr">Delivered ({orderSumary?.deliveredCount || "0"})</div>
                        <div className="progress" style={{ width: '70%', marginLeft: '10px' }}>
                            <div className="progress-bar bg-success" role="progressbar" aria-valuenow={orderSumary?.deliveredCount || "0"} aria-valuemin="0"
                                aria-valuemax={(orderSumary?.deliveredCount + orderSumary?.onDeliveryCount + orderSumary?.canceledCount) || "1"}
                                style={{ width: `${(orderSumary?.deliveredCount / (orderSumary?.deliveredCount + orderSumary?.onDeliveryCount + orderSumary?.canceledCount)) * 100 || 0}%` }}></div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="dlvr">On Delivery ({orderSumary?.onDeliveryCount || "0"})</div>
                        <div className="progress" style={{ width: '70%', marginLeft: '10px' }}>
                            <div className="progress-barP bg-orange" role="progressbar" aria-valuenow={orderSumary?.onDeliveryCount || "0"} aria-valuemin="0"
                                aria-valuemax={(orderSumary?.deliveredCount + orderSumary?.onDeliveryCount + orderSumary?.canceledCount) || "1"}
                                style={{ width: `${(orderSumary?.onDeliveryCount / (orderSumary?.deliveredCount + orderSumary?.onDeliveryCount + orderSumary?.canceledCount)) * 100 || 0}%` }}></div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="dlvr">Canceled ({orderSumary?.canceledCount || "0"})</div>
                        <div className="progress" style={{ width: '70%', marginLeft: '10px' }}>
                            <div className="progress-barC bg-dark" role="progressbar" aria-valuenow={orderSumary?.canceledCount || "0"} aria-valuemin="0"
                                aria-valuemax={(orderSumary?.deliveredCount + orderSumary?.onDeliveryCount + orderSumary?.canceledCount) || "1"}
                                style={{ width: `${(orderSumary?.canceledCount / (orderSumary?.deliveredCount + orderSumary?.onDeliveryCount + orderSumary?.canceledCount)) * 100 || 0}%` }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderSummery;
