import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/apiService';
import Select from 'react-select';
import "./reports.css"
import { RiSkipLeftFill, RiSkipRightFill } from "react-icons/ri";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";
import { SlRefresh } from "react-icons/sl";
import { HiOutlinePrinter } from "react-icons/hi2";
import { PiDownloadSimple } from "react-icons/pi";
import moment from 'moment';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { jsPDF } from 'jspdf';
import { BsFileEarmarkPdf, BsFiletypeCsv } from "react-icons/bs";
import { LiaFileExcel } from "react-icons/lia";
import 'jspdf-autotable';
const StoreTransectionReport = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [SupplierList, setSuppliers] = useState();
    const [PageNo, setPageNo] = useState();
    const [pageSize, setPageSize] = useState(20);
    const [Items, setItems] = useState([]);
    const [pagination, setPagination] = useState();
    const [toDate, setToDate] = useState("13-jun-25");
    const [fromDate, setFromDate] = useState("13-jun-23");
    const [Location, setLocation] = useState([]);
    const [dateFilter, setdateFilter] = useState(false);
    const gridRef = useRef();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const [formData, setFormData] = useState({
        startdate: "13-jun-23",
        endDate: "13-jun-25",
        itemNo: { label: 'Select ItemNo', value: 'All' },
        ItemDescription: { label: 'Select Item', value: 'All' },
        category: { label: 'Select Category', value: 'All' },
        location: { label: 'Select Location', value: 'All' },
    });
    const handleClearFilters = () => {
        setFormData({
            startdate: "13-jun-23",
            endDate: "13-jun-25",
            itemNo: { label: 'Select ItemNo', value: 'All' },
            ItemDescription: { label: 'Select Item', value: 'All' },
            category: { label: 'Select Category', value: 'All' },
            location: { label: 'Select Location', value: 'All' },
        });
        setFromDate("")
        setToDate(" ")
        setdateFilter(false)
    };
    useEffect(() => {
        if (formData || pageSize) {
            getItems();
        }
        if (dateFilter === true) {
            getItems();
        } else {
            return
        }
    }, [pageSize, formData, dateFilter, toDate, fromDate]);
    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const apiService = new ApiService();
                let param = `?isActive=true`
                const categoryResponse = await apiService.getApiParamater("GET_CATEGORY", param);
                setCategoryOptions(categoryResponse?.data?.result);
                const Items = await apiService.getApiParamater("GET_ITEM", param);
                let res = await apiService.getApiParamater('GET_WAREHOUSE_MASTER', param)
                setLocation(res?.data?.result)
                setItems(Items?.data?.result);
            } catch (error) {
                console.error('Error fetching dropdown data:', error);
            }
        };
        fetchDropdownData();
        getItems()
    }, []);
    const getItems = async (pageChange) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('Category', formData.category.value);
            params.append('ItemCode', formData.itemNo.value);
            params.append('Warehouse', formData.location?.value);
            let newPageNo = pagination?.currentPage;

            if (pageChange === "next") {
                newPageNo += 1;
            } else if (pageChange === "prev") {
                newPageNo -= 1;
            } else if (pageChange === "skipNext") {
                newPageNo += 10;
            } else if (pageChange === "skipPrev") {
                newPageNo -= 10;
            }

            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            // if (dateFilter === true) {
                params.append('FromDate', fromDate || "13-jun-2023");
                params.append('ToDate', toDate || "13-jun-2025");
            // }
            debugger
            const res = await apiService.getApiParamater('GET_STOCK_TRANS_REPORT', `?${params.toString()}`);
            setRowData(res?.data?.result?.reverse() || []);
            setPagination(res.data.paginationData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const dateFormatter = (params) => {
        const date = moment.utc(params.value).local();
        return date.format("DD-MM-YYYY");
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    }
    const Comparison = [
        {
            headerName: 'Item',
            children: [
                { field: 'itemCode' },
                { field: 'itemDescription' }
            ]
        },
        // { headerName: 'Item Code', field: 'itemCode', filter: true, floatingFilter: true, width: 170 },
        // {
        //     headerName: 'Item Description', field: 'itemDescription', filter: true, floatingFilter: true, width: 170,
        // },
        { headerName: 'Trans Type', field: 'trans_Type', filter: true, floatingFilter: true, width: 170 },
        {
            headerName: 'Trans Date', field: 'trans_Date', filter: true, floatingFilter: true, width: 170, filter: 'agDateColumnFilter',
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Trans No', field: 'trans_No', filter: true, floatingFilter: true, width: 170 },
        { headerName: 'Unit Type', field: 'unitType', filter: true, floatingFilter: true, width: 170 },
        { headerName: 'Unit Price', field: 'unitPrice', filter: true, floatingFilter: true, width: 170 },
        { headerName: 'Amount', field: 'amount', filter: true, floatingFilter: true, width: 170 },
    ];
    const onBtnCSVExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: 'PurchaseOrderReport.csv'
        });
    }, []);
    const onBtPDFExport = useCallback(() => {
        const doc = new jsPDF();
        const rowData = [];
        const columnHeaders = [];
        const comparisonFields = [
            'recordId',
            'requisitionNo',
            'docDate',
            'vendorName',
            'terms',
            'status',
            'deliveryDate',
            'deliveryStatus',
            'itemCode',
            'itemDescription',
            'uom',
            'qty',
            'unitPrice',
        ];

        const translateStatus = (status) => {
            switch (status) {
                case 1:
                    return 'Approved';
                case 0:
                    return 'Pending';
                case 2:
                    return 'Hold';
                case 3:
                    return 'Rejected';
                default:
                    return status;
            }
        };

        comparisonFields.forEach(field => {
            const header = Comparison.find(col => col.field === field).headerName;
            columnHeaders.push(header);
        });

        gridRef.current.api.forEachNode((node) => {
            const row = [];
            comparisonFields.forEach(field => {
                let value = node.data[field];
                if (field === 'status') {
                    value = translateStatus(value);
                }
                row.push(value);
            });
            rowData.push(row);
        });

        const poppinsFont = 'data:font/ttf;base64,AAEAAAARAQAABAAw7HA...';
        doc.addFileToVFS("Poppins-Regular.ttf", poppinsFont);
        doc.addFont("Poppins-Regular.ttf", "Poppins", "normal");
        doc.setFont("Poppins");
        doc.setFontSize(18);
        doc.setTextColor(40, 40, 40);
        doc.text("Purchase Order Report", 72, 12);
        doc.text("__________________________", 60, 15);
        doc.autoTable({
            head: [columnHeaders],
            body: rowData,
            styles: {
                fontSize: 5,
            },
            headStyles: {
                fillColor: [209, 126, 6],
                textColor: [255, 255, 255],
                fontSize: 5,
            },
            columnStyles: {
                0: { cellWidth: 15 },
                1: { cellWidth: 15 },
                2: { cellWidth: 15 },
                3: { cellWidth: 15 },
                4: { cellWidth: 15 },
                5: { cellWidth: 15 },
                6: { cellWidth: 15 },
                7: { cellWidth: 20 },
                8: { cellWidth: 15 },
                9: { cellWidth: 15 },
                10: { cellWidth: 10 },
                11: { cellWidth: 10 },
                12: { cellWidth: 10 },
            },
            startY: 20
        });

        doc.save('PurchaseOrderReport.pdf');
    }, []);
    const handlePageSizeChange = (event) => {
        const newSize = Number(event.target.value);
        setPageSize(newSize);
    };
    const handleItemChange = (selectedOption) => {
        setFormData({
            ...formData,
            itemNo: selectedOption ? { label: selectedOption.value.itemNo, value: selectedOption.value } : null,
            ItemDescription: selectedOption ? { label: selectedOption.value.itemDescription, value: selectedOption.value } : null,
        });
    }
    const handleCategoryChange = (selectedOption) => {
        setFormData({ ...formData, category: selectedOption });
    }
    const handleFromDate = (e) => {
        setFromDate(e.target.value);
    };
    const handleToDate = (e) => {
        setToDate(e.target.value);
    };
    const handleDateFilterSelection = (e) => {
        setdateFilter(e);
    };
    const handlelocChange = (selectedOption) => {
        setFormData({ ...formData, location: selectedOption });
    };
    return (
        <div className='p-5'>
            <div className='col  start'>
                <h4 className='start'>
                    Store Transection Report
                </h4>
            </div>
            <div className='pb-4'>
                <div className='d-flex gap-3 my-3'>
                    <div className='col'>
                        <Select
                            value={formData?.category}
                            onChange={(selectedOption) => handleCategoryChange(selectedOption)}
                            options={categoryOptions?.map((CatOpt) => ({ value: CatOpt, label: CatOpt?.catName }))}
                        />
                    </div>
                    <div className='col'>
                        <Select
                            value={formData.itemNo}
                            onChange={(selectedOption) => handleItemChange(selectedOption)}
                            options={Items?.map((itm) => ({ value: itm, label: itm?.itemNo }))}
                        />
                    </div>
                    <div className='col'>
                        <Select
                            value={formData.ItemDescription}
                            onChange={(selectedOption) => handleItemChange(selectedOption)}
                            options={Items?.map((itm) => ({ value: itm, label: itm?.itemDescription }))}
                        />
                    </div>
                    <div className='col'>
                        <Select
                            value={formData?.location}
                            onChange={(selectedOption) => handlelocChange(selectedOption)}
                            options={Location?.map((loc) => ({ value: loc, label: loc?.description }))}
                        />
                    </div>
                    <div className='col-4 d-flex gap-3'>
                        <div >
                            <input
                                type='date'
                                className="form-control"
                                value={fromDate}
                                name="fromDate"
                                disabled={!dateFilter}
                                onChange={handleFromDate}
                                placeholder='From  Date '
                            />
                        </div>
                        <div className=''>
                            <input
                                type='date'
                                disabled={!dateFilter}
                                className="form-control"
                                placeholder='To  Date '
                                value={toDate}
                                name="toDate"
                                onChange={handleToDate}
                            />
                        </div>
                        <div className='gap-3 center'>
                            <input className="form-check-input"
                                type="checkbox"
                                id="dateFilter" style={{ width: "26px", height: "26px" }}
                                name="dateFilter"
                                checked={dateFilter}
                                onChange={(e) => handleDateFilterSelection(e.target.checked)} />
                            <label className=''>Date Filter</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className='pageBodr'>
                <div className='row'>
                    <div className='col-6 d-flex gap-5'>
                        <div className='arrows d-flex gap-5'>
                            {pagination?.totalPages < 10 ?
                                <div className='center'><RiSkipLeftFill size={30} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><RiSkipLeftFill size={30} onClick={() => getItems("skipPrev")} /></div>
                            }
                            {pagination?.currentPage === 1 ?
                                <div className='center'><FaAngleLeft size={25} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><FaAngleLeft size={25} onClick={() => getItems("prev")} /></div>
                            }
                            <div className='d-flex gap-3 center'>
                                <div className=''>{pagination?.currentPage || "0"}</div>
                                <div className=''>of</div>
                                <div className=''>{pagination?.totalPages || "0"}</div>
                            </div>
                            {pagination?.currentPage === pagination?.totalPages ?
                                <div className='center'><FaAngleRight size={25} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><FaAngleRight size={25} onClick={() => getItems("next")} /></div>
                            }
                            {pagination?.totalPages > 10 ?
                                <div className='center'><RiSkipRightFill size={30} onClick={() => getItems("skipNext")} /></div> :
                                <div className='center'><RiSkipRightFill size={30} style={{ color: "#ABA3A3" }} /></div>
                            }
                        </div>
                        <div className="gap-4 d-flex">
                            <div className='center'>Page Size</div>
                            <div>
                                <select
                                    className="form-select pagclr"
                                    aria-label="Default select example"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                >
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 '>
                        <div className='end arrows d-flex gap-4'>
                            <div className='center' onClick={handleClearFilters}><SlRefresh size={30} style={{ color: "5B93FF" }} /></div>
                            <div className='center'>
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                                        <PiDownloadSimple size={30} style={{ color: "5B93FF" }} />
                                    </DropdownToggle>
                                    <DropdownMenu className='mt-3'>
                                        <DropdownItem onClick={onBtnCSVExport} className='gap-3 d-flex center'><BsFiletypeCsv /><div>Export CSV</div></DropdownItem>
                                        {/* <DropdownItem onClick={onBtExcelExport} className='gap-3 d-flex center'><LiaFileExcel /><div>Export Excel</div></DropdownItem> */}
                                        <DropdownItem onClick={onBtPDFExport} className='gap-3 d-flex center'><BsFileEarmarkPdf /><div>Export PDF</div></DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <div className='center'><HiOutlinePrinter size={30} style={{ color: "5B93FF" }} /></div>
                            <div className='center'><TfiEmail size={28} style={{ color: "5B93FF" }} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='brdor'>
                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        rowSelection={'single'}
                        columnDefs={Comparison}
                        suppressExcelExport={true}
                        ref={gridRef}
                    />
                </div>
            </div>
        </div>
    );
};
export default StoreTransectionReport; 