import React, { useState, useEffect, Component, useRef } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { FaEdit, FaEye } from "react-icons/fa";
import { MdOutlineEditOff } from "react-icons/md";

const PackingGrid = ({ rowData, fetchPackingData, canEditMaster }) => {
    const gridRef = useRef();
    let navigate = useNavigate();
    const [selectedRowData, setSelectedRowData] = useState(null);

    const handleEdit = (data) => {
        navigate("/inventory/Master/Packing", { state: data })
    };
    const columnDefs = [
        { headerName: 'Code', field: 'type', filter: true, floatingFilter: true, editable: true, minWidth: 550 },
        { headerName: 'Packing Type Name', field: 'description', filter: true, floatingFilter: true, minWidth: 550, editable: true },
        {
            headerName: 'Status', field: 'recordId', cellRenderer: BtnCellRenderer, minWidth: 460,
            cellRendererParams: {
                canEditMaster: canEditMaster,
                handleEdit: handleEdit,
                clicked: function (field, isActive) {
                    updateActiveStatus(field, isActive);
                },
            },
        }
    ];
    const updateActiveStatus = async (id, isActive) => {
        try {
            const apiService = new ApiService();
            let body = {
                recordId: id,
                isActive: isActive
            }
            const res = await apiService.put(`UPDATE_EPACKING`, body);
            if (res?.status === 200) {
                console.log('Updated successfully');
                fetchPackingData();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    const actionsRenderer = (props) => {
        return (
            <div>
                <Button variant="primary" onClick={() => handleEditRow(props.data)}>Edit</Button>
            </div>
        );
    };
    const handleEditRow = (rowData) => {
        setSelectedRowData(rowData);
    };
    return (
        <div className='brdor mt-3'>
            <div className="ag-theme-quartz" style={{ height: 500 }}>
                <AgGridReact
                    rowData={rowData}
                    ref={gridRef}
                    enableFilter={true}
                    floatingFilter={true}
                    rowSelection={'single'}
                    columnDefs={columnDefs}
                    pagination={false}
                    frameworkComponents={{ actionsRenderer }}
                />
            </div>
        </div>
    );
};

class BtnCellRenderer extends Component {

    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this); // Bind edit button handler
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data); // Pass rowData to handleEdit function
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value, !this.props.data.isActive); // Toggle isActive value
    }

    render() {
        const { isActive } = this.props.data;
        const { canEditMaster } = this.props;

        return (
            <div className='d-flex gap-3'>
                {canEditMaster === true ?
                    <div onClick={this.btnEditClickedHandler}><FaEdit size={25} /></div> :
                    <div><MdOutlineEditOff size={25} /></div>
                }
                {canEditMaster === true ?
                    <div className="form-check form-switch center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive}
                            onClick={this.btnClickedHandler}
                        />
                    </div>
                    :
                    <div className="form-check form-switch center">
                        <input disabled
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default PackingGrid;
