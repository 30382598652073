import React, { useEffect, useState, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { ReactComponent as Edit } from '../../Assets/svg/edit.svg';

const RoleGrid = ({ fetchRoleData, rowData }) => {

    useEffect(() => {
        fetchRoleData();
    }, []);


    const [colDefs, setColDefs] = useState([
        { headerName: "Role Code", field: "description", filter: true, floatingFilter: true, minWidth: 620 },
        { headerName: "Role Name", field: "description_BI", filter: true, floatingFilter: true, minWidth: 620 },
        {
            headerName: "Action", field: "id", filter: true, floatingFilter: true, minWidth: 320,
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                clicked: function (field) {
                    
                    // handleShow(field)
                },
            },
        },
    ])
    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };
    return (
        <div className=''>
            <div className="ag-theme-quartz" style={{ height: 700, backgroundColor: "#6E5199" }}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={colDefs}
                    enableFilter={true}
                    floatingFilter={true}
                    rowSelection="multiple"
                    pagination={true}
                    frameworkComponents={frameworkComponents}
                    suppressRowClickSelection={true}
                />
            </div>
        </div>
    )
}
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }

    render() {
        return (
            <div className='d-flex gap-3'>
                <div><Edit /></div>
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`flexSwitchCheck`}
                        checked={this.props}
                        // onChange={(e) => btnClickedHandler(val, e.target.checked)}
                        onClick={this.btnClickedHandler}
                    />
                </div>
            </div>
            // <button onClick={this.btnClickedHandler} className="gridbtn">Delete</button>
        )
    }
}
export default RoleGrid;