import React, { useState, useEffect } from 'react';
import "../landingpage.css"
import "./notFound.css"
import { RiArrowGoBackFill } from "react-icons/ri";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { redirect } from "react-router-dom";
import { getRole } from '../../../Redux/selecters';

const NotFoundPage = () => {

    const userRole = useSelector(getRole);
    let navigate = useNavigate();

    useEffect(() => {
        loader()
    }, []);

    const handleBack = () => {
        if (userRole == 'Admin') {
            navigate('/home');
        } else if (userRole == 'Cashier') {
            navigate('/Cashier/ShiftOpen');
        }
        else if (userRole == 'Customer') {
            navigate('/customer/menu');
        }
        else {
            navigate('/customer/menu');
        }
    };
    const loader = async () => {
        if (userRole === "Admin") {
            navigate("/home");
        } else if (userRole === "Cashier") {
            navigate("/cashier/home");
        } else if (userRole === "Kitchen") {
            navigate("/Kitchen/Orders");
        } else {
            navigate("/");
        }
    };
    return (
        <div className="m-0 p-0">
            {/* <div className='p-5'>
                <div className='p-5 mt-5' >
                    <div className='p-5 mt-5'>
                        <div className='px-5 pt-5'>
                            <div className='pt-5'>
                                <div className='pt-5'>
                                    <div className='pt-5'>
                                        <button className='addItem-btn center gap-3' type="submit" onClick={handleBack}><RiArrowGoBackFill /> Back to home</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default NotFoundPage;
