import React from "react";
import { Link } from 'react-router-dom';
import './table.css';
import { FiUser } from "react-icons/fi";
import { AiOutlineTag } from "react-icons/ai";
import { BsBell } from "react-icons/bs";
import { BsQuestionCircle } from 'react-icons/bs';

const NavBox = ({ id }) => {
    return (
        <div className="p-lg-5">
            <div className="topnav main-BG">
                <div className="container">
                    <div className="nav nav-pills  p-2 d-flex justify-content-between">
                        <Link to="/Cashier/Table" className={`dashitems sidebar-text center ${id === "Table" ? "active" : ""}`}>
                            Tables</Link>
                        <Link to="/Cashier/Reservation" className={`dashitems sidebar-text center ${id === "Reservation" ? "active" : ""}`}>
                            Reserved</Link>
                        <Link to="/Cashier/NewReservation" className={`dashitems sidebar-text center ${id === "NewReservation" ? "active" : ""}`}>
                            New Reservation</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NavBox;
