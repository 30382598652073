import { React, useEffect, useState } from 'react';
import CostumerHeader from '../Header/header';
import "../Menu/costumer.css";
import Select from 'react-select';
import { toast } from 'react-toastify';
import ApiService from '../../services/apiService';

const TirdParty = () => {
    const [Summary, setSummary] = useState(''); // Setting the default value to 'UberEats'
    const [thirdparty, setThirdparty] = useState([]);
    useEffect(() => {
        GetThirdParty()
    }, [])
    const SummaryFilter = [
        { value: '2', label: 'Uber Eats' },
        { value: '1', label: 'Talabat' },
        { value: '3', label: 'Food Noon' },
        { value: '4', label: 'Deliveroo' },
    ];
    const handleTypeSelect = (selectedOption) => {
        setSummary(selectedOption.value);
    };
    const GetThirdParty = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get("GET_DELIVERY");
            setThirdparty(res.data.result);
        } catch (error) {
            console.error('Error fetching menu data:', error);
        }
    };
    const handlePayment = async () => {
        try {
            const currentDatetime = new Date().toISOString();
            const datareq = {
                // orderDate: "2024-02-11T11:59:53.363",
                orderDate: currentDatetime,
                orderType: 2,
                orderSource: Summary?.id,
                orderStatus: 1,
                orderAmount: 945,
                calculatedTax: 5,
                totalDiscount: 10,
                amountCollected: 945,
                amountReturned: 0,
                customerType: 4, // Update as needed 
                // customerId: "63ffc437-7e9e-4e8a-874e-d1d2eb03c846", // Update as needed
                customerId: "2a188e63-eb3d-4723-bdd8-094b5d881f8e", // Update as needed
                customerName: "yasir khan", // Update as needed
                paymentStatus: 2, // Update as needed
                invoiceNumber: "65651651", // Update as needed
                orderItems: [
                    {
                        itemId: parseInt(139), // Update as needed
                        quantity: parseInt(1),
                        kitchenInstructions: "keep spicy",
                        itemIndex: 0,
                    },
                    //  {
                    //     itemId: parseInt(2), // Update as needed
                    //     quantity: parseInt(1),
                    //     kitchenInstructions: "keep spicy",
                    //     itemIndex: 0,
                    // },
                ],
                orderItemsMeta: [
                    // {
                    //     metaItemId: parseInt(2),
                    //     menuItemId: parseInt(2),
                    //     type: parseInt(4),
                    //     metaItemIndex: 0
                    // },
                    {
                        metaItemId: parseInt(2),
                        menuItemId: parseInt(139),
                        type: parseInt(3),
                        metaItemIndex: 1
                    }],
                loggedInUserId: "Guest",
            };

            const apiService = new ApiService();

            let res = await apiService.post("CREATE_ORDER", datareq);
            if (res?.data?.statusCode === 201) {
                // Show success toast
                window.location.reload(false);
                toast.success("Order Created Successfully");
            }
            else {
                toast.warning('Waiting for Order');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="">
            <CostumerHeader />
            <div className='p-lg-5'>
                <div className='row'>
                    <div className="col-lg-5">
                        <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                            options={thirdparty?.map((thirdparty) => ({ value: thirdparty, label: thirdparty?.name }))}
                            onChange={handleTypeSelect}
                        />
                    </div>
                    <div className="col-lg-5 center">
                        <button className='btn btn-primary' onClick={handlePayment}>Submit Order</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TirdParty;
