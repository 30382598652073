import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Edit } from '../../Assets/svg/edit.svg';
import { toast } from 'react-toastify';
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import { hasPermission } from "../../services/roleService"
import Pagination from '../pagination/pagination';

const AdditionalContact = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    let navigate = useNavigate();
    const [glAccount, setGlAccount] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();

    useEffect(() => {
        getItems();
    }, []);

    useEffect(() => {
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
        if (pageSize) {
            getItems();
        }
    }, [pageSize]);

    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    const canEditMaster = checkPermission('Supplier', 'edit');
    const canCreateMaster = checkPermission('Supplier', 'create');

    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
           params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const response = await apiService.getApiParamater('GET_SUPPLIER', `?${params.toString()}`);
            setPagination(response.data.paginationData);
            setRowData(response?.data?.result || []);
        } catch (error) {
        }
    }
    const columnDefs = [
        { headerName: 'Name', field: 'accountName', filter: true, floatingFilter: true, minWidth: 150 },
        { headerName: 'Contact No 1', field: 'cP1ContactNo1', filter: true, floatingFilter: true, minWidth: 150 },
        { headerName: 'Contact No 2', field: 'cP1ContactNo2', filter: true, floatingFilter: true, minWidth: 150 },
        { headerName: 'Phone 1', field: 'phoneNo1', filter: true, floatingFilter: true, minWidth: 150 },
        { headerName: 'Phone 2', field: 'phoneNo2', filter: true, floatingFilter: true, minWidth: 150 },
        { headerName: 'Mobile No', field: 'mobileNo1', filter: true, floatingFilter: true, minWidth: 150 },
        { headerName: 'Fax No', field: 'faxNo1', filter: true, floatingFilter: true, minWidth: 150 },
        { headerName: 'Email', field: 'email1', filter: true, floatingFilter: true, minWidth: 150 },
        { headerName: 'Email', field: 'email2', filter: true, floatingFilter: true, minWidth: 150 },
        {
            headerName: 'Action', field: 'supplier_ID', filter: true, floatingFilter: true, minWidth: 150,
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                clicked: function (field) {

                    // handleShow(field)
                },
            },
        },

    ];
    const [formData, setFormData] = useState({
        Name: "",
        Phone1: "",
        FaxNo: "",
        ContactNo1: "",
        Phone2: "",
        Email: "",
        Email1: "",
        ContactNo2: "",
        MobileNo: "",
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if ((name === 'Phone1' || name === 'ContactNo1' || name === 'Phone2' || name === 'ContactNo2' || name === 'MobileNo') && value.length > 16) {
            return;
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };
    const handleKeyPress = (event) => {
        if (['e', 'E', '+', '-', 'E', 'e'].includes(event.key) || /[a-zA-Z]/.test(event.key)) {
            toast.error('Please enter only numbers.');
            event.preventDefault();
        }
    };
    const handlePaste = (event) => {
        const clipboardData = event.clipboardData.getData('Text');
        if (/[a-zA-Z]/.test(clipboardData) || ['e', 'E', '+', '-'].some(char => clipboardData.includes(char))) {
            toast.error('Please enter only numbers.');
            event.preventDefault();
        }
    };
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const totalPageNumbers = Math.min(pagination?.totalPages, 4);
        let startPage = Math.max(pagination?.currentPage - Math.floor(totalPageNumbers / 2), 1);
        let endPage = startPage + totalPageNumbers - 1;

        if (endPage > pagination?.totalPages) {
            endPage = pagination?.totalPages;
            startPage = endPage - totalPageNumbers + 1;
            if (startPage < 1) {
                startPage = 1;
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers?.push(
                <div key={i} className={`${pagination?.currentPage === i ? "pActive" : "pagen"}`}>
                    {i}
                </div>
            );
        }
        return pageNumbers;
    }
    const handlePageSizeChange = (event) => {
        const newSize = Number(event.target.value);
        setPageSize(newSize);
    };

    return (
        <div className='pt-2'>
            <div className=''>
                {/* <h5 className='col mainInvHead'>Additional Details</h5> */}
                <div className='d-flex justify-content-end gap-3'>
                    <div className=''>
                        <>{canCreateMaster === true ?
                            <button className='mainbtn ' type="submit">
                                <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                            :
                            <button className='mainbtn ' type="submit" style={{ cursor: "not-allowed" }}>
                                <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                        } </>
                    </div>
                    <button className='mainbtnRed'><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setGlAccount(!glAccount)}>
                    <div className='h6 pt-1'>Additional Details</div>
                </div>
                <div className={`slide-content ${glAccount ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row  pb-3">
                            <div className="col-md-4 col-lg-4 d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-1 start">Name</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="Name"
                                    value={formData?.Name}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className=" col-md-4 col-lg-4 d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-1 start">Phone 1</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="Phone1"
                                    value={formData?.Phone1}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className=" col-md-4 col-lg-4 d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-1 start">Fax No</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="FaxNo"
                                    value={formData?.FaxNo}
                                    onChange={handleInputChange}
                                    onKeyPress={handleKeyPress}
                                    onPaste={handlePaste}
                                />
                            </div>
                        </div>
                        <div className="form-group row  pb-3">
                            <div className="col-md-4 col-lg-4 d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-1 start">Contact  No 1</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="ContactNo1"
                                    value={formData?.ContactNo1}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className=" col-md-4 col-lg-4 d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-1 start">Phone 2</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="Phone2"
                                    value={formData?.Phone2}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className=" col-md-4 col-lg-4 d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-1 start">Email</label>
                                <input
                                    type="email"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="Email"
                                    value={formData?.Email}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-4 col-lg-4 d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-1 start">Contact No 2</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="ContactNo2"
                                    value={formData?.ContactNo2}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className=" col-md-4 col-lg-4 d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-1 start">Mobile No</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="MobileNo"
                                    value={formData?.MobileNo}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className=" col-md-4 col-lg-4 d-flex justify-content-between">
                                <label htmlFor="validationCustom01" className="form-label  pt-1 start">Email</label>
                                <input
                                    type="email"
                                    className="form-Input w-70"
                                    disabled={canCreateMaster === true ? false : true}
                                    id="validationCustom01"
                                    name="Email1"
                                    value={formData?.Email1}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='brdor' style={{ height: 550 }}>
                <div className="ag-theme-quartz" style={{ height: 550 }}>
                    <AgGridReact
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        columnDefs={columnDefs}
                        pagination={false}
                        suppressRowClickSelection={true}
                        frameworkComponents={frameworkComponents}
                    />
                </div>
            </div>
            <Pagination pagination={pagination} getItems={getItems} />
        </div>
    );
};
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }

    render() {
        return (
            <div className='d-flex gap-3'>
                <div><Edit /></div>
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`flexSwitchCheck`}
                        checked={this.props}
                        // onChange={(e) => btnClickedHandler(val, e.target.checked)}
                        onClick={this.btnClickedHandler}
                    />
                </div>
            </div>
            // <button onClick={this.btnClickedHandler} className="gridbtn">Delete</button>
        )
    }
}
export default AdditionalContact;
