import React from "react";
import { Link } from 'react-router-dom';
import '../supplier/supplier.css'

const PriceCompNav = ({ id }) => {

    return (
        <div className="p-lg-4">
            <div className="topnav maon-BG">
                <div className="container">
                    <div className="nav nav-pills overslid d-flex justify-content-between">
                        <Link to="/Inventory/PriceComparison/price" className={`dashitems sidebar-text center  ${id === "price" ? "sideActive" : ""}`}>
                            Price Comparison</Link>
                        <Link to="/Inventory/PriceComparison/authorization" className={`dashitems sidebar-text center ${id === "authorization" ? "sideActive" : ""}`}>
                            Authorization</Link>
                        <Link to="/Inventory/PriceComparison/message" className={`dashitems sidebar-text center ${id == "message" ? "sideActive" : ""}`}>
                            Message</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PriceCompNav; 