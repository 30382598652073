

import React, { useEffect, useState } from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "./progress.css";
import canncel from "../../../../../assets/images/Order-Canceled.png"
const Progress = ({ val }) => {
    const [currentStep, setCurrentStep] = useState(0);

    const orderStatusToStep = {
        "New Order": 0,
        Accepted: 1,
        "Ready For Delivery": 4 || 3,
        Completed: 6,
    };

    useEffect(() => {
        let intervalId;

        const fetchOrderStatus = async () => {
            try {
                setCurrentStep(val?.orderStatus);

            } catch (error) {
                console.error("Error fetching order status:", error);
            }
        };

        fetchOrderStatus();

        intervalId = setInterval(fetchOrderStatus, 10000);

        return () => clearInterval(intervalId);
    }, [val?.orderStatus]);

    if (val?.orderStatus === 7) {
        return (
            <div className="center">
                <div>
                    <img src={canncel} className="img-fluid iconimg" />
                    <p className="itmmain pt-3 center">Order Canceled</p>
                </div>
            </div>
        );
    }

    return (
        <ProgressBar
            percent={(currentStep / Object.keys(orderStatusToStep).length) * 100}
            filledBackground="linear-gradient(to right, #6E5199, #6E5199)"
        >
            {Object.keys(orderStatusToStep).map((status, index) => (
                <Step key={index} transition="scale">
                    {({ accomplished }) => (
                        <div className="txtali">
                            <div className="maintxt">
                                {status === "InProgress"
                                    ? "In Progress"
                                    : status === "Completed"
                                        ? "Completed"
                                        : status}
                            </div>
                            <div className="py-2">
                                <div className={`circle ${currentStep >= index ? 'circleActive' : ''}`}></div>
                            </div>
                            <div className="dottim">. </div>
                        </div>
                    )}
                </Step>
            ))}
        </ProgressBar>
    );
};

export default Progress;