import { React, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import AdminHeader from '../header/adminHeader';
import ImageUpload from '../menus/addItems/imagUpload';
import ApiService from '../../../services/apiService';
import { toast } from 'react-toastify';
import userManagement from '../../../services/userManagement';
import Select from 'react-select';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUserId } from '../../../../Redux/selecters';

const CreateBranch = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const branchToEdit = location.state?.branch;
    const [restaurant, setRestaurant] = useState([]);
    const [counters, setCounters] = useState([]);
    const userId = useSelector(getUserId);

    useEffect(() => {
        GetRestaurant();
        GetCounters();
        debugger
        if (branchToEdit) {
            debugger
            setFormData({
                address: branchToEdit.address,
                branches: branchToEdit.branchName,
                contactNumber: branchToEdit.contactNo,
                branchTiming: moment(branchToEdit.branchOpenTime).format("YYYY-MM-DDTHH:mm:ss"),
                close: moment(branchToEdit.branchCloseTime).format("YYYY-MM-DDTHH:mm:ss"),
                restaurant: branchToEdit.restaurantId,
                selectedCounters: branchToEdit?.counters?.map(counter => ({ value: counter.counterId, label: counter.name }))
            });
        }
    }, [branchToEdit]);

    const [formData, setFormData] = useState({
        address: "",
        branches: "",
        contactNumber: "",
        branchTiming: "",
        close: "",
        restaurant: "",
        selectedCounters: []
    });

    const handleSubmit = async () => {
        try {
            debugger
            if (formData?.selectedCounters?.length > 0) {

                const apiService = new ApiService();
                let body = {
                    restaurantId: formData?.restaurant,
                    branchName: formData?.branches,
                    branchOpenTime: moment.utc(formData?.branchTiming).format("YYYY-MM-DDTHH:mm:ss.SSS"),
                    branchCloseTime: moment.utc(formData?.close).format("YYYY-MM-DDTHH:mm:ss.SSS"),
                    contactNo: formData?.contactNumber,
                    address: formData?.address,
                    loggedInUserId: userId,
                    counters: formData.selectedCounters.map(counter => ({ counterId: counter.value })),
                    isActive:true
                };

                if (branchToEdit) {
                    body.id = branchToEdit.id;
                    let res = await apiService.put('UPDATE_BRANCH', body);
                    if (res?.data?.statusCode === 200 || res?.data?.statusCode === 201) {
                        toast.success("Branch Updated Successfully");
                        navigate("/admin/Restaurant");
                    }
                } else {
                    let res = await apiService.post('CREATE_BRANCH', body);
                    if (res?.data?.statusCode === 201) {
                        toast.success("Branch Created Successfully");
                        navigate("/admin/Restaurant");
                    }
                }
            }
            else {
                toast.warning("Please select counter")
            }
        } catch (error) {
            console.error('Error saving Branch:', error);
        }
    };

    const GetRestaurant = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get('GET_RESTAURANT');
            let filtered = res.data.result.filter(res => res?.isActive == true);
            setRestaurant(filtered);
        } catch (error) {
            console.error('Error fetching Restaurant data:', error);
        }
    };

    const GetCounters = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get('GET_COUNTER');
            setCounters(res?.data?.result);
        } catch (error) {
            console.error('Error fetching counters:', error);
        }
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleRestaurantChange = (selectedOption) => {
        if (selectedOption) {
            setFormData({ ...formData, restaurant: selectedOption.value.id });
        } else {
            setFormData({ ...formData, restaurant: '' });
        }
    };

    const handleCounterChange = (selectedOptions) => {
        setFormData({ ...formData, selectedCounters: selectedOptions });
    };

    return (
        <div className="container">
            <div className='p-5'>
                <div className="form-group row mt-4 justify-content-between">
                    <div className="col-md-5 col-lg-5">
                        <label htmlFor="validationCustom01" className="form-label">Restaurant Name</label>
                        <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                            value={restaurant.find(r => r.id === formData.restaurant) ? { value: formData.restaurant, label: restaurant.find(r => r.id === formData.restaurant).name } : null}
                            onChange={handleRestaurantChange}
                            options={restaurant?.map((restaurant) => ({ value: restaurant, label: restaurant.name }))}
                            isClearable
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div className="col-md-5">
                        <label htmlFor="validationCustom02" className="form-label">Branch</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom02"
                            name="branches"
                            value={formData?.branches}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                </div>
                <div className="form-group row mt-4 justify-content-between">
                    <div className="col-md-5 col-lg-5">
                        <label htmlFor="validationCustom07" className="form-label">Counters</label>
                        <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                            isMulti
                            value={formData.selectedCounters}
                            onChange={handleCounterChange}
                            options={counters?.map(counter => ({ value: counter.id, label: counter.name }))}
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-5">
                        <label htmlFor="validationCustom03" className="form-label">Contact Number</label>
                        <input
                            type="number"
                            className="p-2 form-control"
                            id="validationCustom03"
                            name="contactNumber"
                            value={formData.contactNumber}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                </div>
                <div className="form-group row mt-4 justify-content-between">
                    <div className="col-md-5 col-lg-5">
                        <label htmlFor="validationCustom05" className="form-label">Opening Time</label>
                        <input
                            type="dateTime-local"
                            className="p-2 form-control"
                            id="validationCustom05"
                            name="branchTiming"
                            value={formData.branchTiming}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div className="col-md-5">
                        <label htmlFor="validationCustom06" className="form-label">Closing Time</label>
                        <input
                            type="dateTime-local"
                            className="p-2 form-control"
                            id="validationCustom06"
                            name="close"
                            value={formData?.close}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                </div>
                <div className="form-group row mt-4 justify-content-between py-3">
                    <div className="col-md-5 col-lg-5">
                        <label htmlFor="validationCustom03" className="form-label">Address</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom03"
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='end'>
                        <button className='addItem-btn' onClick={handleSubmit}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateBranch;
