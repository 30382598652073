import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import MessageGrid from '../../purchaseRequisition/messageGrid';
import ApiService2 from '../../../../POS/services/apiService';
import ApiService from '../../../services/apiService';
import ItemGridGRN from './itemGrid';
import { toast } from 'react-toastify';
import AuthorizationGrn from '../authorization';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserName } from '../../../../Redux/selecters';
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa6";

const CreateGrn = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const apiService = new ApiService();
    const [messageData, setMessageData] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [ItemData, setItemData] = useState([]);
    const [POData, setPOData] = useState([]);
    const [selectedPOData, setSelectedPOData] = useState([]);
    const [supplier, setSupplier] = useState([]);
    const [usersDetails, setUser] = useState([]);
    const [Department, setDepartment] = useState([]);
    const [WareHouse, setWareHouse] = useState([]);
    const [PurchaseOrder, setPurchaseOrder] = useState([]);
    const [POlist, setPOlist] = useState([]);
    const [SelectedPO, setSelectedPO] = useState([]);
    const [SelectedSupplier, setSelectedSupplier] = useState([]);
    const [apiData, setApiData] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [grnNo, setGrnNumber] = useState([]);
    const [currency, setCurrency] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const itemData = location.state;
    const [Userdata, setUserdata] = useState();
    const userName = useSelector(getUserName);
    const [PurchaseTab, setPurchaseTab] = useState(true);
    const [CostTab, setCostTab] = useState(true);

    const formatDateToDatetimeLocal = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    };
    useEffect(() => {
        fetchData()
        getApprovalUser()
        getCurrency()
    }, [])
    useEffect(() => {
        const item = location.state;
        const selectedOption = POlist?.find(po => po?.pOrderNo === item?.pOrderNo);
        const selectedWareHouse = WareHouse?.find(po => po?.description === item?.warehouse);
        setSelectedPO({ label: selectedOption?.pOrderNo, value: selectedOption })
        setSelectedSupplier({ label: selectedOption?.vendorName, value: selectedOption })
        setSelectedPOData(selectedOption)
        setPOData(item?.grnDetails)
        const formattedDate = selectedOption?.docDate ? formatDateToDatetimeLocal(selectedOption?.docDate) : getCurrentLocalDatetime();
        setFormData({
            GRNNO: item?.grnNo,
            remarks: item?.remarks,
            InvoiceNo: item?.invoiceNo,
            InvoiceDate: item?.invoiceDate || getCurrentLocalDatetime(),
            deliveryDate: item?.delivery_Date || getCurrentLocalDatetime(),
            Department: item?.department,
            PurchaseOrderDate: formattedDate || getCurrentLocalDatetime(),
            location: { label: selectedWareHouse?.description, value: selectedWareHouse }
        });

    }, [POlist, location.state, WareHouse]);
    const fetchData = async () => {
        const Supplier = await apiService.get("GET_SUPPLIER");
        setSupplier(Supplier?.data?.result);
        const department = await apiService.get("GET_DEPARTMENT_MASTER");
        setDepartment(department?.data?.result);
        const WareHouse = await apiService.get("GET_WAREHOUSE_MASTER");
        setWareHouse(WareHouse?.data?.result);
        const PurchaseOrder = await apiService.get("GET_PO");
        setPOlist(PurchaseOrder.data.result)
        let PO = await apiService.get('GET_PO_LIST')
        setPurchaseOrder(PO?.data.result);
    }
    const getApprovalUser = async () => {
        try {
            const apiService2 = new ApiService2()
            const apiService = new ApiService();
            let docParam = `?TypeOfDoc=GRN&DocId=GRN`
            let DocId = await apiService.getApiParamater('GET_DOC_ID', docParam)
            if (itemData?.eye === true) {
                setGrnNumber(itemData.grnNo)
            } else {
                setGrnNumber(DocId?.data.result[0].docId);
            }
            const res = await apiService2.get('GET_USERS');
            const selecteduser = res?.data?.result?.find(dep => dep.name === userName)
            setUserdata(selecteduser)
            let param = `?Type=${"GRN"}&Company=${selecteduser.company}&Department=IT`;
            const response = await apiService.getApiParamater('GET_APPROVAL_USER_LIST', param);
            if (response?.data?.result && response.data.result.length > 0) {
                setUser(response.data.result);
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')

    };
    const handleRequisitionData = (data) => {
        setItemData(data);
        console.log(data, 'reqData')
    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    };
    const [formData, setFormData] = useState({
        GRNNO: "",
        deliveryNote: "",
        remarks: "",
        InvoiceNo: "",
        InvoiceDate: getCurrentLocalDatetime(),
        deliveryDate: getCurrentLocalDatetime(),
        TaxInvoice: "",
        costCenter1: "",
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        Currency: "",
        exchangeRate: "",
        deliveryPoint: "",
        PurchaseOrderDate: "",
        contactPerson: "",
        contatNo: "",
        taskCode: "",
        email: '',
        subcatname: "",
        location: []
    });
    const DeliveryType = [
        { value: 'E-Ticket', label: 'E-Ticket' },
        { value: 'Hand Delivery', label: 'Hand Delivery' },
    ];
    const handlePoChange = (selectedOption) => {
        const selectedpurchase = POlist?.find(po => po?.pOrderNo === selectedOption?.label);
        setSelectedPO(selectedOption);
        setSelectedSupplier({ label: selectedpurchase.vendorName, value: selectedpurchase })
        const formattedDate = selectedpurchase.docDate ? formatDateToDatetimeLocal(selectedpurchase.docDate) : getCurrentLocalDatetime();
        setFormData({
            ...formData,
            PurchaseOrderDate: formattedDate,
            costCenter1: { label: selectedpurchase.costCenter1, value: selectedpurchase.costCenter1 },
            costCenter2: { label: selectedpurchase.costCenter2, value: selectedpurchase.costCenter2 },
            costCenter3: { label: selectedpurchase.costCenter3, value: selectedpurchase.costCenter3 },
            costCenter4: { label: selectedpurchase.costCenter4, value: selectedpurchase.costCenter4 },
        });
        setSelectedPOData(selectedpurchase)
        fetchPOData(selectedOption)
        // setPOData(selectedpurchase?.poDetails)
    };
    const fetchPOData = async (selectedOption) => {
        let param = `?POrderNo=${selectedOption?.label}`
        const PO = await apiService.getApiParamater("GET_GRN_PO", param);
        if (PO.data.statusCode === 204) {
            toast.error("No Items In this Purchase Order")
        }
        setPOData(PO.data.result)
    }
    const handleDepartmentChange = (selectedOption) => {
        setFormData({ ...formData, Department: selectedOption?.description });
    }
    const handleLocationChange = (selectedOption) => {
        setFormData({ ...formData, location: selectedOption });
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'deliveryDate' || name === 'PurchaseOrderDate' || name === 'InvoiceDate') {
            const dateParts = e.target.value.split("-");
            if (dateParts[0].length > 4) {
                return;
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const CreateGRN = async () => {
        debugger
        if (ItemData.length === 0) {
            toast.warning("Please add items");
            return;
        }
        if (!formData?.location.label) {
            toast.warning("Select Location")
            return
        }
        if (!formData?.deliveryDate) {
            toast.warning("Select Delivery Date")
            return
        }
        if (!formData?.PurchaseOrderDate) {
            toast.warning("Select Purchase Order Date")
            return
        }
        if (!formData?.InvoiceDate) {
            toast.warning("Select Invoice Date")
            return
        }
        const missingQtyItem = ItemData.find(item => !item.qty);
        if (missingQtyItem) {
            toast.warning(`Please enter quantity for ${missingQtyItem.itemDescription}`);
            return;
        }
        if (!formData.remarks) {
            toast.warning("Enter remarks");
            return;
        }
        try {
            const totalAmount = ItemData.reduce((sum, row) => sum + (row.amount || 0), 0);
            const apiService = new ApiService()
            const GRNDetailsData = ItemData.map((item, index) => ({
                requisitionNo: item?.requisitionNo,
                srno: index + 1,
                itemCode: item?.ItemNo,
                itemDescription: item.itemDescription,
                uom: item.uom,
                qty: parseInt(item.qty),
                baseUOM: item?.uom,
                baseQty: parseInt(item.baseQty),
                specification: item.specification,
                convValue: parseInt(item?.convValue),
                unitPrice: parseInt(item.baseAmount),
                amount: item.amount,
                baseAmount: parseInt(item.amount),
                convToInvUOM: parseInt(item.convValue),
                // convToInvUOM: 1,
                importedQty: 0,
                vatPerc: item.vat,
                extraQty: 0,
                vatAmount: item.vatAmount,
                prodDate: getCurrentLocalDatetime(),
                expDate: item.expDate,
                isActive: true,
                grnNo: grnNo,
                returnQty: 0,
                invoicedQty: 0,
                vatCode: 1,
                vatDesc: item.vatDes,
                vatUnitPrice: item.vatAmount / item.qty,
                vatBaseAmount: item.vatAmount,
                vatInclusive: selectedPOData.costCenter5 === "Included" ? true : false,
                grossAmount: 0,
                addCharges: 0,
            }));

            const userData = {
                grnNo: grnNo,
                pOrderNo: selectedPOData?.pOrderNo,
                delivery_Date: selectedPOData?.deliveryDate || formData.InvoiceDate,
                status: 0,
                remarks: formData?.remarks,
                modifiedOn: getCurrentLocalDatetime(),
                modifiedBy: userName,
                terms: selectedPOData.terms,
                vendorId: SelectedSupplier?.value?.vendorId,
                vendorName: SelectedSupplier?.label,
                department: "IT",
                // department: formData?.Department,
                costCenter2: selectedPOData?.costCenter2,
                costCenter3: selectedPOData?.costCenter3,
                costCenter4: selectedPOData?.costCenter4,
                isActive: true,
                grnDetailsDtos: GRNDetailsData,
                warehouse: formData?.location.label,
                poType: "GRN",
                invoiceNo: formData.InvoiceNo,
                invoiceDate: formData.InvoiceDate,
                invoiceRef: formData.deliveryNote,
                currencyCode: SelectedCurrency.label,
                exchangeRate: SelectedCurrency?.value.conversionRate,
                amount: totalAmount,
                baseAmount: totalAmount,
                transport_Mode: "From PO",
                vatInclusive: selectedPOData.costCenter5 === "Included" ? true : false,
            };
            const response = await apiService.post("CREATE_GRN", userData);
            if (response?.data?.statusCode === 201) {
                const userData2 = {
                    docType: "GRN",
                    docId: grnNo,
                    department: "IT",
                    company: Userdata.company || "PI",
                };
                const docBody = {
                    docId: "GRN",
                    typeOfDoc: "GRN",
                };
                const response2 = await apiService.post("CREATE_DOC_AUTHORIZATION", userData2);
                const docRes = await apiService.put("UPDATE_DOC_ID", docBody);
                toast.success(`${grnNo} Created successfully`);
                navigate("/inventory/GRN/Listing")
            }
        } catch (error) {
            console.error("Error Creating PR:", error);
        }
    };
    const getCurrency = async () => {
        let param = `?isActive=true`
        const apiService = new ApiService()
        let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
        setCurrency(res?.data?.result)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        setFormData({ ...formData, exchangeRate: filteredDenominations.conversionRate });
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
    }

    const handleBackClick = () => {
        window.history.back();
    };
    const handleReset = () => {
        setFormData({
            GRNNO: "",
            deliveryNote: "",
            remarks: "",
            InvoiceNo: "",
            InvoiceDate: getCurrentLocalDatetime(),
            deliveryDate: getCurrentLocalDatetime(),
            TaxInvoice: "",
            costCenter1: "",
            costCenter2: "",
            costCenter3: "",
            costCenter4: "",
            Currency: "",
            exchangeRate: "",
            deliveryPoint: "",
            PurchaseOrderDate: "",
            contactPerson: "",
            contatNo: "",
            taskCode: "",
            email: '',
            subcatname: "",
            location: []
        });
    }
    return (
        <div className='p-4'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Goods Receipt Note</h5>
                <div className='d-flex justify-content-end gap-3'>
                    <div className='col center'>
                        {itemData?.eye === true ? " " :
                            <>
                                <button className='mainbtn ' onClick={CreateGRN} type="submit">
                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div>
                                </button>
                            </>}
                    </div>
                    {itemData?.eye === true ? " " :
                        <button className='mainbtnRed' onClick={() => handleReset()}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                    }
                    <button onClick={handleBackClick} className='mainbtn'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setPurchaseTab(!PurchaseTab)}>
                    <div className='h6 pt-1'>GRN Details</div>
                </div>
                <div className={`slide-content ${PurchaseTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="grnNo" className="form-label  pt-1 start">GRN No</label>
                                <input
                                    className="form-Input w-70"
                                    value={grnNo}
                                    disabled
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="catcode" className="form-label  pt-1 start">Delivery Date </label>
                                <input className='form-Input w-70' type='datetime-local'
                                    name="deliveryDate"
                                    disabled={itemData?.eye === true ? true : false}
                                    value={formData.deliveryDate} min={getCurrentLocalDatetime()}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Location" className="form-label  pt-1 start">Location</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={formData.location}
                                    isDisabled={itemData?.eye === true ? true : false}
                                    onChange={handleLocationChange}
                                    options={WareHouse?.map((sup) => ({ value: sup, label: sup?.description }))}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">Purchase Order No *</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    value={SelectedPO}
                                    isDisabled={itemData?.eye === true ? true : false}
                                    options={PurchaseOrder?.map((po) => ({ value: po.pOrderNo, label: po.pOrderNo }))}
                                    onChange={(selectedOption) => handlePoChange(selectedOption)}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="deliveryNote" className="form-label  pt-1 start">Delivery Note No</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled={itemData?.eye === true ? true : false}
                                    id="deliveryNote"
                                    value={formData.deliveryNote}
                                    onChange={handleInputChange}
                                    name="deliveryNote"
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="TaxInvoice" className="form-label  pt-1 start">Tax Invoice No ( KRA )</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    disabled={itemData?.eye === true ? true : false}
                                    id="TaxInvoice"
                                    value={formData.TaxInvoice}
                                    onChange={handleInputChange}
                                    name="TaxInvoice"
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="PurchaseOrderDate" className="form-label  pt-1 start">Purchase Order Date</label>
                                <input
                                    type="datetime-local"
                                    className="form-Input w-70"
                                    disabled={true}
                                    id="PurchaseOrderDate" min={getCurrentLocalDatetime()}
                                    value={formData.PurchaseOrderDate}
                                    onChange={handleInputChange}
                                    name="PurchaseOrderDate"
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="InvoiceNo" className="form-label  pt-1 start">Invoice No</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    id="InvoiceNo"
                                    disabled={itemData?.eye === true ? true : false}
                                    value={formData.InvoiceNo}
                                    onChange={handleInputChange}
                                    name="InvoiceNo"

                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">Delivery Type</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    isDisabled={itemData?.eye === true ? true : false}
                                    options={DeliveryType}
                                    onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">Supplier</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    isDisabled={true}
                                    value={SelectedSupplier}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="InvoiceDate" className="form-label  pt-1 start">Invoice Date</label>
                                <input
                                    type="datetime-local" min={getCurrentLocalDatetime()}
                                    className="form-Input w-70"
                                    id="InvoiceDate"
                                    value={formData.InvoiceDate}
                                    disabled={itemData?.eye === true ? true : false}
                                    onChange={handleInputChange}
                                    name="InvoiceDate"
                                />
                            </div>
                            <div className="col d-flex justify-content-between gap-3">
                                <div className="col d-flex justify-content-between">
                                    <label htmlFor="Currency" className="form-label  pt-1 start">Currency</label>
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        className="w-70 p-0"
                                        isSearchable={false}
                                        isDisabled={itemData?.eye === true ? true : false}
                                        value={SelectedCurrency}
                                    />
                                </div>
                                <div className="col d-flex justify-content-between">
                                    <label htmlFor="EXRate" className="form-label  pt-1 start">EX Rate</label>
                                    <input
                                        type="number"
                                        className="form-Input w-70"
                                        disabled
                                        onWheel={(e) => e.target.blur()}
                                        id="exchangeRate"
                                        value={formData.exchangeRate}
                                        onChange={handleInputChange}
                                        name="exchangeRate"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setCostTab(!CostTab)}>
                    <div className='h6 pt-1'>Cost Center</div>
                </div>
                <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="PropertyGroup" className="form-label  pt-1 start">Property Group</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    isDisabled={true}
                                    value={formData.costCenter1}
                                    onChange={handleDepartmentChange}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Property" className="form-label  pt-1 start">Property</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    isDisabled={true}
                                    value={formData.costCenter2}
                                    onChange={handleDepartmentChange}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Department" className="form-label  pt-1 start">Department</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    isDisabled={true}
                                    value={formData.costCenter3}
                                    onChange={handleDepartmentChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row ">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="Outlet" className="form-label  pt-1 start">Outlet</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    className="w-70 p-0"
                                    isDisabled={true}
                                    value={formData.costCenter4}
                                    onChange={handleDepartmentChange}
                                />
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="remarks" className="form-label  pt-1 start">Remarks</label>
                                <input
                                    name="remarks"
                                    type='text'
                                    className="form-Input w-70"
                                    disabled={itemData?.eye === true ? true : false}
                                    id="remarks"
                                    value={formData.remarks}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='col'></div>
                            <div className='col d-flex justify-content-between brderbox p-2'>
                                <a href=''>
                                    Attachment
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`cost-center-section`} >
                <div className="mt-3 cursor-pointer slide-header">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Message
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 3 ? 'active' : ''}`}
                            onClick={() => handleTabClick(3)}
                        >
                            Additional Charges
                        </div>
                    </div>

                </div>
            </div>
            <div className={`slide-content open`}>
                <div className={`grid-1 border-box2 p-3`}>
                    {gridVisibility[0] && (
                        <ItemGridGRN onRequisitionData={handleRequisitionData} itemData={POData} grnData={itemData} selectedPOData={selectedPOData} />
                    )}
                    {gridVisibility[1] && (
                        <AuthorizationGrn handleAuthorization={handleAuthorization}
                            usersDetails={usersDetails}
                            setUser={setUser}
                            itemData={POData} />
                    )}
                    {gridVisibility[2] && (
                        <div className="grid-3 border">
                            <MessageGrid handleMessage={handleMessage} />
                        </div>
                    )}
                    {gridVisibility[3] && (
                        <ItemGridGRN onRequisitionData={handleRequisitionData} />
                    )}
                </div>
            </div>
        </div>
    )
}

export default CreateGrn