import React, { useState, useEffect } from 'react';
import "../../../../Customer/Menu/NavBar/navBar.css";
import "../../menus/menuItemcard.css";
import ApiService from '../../../../services/apiService';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getUserId } from '../../../../../Redux/selecters';
import moment from 'moment';
import PromotionGrid from './promotionGrid';
import Select from 'react-select';

const PromotionMaster = () => {
    const [promotion, setPromotion] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    let UserId = useSelector(getUserId);

    useEffect(() => {
        GetPromotion();
    }, [searchTerm]);

    const [formData, setFormData] = useState({
        name: '',
        price: '',
        discount: '',
        promoCode: '',
        toDate: '',
        fromDate: '',
        Category: '',
        numberOfVouchers: '',
        validityDays: ''
    });
    const Category = [
        { value: '1', label: 'Voucher' },
        { value: '2', label: 'Promotion' },
    ];
    const handleClear = () => {
        setFormData({
            name: '',
            price: '',
            discount: '',
            promoCode: '',
            toDate: '',
            fromDate: '',
            Category: '',
            numberOfVouchers: '',
            validityDays: ''
        });
    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleCategoryChange = (selectedOption) => {
        setFormData({ ...formData, Category: selectedOption?.value });
    }
    const handleCreate = async () => {
        try {
            const datareq = {
                name: formData?.name,
                promoCode: formData?.promoCode,
                discountPercentage: parseInt(formData?.discount),
                discountAmount: parseInt(formData?.price),
                fromDate: moment(formData.fromDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                toDate: moment(formData.toDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                createdBy: UserId,
                numberOfVouchers: parseInt(formData?.numberOfVouchers),
                category: parseInt(formData?.Category),
                validityDays: parseInt(formData?.validityDays),
                loggedInUserId: UserId,
                promotionDetails: [
                    {
                        "promotionID": 0,
                        "vouchorNo": "string",
                        "fromDate": "2024-03-21T08:30:04.634Z",
                        "toDate": "2024-03-21T08:30:04.634Z",
                        "status": "string",
                        "useCount": 0,
                        "validityDays": 0
                    }
                ]
            };
            if (formData.name && formData.price) {
                const apiService = new ApiService();
                let res = await apiService.post("CREATE_PROMOTION", datareq);

                if (res?.data?.statusCode === 201) {
                    toast.success("Promotion Created Successfully");
                    GetPromotion();
                    setFormData({
                        name: '',
                        price: '',
                        discount: '',
                        promoCode: '',
                        toDate: '',
                        fromDate: '',
                        Category: '',
                        numberOfVouchers: '',
                        validityDays: ''
                    })
                } else {
                    toast.warning('Waiting for Response');
                }
            } else {
                toast.error("Fill in all the fields to create add-ons");
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const GetPromotion = async () => {
        try {
            const apiService = new ApiService();
            let parameters = `?Name=${searchTerm}`;
            const res = await apiService.getApiParamater('GET_PROMOTION', parameters);

            setPromotion(res?.data.result);
        } catch (error) {
            console.error('Error fetching AddsOn data:', error);
        }
    };
    
    
    return (
        <div className="p-lg-5 container">
            <div className="row catlist">
                <div className="form-group row mt-2 ">
                    <div className="col-md-6">
                        <label htmlFor="validationCustomName" className="form-label">Name</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            placeholder="Enter Name"
                            id="validationCustomName"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex gap-3">
                            <div className=' w-50'>
                                <label htmlFor="validationCustomPrice" className="form-label">Amount</label>
                                <input
                                    type="number"
                                    placeholder="Price"
                                    className=" form-control"
                                    id="validationCustomPrice"
                                    name="price"
                                    value={formData.price}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className=' w-50'>
                                <label htmlFor="validationCustomDiscount" className="form-label">Discount %</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="validationCustomDiscount"
                                    name="discount"
                                    value={formData?.discount}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group row  mt-3">
                    <div className="col-md-6">
                        <label htmlFor="promoCode" className="form-label">Promo Code</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            placeholder="Enter Promo Code"
                            id="promoCode"
                            name="promoCode"
                            value={formData.promoCode}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="col-md-6">
                        <div className="gap-2 d-flex ">
                            <div className='w-50'>
                                <label htmlFor="fromDate" className="form-label">From Date</label>
                                <input
                                    type="datetime-Local"
                                    className=" form-control"
                                    id="fromDate"
                                    name="fromDate"
                                    value={formData.fromDate}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className='w-50'>
                                <label htmlFor="toDate" className="form-label">To Date</label>
                                <input
                                    type="datetime-Local"
                                    className="form-control"
                                    id="toDate"
                                    name="toDate"
                                    value={formData?.toDate}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group row mt-3 ">
                    <div className="col-md-6">
                        <label htmlFor="validationCustom04" className="form-label cashhead">Select Promotion Type</label>
                        <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                            className=''
                            onChange={handleCategoryChange}
                            options={Category}
                        />
                    </div>
                    {formData?.Category === "1" ?
                        <div className="col-md-6">
                            <div className="d-flex gap-3">
                                <div className=' w-50'>
                                    <label htmlFor="validationCustomPrice" className="form-label">Number Of Vouchers</label>
                                    <input
                                        type="number"
                                        className=" form-control"
                                        id="validationCustomPrice"
                                        name="numberOfVouchers"
                                        value={formData.numberOfVouchers}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className=' w-50'>
                                    <label htmlFor="validationCustomDiscount" className="form-label">Validity Days</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder='Enter Validity Days of voucher'
                                        id="validationCustomDiscount"
                                        name="validityDays"
                                        value={formData?.validityDays}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div> : " "}

                </div>
                <div className="row justify-content-between mt-4">
                    <div
                        className="col-lg-2 col-md-2 addMoreBtn p-3 mt-4 mb-3 mx-3"
                        onClick={handleClear}
                    >
                        Clear
                    </div>
                    <div
                        className="col-lg-2 col-md-2 addItem-btn p-3 mt-4 mb-3 mx-3"
                        onClick={handleCreate}
                    >
                        Save
                    </div>
                </div>
            </div>
            <div className="pt-4">
                <PromotionGrid promotion={promotion} GetPromotion={GetPromotion} />
            </div>
        </div>
    );
};

export default PromotionMaster;
