import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import moment from 'moment/moment';
import { ReactComponent as Extend } from '../assets/images/svgIcons/orderExtend.svg';
import { ReactComponent as InExtend } from '../assets/images/svgIcons/orderInExtend.svg';
import ApiService from '../services/apiService';

const OrderCard = ({ order, onDrop, handleCancle, handleComplete }) => {
    const [orderdetails, setOrderDetails] = useState(false);
    const [{ isDragging }, drag] = useDrag({
        type: 'ORDER',
        item: { id: order.id },
        canDrag: () => order.orderStatus !== 3,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    

    return (
        <div className='pb-3'>
            <div ref={drag}  className='orderbox' style={{ cursor: order.orderStatus === 3 ? 'not-allowed' : 'grab' }}>
                <div className='status-head'>
                    <div>{moment(order?.orderDate).format('DD-MMM, hh:mm a')}</div>
                    <div>00:00</div>
                    {orderdetails === true ?
                        <Extend onClick={() => setOrderDetails(false)} /> : <InExtend onClick={() => setOrderDetails(true)} />}
                </div>
                <div className='mx-3 px-2 pt-3 '>
                    <div className='d-flex justify-content-between'>
                        <div style={{ fontSize: "14px", fontWeight: "500" }}>Item Name</div>
                        <div style={{ fontSize: "14px", fontWeight: "500" }}>Quantity</div>
                    </div>
                    <div className=''>
                        {order?.orderItems?.map((orderItem, itemIndex) => {
                            const matchingMetaItems = order.orderItemsMeta.filter(metaItem => metaItem.menuItemId === orderItem.itemId && metaItem.metaItemIndex === itemIndex); // Filter based on itemIndex
                            return (
                                <div className={`d-flex justify-content-between my-2 ${orderItem?.voidItem ? 'void' : 'odrtbl'}`}>
                                    <div style={orderItem?.voidItem ? { color: 'red' } : {}}>
                                        {orderItem?.menuItemName || "Beef Cheese Burger"}
                                        <div className={`d-flex gap-2`}>
                                            {matchingMetaItems.map(metaItem => (
                                                <div>{metaItem.metaItemName}</div>
                                            ))}
                                        </div>
                                    </div>
                                    <div style={orderItem?.voidItem ? { color: 'red' } : {}}>{orderItem?.quantity}</div>
                                    {orderItem?.voidItem ?
                                        <div style={orderItem?.voidItem ? { color: 'red' } : {}}>{orderItem?.voidItem ? 'Voided' : ''}</div>
                                        : ''
                                    }
                                </div>
                            );
                        })}
                    </div>

                </div>
                {orderdetails === true ?
                    <div className='m-2'>
                        <div class="separators">
                            <hr />
                            <div className='itmKIns'>Order {order?.id}</div>
                            <hr />
                        </div>
                        <div className='p-3 d-flex justify-content-between'>
                            <div style={{ fontSize: "14px", fontWeight: "500" }}>Customer Name</div>
                            <div>{order?.customerName || "Guest"}</div>
                        </div>
                        <div className='px-3 d-flex justify-content-between'>
                            <div style={{ fontSize: "14px", fontWeight: "500" }}>Order Type</div>
                            {order?.orderType === 0 ?
                                <div>Dine-In</div> : ""
                            }
                            {order?.orderType === 1 ?
                                <div>Take-out</div> : ""
                            }
                            {order?.orderType === 2 ?
                                <div>Delivery</div> : ""
                            }
                            {order?.orderType === 3 ?
                                <div>Online Order</div> : ""
                            }
                            {order?.orderType === 4 ?
                                <div>Drive through</div> : ""
                            }
                            {order?.orderType === 5 ?
                                <div>Catering</div> : ""
                            }
                            {order?.orderType === 6 ?
                                <div>Room Service</div> : ""
                            }
                            {order?.orderType === 7 ?
                                <div>Pickup</div> : ""
                            }
                            {order?.orderType === 8 ?
                                <div>Curbside Pickup</div> : ""
                            }
                        </div>
                        <div className='p-3 d-flex justify-content-between'>
                            <div style={{ fontSize: "14px", fontWeight: "500" }}>Table Number</div>
                            <div>{order?.customerName || "Guest"}</div>
                        </div>
                        {order?.orderStatus === 7 ?
                            ""
                            :
                            <>
                                {order?.orderStatus === 3 ?
                                    <div className='p-3 center'>
                                        <div className='AddBtn w-100' onClick={() => handleComplete(order)}>Complete</div>
                                    </div> :
                                    <div className='p-3 d-flex gap-3 center'>
                                        <div className='AddBtn w-50' >Print  {order.kot}</div>
                                        <div className='AddBtn w-50' onClick={() => handleCancle(order)}>Cancel</div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                    : ""
                }

            </div>
        </div>
    );
};

export default OrderCard;
