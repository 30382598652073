import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';
import { RxCross1 } from "react-icons/rx";
import ApiService2 from '../../../POS/services/apiService';
import { IoAddSharp } from "react-icons/io5";
import { useSelector } from 'react-redux';
import { setRows, addRow, removeRow, removeSingleRow, updateRow, clearRows } from '../../../Redux/actions/rowActions';
import { useDispatch } from 'react-redux';
const DirectPurchaseGrid = ({ onRequisitionData, itemData, selectedCheck, compareData }) => {
    const rows = useSelector(state => state.rows.rows);
    const dispatch = useDispatch();
    const [itemRows, setItemRows] = useState([]);
    const [rowsUpdated, setRowsUpdated] = useState(false);
    const [itemOptions, setItemOptions] = useState([]);
    const [descriptionOptions, setDescriptionOptions] = useState([]);
    const [apiData, setApiData] = useState([]);
    const [vat, setvat] = useState([]);
    const [items, setItems] = useState([]);
    const apiService = new ApiService();
    const apiService2 = new ApiService2();
    const initialMount = useRef(true);
    const totalAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.qty * row.baseAmount) || 0), 0)
    const totalVat = rows.reduce((sum, row) => sum + (parseFloat(row?.vatAmount) || 0), 0)
    const netAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
    useEffect(() => {
        getItemsUpdate();
        getVat()
    }, [itemData, selectedCheck]);
    useEffect(() => {
        const getItems = async () => {
            try {
                if (initialMount.current && itemData?.grnDetails && itemData.grnDetails.length > 0) {
                    const res = await apiService.get('GET_ITEM_PACK');
                    const formattedRows = itemData?.grnDetails?.map(detail => {
                        debugger
                        const selecteditem = res.data.result?.find(rec => rec.itemDescription === detail.itemDescription)
                        return {
                            requisitionNo: detail.grnNo,
                            srno: detail.srno,
                            ItemNo: detail.itemCode,
                            recordId: detail.itemCode,
                            itemDescription: detail.itemDescription,
                            branch: '',
                            qty: detail.qty,
                            uom: detail.uom,
                            baseQty: selecteditem.convToInvUOM * detail.qty,
                            convRate: selecteditem.convToInvUOM,
                            baseAmount: detail.unitPrice,
                            specification: detail.specification,
                            amount: detail.amount,
                            stock: detail.stock,
                            discount: detail.discount,
                            vat: detail.vatPerc,
                            vatDes: detail.vatDesc,
                            vatAmount: detail.vatAmount,
                            expDate: detail.expDate,
                        };
                    }).filter(row => row !== null);
                    if (formattedRows.length > 0) {
                        dispatch(setRows(formattedRows));
                        onRequisitionData(formattedRows);
                        setRowsUpdated(true);
                    }
                }
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        };
        if (itemData) {
            getItems();
        }
    }, [itemData, onRequisitionData]);
    useEffect(() => {
        if (initialMount.current) {
            initialMount.current = false;
        } else {
            itemRows.forEach((row, index) => {
                dispatch(updateRow(index, row));
            });
        }
    }, [itemRows, dispatch]);
    useEffect(() => {
        onRequisitionData(rows);
    }, [rows]);
    const getVat = async () => {
        let param = `?isActive=true`
        const vat = await apiService2.getApiParamater('GET_TAX', param);
        setvat(vat.data.result)
    }
    const getItemsUpdate = async () => {
        try {
            let param = `?isActive=true`
            const vatres = await apiService2.getApiParamater('GET_TAX', param);
            if (itemData?.poDetails) {
                const res = await apiService.get('GET_ITEM_PACK');
                const formattedRows = itemData?.poDetails.map(detail => {
                    const selecteditem = res.data.result?.find(item => item.itemDescription === detail.itemDescription);
                    const selectedVat = vatres.data.result?.find(tax => tax.name === selecteditem?.vat);
                    let amount = detail.qty * detail.unitPrice
                    const perAmount = (amount / 100) * selectedVat?.taxPercentage
                    let ExcludedAmount = amount + perAmount
                    debugger
                    return {
                        requisitionNo: itemData.requisitionNo || "",
                        srno: detail.srno,
                        ItemNo: detail.itemCode,
                        recordId: detail.itemCode,
                        itemDescription: detail.itemDescription,
                        qty: detail.qty,
                        uom: detail.uom,
                        stock: selecteditem.convToInvUOM * detail.qty,
                        baseAmount: detail.unitPrice,
                        baseQty: selecteditem.convToInvUOM,
                        specification: detail.specification,
                        amount: selectedCheck === "Included" ? amount : ExcludedAmount,
                        vat: selectedVat?.taxPercentage || 0,
                        vatDes: selectedVat?.name,
                        vatAmount: perAmount
                    };
                }).filter(row => row !== null);
                if (formattedRows.length > 0) {
                    dispatch(setRows(formattedRows));
                    onRequisitionData(formattedRows);
                    setRowsUpdated(true);
                }
            } else {
                if (!itemData?.requisitionNo) {
                    console.error('Requisition number not found in item data');
                    return;
                }
                const response = await apiService.get('GET_PR');
                const selectedPR = response?.data?.result?.find(req => req.requisitionNo === itemData.requisitionNo);
                if (!selectedPR) {
                    console.error('Selected PR not found');
                    return;
                }
                const prDetails = selectedPR?.prDetails || [];
                const res = await apiService.get('GET_ITEM_PACK');
                setItems(res.data.result)
                const formattedRows = itemData?.items.map(detail => {
                    const selectedPritem = prDetails?.find(item => item.itemCode === detail.itemCode);
                    const selecteditem = res.data.result?.find(item => item.itemDescription === detail.itemDescription);
                    const selectedVat = vatres.data.result?.find(tax => tax.name === selecteditem?.vat);
                    if (!selecteditem) {
                        console.error(`Selected item with description ${detail.itemDescription} not found`);
                        return null;
                    }
                    let amount = detail.qty * detail.price
                    const perAmount = (amount / 100) * selectedVat?.taxPercentage
                    let ExcludedAmount = amount + perAmount
                    debugger
                    return {
                        requisitionNo: itemData.requisitionNo || "",
                        srno: itemData.srno,
                        ItemNo: detail.itemCode,
                        itemDescription: detail.itemDescription,
                        qty: detail.qty,
                        stock: selecteditem.convToInvUOM * detail.qty,
                        baseQty: selecteditem.convToInvUOM,
                        uom: detail.uom,
                        baseAmount: detail.price,
                        specification: selectedPritem ? selectedPritem.specification : '',
                        amount: selectedCheck === "Included" ? amount : ExcludedAmount,
                        vat: selectedVat?.taxPercentage || 0,
                        vatAmount: perAmount,
                        vatDes: selectedVat?.name,
                    };
                }).filter(row => row !== null);
                if (formattedRows.length > 0) {
                    dispatch(setRows(formattedRows));
                    onRequisitionData(formattedRows);
                    setRowsUpdated(true);
                }
            }
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };
    useEffect(() => {
        fetchData();
        getVat()
    }, []);
    const fetchData = async () => {
        try {
            const response = await apiService.getApiParamater('GET_ITEM_PACK', '?isActive=true');
            if (response?.data?.result?.length > 0) {
                setApiData(response.data.result);
                setItemOptions(response.data.result.map(item => ({
                    value: item.itemNo,
                    label: item.itemNo
                })));
                setDescriptionOptions(response.data.result.map(item => ({
                    value: item.itemDescription,
                    label: item.itemDescription
                })));
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleInputChange = (index, fieldName, value) => {
        debugger
        const isDuplicate = rows.some((row, rowIndex) => rowIndex !== index && (row.ItemNo === value || row.itemDescription === value));
        if (isDuplicate) {
            toast.error('Please choose a different item.');
            return;
        }
        const updatedRows = [...rows];
        const updatedRow = { ...updatedRows[index], [fieldName]: value, srno: ("0" + (index + 1)).slice(-2) };
        if (fieldName === 'itemNo' || fieldName === 'itemDescription') {
            const selectedItem = apiData.find(item => item[fieldName] === value);
            const selectedVat = vat?.find(tax => tax.name === selectedItem?.vat);
            if (selectedItem) {
                updatedRow.itemDescription = selectedItem.itemDescription;
                updatedRow.ItemNo = selectedItem.itemNo;
                updatedRow.requisitionNo = selectedItem.itemDescription;
                updatedRow.uom = selectedItem.baseUnit;
                updatedRow.specification = selectedItem.specification;
                updatedRow.vat = selectedVat?.taxPercentage;
                updatedRow.stock = 0;
                updatedRow.vatDes = selectedVat?.name;
                updatedRow.baseAmount = selectedItem?.avgPrice || 0;
                updatedRow.baseQty = parseFloat(selectedItem.convToInvUOM);
            }
        }
        if (fieldName === 'qty') {
            const selectedItem = apiData.find(data => data.itemNo === updatedRow.itemNo);
            const baseQty = selectedItem ? selectedItem.convToInvUOM : 1;
            updatedRow.baseQty = parseFloat(baseQty);
        }
        if (fieldName === 'qty' || fieldName === 'baseAmount') {
            const selectedItem = apiData?.find(data => data?.itemDescription === updatedRow?.itemDescription);
            const selectedVat = vat?.find(tax => tax.name === selectedItem?.vat);
            const quantity = parseFloat(updatedRow.qty);
            const unitPrice = parseFloat(updatedRow.baseAmount);
            let amount = quantity * unitPrice || 0
            const perAmount = (amount / 100) * selectedVat?.taxPercentage
            let ExcludedAmount = amount + perAmount
            debugger
            updatedRow.amount = selectedCheck === "Included" ? (amount || 0).toFixed(2) : (ExcludedAmount || 0).toFixed(2);
            updatedRow.stock = updatedRow.baseQty * quantity || 0;
            updatedRow.vatDes = selectedVat?.name;
            updatedRow.vatAmount = perAmount;
        }
        updatedRows[index] = updatedRow;
        dispatch(setRows(updatedRows));
        setItemRows(updatedRows);
        onRequisitionData(updatedRows);
    };
    const addNewRow = () => {
        const newRow = {
            requisitionNo: '',
            srno: '',
            recordId: '',
            ItemNo: '',
            itemDescription: '',
            specification: '',
            qty: '',
            uom: '',
            totalQty: '',
            baseQty: '',
            baseAmount: '',
            stock: '',
            amount: '',
            discount: '',
            vat: 0,
            vatDes: '',
            vatAmount: '',
            expDate: ''
        };
        dispatch(addRow(newRow));
        const updatedRows = [...rows, newRow];
        setItemRows(updatedRows);
        onRequisitionData(updatedRows);
        dispatch(setRows(updatedRows));
    };
    const handleRemoveRow = (index) => {
        dispatch(removeSingleRow(index));
        const updatedRows = rows
            .filter((_, i) => i !== index)
            .map((row, i) => ({
                ...row,
                srno: i + 1
            }));
        onRequisitionData(updatedRows);
        setItemRows(updatedRows)
        dispatch(setRows(updatedRows));

    };
    const handleDateChange = (index, expDate) => {
        const updatedRows = [...rows];
        updatedRows[index].expDate = expDate;
        const updatedSelectedRows = rows.map((row, i) =>
            i === index ? { ...row, expDate: expDate } : row
        );
        setItemRows(updatedSelectedRows);
        onRequisitionData(updatedSelectedRows);
    };
    return (
        <div>
            <div className='table-container p-2 mb-5' style={{ overflowX: 'auto', height: "400px" }}>
                <table>
                    <thead className='dynoTable' >
                        <tr>
                            <th></th>

                            <th>Sr no</th>
                            <th>Item No</th>
                            <th>Description</th>
                            <th>Item Specs</th>
                            <th>Qty</th>
                            <th>Packing UOM</th>
                            <th>Inventory Qty</th>
                            <th>Unit Price</th>
                            <th>Discount</th>
                            <th>VAT %</th>
                            <th>VAT Amount</th>
                            <th>Amount</th>
                            <th>expDate</th>
                        </tr>
                    </thead>

                    <tbody>
                        {Array.isArray(rows) && rows.map((row, index) => (
                            <tr key={index}>
                                {itemData?.requisitionNo ?
                                    <td className='m-0 p-0' style={{ border: "1px solid rgba(118, 118, 118, 0.3)" }}>
                                        <div className='center'> <RxCross1 size={15} /></div></td> :
                                    <td className='m-0 p-0' style={{ border: "1px solid rgba(118, 118, 118, 0.3)" }}>
                                        <div onClick={() => handleRemoveRow(index)} className='center'>
                                            <RxCross1 size={15} /></div></td>
                                }
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.srno} disabled style={{ width: "80px" }} /></td>
                                <td className='m-0 p-0'>
                                    <Select
                                        isDisabled={compareData?.eye === true ? true : false}
                                        options={itemOptions}
                                        value={{ value: row.ItemNo, label: row.ItemNo }}
                                        onChange={selectedOption => handleInputChange(index, 'itemNo', selectedOption.value)} />
                                </td>
                                <td className='m-0 p-0'>
                                    <Select
                                        isDisabled={compareData?.eye === true ? true : false}
                                        options={descriptionOptions}
                                        value={{ value: row.itemDescription, label: row.itemDescription }}
                                        onChange={selectedOption => handleInputChange(index, 'itemDescription', selectedOption.value)}
                                    />
                                </td>
                                <td className='m-0 p-0'><input className='p-2' type="text" disabled={compareData?.eye === true ? true : false}
                                    value={row.specification} style={{ width: "120px" }} onChange={e => handleInputChange(index, 'specification', e.target.value)} /></td>
                                <td className='m-0 p-0'><input className='p-2' onWheel={(e) => e.target.blur()} disabled={compareData?.eye === true ? true : false}
                                    type="number" value={row.qty} style={{ width: "90px" }} onChange={e => handleInputChange(index, 'qty', e.target.value)} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" style={{ width: "140px" }} value={row.uom} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.stock || 0} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="number" onWheel={(e) => e.target.blur()} disabled={compareData?.eye === true ? true : false}
                                    value={row.baseAmount} style={{ width: "120px" }} onChange={e => handleInputChange(index, 'baseAmount', e.target.value)} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.discount} style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" style={{ width: "120px" }} value={(row?.vat === undefined ? 0 : row?.vat + "%") || 0} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row?.vatAmount || 0} disabled style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' style={{ width: "120px" }} disabled type="text" value={(row.amount === NaN ? 0 : row.amount || 0) || 0} /></td>
                                <td className='m-0 p-0'><input className='p-2' type='date' value={row?.expDate} disabled={compareData?.eye === true ? true : false}
                                    onChange={(e) => handleDateChange(index, e.target.value)} style={{ width: "130px" }} /></td>
                            </tr>
                        ))}
                    </tbody>

                </table>
                <div className='d-flex gap-5'>
                    {compareData?.eye === true ? "" :
                        <button className='mainbtn  mt-4 gap-3' onClick={addNewRow}><IoAddSharp color='#fff' className='center mt-1 mx-2' /><div>Add</div>  </button>
                    }
                </div>
            </div>
            <div className='d-flex justify-content-between boxs'>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Discount</div>
                    <div className='field'>---</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Amount</div>
                    <div className='field'>{totalAmount.toFixed(2) || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Discount</div>
                    <div className='field'>---</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Vat</div>
                    <div className='field'>{totalVat.toFixed(2) || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Net Amount</div>
                    <div className='field'>{netAmount.toFixed(2) || "---"}</div>
                </div>
            </div>
        </div>
    );
};

export default DirectPurchaseGrid;
