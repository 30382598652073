import React, { useState } from 'react';
import '../Cashier/components/itemCard.css';
import { useNavigate } from 'react-router';
import { connect } from "react-redux";
import ProfileImage from '../assets/images/pizza.jpg';
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

const OfferCard = ({ val, menu, Currency }) => {
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    let navigate = useNavigate();
    const [CartValue, setCartValue] = useState("");

    const openModal = (e) => {
        setCartValue(e)
        setIsModalOpen(true);
    }
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handleItem = () => {
        navigate('/customer/menu')
    }
    const truncatedAddress = menu?.name?.length > 20 ? menu?.name.substring(0, 25) + '...' : menu?.name;
    const truncatedDescription = menu?.description?.length > 20 ? menu?.description.substring(0, 30) + '...' : menu?.description;
    return (
        <div className=' py-3'>
            <div className='cashcard'>
                <div className='row'>
                    <div className='col-lg-3'>
                        <img src={menu?.imageUrl === null || "" ? ProfileImage :
                            IMAGE_URL + menu?.imageUrl} className='itm_Img' />
                    </div>
                    <div className='col-lg-9'>
                        <div className="card-Head" title={menu?.name}>{truncatedAddress || "--"}</div>
                        <div className='card-subHead' title={menu?.description}>{truncatedDescription || "--"}</div>
                        <div className='card-Prize'>{Currency.currency.name} {menu?.price}</div>
                        <div className='d-flex justify-content-end'>
                            <div className='AddCart-btn center p-2' onClick={handleItem}>
                                Order Now
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(OfferCard);