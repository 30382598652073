import React, { useState, useEffect, useRef, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import Button from 'react-bootstrap/Button';
import { MdEmail } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { AiOutlinePrinter } from "react-icons/ai";
import { clearRows } from '../../../Redux/actions/rowActions';
import { useDispatch } from 'react-redux';
import "../pagination/pagination.css"
import { MdOutlineEditOff } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { hasPermission } from "../../services/roleService"
import { IoAddSharp } from "react-icons/io5";
import Pagination from '../pagination/pagination';

const RequisitionListing = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    let navigate = useNavigate();
    const gridRef = useRef();
    const [permissions, setPermissions] = useState([]);
    let dispatch = useDispatch()

    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    const canEditItemMaster = checkPermission('Purchase Requisition', 'edit');
    const canCreateItemMaster = checkPermission('Purchase Requisition', 'create');

    useEffect(() => {
        getItems();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
    }, []);
    useEffect(() => {
        if (pageSize) {
            getItems();
        }
    }, [pageSize]);
    const handleEdit = (data) => {
        navigate("/Inventory/Requisition/PurchaseRequisition", { state: data })
    }
    const handlePrint = (data) => {
        navigate(`/Inventory/Requisition/${data?.requisitionNo}`, { state: data })
    }
    const handleEye = (data) => {
        const updatedData = { ...data, eye: !data.eye?.active };
        navigate("/Inventory/Requisition/PurchaseRequisition", { state: updatedData });
    }
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
           params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const res = await apiService.getApiParamater('GET_PR', `?${params.toString()}`);
            const storeData = res?.data?.result?.filter(item => item?.reqType === "Purchase Request");
            setRowData(storeData || []);
            setPagination(res.data.paginationData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            // Handle error (e.g., show error message to user)
            setLoading(false); // Set loading to false even in case of error
        }
    }
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("LLL");
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const columnDefs = [
        { headerName: 'Req No', field: 'requisitionNo', filter: true, floatingFilter: true, width: 120 },
        { headerName: 'Request Type', field: 'reqType', filter: true, floatingFilter: true, width: 130 },
        { headerName: 'Location', field: 'location', filter: true, floatingFilter: true, width: 120 },
        { headerName: 'Drop Location', field: 'costCenter1', filter: true, floatingFilter: true, width: 140 },
        { headerName: 'Cost Center 1', field: 'costCenter2', filter: true, floatingFilter: true, width: 130 },
        { headerName: 'Remarks', field: 'remarks', filter: true, floatingFilter: true, width: 110 },
        {
            headerName: 'Status', field: 'status', filter: true, floatingFilter: true,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 2 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }, width: 140
        },
        { headerName: 'Approved By', field: 'approvalUser', filter: true, floatingFilter: true, width: 140 },
        { headerName: 'Created By', field: 'contactPerson', filter: true, floatingFilter: true, width: 130 },
        {
            headerName: "Created Date", field: "requisitionDate", filter: true, floatingFilter: true,
            valueFormatter: dateFormatter,
            comparator: dateComparator, width: 130
        },
        { headerName: 'Email', field: 'Email', filter: true, floatingFilter: true, width: 120 },
        {
            headerName: 'Actions', field: 'recordId', cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                handleEdit: handleEdit,
                canEdit: canEditItemMaster,
                handleEye: handleEye,
                handlePrint: handlePrint,
                clicked: function (field, isActive) {
                    updateActiveStatus(field, isActive);
                },
            }, width: 200
        }
    ];
    const actionsRenderer = (props) => {
        return (
            <div>
                <Button variant="primary" onClick={() => handleEditRow(props.data)}>Edit</Button>
            </div>
        );
    };
    const clearAllRows = () => {
        dispatch(clearRows());
    };
    const handlenew = () => {
        clearAllRows();
        navigate('/Inventory/Requisition/PurchaseRequisition')
    }
    const handleEditRow = (rowData) => {
        setSelectedRowData(rowData);
    };
    const updateActiveStatus = (rowData) => {
        setSelectedRowData(rowData);
    };
    return (
        <div className='p-4'>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div className='d-flex justify-contant-between '>
                        <h5 className='col mainInvHead'>Purchase Requisition List</h5>
                        <div className='d-flex justify-content-end gap-3'>
                            <div className='col center'>
                                {canCreateItemMaster === true ?
                                    <button className='mainbtn ' type="submit" onClick={() => handlenew()}>
                                        <IoAddSharp color='#fff' className='center mt-1 mx-2' /> <div>Add New</div></button>
                                    :
                                    <button className='mainbtn ' type="submit" style={{ cursor: "not-allowed" }}>
                                        <IoAddSharp color='#fff' className='center mt-1 mx-2' /> <div>Add New</div></button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='brdor  mt-3'>
                        <div className="ag-theme-quartz" style={{ height: 650 }}>
                            <AgGridReact
                                rowData={rowData}
                                enableFilter={true}
                                floatingFilter={true}
                                rowSelection={'single'}
                                columnDefs={columnDefs}
                                rowHeight={50}
                                pagination={false}
                                frameworkComponents={{ actionsRenderer }}
                                suppressExcelExport={true}
                            />
                        </div>
                    </div>
                    <Pagination pagination={pagination} getItems={getItems} />
                </>
            )}
        </div>
    );
};
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
        this.btnEyeClickedHandler = this.btnEyeClickedHandler.bind(this);
        this.btnPrintClickedHandler = this.btnPrintClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value, !this.props.data.isActive); // Toggle isActive value
    }
    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data);
    }
    btnEyeClickedHandler() {
        const { data, handleEye } = this.props;
        handleEye(data);
    }
    btnPrintClickedHandler() {
        const { data, handlePrint } = this.props;
        handlePrint(data);
    }
    render() {
        const { isActive } = this.props.data;
        const { canEdit } = this.props;
        return (
            <div className='d-flex gap-3'>
                {/* <div><Link /></div> */}
                {canEdit && (
                    <>
                        {this.props.data?.status === 1 ?
                            <div><MdOutlineEditOff size={25} /></div>
                            :
                            <div onClick={this.btnEditClickedHandler}><FaEdit size={25} /></div>
                        }
                    </>)}
                <div>
                    <MdEmail size={25} />
                </div>
                <div>
                    <FaRegEye onClick={this.btnEyeClickedHandler} size={25} />
                </div>
                <div>
                    <AiOutlinePrinter onClick={this.btnPrintClickedHandler} size={25} />
                </div>
            </div>
            // <button onClick={this.btnClickedHandler} className="gridbtn">Delete</button>
        )
    }
}
export default RequisitionListing;
