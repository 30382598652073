import React, { useState, useEffect } from "react";
import {
    CardElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import { createHubConnection, invokeBroadCastMessage } from "../../../services/signalRService";
import ApiService from "../../../services/apiService";
import userManagement from "../../../services/userManagement";

const stripePromise = loadStripe("pk_test_51Kku1CLBbHIb8JaUQmSNv3YkbJHrbH1u2GaA2mkAOChyuEVYFAVHi7UotKNDnt2cSBBsYz9ebgndtPdkoXToInXP00fLEPzoyA");

const CashStripe = ({ handlePaymentSuccess, data, cardDetails, total, Currency, comboCart }) => {

    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [Owner, setOwner] = useState('');
    const [cardOwnerError, setCardOwnerError] = useState('');
    const [saveCard, setSaveCard] = useState(false);

    const handleInputChange = (owner) => {
        setOwner(owner);
        setCardOwnerError('');
    };

    const handleCheckboxChange = (e) => {
        setSaveCard(e.target.checked);
    };

    const mapDynamicMetaData = (item) => {
        const result = [];
        if (item?.selectedModifier) {
            result.push(...item.selectedModifier?.map((modifier) => ({
                metaItemId: parseInt(modifier?.metaItemId),
                type: parseInt(modifier?.metaType),
                menuItemId: parseInt(item?.itemData?.id || item?.itemData?.itemId),
                metaItemIndex: parseInt(item?.index)
            })));
        }

        if (item?.selectedAdOn) {
            result.push(...item.selectedAdOn?.map((adOn) => ({
                metaItemId: parseInt(adOn?.metaItemId),
                type: parseInt(adOn?.metaType),
                menuItemId: parseInt(item?.itemData?.id || item?.itemData?.itemId),
                metaItemIndex: parseInt(item?.index)
            })));
        }

        if (item?.selectedVariant) {
            if (Array.isArray(item.selectedVariant)) {
                result.push(...item.selectedVariant.map((variant) => ({
                    metaItemId: parseInt(variant?.metaItemId),
                    type: parseInt(variant?.metaType),
                    menuItemId: parseInt(item?.itemData?.id || item?.itemData?.itemId),
                    metaItemIndex: parseInt(item?.index)
                })));
            } else {
                result.push({
                    metaItemId: parseInt(item?.selectedVariant?.metaItemId),
                    type: parseInt(item?.selectedVariant?.metaType),
                    menuItemId: parseInt(item?.itemData?.id || item?.itemData?.itemId),
                    metaItemIndex: parseInt(item?.index)
                });
            }
        }
        return result;
    };

    const validateForm = () => {
        let isValid = true;
        if (!Owner) {
            setCardOwnerError("Card Owner is required.");
            isValid = false;
        }
        return isValid;
    };

    useEffect(() => {
        const handleReceiveMessage = (user, message) => {
            console.log(`Received message from ${user}: ${message}`);
        };

        const handleReceiveAck = (user, message) => {
            console.log(`Received acknowledgment from ${user}: ${message}`);
        };

        const handleReceiveRejection = (user, message) => {
            console.log(`Received rejection from ${user}: ${message}`);
        };

        const hubConnection = createHubConnection(
            handleReceiveMessage,
            handleReceiveAck,
            handleReceiveRejection
        );

        return () => {
            hubConnection.stop();
        };
    }, []);

    const totalTax = data.reduce((acc, item) => {
        acc += (parseInt(item.itemData.tax) || 0);
        acc += item.selectedModifier.reduce((modifierAcc, modifier) => {
            modifierAcc += (parseInt(modifier.metaItemTax) || 0);
            return modifierAcc;
        }, 0);
        acc += item.selectedAdOn.reduce((addonAcc, addon) => {
            addonAcc += (parseInt(addon.metaItemTax) || 0);
            return addonAcc;
        }, 0);
        return acc;
    }, 0);

    const totalDiscount = data.reduce((acc, item) => {
        acc += (parseInt(item.itemData.discount) || 0);
        acc += item.selectedModifier.reduce((modifierAcc, modifier) => {
            modifierAcc += (parseInt(modifier.metaItemDiscount) || 0);
            return modifierAcc;
        }, 0);
        acc += item.selectedAdOn.reduce((addonAcc, addon) => {
            addonAcc += (parseInt(addon.metaItemDiscount) || 0);
            return addonAcc;
        }, 0);
        return acc;
    }, 0);

    const handlePayment = async (clientSecret) => {
        const userId = userManagement.getUserId();

        try {
            handlePaymentSuccess();
            if (saveCard) {
                await saveCardDetails(clientSecret);
            }
            else {
                toast.warning("Something is wrong in your order..Try again please!!");
            }

        } catch (error) {
            console.error("Error:", error);
        }
    };

    const saveCardDetails = async (clientSecret) => {
        const apiService = new ApiService();
        const userId = userManagement.getUserId();

        try {
            const body = JSON.stringify({
                userId: userId?.toString(),
                clientSecret: clientSecret,
                cardNumber: cardDetails.cardNumber,
                cardHolderName: Owner,
            });

            const response = await apiService.post("CREATE_CARD_DETAIL", body);

            if (response.data.statusCode === 201) {
                toast.success("Card details saved successfully!");
            } else {
                toast.warning("Failed to save card details.");
            }
        } catch (error) {
            console.error("Error saving card details:", error);
            toast.error("Error saving card details.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        if (!validateForm()) {
            return;
        }
        setIsLoading(true);

        try {
            const { clientSecret } = await fetchPaymentIntent();

            const { paymentIntent, error: confirmPaymentError } =
                await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: elements.getElement(CardElement),
                        billing_details: {
                            name: Owner,
                        },
                    },
                });

            if (confirmPaymentError) {
                setMessage(`Payment failed: ${confirmPaymentError.message}`);
            } else {
                setMessage("Payment succeeded!");
                handlePayment(clientSecret);
            }
        } catch (error) {
            setMessage(`Payment failed: ${error.message}`);
        }

        setIsLoading(false);
    };

    const fetchPaymentIntent = async () => {
        const amountInCents = Math.round(total * 100);
        if (Owner) {
            const body = JSON.stringify({
                amount: amountInCents,
                currency: "usd",
            });
            const apiService = new ApiService();

            try {
                const response = await apiService.post("CREATE_PAYMENT_INTENT", body);
                return response.data;
            } catch (error) {
                console.error("Error fetching payment intent:", error);
                throw error;
            }
        } else {
            toast.warning("Please Enter Card Details")
        }
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit} className="payment-form">
            <div className="payment-form-header">
                <h2 className="payment-form-title">Payment</h2>
                <div className="payment-form-line"></div>
            </div>

            <div className="form-group">
                <label htmlFor="username">
                    <h6>Card Owner</h6>
                </label>
                <input
                    type="text"
                    name="cardOwner"
                    placeholder="Card Owner Name"
                    className={`form-control ${cardOwnerError ? 'is-invalid' : ''}`}
                    onChange={(e) => handleInputChange(e.target.value)}
                />
                {cardOwnerError && <div className="invalid-feedback">{cardOwnerError}</div>}
            </div>
            <div className="form-group py-4">
                <label htmlFor="cardNumber">
                    <h6>Card Details</h6>
                </label>
                <CardElement id="card-element" className="card-element form-control" />
            </div>
            <div className="form-group form-check">
                <input
                    className="form-check-input"
                    type="checkbox"
                    name="saveCard"
                    id="saveCard"
                    checked={saveCard}
                    onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="saveCard">
                    Save card details
                </label>
            </div>
            <button className="btn btn-primary w-100" type="submit" disabled={isLoading}>
                {isLoading ? `Paying ${Currency.currency.name} ${(total + totalTax) - totalDiscount}` : `Pay ${Currency.currency.name} ${(total + totalTax) - totalDiscount}`}
            </button>
            <div className="payment-form-description py-4">
                Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.
            </div>
            {message && <div className="payment-message">{message}</div>}
        </form>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(CashStripe);
