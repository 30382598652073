import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/apiService';
import Select from 'react-select';
import "./reports.css"
import { RiSkipLeftFill, RiSkipRightFill } from "react-icons/ri";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";
import { SlRefresh } from "react-icons/sl";
import { HiOutlinePrinter } from "react-icons/hi2";
import { PiDownloadSimple } from "react-icons/pi";
import moment from 'moment';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { BsFileEarmarkPdf, BsFiletypeCsv } from "react-icons/bs";
import { LiaFileExcel } from "react-icons/lia";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

const ItemReports = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [StockType, setStockType] = useState();
    const [PageNo, setPageNo] = useState();
    const [pageSize, setPageSize] = useState(20);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [pagination, setPagination] = useState();
    const [Location, setLocation] = useState([]);
    const gridRef = useRef();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [emailModal, setEmailModal] = useState(false);
    const onOpenModal = () => setEmailModal(true);
    const onCloseModal = () => setEmailModal(false);
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const [formData, setFormData] = useState({
        ItemNo: "",
        email: "",
        ItemDec: "",
        Category: [{ label: 'Select Category', value: 'Select Category' }],
        SubCategory: [{ label: 'Select Sub Category', value: 'Select Sub Category' }],
        StockType: [{ label: 'Select Stock Type', value: 'Select Sub Category' }],
        Warehouse: [{ label: 'Select Ware House', value: 'Select Sub Category' }],
    });

    let navigate = useNavigate();
    const staticOptions = [];

    useEffect(() => {
        getItems();
    }, [formData, pageSize]);
    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const apiService = new ApiService();
                let param = `?isActive=true`
                const categoryResponse = await apiService.getApiParamater("GET_CATEGORY", param);
                setCategoryOptions(categoryResponse?.data?.result);
                const subCategoryResponse = await apiService.getApiParamater("GET_SUB_CATEGORY", param);
                setSubCategoryOptions(subCategoryResponse?.data?.result);
                const stockType = await apiService.getApiParamater("GET_STOCK_TYPE", param);
                setStockType(stockType?.data?.result);
                let res = await apiService.getApiParamater('GET_WAREHOUSE_MASTER', param)
                setLocation(res?.data?.result)
            } catch (error) {
                console.error('Error fetching dropdown data:', error);
            }
        };
        fetchDropdownData();
        getItems()
    }, []);
    const getItems = async (pageChange) => {
        try {
            const apiService = new ApiService();
            const currentDatetimeUTC = new Date().toISOString();
            const params = new URLSearchParams();
            if (formData?.ItemNo) {
                params.append('ItemNo', formData.ItemNo.toString());
            }
            if (formData?.ItemDec) {
                params.append('ItemDescription', formData.ItemDec);
            }
            params.append('Category', formData.Category?.value?.catCode || "0");
            params.append('SubCategory', formData.SubCategory?.value?.subcatcode || "0");
            params.append('StockType', formData.StockType?.value?.stockTypeCode || "0");
            params.append('Warehouse', formData.Warehouse?.label || "0");
            params.append('AsOnDate', currentDatetimeUTC);

            let newPageNo = pagination?.currentPage;

            if (pageChange === "next") {
                newPageNo += 1;
            } else if (pageChange === "prev") {
                newPageNo -= 1;
            } else if (pageChange === "skipNext") {
                newPageNo += 10;
            } else if (pageChange === "skipPrev") {
                newPageNo -= 10;
            }

            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);

            const res = await apiService.getApiParamater('GET_ITEMS_REPORT', `?${params.toString()}`);
            setRowData(res?.data?.result?.reverse() || []);
            setPagination(res.data.paginationData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const handleCategoryChange = (selectedOption) => {
        setFormData({ ...formData, Category: selectedOption });
    }
    const handleSubCategoryChange = (selectedOption) => {
        setFormData({ ...formData, SubCategory: selectedOption });
    }
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleStockChange = (selectedOption) => {
        setFormData({ ...formData, StockType: selectedOption });
    }
    const handlelocChange = (selectedOption) => {
        setFormData({ ...formData, Warehouse: selectedOption });
    };
    const onBtnCSVExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: 'itemsReport.csv'
        });
    }, []);
    const onBtExcelExport = useCallback(() => {
        gridRef.current.api.exportDataAsExcel({
            fileName: 'itemsReport.xlsx'
        });
    }, []);
    const onBtPDFExport = useCallback(() => {
        const doc = new jsPDF();
        const rowData = [];
        const columnHeaders = [];
        const comparisonFields = ['itemNo', 'itemDescription', 'category', 'subCategory', 'stockType', 'unitMeasurement', 'warehouseLocation', 'purchasePrice'];

        Comparison.forEach(col => {
            columnHeaders.push(col.headerName);
        });

        gridRef.current.api.forEachNode((node) => {
            const row = [];
            comparisonFields.forEach(field => {
                row.push(node.data[field]);
            });
            rowData.push(row);
        });

        const poppinsFont = 'data:font/ttf;base64,AAEAAAARAQAABAAw7HA...';
        doc.addFileToVFS("Poppins-Regular.ttf", poppinsFont);
        doc.addFont("Poppins-Regular.ttf", "Poppins", "normal");
        doc.setFont("Poppins");
        doc.setFontSize(18);
        doc.setTextColor(40, 40, 40);
        doc.text("Item Master Report", 75, 12);
        doc.text("__________________________", 60, 15);
        doc.setLineWidth(2);
        doc.autoTable({
            head: [columnHeaders],
            body: rowData,
            styles: {
                fontSize: 6,
            },
            headStyles: {
                fillColor: [209, 126, 6],
                textColor: [255, 255, 255],
                fontSize: 6,
            },
            columnStyles: {
                0: { cellWidth: 19 },
                1: { cellWidth: 19 },
                2: { cellWidth: 19 },
                3: { cellWidth: 19 },
                4: { cellWidth: 19 },
                5: { cellWidth: 19 },
                6: { cellWidth: 19 },
                7: { cellWidth: 19 },
                8: { cellWidth: 15 },
                9: { cellWidth: 15 },
                10: { cellWidth: 15 }
            },
            startY: 20,
            theme: 'striped'
        });
        doc.save('itemsReport.pdf');
    }, []);
    const handlePageSizeChange = (event) => {
        const newSize = Number(event.target.value);
        setPageSize(newSize);
        gridRef.current.api.paginationSetPageSize(newSize);
    };
    const handleClearFilters = () => {
        setFormData({
            ItemNo: "",
            ItemDec: "",
            email: "",
            Category: [{ label: 'Select Category', value: 'Select Category' }],
            SubCategory: [{ label: 'Select Sub Category', value: 'Select Sub Category' }],
            StockType: [{ label: 'Select Stock Type', value: 'Select Sub Category' }],
            Warehouse: [{ label: 'Select Ware House', value: 'Select Sub Category' }],
        });
    };
    const Comparison = [
        { headerName: 'Item Code', field: 'itemNo', filter: true, floatingFilter: true, width: 120 },
        { headerName: 'Description ', field: 'itemDescription', filter: true, floatingFilter: true, width: 130 },
        { headerName: 'Category', field: 'category', filter: true, floatingFilter: true, width: 130 },
        { headerName: 'Sub Category', field: 'subCategory', filter: true, floatingFilter: true, width: 150 },
        { headerName: 'Stock Type', field: 'stockType', filter: true, floatingFilter: true, width: 130 },
        { headerName: 'Packing UOM', field: 'baseUnit', filter: true, floatingFilter: true, width: 130 },
        { headerName: 'Inventory UOM', field: 'unitMeasurement', filter: true, floatingFilter: true, width: 125 },
        {
            headerName: 'Packing QTY', field: 'qty', filter: true, floatingFilter: true, width: 130,
            valueFormatter: (params) => params?.value?.toFixed(2)
        },
        { headerName: 'Inventory Qty', field: 'inventoryQty', filter: true, floatingFilter: true, width: 140 },
        { headerName: 'Warehouse', field: 'warehouseLocation', filter: true, floatingFilter: true, width: 110 },
        {
            headerName: 'Unit Price', field: 'price', filter: true, floatingFilter: true, width: 150,
            valueFormatter: (params) => params?.value?.toFixed(2)
        },
        {
            headerName: 'Amount', field: 'amount', filter: true, floatingFilter: true, width: 110,
            valueFormatter: (params) => params?.value?.toFixed(2)
        },
    ];
    const SendEmail = async () => {
        try {
            if (!formData.email) {
                toast.warning("Enter User Email to send Report")
                return;
            }
            const requestBody = {
                // toEmail: "shahzaibawan864@gmail.com",
                toEmail: formData.email,
                subject: "Item Report",
                body: `
                <div className='printhead2 mb-5 px-4'>
                    <table>
                        <thead className='dynoTable' style='background-color: #6E5199; color: #fff;'>
                            <tr>
                                <th>Item Code</th>
                                <th>Description</th>
                                <th>Category</th>
                                <th>Sub Category</th>
                                <th>Stock Type</th>
                                <th>Packing UOM</th>
                                <th>Inventory UOM</th>
                                <th>Packing QTY</th>
                                <th>Inventory Qty</th>
                                <th>Warehouse</th>
                                <th>Price</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${rowData?.map((row, index) => `
                                <tr key=${index}>
                                    <td className='m-0 p-0'><input style='width: 100px;' className='p-2' type='text' value='${row.itemNo}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' disabled value='${row.itemDescription}' /></td>
                                    <td className='m-0 p-0'><input style='width: 140px;' className='p-2' disabled value='${row.category}' /></td>
                                    <td className='m-0 p-0'><input style='width: 140px;' className='p-2' disabled value='${row.subCategory}' /></td>
                                    <td className='m-0 p-0'><input style='width: 80px;' className='p-2' disabled value='${row.stockType}' /></td>
                                    <td className='m-0 p-0'><input style='width: 150px;' className='p-2' disabled value='${row.baseUnit}' /></td>
                                    <td className='m-0 p-0'><input style='width: 120px;' className='p-2' disabled value='${row.unitMeasurement}' /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' disabled value='${row.qty}' /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.inventoryQty}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.warehouseLocation}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' type='text' value='${row.price}' disabled /></td>
                                    <td className='m-0 p-0'><input className='p-2 w-100' type='text' value='${row.amount?.toFixed(2) || 0}' disabled /></td>
                                </tr>
                            `).join('')}
                        </tbody>
                    </table>
                </div>`
            }
            const apiService = new ApiService();
            const response = await apiService.post("SEND_EMAIL", requestBody);
            if (response.status === 200) {
                onCloseModal()
                toast.success("Email send successfully")
                handleClearFilters()
            }
            debugger
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <div className='p-4'>
            <h4 className='py-3 mainInvHead' >
                Item Master Report
            </h4>
            <div className=' justify-content-between d-flex pb-4 gap-3'>
                <div className='col'>
                    <input
                        type='text'
                        className="form-Input"
                        value={formData.ItemNo}
                        name="ItemNo"
                        onChange={handleInputChange}
                        placeholder='Item No'
                    />
                </div>
                <div className='col'>
                    <input
                        type='text'
                        className="form-Input"
                        value={formData.ItemDec}
                        name="ItemDec"
                        onChange={handleInputChange}
                        placeholder='Item Description'
                    />
                </div>
                <div className='col'>
                    <Select
                        className="p-0"
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            padding: 9
                        })}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        value={formData?.Category}
                        onChange={(selectedOption) => handleCategoryChange(selectedOption)}
                        options={categoryOptions?.map((CatOpt) => ({ value: CatOpt, label: CatOpt?.catName }))}
                    />
                </div>
                <div className='col'>
                    <Select
                        className="p-0"
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            padding: 9
                        })}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        value={formData?.SubCategory}
                        onChange={(selectedOption) => handleSubCategoryChange(selectedOption)}
                        options={subCategoryOptions?.map((SubCatOpt) => ({ value: SubCatOpt, label: SubCatOpt?.subcatname }))}
                    />
                </div>
                <div className='col'>
                    <Select
                        className="p-0"
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            padding: 9
                        })}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        value={formData.StockType}
                        onChange={(selectedOption) => handleStockChange(selectedOption)}
                        options={StockType?.map((Stock) => ({ value: Stock, label: Stock?.stockTypeName }))}
                    />
                </div>
                <div className='col'>
                    <Select
                        className="p-0"
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            padding: 9
                        })}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        value={formData?.Warehouse}
                        onChange={(selectedOption) => handlelocChange(selectedOption)}
                        options={Location?.map((loc) => ({ value: loc, label: loc?.description }))}
                    />
                </div>
            </div>
            <div className='pageBodr'>
                <div className='row'>
                    <div className='col-6 d-flex gap-5'>
                        <div className='arrows d-flex gap-5'>
                            {pagination?.totalPages < 10 ?
                                <div className='center'><RiSkipLeftFill size={30} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><RiSkipLeftFill size={30} onClick={() => getItems("skipPrev")} /></div>
                            }
                            {pagination?.currentPage === 1 ?
                                <div className='center'><FaAngleLeft size={25} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><FaAngleLeft size={25} onClick={() => getItems("prev")} /></div>
                            }
                            <div className='d-flex gap-3 center'>
                                <div className=''>{pagination?.currentPage}</div>
                                <div className=''>of</div>
                                <div className=''>{pagination?.totalPages}</div>
                            </div>
                            {pagination?.currentPage === pagination?.totalPages ?
                                <div className='center'><FaAngleRight size={25} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><FaAngleRight size={25} onClick={() => getItems("next")} /></div>
                            }
                            {pagination?.totalPages > 10 ?
                                <div className='center'><RiSkipRightFill size={30} onClick={() => getItems("skipNext")} /></div> :
                                <div className='center'><RiSkipRightFill size={30} style={{ color: "#ABA3A3" }} /></div>
                            }
                        </div>
                        <div className="gap-4 d-flex">
                            <div className='center'>Page Size</div>
                            <div>
                                <select
                                    className="form-select pagclr"
                                    aria-label="Default select example"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                >
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 '>
                        <div className='end arrows d-flex gap-4'>
                            <div className='center' onClick={handleClearFilters}><SlRefresh size={30} style={{ color: "5B93FF" }} /></div>
                            <div className='center'>
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                                        <PiDownloadSimple size={30} style={{ color: "5B93FF" }} />
                                    </DropdownToggle>
                                    <DropdownMenu className='mt-3'>
                                        <DropdownItem onClick={onBtnCSVExport} className='gap-3 d-flex center'><BsFiletypeCsv /><div>Export CSV</div></DropdownItem>
                                        {/* <DropdownItem onClick={onBtExcelExport} className='gap-3 d-flex center'><LiaFileExcel/><div>Export Excel</div></DropdownItem> */}
                                        <DropdownItem onClick={onBtPDFExport} className='gap-3 d-flex center'><BsFileEarmarkPdf /><div>Export PDF</div></DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <div className='center'><HiOutlinePrinter size={30} style={{ color: "5B93FF" }} /></div>
                            <div className='center'><TfiEmail size={28} style={{ color: "5B93FF" }} onClick={onOpenModal} /></div>
                            <Modal size="md"
                                show={emailModal} onHide={onCloseModal}
                                onRequestClose={onCloseModal}
                                contentLabel='Email Modal'
                                style={customStyles} closeButton
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title className='center'>Send Email</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        <input
                                            type='email'
                                            className="form-Input"
                                            id="validationCustom02"
                                            value={formData.email}
                                            name="email"
                                            onChange={handleInputChange}
                                            placeholder='Enter Email'
                                        />
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className='end p-3'>
                                    <button className='AddBtn' onClick={SendEmail} style={{ background: "#6E5199" }}>Save</button>
                                </Modal.Footer>
                            </Modal >
                        </div>
                    </div>
                </div>
            </div>
            <div className='brdor'>
                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        rowSelection={'single'}
                        columnDefs={Comparison}
                        ref={gridRef}
                        suppressExcelExport={true}
                    />
                </div>
            </div>
        </div>
    );
};
export default ItemReports;
const customStyles = {
    content: {
        width: '30%', // Set the desired width
        height: '75%', // Set the desired 
        top: '50%', // Center vertically
        left: '50%', // Center horizontally
        right: '50%', // Center horizontally
        transform: 'translate(-50%, -50%)', // Center both vertically and horizontally
    },
};