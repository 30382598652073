import React, { useState, useEffect } from 'react';
import ImageUpload from '../menus/addItems/imagUpload';
import "./master.css";
import ApiService from '../../../services/apiService';
import userManagement from '../../../services/userManagement';
import { toast } from 'react-toastify';
import { ReactComponent as Delete } from '../../../assets/images/svgIcons/Delete.svg';
import { BiEdit } from 'react-icons/bi';

const AddVarients = () => {

    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        getVariants();
    }, [searchTerm]);

    const [formData, setFormData] = useState({
        variantName: '',
        variantsList: [],
    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const getVariants = async () => {
        try {
            const apiService = new ApiService();
            let parameters = `?Name=${searchTerm}`;
            const res = await apiService.getApiParamater('GET_VARIANTS', parameters);
            setFormData({ ...formData, variantsList: res.data.result });
        } catch (error) {
            console.error('Error fetching Variants data:', error);
        }
    };

    const createVariant = async () => {
        try {
            const userId = userManagement.getUserId();
            const { variantName } = formData;

            if (!variantName) {
                toast.error("Please enter a variant name");
                return;
            }

            const body = {
                loggedInUserId: userId,
                name: variantName,
                active: true,
            };

            const apiService = new ApiService();
            const res = await apiService.post('CREATE_VARIANTS', body);

            if (res?.data?.statusCode === 201) {
                toast.success("Variant Created Successfully");
                setFormData({ ...formData, variantName: '' });
                getVariants(); // Refresh the variants list after creating a new variant
            }
        } catch (error) {
            console.error('Error creating variant:', error);
        }
    };

    const handleUpdateVariant = async (variants, newActiveStatus) => {
        try {
            const apiService = new ApiService();
            const catData = {
                id: variants?.id,
                name: variants?.name,
                loggedInUserId: variants?.loggedInUserId,
                active: newActiveStatus,
            };
            const res = await apiService.put(`UPDATE_VARIANTS`, catData);
            if (res?.data?.statusCode === 200) {
                toast.success(`Variant ${variants?.name} updated successfully!`);
                getVariants();
            } else {
                toast.error("Failed to update Variant. Please try again!");
            }
        } catch (error) {
            console.error('Error updating Variant:', error);
        }
    };

    const handleSwitchChange = async (variants, newActiveStatus) => {
        try {
            await handleUpdateVariant(variants, newActiveStatus);
        } catch (error) {
            console.error('Error updating Variant:', error);
        }
    };

    return (
        <div className='p-5'>
            <div className='p-5 container'>
                <div className='catlist p-5'>
                    <div className='row '>
                        <div className="col">
                            <label htmlFor="validationCustom01" className="form-label">Variants *</label>
                            <input
                                type="text"
                                className="p-2 form-control"
                                id="validationCustom01"
                                name="variantName"
                                value={formData.variantName}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                    </div>
                    <div className='row  pt-3 '>
                        <div className='end'><button onClick={createVariant} className='addItem-btn' >Save</button></div>
                    </div>
                </div>
                <div className='col-lg-4  mt-5 mb-3 pt-4'>
                    <input
                        type='Search'
                        className='form-control'
                        id='validationCustom01'
                        placeholder='Search Variants'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className='valid-feedback'>Looks good!</div>
                </div>
                <div style={{ height: "470px" }} className='catlist table-responsive'>
                    <table className='table table-striped mt-3'>
                        <thead>
                            <tr>
                                <th scope='col'>#</th>
                                <th scope='col'>Variant Name</th>
                                <th scope='col'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {formData?.variantsList?.length === 0 ? (
                                <tr>
                                    <td colSpan="3" className="text-center">No variants found</td>
                                </tr>
                            ) : (
                                formData?.variantsList?.map((variant, index) => (
                                    <tr key={index}>
                                        <th scope='row'>{index + 1}</th>
                                        <td>{variant.name}</td>
                                        <td>
                                            <div className="d-flex text-center">
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id={`flexSwitchCheck${variant.id}`}
                                                        checked={variant.active}
                                                        onChange={(e) => handleSwitchChange(variant, e.target.checked)}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AddVarients;
