import React, { useState } from "react";
import '../supplier/supplier.css'
import { useLocation, useParams } from 'react-router-dom';
import PriceCompNav from "./pCNavBar";
import PriceCompCreate from "./priceCompCreate";
import AuthorizationList from "./authorization";
import MessageList from "./messageList";


const PriceComparision = () => {
    const { id } = useParams()
    const { state } = useLocation()

    return (
        <div className="p-3">
            <div className="row px-lg-5">
                {(() => {
                    switch (id) {
                        case "price":
                            return <> <PriceCompNav id={id} state={state} /><PriceCompCreate state={state} /></>
                        case "authorization":
                            return <> <PriceCompNav id={id} state={state} /><AuthorizationList state={state} /></>
                        case "message":
                            return <> <PriceCompNav id={id} state={state} /><MessageList state={state} /></>
                        default:
                            return <><PriceCompNav id={"price"} /><PriceCompCreate /></>
                    }
                })()}
            </div>
        </div>
    );
}
export default PriceComparision;