import React, { useState, useEffect } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import ApiService from "../../services/apiService";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import  {createHubConnection, invokeBroadCastMessage } from '../../services/signalRService';
import userManagement from "../../services/userManagement";
import { useNavigate } from 'react-router-dom';
import { connect } from "react-redux";

const stripePromise = loadStripe("pk_test_51Kku1CLBbHIb8JaUQmSNv3YkbJHrbH1u2GaA2mkAOChyuEVYFAVHi7UotKNDnt2cSBBsYz9ebgndtPdkoXToInXP00fLEPzoyA");

const StripeForm = ({ handlePaymentSuccess, data, cardDetails,total,Currency ,comboCart}) => {
  
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [Owner, setOwner] = useState();


  const handleInputChange = (owner) => {
    
    setOwner(owner);
    setCardOwnerError('');

  };
  const [cardOwnerError, setCardOwnerError] = useState('');

  const mapDynamicMetaData = (item) => {

    const result = [];
    if (item?.selectedModifier) {

        result.push(...item.selectedModifier?.map((modifier) => ({
            metaItemId: parseInt(modifier?.metaItemId),
            type: parseInt(modifier?.metaType),
            menuItemId: parseInt(item?.itemData?.id || item?.itemData?.itemId),
            metaItemIndex: parseInt(item?.index)
        })));
    }

    if (item?.selectedAdOn) {
        result.push(...item.selectedAdOn?.map((adOn) => ({
            metaItemId: parseInt(adOn?.metaItemId),
            type: parseInt(adOn?.metaType),
            menuItemId: parseInt(item?.itemData?.id || item?.itemData?.itemId),
            metaItemIndex: parseInt(item?.index)
        })));
    }

    if (item?.selectedVariant) {
        if (Array.isArray(item.selectedVariant)) {
            result.push(...item.selectedVariant.map((variant) => ({
                metaItemId: parseInt(variant?.metaItemId),
                type: parseInt(variant?.metaType),
                menuItemId: parseInt(item?.itemData?.id || item?.itemData?.itemId),
                metaItemIndex: parseInt(item?.index)

            })));
        } else {
            result.push({
                metaItemId: parseInt(item?.selectedVariant?.metaItemId),
                type: parseInt(item?.selectedVariant?.metaType),
                menuItemId: parseInt(item?.itemData?.id || item?.itemData?.itemId),
                metaItemIndex: parseInt(item?.index)

            });
        }
    }


    return result;
};
  const validateForm = () => {
    let isValid = true;

    // if (!Owner.trim()) {
    //   setCardOwnerError('Card owner name is required');
    //   isValid = false;
    // }

    // Add more validations as needed

    return isValid;
  };
  useEffect(() => {
    const initializeStripe = async () => {
      try {
        const stripeInstance = await stripePromise;
        const elementsInstance = stripeInstance.elements();

        const cardNumberElement = elementsInstance.create("cardNumber", {
          style: {
            base: {
              fontSize: "16px",
              color: "#32325d",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
          },
        });
        cardNumberElement.mount("#card-number-element");

        const cardExpiryElement = elementsInstance.create("cardExpiry", {
          style: {
            base: {
              fontSize: "16px",
              color: "#32325d",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
          },
        });
        cardExpiryElement.mount("#card-expiry-element");

        const cardCvcElement = elementsInstance.create("cardCvc", {
          style: {
            base: {
              fontSize: "16px",
              color: "#32325d",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
          },
        });
        cardCvcElement.mount("#card-cvc-element");
      } catch (error) {
        console.error("Error initializing Stripe:", error);
      }
    };

    initializeStripe();


  }, [stripePromise]);
  useEffect(() => {
    const handleReceiveMessage = (user, message) => {
      // Handle received message
      console.log(`Received message from ${user}: ${message}`);
    };

    const handleReceiveAck = (user, message) => {
      // Handle received acknowledgment
      console.log(`Received acknowledgment from ${user}: ${message}`);
    };

    const handleReceiveRejection = (user, message) => {
      // Handle received rejection
      console.log(`Received rejection from ${user}: ${message}`);
    };

    // Initialize SignalR connection
    const hubConnection = createHubConnection(
      handleReceiveMessage,
      handleReceiveAck,
      handleReceiveRejection
    );

    return () => {
      hubConnection.stop();
    };
  }, []);
  const totalTax = data.reduce((acc, item) => {
    // Include item tax
    
    acc += (parseInt(item.itemData.tax) || 0);

    // Include selected modifier tax
    acc += item.selectedModifier.reduce((modifierAcc, modifier) => {
        modifierAcc += (parseInt(modifier.metaItemTax) || 0);
        return modifierAcc;
    }, 0);

    // Include selected addon tax
    acc += item.selectedAdOn.reduce((addonAcc, addon) => {
        addonAcc += (parseInt(addon.metaItemTax) || 0);
        return addonAcc;
    }, 0);

    return acc;
}, 0);
// Calculate total discount including item, selected modifiers, and selected addons
const totalDiscount = data.reduce((acc, item) => {
// Include item discount
acc += (parseInt(item.itemData.discount) || 0);

// Include selected modifier discount
acc += item.selectedModifier.reduce((modifierAcc, modifier) => {
  modifierAcc += (parseInt(modifier.metaItemDiscount) || 0);
  return modifierAcc;
}, 0);

// Include selected addon discount
acc += item.selectedAdOn.reduce((addonAcc, addon) => {
  addonAcc += (parseInt(addon.metaItemDiscount) || 0);
  return addonAcc;
}, 0);

return acc;
}, 0);

const handlePayment = async (clientSecret) => {
  const userId = userManagement.getUserId();
  
  try {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString();

      // Construct the order request
      const datareq1 = {
          orderDate: formattedDate,
          orderType: 2,
          orderSource: 0,
          orderStatus: 0,
          orderAmount: (parseInt(total) + parseInt(totalTax)) - parseInt(totalDiscount),
          calculatedTax: totalTax,
          totalDiscount: totalDiscount,
          amountCollected: (parseInt(total) + parseInt(totalTax)) - parseInt(totalDiscount),
          amountReturned: 0,
          customerType: 4,
          customerId: userId?.toString() || "Guest",
          customerName: Owner || "Guest",
          paymentStatus: 2,
          invoiceNumber: "string",
          orderItems: [
              ...(data ? data.flatMap((item, index) => ({
                  itemId: parseInt(item.itemData.id || item?.itemData?.itemId),
                  quantity: parseInt(item.quantity),
                  kitchenInstructions: item.specialInstructions,
                  itemIndex: index
              })) : []),
              ...(comboCart.items ? comboCart.items.flatMap((item, index) => {
                  const comboQuantity = parseInt(item.quantity) || 1;

                  return item.comboItems.map(comboItem => ({
                      itemId: comboItem.itemId,
                      quantity: comboQuantity,
                      kitchenInstructions: item.specialInstructions || "",
                      itemIndex: index
                  }));
              }) : [])
          ],
          orderItemsMeta: [
              ...(data ? data?.flatMap(mapDynamicMetaData) : []),
              ...(comboCart.items ? comboCart.items.flatMap((item, index) => {
                  const comboQuantity = parseInt(item.quantity) || 1;

                  return item.comboItems.flatMap(modifier => modifier.metaItemIds.map(metaItemId => ({
                      metaItemId: parseInt(metaItemId),
                      type: parseInt(3),
                      menuItemId: parseInt(modifier.itemId),
                      metaItemIndex: parseInt(index)
                  })));
              }) : [])
          ],
          loggedInUserId: userId || "Guest",
      };

      const apiService = new ApiService();

      let res = await apiService.post("CREATE_ORDER", datareq1);
      
      if (res?.data?.statusCode === 201) {
          // Invoke SignalR method send broad cast message here
          invokeBroadCastMessage("New Order Placed");

          // Show success toast outside the cross-origin frame
          toast.success("Order Created Successfully");
          let datareq = {
            orderId: res?.data?.result?.id.toString(),
            paymentDate: new Date().toISOString(),
            paymentAmount: res?.data?.result?.orderAmount,
            paymentMethod: 2,
            paymentStatus: 2,
            transactionId:clientSecret ,
            calculatedTax:parseInt(totalTax),
            orderAmount:parseInt(total),
            loggedInUserId: userId || "Guest"
        }
        let res2 = await apiService.post("CREATE_PAYMENT", datareq)
          handlePaymentSuccess();
      } else {
          // Show error toast outside the cross-origin frame
          toast.warning("Something is wrong in your order..Try again please!!");
      }

  } catch (error) {
      console.error("Error:", error);
  }
};

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    try {

      const userRole = userManagement.getUserRole();

      const { clientSecret } = await fetchPaymentIntent();

      const { paymentIntent, error: confirmPaymentError } =
        await stripe.confirmCardPayment(clientSecret, {
          payment_method: 'pm_card_visa',
        });

      if (confirmPaymentError) {
        
        setMessage(`Payment failed: ${confirmPaymentError.message}`);
      } else {
        setMessage("Payment succeeded!");
        handlePayment(clientSecret);
      }
    } catch (error) {
      setMessage(`Payment failed: ${error.message}`);
    }

    setIsLoading(false);
  };

  const fetchPaymentIntent = async () => {
    const amountInCents = Math.round(total * 100);
    if(Owner)  {

    const body = JSON.stringify({
      amount: amountInCents,
      currency: "usd",
    });
    const apiService = new ApiService();

    try {
      const response = await apiService.post("CREATE_PAYMENT_INTENT", body);
      return response.data;
    } catch (error) {
      console.error("Error fetching payment intent:", error);
      throw error;
    }
  }
  else{
    toast.warning("Please Enter Card Details")
  }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="headTxt">Payment</div>
      <div className="lineBreak py-lg-3"></div>
      <div className="bodytxt pt-3">Pay with:</div>
      <div className="d-flex py-3">
        <div className="">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDisabled"
            id="Card"
            checked
            style={{ width: "20px", height: "20px" }}
          />
          <label className="form-check-label checkItem px-3" htmlFor="Card">
            Card
          </label>
        </div>
      </div>
      
      <div className="form-group">
        <label htmlFor="username">
          <h6>Card Owner</h6>
        </label>
        <input
          type="text"
          name="cardOwner"
          placeholder="Card Owner Name"
          className={`form-control-Payment w-100 ${cardOwnerError ? 'is-invalid' : ''}`}
          onChange={(e) => handleInputChange(e.target.value)}
        />
        {cardOwnerError && <div className="invalid-feedback">{cardOwnerError}</div>}
      </div>
      <div className="form-group py-4">
        <label htmlFor="cardNumber">
          <h6>Card number</h6>
        </label>
        <div id="card-number-element" className="card-element" />
      </div>
      <div className="row">
        <div className="col-sm-8">
          <div className="form-group">
            <label>
              <span className="hidden-xs">
                <h6>Expiration Date</h6>
              </span>
            </label>
            <CardExpiryElement className="form-control-Payment" />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group mb-4">
            {" "}
            <label data-toggle="tooltip" title="Three digit CV code on the back of your card">
              <h6>
                CVV <i className="fa fa-question-circle d-inline"></i>
              </h6>
            </label>{" "}
            <CardCvcElement className="form-control-Payment" />
          </div>
        </div>
      </div>
      <div className="d-flex gap-3">
        <input
          className="form-check-input"
          type="checkbox"
          name="flexRadioDisabled"
          id="save"
          checked={cardDetails.saveCardDetails}
          style={{ width: "16px", height: "16px" }}
          onChange={handleInputChange}
        />
        <h6 className="form-check-label" htmlFor="save">
          Save card details
        </h6>
      </div>
      {isLoading ? (
              <button className="w-100 AddBtn" disabled type="submit">
    
            Paying {(total + totalTax)-totalDiscount}
    {/* <Loader />  */}

          
                </button>
  ) : (
      <button className="w-100 AddBtn" type="submit">
    
    Pay {Currency.currency.name}   {parseInt((total) + parseInt(totalTax))-parseInt(totalDiscount)}

      </button>
  )}

      <div className="descrip py-4">
        Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.
      </div>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(StripeForm);

