import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';
import SupplierTypeGrid from './grid';
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import { hasPermission } from "../../services/roleService"
import Pagination from '../pagination/pagination';

const SupplierType = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [defaultSelection, setDefault] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();

    const [formData, setFormData] = useState({
        recordId: "",
        SupplierCode: "",
        SupplierType: "",
    });
    useEffect(() => {
        window.history.replaceState({}, '')
        const pack = location.state;
        setFormData({
            recordId: pack?.record_Id,
            SupplierType: pack?.description || "",
            SupplierCode: pack?.description_BI || "",
        });
        setDefault(pack?.isDefault)
    }, [location?.state]);
    useEffect(() => {
        fetchRoleData();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
        if (pageSize) {
            fetchRoleData();
        }
    }, [pageSize]);

    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    const canEditMaster = checkPermission('Supplier Type Master', 'edit');
    const canCreateMaster = checkPermission('Supplier Type Master', 'create');

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const fetchRoleData = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const response = await apiService.getApiParamater('GET_SUPPLIER_TYPE', `?${params.toString()}`);
            setPagination(response.data.paginationData);
            setRowData(response.data?.result);
        } catch (error) {
            console.error("Error fetching Currency data:", error);
        }
    };
    const handleSave = async () => {
        if (!formData.SupplierType || !formData.SupplierCode) {
            if (!formData.SupplierType) { toast.warning("Enter Supplier Type") }
            if (!formData.SupplierCode) { toast.warning("Enter Supplier Code") }
            return;
        }
        try {
            const apiService = new ApiService();
            let body = {
                description: formData?.SupplierType,
                description_BI: formData?.SupplierCode,
                createdBy: 'User',
                isActive: true
            }
            const response = await apiService.post('CREATE_SUPPLIER_TYPE', body);
            if (response?.data?.statusCode == 201) {

                fetchRoleData()
                toast.success("Supplier type created successfully");
                setFormData({
                    SupplierCode: "",
                    SupplierType: "",
                });
            } else if (response?.data?.statusCode === 208) {
                toast.error("Supplier type Already Exist");
            }
            else {
                toast.error("Failed to create supplier type");
            }
        } catch (error) {
            console.error("Error creating supplier type:", error);
            toast.error("Failed to create supplier type");
        }
    };
    const updateFormData = async () => {
        try {
            const apiService = new ApiService();
            let body = {
                record_Id: formData?.recordId,
                description_BI: formData?.SupplierCode,
                description: formData?.SupplierType,
            }
            const res = await apiService.put(`UPDATE_SUPPLIER_TYPE`, body);

            if (res?.data?.statusCode === 201) {
                toast.success('Form data updated successfully');
                setFormData({
                    recordId: "",
                    SupplierCode: "",
                    SupplierType: "",
                })
                fetchRoleData()
                setDefault(false)
                window.location.reload()
                window.history.replaceState({}, '')
            } else {
                console.error('Failed to update form data');
            }
        } catch (error) {
            console.error('Error updating form data:', error);
        }
    };

    return (
        <div className='px-4 pt-3'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Supplier Type Master</h5>
                <div className='d-flex justify-content-end gap-3'>
                    <div className='col center'>
                        {location.state ? (
                            <button className='mainbtn ' onClick={updateFormData}>Update</button>
                        ) : (
                            <>{canCreateMaster === true ?
                                <button className='mainbtn ' type="submit" onClick={() => handleSave()}>
                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                                :
                                <button className='mainbtn ' type="submit" style={{ cursor: "not-allowed" }}>
                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                            } </>
                        )}
                    </div>
                    <button className='mainbtnRed'><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                </div>
            </div>
            <div className='py-lg-3'>
                <div className='brderbox p-lg-3'>
                    <div className="form-group row">
                        <div className="col-lg-4">
                            <label htmlFor="validationCustom01" className="form-label">Supplier type Code</label>
                            <input
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                className="form-Input w-70"
                                id="validationCustom01"
                                disabled={canCreateMaster === true ? false : true}
                                name="SupplierCode"
                                value={formData.SupplierCode}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-lg-4">
                            <label htmlFor="validationCustom02" className="form-label">Supplier Type</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom02"
                                disabled={canCreateMaster === true ? false : true}
                                name="SupplierType"
                                value={formData?.SupplierType}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-2'>
                <SupplierTypeGrid fetchRoleData={fetchRoleData} rowData={rowData} canEditMaster={canEditMaster} />
            </div>
            <Pagination pagination={pagination} getItems={fetchRoleData} />

        </div>
    )
}

export default SupplierType;
