import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Search from "../../../assets/images/search.png"
import { useNavigate } from 'react-router-dom'

const DayOpeningList = () => {

    let navigate = useNavigate();

    const rowData = [
        { id: 1, branchName: 'Branch A', voucherCode: 'VC001', voucherDate: '2022-01-01', counter: '01', amount: 1000, shift: "Morning", payIn: 200, remark: "Tissue", details: 'Details 1' },
        { id: 2, branchName: 'Branch B', voucherCode: 'VC002', voucherDate: '2022-01-02', counter: '02', amount: 1200, shift: "Evening", payIn: 250, remark: "Tissue", details: 'Details 2' },
        // Add more rows as needed
    ];

    const columnDefs = [
        { headerName: 'Id', field: 'id', filter: true, floatingFilter: true },
        { headerName: 'Branch Name', field: 'branchName', filter: true, floatingFilter: true },
        { headerName: 'Voucher Code', field: 'voucherCode', filter: true, floatingFilter: true },
        { headerName: 'Voucher Date', field: 'voucherDate', filter: true, floatingFilter: true },
        { headerName: 'Counter', field: 'counter', filter: true, floatingFilter: true },
        { headerName: 'Amount', field: 'amount', filter: true, floatingFilter: true },
        { headerName: 'Shift', field: 'shift', filter: true, floatingFilter: true },
        { headerName: 'Remark', field: 'remark', filter: true, floatingFilter: true },
        {
            headerName: 'Details',
            cellRendererFramework: (params) => (
                <button onClick={() => handleViewDetails(params.data)}>View</button>
            ),
            minWidth: 100,
        },];
    const handleViewDetails = (data) => {
        console.log('View Details:', data);
    };
    const handlehandoverform = () => {
        navigate('/Cashier/DayOpen');
    };
    return (<>
        <div className='row d-flex justify-content-between  m-5'>
            <div className='col-lg-2 col-sm-2'>
                <div className='HandOverbtn row'>
                    <div className='col-lg-10 col-sm-10' onClick={() => handlehandoverform()}>
                        Add Day Opening
                    </div>
                    {/* <div className='col-lg-2 col-sm-2'>
                        <img className='plus-icon' src={Plus} />
                    </div> */}
                </div>
            </div>
            <div className='col-lg-4 col-sm-4'>
                <div>
                    <img className='searchIcon ' search-icon src={Search} />
                    <input className='form-control' type='search' />
                </div>
            </div>
            <div className='col-lg-2 col-sm-4 d-inline-flex gap-3'>
                <div className='btn-border'>
                    PDF
                </div>
                <div className='btn-border'>
                    CSV
                </div>
            </div>
        </div>
        <div className='px-5'>
            <div className='brdor  mt-3' style={{ }}>

                <div className="ag-theme-alpine" style={{}}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowData={rowData}
                        domLayout='autoHeight'
                        rowHeight={60}  // Adjust row height as needed
                        pagination={true}
                        suppressRowClickSelection={true}
                    />
                </div>
            </div>
        </div>
    </>
    );
};

export default DayOpeningList;
