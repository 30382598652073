import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import ApiService from '../../../../services/apiService';
import CurrencyGrid from './currencyGrid';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { typeOptions } from '../../../../../Enums/config';

const AddCurrency = ({ Currency }) => {
    const location = useLocation();
    const [editCurrency, setEditCurrency] = useState(location.state || null);

    const [Currencies, setCurrency] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        code: '',
        conversionRate: '',
        default: false,
        loggedInUserId: '',
        currencyDenomination: [{ value: '', type: 0 }],
    });

    useEffect(() => {
        GetCurrency();
        if (editCurrency) {
            setFormData(editCurrency);
        }
    }, [editCurrency]); // Add editCurrency as a dependency

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleDenominationChange = (index, e) => {
        const { value } = e.target;
        const currencyDenomination = [...formData.currencyDenomination];
        currencyDenomination[index].value = value;
        setFormData({ ...formData, currencyDenomination });
    };

    const handleTypeChange = (index, selectedOption) => {
        const { type } = selectedOption;
        const currencyDenomination = [...formData.currencyDenomination];
        currencyDenomination[index].type = type;
        setFormData({ ...formData, currencyDenomination });
    };

    const handleAddRow = () => {
        const currencyDenomination = [...formData.currencyDenomination, { value: 0, type: 0 }];
        setFormData({ ...formData, currencyDenomination });
    };

    const handleSave = async () => {
        try {
            const apiService = new ApiService();
            const endpoint = editCurrency ? 'UPDATE_CURRENCY' : 'CREATE_CURRENCY';
            if (editCurrency) {
                const response = await apiService.put(endpoint, formData);
            } else {
                const response = await apiService.post(endpoint, formData);
                if (response.status === 200) {  
                    toast.success(response.data.message);
                }
            }
            GetCurrency();
            resetFormData();
        } catch (error) {
            console.error('Error saving currency:', error);
            toast.error('Error saving currency');
        }
    };

    const resetFormData = () => {
        setFormData({
            name: '',
            code: '',
            conversionRate: 0,
            default: false,
            loggedInUserId: '',
            currencyDenomination: [{ value: 0, type: 0 }],
        });
        setEditCurrency(null);
    };

    const GetCurrency = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService.get('GET_CURRENCY');
            setCurrency(res?.data?.result);
        } catch (error) {
            console.error('Error fetching currency data:', error);
            toast.error('Error fetching currency data');
        }
    };

    const handleEditCurrency = (currency) => {
debugger
        setFormData(currency);
        setEditCurrency(currency);
    };

    return (
        <div className="p-5 m-0">
            <div className="catlist">
                <div className="row">
                    <div className="col-lg-5">
                        <label htmlFor="name">Currency Name</label>
                        <input
                            type="text"
                            className="form-control"
                            name="name"
                            value={formData?.name}
                            onChange={handleInputChange}
                            placeholder="Currency Name"
                            required
                        />
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-5">
                        <label htmlFor="code">Currency Code</label>
                        <input
                            type="text"
                            className="form-control"
                            name="code"
                            value={formData?.code}
                            onChange={handleInputChange}
                            placeholder="Currency Code"
                            required
                        />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-5">
                        <label htmlFor="conversionRate">Conversion Rate</label>
                        <input
                            type="number"
                            className="form-control"
                            name="conversionRate"
                            value={formData?.conversionRate}
                            onChange={handleInputChange}
                            placeholder="Conversion Rate"
                            required
                        />
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-5 d-flex justify-space-between">
                        <div className="form-check mt-4">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                name="default"
                                checked={formData?.default}
                                onChange={(e) => setFormData({ ...formData, default: e.target.checked })}
                                style={{ width: '1.2rem', height: '1.2rem' }}
                            />
                            <label className="form-check-label ml-5" htmlFor="default">
                                Default
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row mt-5 mb-5">
                    <h3>Denomination</h3>
                    {formData?.currencyDenomination.map((denomination, index) => (
                        <div key={index} className="row mt-3">
                            <div className="col-lg-5">
                                <label htmlFor={`value${index}`}>Value</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id={`value${index}`}
                                    value={denomination.value}
                                    onChange={(e) => handleDenominationChange(index, e)}
                                    placeholder="Value"
                                    required
                                />
                            </div>
                            <div className="col-lg-1"></div>
                            <div className="col-lg-5">
                                <label>Type</label>
                                <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    value={typeOptions.find((option) => option.type === denomination.type)}
                                    onChange={(selectedOption) => handleTypeChange(index, selectedOption)}
                                    options={typeOptions}
                                    placeholder="Type"
                                />
                            </div>
                        </div>
                    ))}
                    <div className="col-lg-12 mt-3">
                        <button className="btn btn-primary" onClick={handleAddRow}>
                            Add More
                        </button>
                    </div>
                </div>
                <div className="row mt-5 mb-5 d-flex justify-content-center">
                    <div className="col-lg-2">
                        <button className=" addItem-btn" onClick={handleSave}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <CurrencyGrid data={Currencies} onEditCurrency={handleEditCurrency} />
            </div>
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(AddCurrency);
