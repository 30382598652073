// employeReducer.js
import { SET_EMPLOYEE_DATA } from '../actions/types'; // Corrected import

const initialState = {
  employeData: null // Updated state key
};

const employeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMPLOYEE_DATA:
      return {
        ...state,
        employeData: action.payload
      };
    default:
      return state;
  }
};

export default employeReducer;
