import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { ReactComponent as Link } from '../../Assets/svg/linkIcon.svg';
import { ReactComponent as Add } from '../../Assets/svg/Add.svg';
import moment from 'moment';
import ApiService from '../../services/apiService';
import { AiOutlinePrinter } from "react-icons/ai"
import { IoAddSharp } from "react-icons/io5";
import Pagination from '../pagination/pagination';
const PurchaseReturn = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();
    const staticOptions = [];
    const apiService = new ApiService();
    const [messageData, setMessageData] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const StatusFilter = [
        { value: 'All', label: 'All' },
        { value: 'Pending', label: 'Pending' },
        { value: 'Approved', label: 'Approved' },
        { value: 'Hold', label: 'Hold' },
        { value: 'Rejected', label: 'Rejected' },
    ];
    useEffect(() => {
        if (pageSize) {
            getItems();
        }
    }, [pageSize]);
    useEffect(() => {
        getItems();
    }, []);
    const handleEdit = (data) => {
        navigate("/inventory/PurchaseReturn/Create", { state: data })
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("MMM Do YYYY");
    };
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const res = await apiService.getApiParamater('GET_P_RETURN', `?${params.toString()}`);
            res?.data?.result.sort((a, b) => b.recordId - a.recordId);
            setPagination(res.data.paginationData)
            setRowData(res?.data?.result || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const handlePrint = (data) => {
        navigate(`/inventory/PurchaseReturn/Print/${data?.recordId}`, { state: data })
    }
    const Comparison = [
        {
            headerName: 'PR No', field: 'prNo', filter: true, floatingFilter: true, width: 135,
        },
        {
            headerName: 'GRN No', field: 'grnNo', filter: true, floatingFilter: true, width: 140,
        },
        {
            headerName: 'PO No', field: 'pOrderNo', filter: true, floatingFilter: true, width: 140,
        },
        {
            headerName: 'Date', field: 'delivery_Date', filter: true, floatingFilter: true, width: 140,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Supplier', field: 'vendorName', filter: true, floatingFilter: true, minWidth: 110 },
        { headerName: 'Supplier ID', field: 'vendorId', filter: true, floatingFilter: true, minWidth: 110 },
        { headerName: 'Remarks', field: 'remarks', filter: true, floatingFilter: true, minWidth: 110 },
        {
            headerName: 'Status', field: 'status', filter: true, floatingFilter: true, minWidth: 110,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 2 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 3 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }
        },
        {
            headerName: 'Action', field: 'id', cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                handleEdit: handleEdit,
                handlePrint: handlePrint,
                clicked: function (field) {
                    // handleShow(field)
                },
            },
        }
    ];
    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };
    const handlenew = () => {
        navigate('/inventory/PurchaseReturn/Create')
    }
    return (
        <div className='p-4'>
            <div className=' justify-content-between d-flex align-items-center pb-4'>
                <h4 className='mainInvHead'>Purchase Return List</h4>
                <div className='col-3'>
                    <Select
                        className="p-0"
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            padding: 9
                        })}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        value={staticOptions}
                        options={StatusFilter}
                    />
                </div>
                <div onClick={handlenew} className='mainbtn  flex-end  gap-3'>
                    <IoAddSharp color='#fff' className='center mt-1 mx-2' />
                    <div>Add New</div>
                </div>
            </div>
            <div className='brdor'>
                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        rowSelection={'single'}
                        columnDefs={Comparison}
                        pagination={false}
                        suppressExcelExport={true}
                        rowHeight={50}
                        suppressRowClickSelection={true}
                        frameworkComponents={{ frameworkComponents }}
                    />
                </div>
            </div>
            <Pagination pagination={pagination} getItems={getItems} />
        </div>
    );
};
class BtnCellRenderer extends Component {

    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
        this.btnPrintClickedHandler = this.btnPrintClickedHandler.bind(this);
    }

    btnPrintClickedHandler() {
        const { data, handlePrint } = this.props;
        handlePrint(data);
    }
    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }
    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data);
    }
    render() {
        return (
            <div className='d-flex gap-3'>
                <div><Link /></div>
                {/* {this.props.data?.status === 1 ?
                    <div><TbBallpenOff size={28} /></div>
                    :
                    <div onClick={this.btnEditClickedHandler}><Edit /></div>
                } */}
                <div>
                    <AiOutlinePrinter onClick={this.btnPrintClickedHandler} size={30} />
                </div>
            </div>
        )
    }
}
export default PurchaseReturn; 