import React, { useState, useEffect } from 'react';
import ApiService from '../../../services/apiService';

const InvoiceGrnTable = ({ GrnData, itemData, SelectedPO, selectedInvoice }) => {
    const [selectedRow, setSelectedRow] = useState();

    const [rows, setRows] = useState([{
        poNo: '',
        grnNo: '',
        invoiceNo: '',
        refNo: '',
        grnDate: '',
        currency: '',
        amount: '',
        checked: ''
    }]);
    const totalAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
    useEffect(() => {
        getGrn();
    }, [SelectedPO]);
    const getGrn = async () => {
        try {
            const apiService = new ApiService();
            const grnres = await apiService.get('GET_GRN');
            debugger
            const SelectedGrn = grnres.data.result.filter(data => data.pOrderNo === SelectedPO?.pOrderNo);
            const SelectedGrnCheck = SelectedGrn.find(data =>
                selectedInvoice.grnDetails.some(grnDetail => grnDetail.grnNo === data.grnNo)
            );
            const formattedRows = SelectedGrn?.map(detail => {
                debugger
                return {
                    poNo: detail.pOrderNo,
                    grnNo: detail?.grnNo,
                    invoiceNo: detail.invoiceNo,
                    refNo: '',
                    grnDate: detail.modifiedOn,
                    currency: detail.currencyCode,
                    amount: detail?.amount,
                    checked: SelectedGrnCheck.grnNo === detail?.grnNo ? 1 : 0,
                };
            }).filter(row => row !== null);
            if (formattedRows?.length > 0) {
                setRows(formattedRows || []);
                GrnData(formattedRows);
            }
        } catch (error) {
            console.error('Error fetching Grn:', error);
        }
    };
    return (
        <div>
            <div className='table-container mb-5' style={{ overflowX: 'auto', height: "400px" }}>
                <table>
                    <thead className='dynoTable'>
                        <tr>
                            <th></th>
                            <th>PO no</th>
                            <th>Grn No</th>
                            <th>Invoice No</th>
                            <th>Ref NO</th>
                            <th>GRN Date</th>
                            <th>Currency</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(rows) && rows?.map((row, index) => (
                            <tr key={index}>
                                <td className='m-0 p-0 text-center' style={{ border: "1px solid rgba(118, 118, 118, 0.3)", width: '45px' }}>
                                    <input className='form-check-input' checked={row.checked === 1} disabled
                                        type="checkbox" style={{ width: "26.81px", height: "26.81px" }} />
                                </td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.poNo ? `${row.poNo}` : " "} disabled /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.grnNo ? `${row.grnNo}` : " "} disabled /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.invoiceNo} disabled /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.refNo} disabled /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.modifiedOn} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.currency} disabled /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.amount} /></td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </div>
            <div className='d-flex justify-content-center boxs'>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Amount</div>
                    <div className='field'>{totalAmount || "---"}</div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceGrnTable;
