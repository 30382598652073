import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { BiEdit } from 'react-icons/bi';
import ApiService from '../../../../services/apiService';
import "../master.css"; // Import CSS file for better structure

const UserClaimTable = ({ claim, index, HandleEditClaim, GetClaim }) => {
    const [isActive, setIsActive] = useState(false);
    
    const toggleClaimStatus = async () => {
        try {
            const body = {
                id: claim?.id,
                active: isActive
            }
            
            const apiService = new ApiService();
            const res = await apiService.post(`UPDATE_CLAIM`, body);
            if (res?.data?.statusCode === 201) {
                toast.success(`Claim ${isActive ? 'activated' : 'deactivated'} successfully`);
                GetClaim()
            }
        } catch (error) {
            console.error('Error toggling claim status:', error);
        }
    };

    const handleSwitchChange = () => {
        setIsActive(!isActive);
        toggleClaimStatus()
    };
    return (
        <tr key={index}>
            <td>{claim?.claimValue}</td>
            <td>{claim?.claimType}</td>
            <td>{claim?.active === true ? 'Active' : 'Inactive'}</td>
            <td>
                <div className='action-icons d-flex gap-4 align-items-center'>
                    <BiEdit size={25} onClick={() => HandleEditClaim(claim?.id, claim?.claimValue)} className='edit-icon' />
                    <div className="d-flex text-center gap-3">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={claim?.active}
                                onChange={handleSwitchChange}
                            />
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default UserClaimTable;
