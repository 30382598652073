import React, { useState, useEffect } from 'react';
import Cake from '../../../../Cashier/assets/images/CakeImg.png';
import '../../../../Cashier/components/itemCard.css';
import "../../../../Cashier/components/categoryCard/category.css"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApiService from '../../../../services/apiService';

const RestaurentMasterCard = ({ val, fetchKitchenArea }) => {
    const ImageUrl = process.env.REACT_APP_IMAGE_URL;
    
    const truncatedAddress = val?.name?.length > 20 ? val?.name.substring(0, 30) + '...' : val?.name;
    const handleUpdateCategory = async (category, newActiveStatus) => {
        try {
            const apiService = new ApiService();
            const catData = {
                id: category?.id,
                name: category?.name,
                loggedInUserId: category?.loggedInUserId,
                active: newActiveStatus,
                imageUrl: category.imageUrl,
            };
            const res = await apiService.put(`UPDATE_RESTAURANT_AREA`, catData);
            if (res?.data?.statusCode === 200) {
                toast.success("Area updated successfully!");
                fetchKitchenArea();
            } else {
                toast.error("Failed to update Area. Please try again!");
            }
        } catch (error) {
            console.error('Error updating Area:', error);
        }
    };
    const handleSwitchChange = async (category, newActiveStatus) => {
        try {
            await handleUpdateCategory(category, newActiveStatus);
        } catch (error) {
            console.error('Error updating category:', error);
        }
    };
    return (
        <div className='col-lg-3 col-md-4 col-sm-4 col-xs-6 py-3'>
            <div className='catCard'>
                <div className='d-flex justify-content-around'>
                    <div>
                        <img className='catImg' src={ImageUrl + val?.imageUrl || Cake} />
                    </div>
                    <div className='center'>
                        <div className="card-Head" title={val?.name}>{truncatedAddress || "--"}</div>
                    </div>
                    <div className="center">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id={`flexSwitchCheck${val.id}`}
                                checked={val?.active}
                                onChange={(e) => handleSwitchChange(val, e.target.checked)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RestaurentMasterCard;
