import { React, useState, useEffect } from 'react'
import ImageUpload from '../menus/addItems/imagUpload';
import CategoryCard from '../../../Cashier/components/categoryCard/categoryCard';
import "./master.css"
import ApiService from '../../../services/apiService';
import userManagement from '../../../services/userManagement';
import { toast } from 'react-toastify';
import CsvUploader from './UploadCSV/CSVFile';
import { ReactComponent as Delete } from '../../../assets/images/svgIcons/Delete.svg';
import { ReactComponent as Edit } from '../../../../POS/assets/images/svgIcons/Edit.svg';
import { connect } from "react-redux";

const UploadFile = ({Currency}) => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
    }, []);
    const [formData, setFormData] = useState({
        category: "",
    });

    return (
        <div className='p-5 container'>
            <div className='center'>
                <div className='col-lg-5'>
                    <CsvUploader />
                </div>
            </div>
            <div>
                <div className="col-lg-4">
                    <input
                        type="Search"
                        className="form-control"
                        id="validationCustom01"
                        placeholder='Search Menu Item'
                        // name="subcategory"
                        // value={formData.subcategory}
                        // onChange={handleInputChange}
                        required
                    />
                    <div className="valid-feedback">
                        Looks good!
                    </div>
                </div>
                <div className='catlist mt-5 p-5 table-responsive'>
                    <table class="table table-striped mt-3">
                        <thead className=''>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Item Name</th>
                                <th scope="col">Price</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>Pizza</td>
                                <td>{Currency.currency.name} 200</td>
                                <td>
                                    <div className='d-flex gap-3'>
                                        <div><Edit /></div>
                                        <div><Delete /></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(UploadFile);