import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../../Customer/Payment/payment.css';
import Item2 from '../../../assets/images/P1.png';
import { PaymentElement, useStripe, useElements, Elements } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import CashStripe from './cashStripe';
import { clearCart } from '../../../../Redux/actions/cartAction';
import { invokeBroadCastMessage } from '../../../services/signalRService';

const stripePromise = loadStripe('pk_test_51Kku1CLBbHIb8JaUQmSNv3YkbJHrbH1u2GaA2mkAOChyuEVYFAVHi7UotKNDnt2cSBBsYz9ebgndtPdkoXToInXP00fLEPzoyA');


const CardPayment = ({ Currency }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const dispatch = useDispatch();
    const data = state;

    const [totalPrice, setTotalPrice] = useState();
    const [cardDetails, setCardDetails] = useState({
        cardOwner: '',
        cardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        cvc: '',
        saveCardDetails: false,
    });

    useEffect(() => {
        const calculateTotalPrice = () => {
            const itemsTotal = state.orderItems.reduce((total, item) => total + item.menuItemPrice * item.quantity, 0);
            const metaItemsTotal = state.orderItemsMeta.reduce((total, meta) => total + (meta.variantPrice || meta.metaItemPrice || 0), 0);
            return itemsTotal + metaItemsTotal;
        };

        setTotalPrice(calculateTotalPrice());
    }, [state.orderItems, state.orderItemsMeta]);

    const handlePaymentSuccess = () => {
        invokeBroadCastMessage("New Order Placed");
        dispatch(clearCart());
        navigate('/Cashier/SaleHistory');
    };

    return (
        <div className=''>
            <div className='px-lg-5'>
                <div className='px-lg-5'>
                    <div className='px-lg-5'>
                        <div className='row p-lg-5'>
                            <div className='col-lg-6 p-lg-5'>
                                <div className='headTxt'>Order Summary</div>
                                <div className='lineBreak py-lg-3'></div>
                                {state.orderItems?.map((item, index) => (
                                    <div key={index} className='row pt-4'>
                                        <div className='col-lg-2'>
                                            <img src={Item2} alt='Item' className='itmImg' />
                                        </div>
                                        <div className='col-lg-8'>
                                            <div className='bodytxt'>{item.menuItemName}</div>
                                            <div className='bodytxt mainclr'>Qty: {item.quantity}</div>
                                        </div>
                                        <div className='bodytxt col-2'>{`${Currency.currency.name}  ${item.menuItemPrice * item.quantity}`}</div>
                                    </div>
                                ))}

                                {state.orderItemsMeta?.map((meta, index) => (
                                    <div key={index} className='row p-2'>
                                        <div className=' bodytxtm mainclrm offset-2 col-lg-3 col-ms-3'>
                                            {meta.metaItemName}
                                        </div>
                                        <div className='offset-lg-5 bodytxtm col-lg-2 col-ms-2'>
                                            {`${Currency.currency.name} ${meta.variantPrice || meta.metaItemPrice || 0}`}
                                        </div>
                                    </div>
                                ))}

                                <div className='lineBreak py-lg-3'></div>
                                <div className='form-group pt-5'>
                                    <div className='input-group gap-5'>
                                        <input
                                            type='text'
                                            name='cardNumber'
                                            placeholder='Gift or discount code'
                                            className='form-control-Payment w-75'
                                            required
                                        />
                                        <button type='button' className='aplybtn'>
                                            Apply
                                        </button>
                                    </div>
                                </div>
                                <div className='lineBreak py-lg-4'></div>
                                <div className='d-flex justify-content-between py-4'>
                                    <div className='bodytxt'>Subtotal</div>
                                    <div className='bodytxt'>{`${Currency.currency.name}  ${totalPrice}`}</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className='bodytxt'>Shipping</div>
                                    <div className='bodytxt'>{Currency.currency.name}  0.00</div>
                                </div>
                                <div className='lineBreak py-lg-4'></div>
                                <div className='d-flex justify-content-between pt-lg-4'>
                                    <div>
                                        <div className='bodytxt'>Total</div>
                                        <div className='mainclr'>{`Including all taxes and discounts`}</div>
                                    </div>
                                    <div className='totalprice'>{`${Currency.currency.name}  ${totalPrice}`}</div>
                                </div>
                            </div>
                            <div className="col-lg-6 p-lg-5">
                                {/* Assuming the CashStripe component is responsible for handling payment */}
                                <Elements stripe={stripePromise}>
                                    <CashStripe
                                        handlePaymentSuccess={handlePaymentSuccess}
                                        data={data}
                                        cardDetails={cardDetails}
                                        total={totalPrice}
                                    />
                                </Elements>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(CardPayment);