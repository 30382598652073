import { React, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as List } from '../../../assets/images/svgIcons/list.svg';
import { ReactComponent as Next } from '../../../assets/images/svgIcons/pagination_right.svg';
import { ReactComponent as Previous } from '../../../assets/images/svgIcons/pagination_left.svg';
import RestaurantSlider from './restaurantSlider';
import AdminHeader from '../header/adminHeader';
import BranchesGrid from './grid';
import { restaurantList } from './map';
import ApiService from '../../../services/apiService';

const Restaurant = () => {
    let navigate = useNavigate();
    const [branches, setBranches] = useState([]);

    const handleRestaurant = () => {
        navigate('/admin/Restaurant/Create');
    }; const handleBranch = () => {
        navigate('/admin/Restaurant/Branch/Create');
    };
    useEffect(() => {
        GetBranches()
    }, []);

    const GetBranches = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get('GET_BRANCH');
            setBranches(res.data.result.reverse());
        } catch (error) {
            console.error('Error fetching Restaurant data:', error);
        }
    };
    return (
        <div className="container-fluid">
            <div className="pt-5">
                <div className='p-5'>
                    <RestaurantSlider />
                </div>
                <div className='d-flex justify-content-between px-5'>
                    <div className='branchbox'>Branches</div>
                    <div className='d-flex gap-3'>
                        <div className='addbrnchbtn' onClick={handleRestaurant}>Add Restaurant  +</div>
                        <div className='addbrnchbtn' onClick={handleBranch}>Add Branch  +</div>
                    </div>
                </div>
                <div className='p-5'>
                    <BranchesGrid branches={branches} GetBranches={GetBranches}/>
                </div>
            </div>
        </div>
    )
}

export default Restaurant