import React, { useEffect, useState } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../../services/apiService';
import moment from 'moment';
import { toast } from 'react-toastify';
import POSPagination from '../pagination/pagination';

const PaymentManagment = () => {
    const [selectedFilter, setSelectedFilter] = useState('4');
    const [customer, setCustomer] = useState(null);
    const [dataNull, setDataNull] = useState(false);
    const [pagination, setPagination] = useState();
    const [pageSize, setPageSize] = useState(20);
    useEffect(() => {
        fetchData();
    }, [selectedFilter]);
    const fetchData = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            params.append('GetBy', selectedFilter);
            const res = await apiService.getApiParamater('GET_PAYMENT', `?${params.toString()}`);
            if (res?.data?.result) {
                setPagination(res.data.paginationData);
                setDataNull(false);
                setCustomer(res.data.result);
            } else {
                setCustomer(null);
                setDataNull(true);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error(error?.response?.data?.message || 'Error fetching data');
            setDataNull(true);
        }
    };
    const mapPaymentType = (orderType) => {
        switch (orderType) {
            case 0:
                return "Pending";
            case 1:
                return "Authorized";
            case 2:
                return "Paid";
            case 3:
                return "Refunded";
            case 4:
                return "Failed";
            default:
                return "Unknown Order Type";
        }
    };

    const ThirdFilter = [
        { value: '0', label: 'Day' },
        { value: '1', label: 'Week' },
        { value: '2', label: 'Month' },
        { value: '3', label: 'Yearly' },
        { value: '4', label: 'All Time' },
    ];

    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };

    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("MMM Do YYYY");
    };
    const colDefs = [
        { headerName: 'Amount', field: 'paymentAmount', filter: true, floatingFilter: true, minWidth: 250 },
        {
            headerName: 'Order No',
            field: 'orderId',
            filter: true,
            floatingFilter: true,
            minWidth: 250,
            valueGetter: params => {
                // Check the conditions for showing Transaction Type based on orderId and paymentAmount
                if (params.data.orderId < 0 && params.data.paymentAmount < 0) {
                    return 'Pay Out';
                } else if (params.data.orderId < 0 && params.data.paymentAmount > 0) {
                    return 'Pay In';
                } else if (params.data.orderId === -1) {
                    return params.data.transactionType; // Fallback to transactionType if orderId is -1
                } else {
                    return params.data.orderId; // Otherwise, show orderId
                }
            }
        },
        {
            headerName: 'Date', field: 'paymentDate', filter: true, floatingFilter: true, minWidth: 250,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        {
            headerName: 'Payment method', field: 'paymentMethodName', filter: true, floatingFilter: true, minWidth: 250,
            valueGetter: params => {
                if (params.data.paymentStatus === 6) {
                    return 'Cash Out';
                } else if (params.data.paymentStatus === 5) {
                    return 'Cash In';
                }
                else if (params.data.paymentStatus === null) {
                    return '---';
                } else {
                    return params.data.paymentMethodName;
                }
            }
        },
        {
            headerName: 'Transaction ID', field: 'transactionId', filter: true, floatingFilter: true, minWidth: 250,
            valueGetter: params => {
                return params.data.transactionId == null ? '---' : params.data.transactionId
            }
        },
        {
            headerName: 'Status', field: 'paymentStatus', filter: true, floatingFilter: true, minWidth: 250,
            cellRenderer: (params) => mapPaymentType(params.value),
        },
        {
            headerName: 'Transaction Type', field: 'transactionType', filter: true, floatingFilter: true, minWidth: 250,
            valueGetter: params => {
                if (params.data.orderId < 0 && params.data.paymentAmount < 0) {
                    return 'Pay Out';
                } else if (params.data.orderId < 0 && params.data.paymentAmount > 0) {
                    return 'Pay In';
                } else {
                    return 'Sales';
                }
            }
        }
    ];
    const handleFilterChange = (filter) => {
        setSelectedFilter(filter.value);
    };
    return (
        <div className="p-lg-5">
            <div className="gap-3 end">
                <div>
                    <nav className="nav nav-pills weekfilter">
                        {ThirdFilter.map((filter) => (
                            <button
                                key={filter.value}
                                className={`nav-link ${selectedFilter === filter.value ? 'activecls' : ''}`}
                                onClick={() => handleFilterChange(filter)}
                            >
                                {filter.label}
                            </button>
                        ))}
                    </nav>
                </div>
                <div className="gap-2 d-flex">
                    <button className="btn-border">Excel</button>
                    <button className="btn-border">CSV</button>
                </div>
            </div>
            {dataNull ? (
                <div className="text-center justify-content-center mt-5 pt-5 ">
                    <h4 className='mt-5'>
                        No data for {selectedFilter == 0 ? "Today" : selectedFilter == 1 ? "Weekly" : selectedFilter == 2 ? "Monthly" : "Yearly"}
                    </h4>
                </div>
            ) : (
                <div className='mt-4  m-0 POsgridshad'>
                    <div className="ag-theme-quartz" style={{ height: 600, backgroundColor: '#6E5199' }}>
                        <AgGridReact
                            rowData={customer || null}
                            columnDefs={colDefs}
                            enableFilter={true}
                            floatingFilter={true}

                            getRowStyle={(params) => {
                                if (params.node.rowIndex % 2 === 0) {
                                    return { background: '#F0EDF5' };
                                } else {
                                    return { background: '#fff' };
                                }
                            }}
                            suppressRowClickSelection={true}
                        />
                    </div>
                    <POSPagination setPageSize={setPageSize} pagination={pagination} getItems={fetchData} />
                </div>
            )}
        </div>
    );
};

export default PaymentManagment;
