import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import "../report.css"
import ApiService from '../../../../services/apiService';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import POSPagination from '../../pagination/pagination'; import { TfiEmail } from "react-icons/tfi";
import { HiOutlinePrinter } from "react-icons/hi2";
import { PiDownloadSimple } from "react-icons/pi";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { BsFileEarmarkPdf, BsFiletypeCsv } from "react-icons/bs";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import InvApiService from '../../../../../INVENTORY/services/apiService';

const TexReports = ({ }) => {
    let navigate = useNavigate();
    const [selectedFilter, setSelectedFilter] = useState('1');
    const [data, setData] = useState([]);
    const gridRef = useRef();
    const [pagination, setPagination] = useState();
    const [pageSize, setPageSize] = useState(20);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [emailModal, setEmailModal] = useState(false);
    const onOpenModal = () => setEmailModal(true);
    const onCloseModal = () => setEmailModal(false);
    debugger
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const [formData, setFormData] = useState({
        itemNo: { label: 'Select ItemNo', value: 'All' },
        ItemDescription: { label: 'Select Item', value: 'All' },
        email: "",
        Category: [{ label: 'Select Category', value: 'Select Category' }],
        SubCategory: [{ label: 'Select Sub Category', value: 'Select Sub Category' }],
        StockType: [{ label: 'Select Stock Type', value: 'Select Sub Category' }],
        Warehouse: [{ label: 'Select Ware House', value: 'Select Sub Category' }],
    });
    useEffect(() => {
        getReportData()
    }, [selectedFilter]);
    const handleFilterChange = async (selectedFilter) => {
        setSelectedFilter(selectedFilter?.value);
        console.log('Selected Option:', selectedFilter);
        await getReportData();
    };
    const getReportData = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService
                (); const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            params.append('GetBy', selectedFilter);
            const res = await apiService.getApiParamater('GET_TAX_REPORT', `?${params.toString()}`);
            setData(res?.data?.result || []);
            setPagination(res.data.paginationData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const ThirdFilter = [
        { value: '0', label: 'Day' },
        { value: '1', label: 'Week' },
        { value: '2', label: 'Month' },
        { value: '3', label: 'Year' },
        { value: '4', label: 'All Time' },
    ];
    const handlechart = () => {
        navigate('/admin/Reports/home')
    }
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.isValid() ? date.format("DD-MM-YYYY") : null;
    }
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    }
    const transformedOrders = data?.map(order => ({
        ...order,
        transactionAmount: Number(order.transactionAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        taxAmount: Number(order.taxAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    }));
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const onBtnCSVExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: 'taxReport.csv'
        });
    }, []);
    const onBtPDFExport = useCallback(() => {
        const doc = new jsPDF();
        const data = [];
        const columnHeaders = [];
        const comparisonFields = ['itemNo', 'itemDescription', 'category', 'subCategory', 'stockType', 'unitMeasurement', 'warehouseLocation', 'purchasePrice'];

        Comparison.forEach(col => {
            columnHeaders.push(col.headerName);
        });

        gridRef.current.api.forEachNode((node) => {
            const row = [];
            comparisonFields.forEach(field => {
                row.push(node.data[field]);
            });
            data.push(row);
        });

        const poppinsFont = 'data:font/ttf;base64,AAEAAAARAQAABAAw7HA...';
        doc.addFileToVFS("Poppins-Regular.ttf", poppinsFont);
        doc.addFont("Poppins-Regular.ttf", "Poppins", "normal");
        doc.setFont("Poppins");
        doc.setFontSize(18);
        doc.setTextColor(40, 40, 40);
        doc.text("Tax Report", 75, 12);
        doc.text("__________________________", 60, 15);
        doc.setLineWidth(2);
        doc.autoTable({
            head: [columnHeaders],
            body: data,
            styles: {
                fontSize: 6,
            },
            headStyles: {
                fillColor: [209, 126, 6],
                textColor: [255, 255, 255],
                fontSize: 6,
            },
            columnStyles: {
                0: { cellWidth: 19 },
                1: { cellWidth: 19 },
                2: { cellWidth: 19 },
                3: { cellWidth: 19 },
                4: { cellWidth: 19 },
                5: { cellWidth: 19 },
                6: { cellWidth: 19 },
                7: { cellWidth: 19 },
                8: { cellWidth: 15 },
                9: { cellWidth: 15 },
                10: { cellWidth: 15 }
            },
            startY: 20,
            theme: 'striped'
        });
        doc.save('taxReport.pdf');
    }, []);
    const handleClearFilters = () => {
        getReportData()
    }
    const Comparison = [
        {
            headerName: 'Date', field: 'transactionDate', filter: true, floatingFilter: true, width: 320, filter: 'agDateColumnFilter',
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Transaction', field: 'transactionNo', filter: true, floatingFilter: true, width: 320 },
        { headerName: 'Transaction type', field: 'transactionType', filter: true, floatingFilter: true, width: 320 },
        { headerName: 'Transaction Amount', field: 'transactionAmount', filter: true, floatingFilter: true, width: 320 },
        { headerName: 'Tax Amount', field: 'taxAmount', filter: true, floatingFilter: true, width: 320 },
    ];
    const SendEmail = async () => {
        try {
            if (!formData.email) {
                toast.warning("Enter User Email to send Report")
                return;
            }
            const requestBody = {
                // toEmail: "shahzaibawan864@gmail.com",
                toEmail: formData.email,
                subject: "Tax Report",
                body: `
                <div className='printhead2 mb-5 px-4'>
                    <table>
                        <thead className='dynoTable' style='background-color: #5B93FF; color: #fff;'>
                            <tr>
                                <th>Date</th>
                                <th>Transaction</th>
                                <th>Transaction type</th>
                                <th>Transaction Amount</th>
                                <th>Tax Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${data?.map((row, index) => `
                                <tr key=${index}>
                                    <td className='m-0 p-0'><input style='width: 100px;' className='p-2' type='text' value='${row.transactionDate}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' disabled value='${row.transactionNo}' /></td>
                                    <td className='m-0 p-0'><input style='width: 140px;' className='p-2' disabled value='${row.transactionType}' /></td>
                                    <td className='m-0 p-0'><input className='p-2 w-100' type='text' value='${row.transactionAmount?.toFixed(2)}' disabled /></td>
                                    <td className='m-0 p-0'><input className='p-2 w-100' type='text' value='${row.taxAmount?.toFixed(2)}' disabled /></td>
                                </tr>
                            `).join('')}
                        </tbody>
                    </table>
                </div>`
            }
            const invapiService = new InvApiService();
            const response = await invapiService.post("SEND_EMAIL", requestBody);
            if (response.status === 200) {
                onCloseModal()
                toast.success("Email send successfully")
                handleClearFilters()
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    return (
        <div className='p-lg-5'>
            <div className='px-lg-5'>
                <div className='d-flex justify-content-between p-2 gridBg mt-2' style={{ borderRadius: "8px 8px 0px 0px " }}>
                    {/* <div className='viebtn' onClick={() => handlechart()}><Chart /></div> */}
                    <div className='d-flex  p-0'>
                        <nav className="nav nav-pills weekfilter ">
                            {ThirdFilter?.map((filter) => (
                                <button
                                    key={filter.value}
                                    className={`nav-link ${selectedFilter === filter.value ? 'activecls' : ''}`}
                                    onClick={() => handleFilterChange(filter)}
                                >
                                    {filter.label}
                                </button>
                            ))}
                        </nav>
                    </div>
                    <div className='d-flex gap-3 '>
                        <div className='mainbtnGry flex-end gap-2'>
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                                    <PiDownloadSimple size={25} />
                                </DropdownToggle>
                                <DropdownMenu className='mt-3'>
                                    <DropdownItem onClick={onBtnCSVExport} className='gap-3 d-flex center'><BsFiletypeCsv /><div>Export CSV</div></DropdownItem>
                                    {/* <DropdownItem onClick={onBtExcelExport} className='gap-3 d-flex center'><LiaFileExcel/><div>Export Excel</div></DropdownItem> */}
                                    <DropdownItem onClick={onBtPDFExport} className='gap-3 d-flex center'><BsFileEarmarkPdf /><div>Export PDF</div></DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div className='mainbtnRed flex-end '><HiOutlinePrinter size={25} /></div>
                        <div className='mainbtn flex-end gap-1' onClick={onOpenModal}>
                            <div className='center'><TfiEmail size={25} /></div>
                        </div>
                    </div>
                </div>
                <div className='POsgridshad'>
                    <div className="ag-theme-quartz" style={{ height: 600 }}>
                        <AgGridReact
                            rowData={transformedOrders}
                            columnDefs={Comparison}
                            ref={gridRef}
                            getRowStyle={(params) => {
                                if (params.node.rowIndex % 2 === 0) {
                                    return { background: '#F0EDF5' };
                                } else {
                                    return { background: '#fff' };
                                }
                            }}
                        />
                    </div>
                    <POSPagination setPageSize={setPageSize} pagination={pagination} getItems={getReportData} />
                </div>
            </div>
            <Modal size="md"
                show={emailModal} onHide={onCloseModal}
                contentLabel='Email Modal'
                style={customStyles}
            >
                <Modal.Header closeButton>
                    <Modal.Title className='center'>Send Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <input
                            type='email'
                            className="form-Input w-100"
                            id="validationCustom02"
                            value={formData.email}
                            name="email"
                            onChange={handleInputChange}
                            placeholder='Enter Email'
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className='end p-3'>
                    <button className='mainbtnGry' onClick={onCloseModal}>Close</button>
                    <button className='mainbtnRed' onClick={SendEmail}>Save</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(TexReports);
const customStyles = {
    content: {
        width: '30%',
        height: '75%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
};