import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./restaurant.css";
import Carousel from "react-elastic-carousel";
import styled from "styled-components";
import logo3 from "../../../assets/images/Logos/image25.png";
import ApiService from '../../../services/apiService';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

const RestaurantSlider = () => {
    const navigate = useNavigate();
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 1, itemsToScroll: 2, pagination: false },
        { width: 850, itemsToShow: 2 },
        { width: 1150, itemsToShow: 3, itemsToScroll: 2 },
        { width: 1450, itemsToShow: 4 },
        { width: 1750, itemsToShow: 5 },
    ];
    const [restaurant, setRestaurant] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [value, setvalue] = useState('');
    const ImageUrl = process.env.REACT_APP_IMAGE_URL;
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    useEffect(() => {
        GetRestaurant();
    }, []);
    
    const GetRestaurant = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService.get('GET_RESTAURANT');
            setRestaurant(res.data.result);
        } catch (error) {
            console.error('Error fetching menu data:', error);
        }
    };
    
    const UpdateItem = async (val) => {
        const apiService = new ApiService();
        val.isActive = !val.isActive;
        let body = {
            id: val?.id,
            name: val?.name,
            imageUrl: val?.imageUrl,
            noOfEmployees: val?.noOfEmployees,
            address: val?.address,
            loggedInUserId: val?.loggedInUserId,
            isActive: val?.isActive
        };
        let res = await apiService.put("UPDATE_RESTAURANT", body);
        if (res?.data?.statusCode === 200) {
            setShow(false);
            window.location.reload(false);
            toast.success("Restaurant Successfully Updated");
        } else {
            toast.warning('Waiting for Restaurant update');
        }
    };
    
    const handleview = (val) => {
        setvalue(val);
        setShow(true);
    };
    
    const handleaddress = (val) => {
        let truncatedAddress = val?.address?.length > 20 ? val?.address.substring(0, 25) + '...' : val?.address;
        return truncatedAddress;
    };
    
    return (
        <>
            <Carousel loop breakPoints={breakPoints} className="d-flex justify-content-center">
                {restaurant?.map((val, itemIndex) => (
                    <div key={itemIndex}>
                        <div className='res-card'>
                            <div className='row'>
                                <img src={ImageUrl + val?.imageUrl || logo3} className='res-cardImg' />
                            </div>
                            <div className='d-flex justify-content-between p-3'>
                                <div className=''>
                                    <div className='res-title'>Name :</div>
                                    <div className='res-title'>Address :</div>
                                    <div className='res-title'>Employees :</div>
                                </div>
                                <div className=''>
                                    <div className='res-txt'>{val?.name}</div>
                                    <div className='res-txt'>{handleaddress(val)}</div>
                                    <div className='res-txt'>{val?.noOfEmployees}</div>
                                </div>
                            </div>
                        </div>
                        <div className='center gap-3 p-3'>
                            <button className='btn btn-primary col-6' onClick={() => navigate('/admin/Restaurant/Create', { state: { restaurant: val, isEditMode: true } })}>Edit</button>
                            <button className='btn btn-danger col-6' onClick={() => handleview(val)}>{val?.isActive ? "InActive" : "Active"}</button>
                        </div>
                    </div>
                ))}
            </Carousel>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{value?.isActive ? "InActive" : "Active"} Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to {value?.isActive ? "InActive" : "Active"} {value?.name}?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => UpdateItem(value)}>
                        {value?.isActive ? "InActive" : "Active"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default RestaurantSlider;
