import React from "react";
import { useParams } from 'react-router-dom';
import NavBox from "./navBox";
import Reservation from "./resevations";
import NewReservation from "./newReservation";
import TableDrag from "./tableDrag";

const Table = () => {
    const { id } = useParams();

    return (
        <div className="p-3 container-fluid">
            <div className="row px-lg-5">
                {(() => {
                    switch (id) {
                        case "Table":
                            return <> <NavBox id={id} /><TableDrag/></>;
                        case "Reservation":
                            return <> <NavBox id={id} /><Reservation /></>;
                        case "NewReservation":
                            return <> <NavBox id={id}/><NewReservation /></>;
                        default:
                            return <><NavBox id={"Table"} /><TableDrag /></>;
                    }
                })()}
            </div>
        </div>
    );
}

export default Table;
