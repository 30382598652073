import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import StockGrid from './stockGrid';
import { toast } from 'react-toastify';
import ApiService from '../../../INVENTORY/services/apiService'
import { hasPermission } from "../../services/roleService"
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import Pagination from '../pagination/pagination';


const StockMaster = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();

    const [formData, setFormData] = useState({
        recordId: "",
        stockTypeCode: "",
        stockTypeName: "",
    });

    useEffect(() => {
        getItems();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
        if (pageSize) {
            getItems();
        }
    }, [pageSize]);

    useEffect(() => {
        window.history.replaceState({}, '')
        const stock = location.state;
        setFormData({
            recordId: stock?.recordid,
            stockTypeName: stock?.stockTypeName || "",
            stockTypeCode: stock?.stockTypeCode || "",
        });

    }, [location?.state]);

    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    const canEditMaster = checkPermission('Stock Master', 'edit');
    const canCreateMaster = checkPermission('Stock Master', 'create');
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        if (!formData.stockTypeCode || !formData.stockTypeName) {
            if (!formData.stockTypeName) { toast.warning("Enter Stock Type Name") }
            if (!formData.stockTypeCode) { toast.warning("Enter Stock Type Code") }
            return;
        }
        try {
            const apiService = new ApiService()
            let data = {
                stockTypeCode: formData?.stockTypeCode,
                stockTypeName: formData?.stockTypeName,
                active: 1,
            }
            const response = await apiService.post('CREATE_STOCK_TYPE', data);

            if (response?.data?.statusCode === 201 || 200) {
                getItems()
                toast.success(response?.data?.message)
                setFormData({
                    stockTypeCode: "",
                    stockTypeName: "",
                });

            } else if (response?.data?.statusCode === 208) {
                toast?.warning(response?.data?.message)
                setFormData({
                    stockTypeCode: "",
                    stockTypeName: "",
                });
            }
        } catch (error) {
            toast.error("Please try Again!")
        }
    };
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const res = await apiService.getApiParamater('GET_STOCK_TYPE', `?${params.toString()}`);
            setPagination(res.data.paginationData);
            setRowData(res?.data?.result || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    };
    const updateFormData = async () => {
        try {
            const apiService = new ApiService();
            let body = {
                recordId: formData?.recordId,
                stockTypeName: formData?.stockTypeName,
                stockTypeCode: formData?.stockTypeCode,
            }
            const res = await apiService.put(`UPDATE_STOCK_TYPE`, body);

            if (res?.data?.statusCode === 201) {
                toast.success('Form data updated successfully');
                setFormData({
                    recordId: "",
                    stockTypeCode: "",
                    stockTypeName: "",
                })
                window.location.reload()
                window.history.replaceState({}, '')
                getItems()
            } else {
                console.error('Failed to update form data');
            }
        } catch (error) {
            console.error('Error updating form data:', error);
        }
    };
    return (
        <div className='px-4 pt-3'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Stock Type Master</h5>
                <div className='d-flex justify-content-end gap-3'>
                    <div className='col center'>
                        {location.state ? (
                            <button className='mainbtn ' onClick={updateFormData}>Update</button>
                        ) : (
                            <>{canCreateMaster === true ?
                                <button className='mainbtn ' type="submit" onClick={() => handleSubmit()}>
                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                                :
                                <button className='mainbtn ' type="submit" style={{ cursor: "not-allowed" }}>
                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                            } </>
                        )}
                    </div>
                    <button className='mainbtnRed'><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                </div>
            </div>
            <div className='py-lg-3'>
                <div className='brderbox p-3'>
                    <div className="form-group row">
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Stock Type Code *</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom01"
                                disabled={canCreateMaster === true ? false : true}
                                name="stockTypeCode"
                                value={formData.stockTypeCode}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Stock Type Name *</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom01"
                                name="stockTypeName"
                                disabled={canCreateMaster === true ? false : true}
                                value={formData.stockTypeName}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-2'>
                <StockGrid rowData={rowData} getItems={getItems} loading={loading} canEditMaster={canEditMaster} />
            </div>
            <Pagination pagination={pagination} getItems={getItems} />
        </div>
    )
}
export default StockMaster;
