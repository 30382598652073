import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as Delete } from '../../../../assets/images/svgIcons/Delete.svg';
import { BiEdit } from 'react-icons/bi';
import ApiService from '../../../../services/apiService';
import userManagement from '../../../../services/userManagement';
import UserRoleTable from './roleTable';
import "../master.css"; // Import CSS file for better structure

const UserRole = () => {
    const [formData, setFormData] = useState({
        name: '',
        UserRoleList: [],
    });
    
    useEffect(() => {
        getRole();
    }, []);

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const getRole = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService.get('GET_ROLE');
            setFormData({ ...formData, UserRoleList: res.data.result });
        } catch (error) {
            console.error('Error fetching User Roles:', error);
        }
    };

    const createRole = async () => {
        try {
            let Token = localStorage.getItem('Token')
            const userId = userManagement.getUserId(Token);
            const { name } = formData;

            if (!name) {
                toast.error("Please enter a role name");
                return;
            }

            const body = {
                rolesDtoInfo: {
                    id: userId,
                    name: name,
                    normalizedName: name,
                    createdBy: "string",
                    modifiedBy: "string"
                }
            };

            const apiService = new ApiService();
            const res = await apiService.post('CREATE_ROLE', body);

            if (res?.data?.statusCode === 201) {
                toast.success("User Role created successfully");
                setFormData({ ...formData, name: '' });
                getRole();
            }
        } catch (error) {
            console.error('Error creating Role:', error);
        }
    };

    return (
        <div className='p-5 '>
            <div className='user-shadow'>
                <div className='row d-flex align-center p-5'>
                    <div className="col-3">
                        <label htmlFor="roleName" className="form-label">User Role *</label>
                        <input
                            type="text"
                            className="form-control"
                            id="roleName"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">Looks good!</div>
                    </div>

                    <div className='offset-1 col-lg-2 d-flex align-items-end'>
                        <div onClick={createRole} className=' addItem-btn save-btn'>Save</div>
                    </div>
                </div>
            </div>
            <div className='role-list-container mt-5'>
                <table className='table table-striped mt-3'>
                    <thead>
                        <tr>
                            <th scope='col'>Role Name</th>
                            <th scope='col'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {formData?.UserRoleList?.length === 0 ? (
                            <tr>
                                <td colSpan="2" className="text-center">No Roles found</td>
                            </tr>
                        ) : (
                            formData?.UserRoleList?.map((role, index) => (
                                <UserRoleTable data={role} />
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UserRole;
