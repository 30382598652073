const initialState = {
    rows: [],
};

const rowReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ROWS':
            return {
                ...state,
                rows: action.payload,
            };
        case 'ADD_ROW':
            return {
                ...state,
                rows: [...state.rows, action.payload],
            };
        case 'REMOVE_ROW_INDEX':
            return {
                ...state,
                rows: state.rows.filter((_, index) => index !== action.payload)
            };
        case 'REMOVE_ROW':
            return {
                ...state,
                rows: state.rows.slice(0, -1),
            };
        case 'CLEAR_ROWS':
            return {
                ...state,
                rows: [{
                    srno: '',
                    itemNo: '',
                    requisitionNo: '',
                    itemDescription: '',
                    branch: '',
                    qty: '',
                    uom: '',
                    baseQty: '',
                    convRate: '',
                    baseAmount: '',
                    amount: '',
                    stock: '',
                    bol: '',
                    recordId: '',
                    specification: '',
                    supplierId: '',
                }],
            };
        case 'UPDATE_ROW':
            const { index, row } = action.payload;
            return {
                ...state,
                rows: state.rows.map((r, i) => i === index ? row : r),
            };
        default:
            return state;
    }
};

export default rowReducer;
