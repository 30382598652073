import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AppRoutes from "./Routes/appRoutes"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { configureReduxStore } from './Redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
function App() {
  const stripePromise = loadStripe('pk_test_51Kku1CLBbHIb8JaUQmSNv3YkbJHrbH1u2GaA2mkAOChyuEVYFAVHi7UotKNDnt2cSBBsYz9ebgndtPdkoXToInXP00fLEPzoyA');
  const { store, persistor } = configureReduxStore();
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>      
          <Elements stripe={stripePromise}>
          <BrowserRouter>
          <AppRoutes />
          </BrowserRouter>

        </Elements>
        
          <ToastContainer
            position="top-right" // Adjust the position as needed
            autoClose={5000} // Adjust the autoClose time as needed
          />
        </PersistGate>
      </Provider>
    </>
  );
}


export default App;
