import React, { useState, useEffect } from 'react';
import ItemCard from '../components/itemCard';
import CategoryCard from '../components/categoryCard/categoryCard';
import ApiService from '../../services/apiService';
import CashierBucket from '../components/cashierBucket';
import CashierHistory from '../components/history';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackArrow } from '../../assets/images/svgIcons/LeftArrow.svg';
import './cashierMenu.css';

function CashierMenu() {
  const [activeView, setActiveView] = useState('Place Order');
  const [menu, setMenu] = useState([]);
  const [combo, setCombo] = useState([]);
  const [addsOns, setAddsOn] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [orders, setOrders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    GetOrders();
    GetCategory();
  }, [searchTerm]);

  useEffect(() => {
    if (selectedCategory) {
      handleMenu();
      GetSubCategories(selectedCategory?.id);
    }
  }, [selectedCategory, isDisabled]);

  const handleDisableToggle = (val) => {
    debugger
    setIsDisabled(val);
  };

  const GetOrders = async () => {
    try {
      const apiService = new ApiService();
      let res = await apiService.get('GET_ORDERS');
      const filteredOrders = res?.data?.result?.filter((item) => item?.active === true);
      setOrders(filteredOrders);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const GetCategory = async () => {
    try {
      const apiService = new ApiService();
      let parameters = `?Name=${searchTerm}`;
      const res = await apiService.getApiParamater('GET_CATEGORY', parameters);
      const filteredCategories = res?.data?.result?.filter((item) => item?.active === true);
      setCategories(filteredCategories);
    } catch (error) {
      console.error('Error fetching AddsOn data:', error);
    }
  };

  const GetSubCategories = async (categoryId) => {
    try {
      const apiService = new ApiService();
      let parameters = `?CategoryId=${categoryId}`;
      let res = await apiService.getApiParamater('GET_SUBCATEGORY', parameters);
      if (res?.data?.statusCode == 204) {
        setSubCategories("");
      }
      else if (res?.data?.statusCode == 200) {

        const filteredSubCategories = res.data.result.filter((item) => item?.active === true);
        setSubCategories(filteredSubCategories);
      }
    } catch (error) {
      console.error('Error fetching sub-category data:', error);
    }
  };

  const handleMenuClick = (selectedCategory) => {
    setSelectedCategory(selectedCategory);
  };

  const handleMenu = async (sub) => {
    setSelectedSubCategory(sub?.name);
    try {
      const apiService = new ApiService();
      let parameters = sub ? `?SubCategoryId=${sub?.id}` : `?CategoryId=${selectedCategory?.id}`;
      let res = await apiService.getApiParamater('GET_MENU', parameters);
      if (res?.data?.statusCode === 200) {
        const filteredMenu = res.data.result.filter((item) => item?.active === true);
        setMenu(filteredMenu);
      }
      else {
        console.error('Error fetching menu by category:', res?.data?.message);
      }
    } catch (error) {
      console.error('Error fetching menu by category:', error);
    }
  };

  const GetCombo = async () => {
    try {
      const apiService = new ApiService();
      let res = await apiService.get("GET_COMBO");
      if (res?.data?.statusCode === 200) {
        const filteredCombo = res.data.result.filter((item) => item?.active === true);
        setCombo(filteredCombo);
      } else {
        console.error('Error fetching menu by Combo:', res?.data?.message);
      }
    } catch (error) {
      console.error('Error fetching menu data:', error);
    }
  };

  const GetAddsOn = async () => {
    try {
      const apiService = new ApiService();
      let res = await apiService.get("GET_ADDONS");
      if (res?.data?.statusCode === 200) {
        const filteredAddsOn = res.data.result.filter((item) => item?.active === true);
        setAddsOn(filteredAddsOn);
      } else {
        console.error('Error fetching menu by addsOn:', res?.data?.message);
      }
    } catch (error) {
      console.error('Error fetching AddsOn data:', error);
    }
  };

  const addToCart = (item) => {
    const existingItem = cartItems?.find((cartItem) => cartItem?.id === item?.itemData?.id);

    if (item?.addsOns) {
      // Handle adding addons to the cart
      const addon = item.addsOns;
      const existingAddon = cartItems?.find((cartItem) => cartItem?.id === addon.id);

      if (existingAddon) {
        const updatedCartItems = cartItems?.map((cartItem) =>
          cartItem.id === addon.id ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
        );
        setCartItems(updatedCartItems);
      } else {
        setCartItems([...cartItems, { ...addon, quantity: 1 }]);
      }
    } else if (existingItem) {
      // Handle updating quantity for existing items
      const updatedCartItems = cartItems?.map((cartItem) =>
        cartItem.id === item?.itemData?.id ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
      );
      setCartItems(updatedCartItems);
    } else {
      // Handle adding new items to the cart
      setCartItems([...cartItems, { ...item, quantity: 1 }]);
    }
  };

  const removeFromCart = (index) => {
    const newCartItems = [...cartItems];
    newCartItems.splice(index, 1);
    setCartItems(newCartItems);
  };

  const updateQuantity = (index, newQuantity) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].quantity = newQuantity;
    setCartItems(updatedCartItems);
  };

  const handleBack = () => {
    setSelectedCategory(null);
    setMenu([]);
  };

  const handleBackbtn = () => {
    setCombo([]);
  };

  const handleBackAdd = () => {
    setAddsOn([]);
  };

  return (
    <div className='pt-5 px-5'>
      <div className='row px-3'>
        <div className='col-lg-5'>
          {activeView === 'Place Order' && (
            <div>
              <CashierBucket
                cartItems={cartItems}
                handleDisableToggle={handleDisableToggle}
                removeFromCart={removeFromCart}
                updateQuantity={updateQuantity}
              />
            </div>
          )}
          {activeView === 'History' && (
            <div>
              <CashierHistory val={orders} />
            </div>
          )}
        </div>
        {selectedCategory ? (
          <div className='col-lg-7 ovrflo'>
            <div className='row'>
              <div className='col-5'>
                <button className='AddBtn' onClick={handleBack}>
                  <BackArrow /> Back
                </button>
              </div>
              <div className='col-7'>
                <div className='catbtn'>{selectedCategory?.name}</div>
              </div>
            </div>
            {subCategories &&
            <div className='subcat mt-4 mb-3'>
              <div className='d-flex justify-content-around'>
                {
                subCategories?.map((cat) => (
                  <div
                    key={cat.id}
                    className={`subcatlist cursor-pointer ${selectedSubCategory === cat?.name && 'CatActive'}`}
                    onClick={() => handleMenu(cat)}>
                    {cat?.name}
                  </div>
                ))}
              </div>
            </div>
            }

            <div className='CashierGrid'>
              {menu?.map((item) => (
                <ItemCard key={item.id} val={item} addToCart={addToCart} />
              ))}
            </div>
          </div>
        ) : (
          <>
            {combo && combo.length > 0 ? (
              <div className='col-lg-7 ovrflo'>
                <div className='row'>
                  <div className='col-5'>
                    <button className='AddBtn' onClick={handleBackbtn}>
                      <BackArrow /> Back
                    </button>
                  </div>
                  <div className='col-7'>
                    <div className='catbtn'>Combo</div>
                  </div>
                </div>
                <div className='CashierGrid'>
                  {combo?.map((item) => (
                    <ItemCard key={item.id} val={item} addToCart={addToCart} combo={item} />
                  ))}
                </div>
              </div>
            ) : addsOns && addsOns.length > 0 ? (
              <div className='col-lg-7 ovrflo'>
                <div className='row'>
                  <div className='col-5'>
                    <button className='AddBtn' onClick={handleBackAdd}>
                      <BackArrow /> Back
                    </button>
                  </div>
                  <div className='col-7'>
                    <div className='catbtn'>Adds On</div>
                  </div>
                </div>
                <div className='CashierGrid'>
                  {addsOns?.map((item) => (
                    <ItemCard key={item.id} val={item} addToCart={addToCart} addsOns={item} />
                  ))}
                </div>
              </div>
            ) : (
              <div className='col-lg-7'>
                <div className='d-flex justify-content-between'>
                  <h4>Categories</h4>
                  <div>
                    <input
                      type='Search'
                      className='form-control'
                      id='validationCustom01'
                      placeholder='Search Categories'
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
                <div className={`CategoryGrid mt-4 ${!isDisabled ? 'disabled-div' : ''}`}>
                  {categories?.map((item) => (
                    <CategoryCard
                      key={item.id}
                      val={item}
                      handleMenuClick={handleMenuClick}
                      isDisabled={isDisabled} // Pass down the isDisabled prop
                    />
                  ))}
                  <div className='py-2'>
                    <div className='catCard' onClick={() => GetCombo()}>
                      <div className='d-flex justify-content-around'>
                        <div className='center' style={{ padding: "21px" }}>
                          <div className="card-Head">Combo</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='py-2'>
                    <div className='catCard' onClick={() => GetAddsOn()}>
                      <div className='d-flex justify-content-around'>
                        <div className='center' style={{ padding: "21px" }}>
                          <div className="card-Head">AddsOn</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default CashierMenu;
