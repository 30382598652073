import React, { useState, useEffect } from 'react';
import Cake from '../../../../Cashier/assets/images/CakeImg.png';
import '../../../../Cashier/components/itemCard.css';
import { useNavigate } from 'react-router';
import "../../../../Cashier/components/categoryCard/category.css"
import { BsThreeDots } from "react-icons/bs";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApiService from '../../../../services/apiService';

const CategoryMasterCard = ({ val, GetCategory }) => {
    const [CartValue, setCartValue] = useState("");
    const [showMenu, setShowMenu] = useState(false);
    const [show, setShow] = useState(false);
    let navigate = useNavigate();
    const [isActive, setIsActive] = useState()
    const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setShowMenu(false)
    }
    const truncatedAddress = val?.name?.length > 20 ? val?.name.substring(0, 30) + '...' : val?.name;

    const handleMenuToggle = (e) => {
        setShowMenu(!showMenu);
    };

    const handleDelete = async () => {

        const apiService = new ApiService();
        let body = {
            id: parseInt(val?.id)
        }
        let res = await apiService.delete("DELETE_CATEGORY", { data: body });

        if (res?.data?.statusCode === 204) {
            // Show success toast
            setShow(false)
            toast.success("Category Successfully Deleted");
            GetCategory()
        }
        else {
            toast.warning('Waiting for menu update');
        }
    };
    const handleUpdateCategory = async (category, newActiveStatus) => {
        try {
            const apiService = new ApiService();
            const catData = {
                id: category?.id,
                name: category?.name,
                loggedInUserId: category?.loggedInUserId,
                active: newActiveStatus,
                imageUrl: category.imageUrl,
            };
            const res = await apiService.put(`UPDATE_CATEGORY`, catData);
            if (res?.data?.statusCode === 200) {
                toast.success("Category updated successfully!");
                GetCategory();
            } else {
                toast.error("Failed to update Category. Please try again!");
            }
        } catch (error) {
            console.error('Error updating Category:', error);
        }
    };

    const handleSwitchChange = async (category, newActiveStatus) => {
        try {
            setIsActive(newActiveStatus);
            await handleUpdateCategory(category, newActiveStatus);
        } catch (error) {
            console.error('Error updating category:', error);
        }
    };
    return (
        <div className='col-lg-3 col-md-4 col-sm-4 col-xs-6 py-3'>
            <div className='catCard'>
                <div className='d-flex justify-content-around'>
                    <div>
                        <img className='catImg' src={IMAGE_URL + val?.imageUrl || Cake} />
                    </div>
                    <div className='center'>
                        <div className="card-Head" title={val?.name}>{truncatedAddress || "--"}</div>
                    </div>
                    <div className="center">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id={`flexSwitchCheck${val.id}`}
                                checked={val?.active}
                                onChange={(e) => handleSwitchChange(val, e.target.checked)}
                            />
                        </div>
                    </div>
                    {/* <div className=''>
                        <div className='three-dots' onClick={() => handleMenuToggle()}>
                            <BsThreeDots />
                        </div>
                        {showMenu && (
                            <div className='menubx'>
                                <div onClick={() => handleShow()}>Delete</div>
                            </div>
                        )}
                    </div> */}
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you Sure you want to delete {val?.name}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => handleDelete()}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CategoryMasterCard;
