import React, { useState, useEffect, useRef, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Add } from '../../Assets/svg/Add.svg';
import moment from 'moment/moment';
import Button from 'react-bootstrap/Button';
import { ReactComponent as Edit } from '../../Assets/svg/edit.svg'
import { IoLink } from "react-icons/io5";
import { TbBallpenOff } from "react-icons/tb";
import { IoAddSharp } from "react-icons/io5";
import Pagination from '../pagination/pagination';
const IssueReturn = ({ onRequisitionData, itemData }) => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const [selectedRowData, setSelectedRowData] = useState(null);
    let navigate = useNavigate();
    const gridRef = useRef();
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    useEffect(() => {
        if (pageSize) {
            getItems();
        }
    }, [pageSize]);

    useEffect(() => {
        getItems();
    }, []);

    const handleEdit = (data) => {
        // navigate("/Inventory/Requisition/PurchaseRequisition", { state: data })
    };
    const handlePrint = (data) => {
        // navigate(`/Inventory/Requisition/PurchaseRequisition/${data?.reqositionNo}`, { state: data })
    };
    const handleEye = (data) => {
        const updatedData = { ...data, eye: !data.eye?.active };
        // navigate("/Inventory/Requisition/PurchaseRequisition", { state: updatedData });
    };
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
           params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const res = await apiService.getApiParamater('GET_ISSUE_RETURN', `?${params.toString()}`);
            setPagination(res.data.paginationData)
            let responseSort = res?.data?.result.reverse()
            setRowData(responseSort || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            // Handle error (e.g., show error message to user)
            setLoading(false); // Set loading to false even in case of error
        }
    }
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("LLL");
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const columnDefs = [
        { headerName: 'Issue Return No', field: 'issueRNo', filter: true, floatingFilter: true },
        { headerName: 'Issue  No', field: 'issueNo', filter: true, floatingFilter: true },
        {
            headerName: "Date", field: "irDate", filter: true, floatingFilter: true,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Department', field: 'costCenter1', filter: true, floatingFilter: true },
        { headerName: 'Ware House', field: 'warehouse', filter: true, floatingFilter: true },
        { headerName: 'Currency', field: 'currencyCode', filter: true, floatingFilter: true },
        { headerName: 'Issued By', field: 'approvalUser', filter: true, floatingFilter: true },
        { headerName: 'Remarks', field: 'remarks', filter: true, floatingFilter: true },
        {
            headerName: 'Status', field: 'status', filter: true, floatingFilter: true,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 3 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }
        },
        {
            headerName: 'Actions', field: 'recordId', cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                handleEdit: handleEdit,
                handleEye: handleEye,
                clicked: function (field, isActive) {
                    updateActiveStatus(field, isActive);
                },
            },
        }
    ];
    const actionsRenderer = (props) => {
        return (
            <div>
                <Button variant="primary" onClick={() => handleEditRow(props.data)}>Edit</Button>
            </div>
        );
    };
    const handlenew = () => {
        navigate('/inventory/Issue/Return/Create')
    }
    const handleEditRow = (rowData) => {
        setSelectedRowData(rowData);
    };
    const updateActiveStatus = (rowData) => {
        setSelectedRowData(rowData);
    };
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const totalPageNumbers = Math.min(pagination?.totalPages, 4);
        let startPage = Math.max(pagination?.currentPage - Math.floor(totalPageNumbers / 2), 1);
        let endPage = startPage + totalPageNumbers - 1;

        if (endPage > pagination?.totalPages) {
            endPage = pagination?.totalPages;
            startPage = endPage - totalPageNumbers + 1;
            if (startPage < 1) {
                startPage = 1;
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers?.push(
                <div key={i} className={`${pagination?.currentPage === i ? "pActive" : "pagen"}`}>
                    {i}
                </div>
            );
        }
        return pageNumbers;
    }
    const handlePageSizeChange = (event) => {
        const newSize = Number(event.target.value);
        setPageSize(newSize);
    };
    return (
        <div className='p-4'>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div className='justify-content-between d-flex align-items-center'>
                        <h4 className='mainInvHead'>
                            Issue Return
                        </h4>
                        <div onClick={handlenew} className='mainbtn  flex-end  gap-3'>
                            <IoAddSharp color='#fff' className='center mt-1 mx-2' />
                            <div>Add New</div>
                        </div>
                    </div>
                    <div className='brderbox mt-3'>
                        <div className="ag-theme-quartz" style={{ height: 650 }}>
                            <AgGridReact
                                rowData={rowData}
                                enableFilter={true}
                                floatingFilter={true}
                                rowSelection={'single'}
                                rowHeight={50}
                                columnDefs={columnDefs}
                                pagination={false}
                                frameworkComponents={{ actionsRenderer }}
                                suppressExcelExport={true}
                            />
                        </div>
                    </div>
                    <Pagination pagination={pagination} getItems={getItems} />
                </>
            )}
        </div>
    );
};
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
        this.btnEyeClickedHandler = this.btnEyeClickedHandler.bind(this);
        this.btnPrintClickedHandler = this.btnPrintClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value, !this.props.data.isActive); // Toggle isActive value
    }
    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data);
    }
    btnEyeClickedHandler() {
        const { data, handleEye } = this.props;
        handleEye(data);
    }
    btnPrintClickedHandler() {
        const { data, handleEye } = this.props;
        handleEye(data);
    }
    render() {
        const { isActive } = this.props.data; // Extract isActive from rowData
        return (
            <div className='d-flex gap-3'>
                {/* <div><Link /></div> */}
                {this.props.data?.status === 1 ?
                    <div><TbBallpenOff size={28} /></div>
                    :
                    <div onClick={this.btnEditClickedHandler}><Edit /></div>
                }
                <div>
                    <IoLink size={30} />
                </div>
            </div>
            // <button onClick={this.btnClickedHandler} className="gridbtn">Delete</button>
        )
    }
};

export default IssueReturn;
