import React, { useState } from 'react';
import '../../../../../Cashier/components/Table/table.css';
import { connect } from "react-redux";
import { useDrag } from 'react-dnd';

const TableBox = ({ id, left, top, name, val }) => {

    const [{ isDragging }, dragRef] = useDrag(
        () => ({
            type: 'BOX',
            item: { id: id, left, top, val },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [id, left, top , val]
    );

    return (
        <div ref={dragRef}
            id={id}
            style={{
                position: 'absolute',
                left,
                top,
                opacity: isDragging ? 0.5 : 1,
                cursor: 'move',
                width: '200px',
            }}
        >
            <div className=''>
                <div className='d-flex justify-content-center'>
                    <div className='chair'>1</div>
                </div>
                <div class="d-flex align-items-center center">
                    <div className='chairlft'>2</div>
                    {val?.shape === 1 ?
                        <div class="col-6">
                            <div className='tablebox Rectangle'>{name}</div>
                        </div> : " "
                    }
                    {val?.shape === 2 ?
                        <div class="">
                            <div className='tablebox Circle'>{name}</div>
                        </div> : " "
                    }
                    {val?.shape === 3 ?
                        <div class="">
                            <div className='tablebox Square'>{name}</div>
                        </div> : " "
                    }
                    {val?.shape === 4 ?
                        <div class="">
                            <div className='tablebox Oval'>{name}</div>
                        </div> : " "
                    }
                    <div className='chairrit'>3</div>
                </div>
                <div className='d-flex justify-content-center'>
                    <div className='chairbtm'>1</div>
                </div>
            </div>
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(TableBox);