import React from "react";
import { Link } from 'react-router-dom';
import '../supplier/supplier.css'

const UserNav = ({ id }) => {

    return (
        <div className="py-lg-5">
            <div className="topnav maon-BG">
                <div className="container">
                    <div className="nav nav-pills overslid d-flex justify-content-between">
                        <Link to="/Inventory/UserMaster/list" className={`dashitems sidebar-text center  ${id === "list" ? "sideActive" : ""}`}>
                            Users</Link>
                        <Link to="/Inventory/UserMaster/create" className={`dashitems sidebar-text center ${id === "create" ? "sideActive" : ""}`}>
                            Add User</Link>
                        {/* <Link to="/Inventory/UserMaster/access" className={`dashitems sidebar-text center ${id === "access" ? "sideActive" : ""}`}>
                            Access & Rights</Link> */}
                        <Link to="/Inventory/UserMaster/rights" className={`dashitems sidebar-text center ${id === "rights" ? "sideActive" : ""}`}>
                           User Rights</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default UserNav; 