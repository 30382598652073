// import React, { useState, useEffect } from "react";
// import './supplier.css';
// import Select from 'react-select';
// import "./usermaster.css";
// import "./rights.css";
// import { masters, Issue, Orders } from "./data";

// import { toast } from "react-toastify";
// import 'react-sortable-tree/style.css';
// import { useSelector } from "react-redux";
// import ApiService1 from "../../../../../INVENTORY/services/apiService";
// import ApiService from "../../../../services/apiService";
// import { hasPermission } from "../../../../../INVENTORY/services/roleService";
// import { getUserId } from "../../../../../Redux/selecters";

// const UserRights = () => {
//     const [selectedModules, setSelectedModules] = useState([]);
//     const [subModuleStates, setSubModuleStates] = useState({});
//     const [Roles, setRoles] = useState([]);
//     const [users, setUsers] = useState([]);
//     const [usersList, setUsersList] = useState([]);
//     const [permissions, setPermissions] = useState([]);
//     const [loading, setLoading] = useState(false);

//     const userId = useSelector(getUserId);
//     const apiService = new ApiService1();
//     const apiServicePos = new ApiService();
//     const [formData, setFormData] = useState({
//         userRole: null,
//         user: null,
//     });

//     useEffect(() => {
//         const fetchData = async () => {
//             setLoading(true);
//             try {
//                 const roleRes = await apiServicePos.get('GET_ROLE');
//                 const userRes = await apiServicePos.get('GET_USERS');
//                 setRoles(roleRes.data.result);
//                 setUsers(userRes.data.result);
//                 setUsersList(userRes.data.result);
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//                 toast.error('Error fetching data. Please try again later.');
//             } finally {
//                 setLoading(false);
//             }
//         };
//         fetchData();
//     }, []);

//     const modules = {
//         Master: masters,
//         Order: Orders,
//         Issue: Issue,
//     };

//     const checkPermission = (moduleName, action) => {
//         return hasPermission(moduleName, action, permissions);
//     };

//     const handleModuleClick = (moduleName) => {
//         setSelectedModules((prevModules) =>
//             prevModules.includes(moduleName)
//                 ? prevModules.filter((mod) => mod !== moduleName)
//                 : [...prevModules, moduleName]
//         );
//     };

//     const handleCheckboxChange = (moduleName, submoduleLabel, permission) => {
//         setSubModuleStates((prevState) => {
//             const updatedState = {
//                 ...prevState,
//                 [moduleName]: {
//                     ...prevState[moduleName],
//                     [submoduleLabel]: {
//                         ...prevState[moduleName]?.[submoduleLabel],
//                         [permission.toLowerCase()]: !prevState[moduleName]?.[submoduleLabel]?.[permission.toLowerCase()],
//                     },
//                 },
//             };
//             return updatedState;
//         });
//     };

//     const fetchUserPermissions = async (userId) => {
//         setLoading(true);
//         try {
//             const param = `?User_Id=${userId}`;
//             const response = await apiService.getApiParamater(`GET_USER_ROLE`, param);
//             const permissionsData = response?.data?.result;

//             // Check if there are permissions; if not, clear the state
//             if (!permissionsData || permissionsData.length === 0) {
//                 setPermissions([]);
//                 initializeSubModuleStates([]);
//             } else {
//                 setPermissions(permissionsData);
//                 initializeSubModuleStates(permissionsData);
//             }
//         } catch (error) {
//             console.error('Error fetching user permissions:', error);
//             toast.error('Error fetching user permissions. Please try again later.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     const initializeSubModuleStates = (permissions) => {
//         const initialStates = {};
//         const moduleNames = Object.keys(modules);

//         moduleNames.forEach((module) => {
//             initialStates[module] = {};
//             modules[module].forEach((submodule) => {
//                 const submoduleLabel = submodule.label;
//                 const permission = permissions.find((perm) => perm.description === submoduleLabel);

//                 initialStates[module][submoduleLabel] = {
//                     enable: permission ? permission.saveCommand : false,
//                     add: permission ? permission.newCommand : false,
//                     edit: permission ? permission.editCommand : false,
//                     delete: permission ? permission.deleteCommand : false,
//                     query: permission ? permission.queryCommand : false,
//                 };
//             });
//         });

//         if (permissions.length === 0) {
//             setSubModuleStates({});
//         } else {
//             setSubModuleStates(initialStates);
//         }
//     };

//     const createRole = async () => {
//         try {
//             if (!formData.user) {
//                 toast.error("Please Select User");
//                 return;
//             }
//             setLoading(true);
//             const currentDatetimeUTC = new Date().toISOString();
//             const userData = {
//                 user_Id:formData.user.value.user_Id ,
//                 updateUserDetails: Object.keys(subModuleStates).flatMap((module) =>
//                     Object.keys(subModuleStates[module]).map((subModuleLabel) => ({
//                         userId: formData.user.value.user_Id,
//                         companyId: formData.user.value.company,
//                         groupId: "",
//                         description: subModuleLabel,
//                         newCommand: subModuleStates[module][subModuleLabel].add || false,
//                         editCommand: subModuleStates[module][subModuleLabel].edit || false,
//                         deleteCommand: subModuleStates[module][subModuleLabel].delete || false,
//                         queryCommand: subModuleStates[module][subModuleLabel].query || false,
//                         saveCommand: subModuleStates[module][subModuleLabel].enable || false,
//                         isActive: true,
//                         createdBy: userId,
//                         createdOn: currentDatetimeUTC,
//                         modifiedBy: userId,
//                         modifiedOn: currentDatetimeUTC,
//                     }))
//                 ),
//             };
//             const response = await apiService.put("UPDATE_USER_ROLE", userData);
//             if (response?.data?.statusCode === 201) {
//                 toast.success("Role created successfully!");
//                 setFormData({
//                     userRole: null,
//                     user: null,
//                 });
//                 setSubModuleStates({});
//                 setSelectedModules([]);
//             } else {
//                 toast.error("Error creating role. Please try again.");
//             }
//         } catch (error) {
//             console.error("Error Creating Role:", error);
//             toast.error("Error Creating Role. Please try again later.");
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleRoleChange = (selectedOption) => {
//         setFormData({ ...formData, userRole: selectedOption });
//         const filteredUser = users.filter(dep => dep.roleName === selectedOption.label);
//         setUsersList(filteredUser);
//     };

//     const handleUserChange = (selectedOption) => {
//         setFormData({ ...formData, user: selectedOption });
//         fetchUserPermissions(selectedOption.value.user_Id);
//     };

//     return (
//         <div className="py-lg-3">
//             <div className="d-flex justify-content-between">
//                 <div className="col-3">
//                     <label htmlFor="validationCustom01" className="form-label">Select Role</label>
//                     <Select styles={{
                                       
//                         value={formData?.userRole}
//                         onChange={handleRoleChange}
//                         options={Roles?.map((role) => ({ value: role, label: role?.name }))}
//                     />
//                 </div>
//                 <div className="col-3">
//                     <label htmlFor="validationCustom01" className="form-label">User Name</label>
//                     <Select styles={{
                
//                         value={formData?.user}
//                         onChange={handleUserChange}
//                         options={usersList?.map((usr) => ({ value: usr, label: usr?.name }))}
//                     />
//                 </div>
//             </div>
//             <div className="headbx mt-3">
//                 <div className="col-5 "><div>Module</div></div>
//                 <div className="col-7 d-flex justify-content-around">
//                     <div></div>
//                     <div>Add</div>
//                     <div>Edit</div>
//                     <div>Delete</div>
//                     <div>Query</div>
//                 </div>
//             </div>
//             <div className="module-container">
//                 <div className="module-selection">
//                     {Object.keys(modules).map((module) => (
//                         <div
//                             key={module}
//                             className={`module-option ${selectedModules.includes(module) ? "active" : ""}`}
//                             onClick={() => handleModuleClick(module)}
//                         >
//                             {module}
//                         </div>
//                     ))}
//                 </div>
//                 <div className="submodule-tree">
//                     {selectedModules?.map((module) => (
//                         <div key={module} className="module-tree">
//                             <div className="module-title">{module}</div>
//                             <div className="submodule-list">
//                                 {modules[module]?.map((submodule) => (
//                                     <div key={submodule.label} className="submodule-item d-flex row">
//                                         <div className="submodule-title col-3">{submodule.label}</div>
//                                         <div className="checkbox-group d-flex justify-content-between col-9">
//                                             {[ "Add", "Edit", "Delete", "Query"].map((permission) => (
//                                                 <div key={permission} className="checkbox-item">
//                                                     <input
//                                                         type="checkbox"
//                                                         style={{ width: "25px", height: "25px" }}
//                                                         checked={!!subModuleStates[module]?.[submodule.label]?.[permission.toLowerCase()]}
//                                                         onChange={() => handleCheckboxChange(module, submodule.label, permission)}
//                                                     />
//                                                 </div>
//                                             ))}
//                                         </div>
//                                     </div>
//                                 ))}
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//                 {Object.keys(subModuleStates).length > 0 && (
//                     <div className="save-button-container">
//                         <button
//                             className="save-button"
//                             onClick={createRole}
//                             disabled={loading}
//                         >
//                             {loading ? 'Saving...' : 'Save'}
//                         </button>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default UserRights;
import React, { useState, useEffect } from "react";
import Select from 'react-select';
import ApiService from "../../../../services/apiService";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import userManagement from "../../../../services/userManagement";

const UserRights = ({ userID, showNavBox }) => {
    const { id } = useParams();
    const [users, setUsers] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const { state } = useLocation();
    const employeeData = useSelector(state => state?.employeData?.employeData);


    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null); // Add selectedUser state
    const [moduleClaims, setModuleClaims] = useState([]);
    const [crudClaims, setCrudClaims] = useState([]);
    const [activeModule, setActiveModule] = useState(null);
    const [allClaims, setAllClaims] = useState([]);
    const apiService = new ApiService();
    let token = localStorage.getItem('Token')
    const user = userManagement?.getUserId(token)
    // useEffect(() => {
    //     if (!employeeData?.userId || state?.userId || userID) {
    //         getRoles();
    //     }
    // }, [employeeData]);

    useEffect(() => {
        getClaims();

        if (employeeData?.userId || userID) {
            getUserClaims(employeeData?.userId);
        }
    }, [employeeData]);

    // useEffect(() => {
    //     if (selectedRole) {
    //         // Fetch users based on the selected role
    //         getUsersByRole(selectedRole?.value);
    //     }
    // }, [selectedRole]);

    useEffect(() => {
        const fetchData = async () => {
            // setLoading(true);
            try {
                const roleRes = await apiService.get('GET_ROLE');
                const userRes = await apiService.get('GET_USERS');
                setRoles(roleRes.data.result);
                setUsers(userRes.data.result);
                setUsersList(userRes.data.result);
            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error('Error fetching data. Please try again later.');
            } finally {
                // setLoading(false);
            }
        };
        fetchData();
    }, []);
    const getClaims = async (roleName) => {
        try {
            const res = await apiService.get('GET_CLAIM');
            const claimsArray = Object.values(res.data.result);
            setModuleClaims(claimsArray?.filter(claim => claim.claimType === 'module'));

            setCrudClaims(claimsArray?.filter(claim => claim.claimType === 'crud'));
            setAllClaims(claimsArray); // Set all claims
        } catch (error) {
            console.error('Error fetching Claims:', error);
        }
    };

    const getUserClaims = async (userId) => {
        try {
            let param = `?UserId=${userId}`;
            const res = await apiService.getApiParamater(`GET_USER_CLAIM`, param);
            const userClaimIds = res?.data?.result?.map(claim => claim?.claimId);

            const updatedCrudClaims = crudClaims.map(claim => ({
                ...claim,
                enabled: userClaimIds.includes(claim.id)
            }));

            setCrudClaims(updatedCrudClaims);
        } catch (error) {
            console.error('Error fetching User Claims:', error);
        }
    };



    const handleModuleClick = (moduleName) => {
        setActiveModule(moduleName);
        const moduleClaim = moduleClaims.find(claim => claim.claimValue === moduleName);
        if (moduleClaim) {
            updateCrudClaims(moduleClaim.claimValue);
        } else {
            console.error(`Module claim not found for ${moduleName}`);
        }
    };

    const updateCrudClaims = (moduleName) => {
        // Capitalize the first letter of each word in moduleName
        const capitalizedModuleName = moduleName
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        const crudPrefix = capitalizedModuleName + '.';
        const moduleCrudClaims = allClaims.filter(claim => claim.claimType === 'crud' && claim.claimValue.startsWith(crudPrefix));
        const updatedCrudClaims = moduleCrudClaims.map(claim => {
            // Extract the relevant part of the claim name (remove the module prefix)
            const displayName = claim.claimValue.substring(capitalizedModuleName.length + 1);
            // Check if the claim is enabled for the user
            const enabled = crudClaims.find(c => c.id === claim.id)?.enabled || false;
            return {
                id: claim.id,
                name: displayName, // Display the extracted name
                enabled: enabled
            };
        });
        setCrudClaims(updatedCrudClaims);
    };


    const handleRoleChange = (selectedOption) => {
        setSelectedRole(selectedOption);
    };

    const handleSetUserChange = (selectedOption) => {
        setSelectedUser(selectedOption);
    };
    const handleCheckboxChange = (claimId) => async (e) => {
        const isChecked = e.target.checked;

        try {
            if (isChecked) {
                // Enable CRUD claim
                debugger
                let body = {
                    userId: selectedUser?.value?.id || employeeData?.userId || state?.userId,
                    claimId: claimId,
                    createdBy: user,
                    modifiedBy: user
                }
                debugger
                let response = await apiService.post('CREATE_USER_CLAIM', body);
                if (response?.statusCode == 200 || 201) {
                    toast.success("Permission granted successfully")
                }
            } else {
                // Disable CRUD claim
                await apiService.post('DELETE_USER_CLAIM', { employeeData, claimId });
            }

            // Update the checkbox state based on the user's claims
            const updatedCrudClaims = crudClaims.map(claim => {
                if (claim.id === claimId) {
                    return {
                        ...claim,
                        enabled: isChecked
                    };
                } else {
                    return claim;
                }
            });

            setCrudClaims(updatedCrudClaims);
        } catch (error) {
            if (error?.response?.status == 400) {
                toast.warning("Select Role Or User to grant access")
            }
            console.error('Error updating user claims:', error);
        }
    };


    return (
        <div className="p-3">
            <div className="px-lg-5">
                <div className="d-flex justify-content-between">
                    <div className="col-3">
                        <label htmlFor="validationCustom01" className="form-label">Select Role</label>
                        <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                            value={selectedRole?.userRole}
                            onChange={handleRoleChange}
                            options={roles?.map((role) => ({ value: role, label: role?.name }))}
                        />
                    </div>
                    <div className="col-3">
                        <label htmlFor="validationCustom01" className="form-label">User Name</label>
                        <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                            value={selectedUser?.user}
                            onChange={handleSetUserChange}
                            options={usersList?.map((usr) => ({ value: usr, label: usr?.name }))}
                        />
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-lg-3">
                        <div className="p-3" style={{
                            border: 'solid #dbdbdb',
                            borderRadius: '13px'}}>
                        {moduleClaims?.map((claim) => (
                            <div
                                key={claim.id}
                                className={`sidebar-text p-2 center ${activeModule === claim.claimValue ? 'active' : ''}`}
                                onClick={() => handleModuleClick(claim.claimValue)}
                            >
                                {claim.claimValue}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-lg-9">
                    {activeModule &&
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Permission</th>
                                    <th scope="col">Acess</th>
                                </tr>
                            </thead>
                            <tbody>
                                {crudClaims.map(({ id, name, enabled }) => (
                                    <tr key={id}>
                                        <td>{name?.charAt(0)?.toUpperCase() + name?.slice(1)}</td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={enabled}
                                                onChange={handleCheckboxChange(id)}
                                            // disabled={!enabled} // Disable checkbox if not enabled
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        </div>
        </div >
    );
};

export default UserRights;
