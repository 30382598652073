import { React, useEffect, useState } from 'react';
import AdminHeader from '../header/adminHeader';
import Filter from './filter';
import "./dashboard.css"
import { thirdParty, Revenue } from './dummyData';
import ThirdPartyCard from './ItemCard/thirdPCard';
import CustomBarChart from '../charts/barChart';
import StockInformation from './Stock/stockInfo';
import OrderSummery from './orderSum/orderSum';
import LineChart from '../charts/lineChart';
import ThirdParty from './Stock/thirdParty';
import ApiService from '../../../services/apiService';
import RevenueCard from './ItemCard/revenueCard';

const Dashboard = () => {

    const [selectedFilter, setSelectedFilter] = useState('4');
    const [data, setData] = useState([]);
    const ThirdFilter = [
        { value: '0', label: 'Day' },
        { value: '1', label: 'Week' },
        { value: '2', label: 'Month' },
        { value: '3', label: 'Year' },
        { value: '4', label: 'All Time' },
    ];
    useEffect(() => {
        getSumData(selectedFilter);
    }, [selectedFilter]);
    const handleFilterChange = async (selectedFilter) => {
        setSelectedFilter(selectedFilter?.value);
        console.log('Selected Option:', selectedFilter);
        await getSumData(); // Ensure the function is awaited
        console.log('Updated Order Summary:', data);
    };
    const getSumData = async () => {
        try {
            const apiService = new ApiService();
            let paramaters = `?GetBy=${selectedFilter}`;
            let res = await apiService.getApiParamater("DASH_THIRD_COUNT", paramaters);
            setData(res?.data?.result.reverse());
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    return (
        <div className='p-lg-5 m-0'>
            <div className='pb-4 pt-2 end mbl-center'>
                <nav className="nav nav-pills weekfilter ">
                    {ThirdFilter?.map((filter) => (
                        <button
                            key={filter?.value}
                            className={`nav-link ${selectedFilter === filter.value ? 'activecls' : ''}`}
                            onClick={() => handleFilterChange(filter)}
                        >
                            {filter?.label}
                        </button>
                    ))}
                </nav>
            </div>
            <div className="trdptybox p-lg-5">
                <ThirdPartyCard val={data} />
            </div>
            <div className='py-lg-5'>
                <div className='trdptybox  p-lg-5 '>
                    <RevenueCard val={selectedFilter} />
                </div>
            </div>
            <div className='row justify-content-between'>
                {/* <div className='col-lg-6'>
                        <CustomBarChart />
                    </div> */}
                <div className="col-lg-6">
                    <StockInformation />
                </div>
                <div className="col-lg-6">
                    <OrderSummery val={selectedFilter} />
                </div>
            </div>
            {/* {/* <div className='row py-5'> */}
            <div className='col pt-5'>
                <div className='dashcard'>
                    <div className='pb-3'>
                        <div className='chatrtHead'>Sales by Delivery Sources</div>
                    </div>
                    <LineChart selectedFilter={selectedFilter} />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
