import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useState, Component, useMemo, useCallback } from 'react';
import moment from 'moment/moment';
import "./style.css"
import { useNavigate } from 'react-router-dom';
const OrderDataGrid = ({ orders }) => {

    const [id, setId] = useState();
    let navigate = useNavigate();

    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("MMM Do YYYY");
    };
    const mapOrderType = (orderType) => {
        switch (orderType) {
            case 0:
                return "Dine-In";
            case 1:
                return "Take-Away";
            case 2:
                return "Delivery";
            case 3:
                return "Online Order";
            case 4:
                return "Drive through";
            case 5:
                return "Catering";
            case 6:
                return "Room Service";
            case 7:
                return "Pickup";
            case 8:
                return "Curbside Pickup";
            default:
                return "Unknown Order Type";
        }
    };
    const mapOrderStatus = (orderStatus) => {
        switch (orderStatus) {
            case 0:
                return "New Order";
            case 1:
                return "Accepted";
            case 2:
                return "Preparing";
            case 3:
                return "Ready For Delivery";
            case 4:
                return "Ready For Pick up";
            case 5:
                return "Out For Delivery";
            case 6:
                return "Delivered";
            case 7:
                return "Cancelled";
            case 8:
                return "On-Hold";
            default:
                return "Unknown Order Status";
        }
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const mapOrderSource = (orderSource) => {
        switch (orderSource) {
            case 0:
                return "Website";
            case 1:
                return "Mobile App";
            case 2:
                return "Phone";
            case 3:
                return "In Person";
            case 4:
                return "Third Party Delivery";
            case 5:
                return "Cashier";
            case 6:
                return "Waiter";
            default:
                return "Unknown Order Source";
        }
    };
    const mapPaymentType = (orderType) => {
        switch (orderType) {
            case 0:
                return "Pending";
            case 1:
                return "Authorized";
            case 2:
                return "Paid";
            case 3:
                return "Refunded";
            case 4:
                return "Failed";
            default:
                return "Unknown Order Type";
        }
    };
    const transformedOrders = orders.map(order => ({
        ...order,
        id: `ORD-${order.id}`,
        orderAmount: Number(order.orderAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }));
    const handleShow = (field) => {
        setId(field)
        navigate(`/admin/order/${field}`, { state: field });
    }
    const [colDefs, setColDefs] = useState([
        { headerName: "Order Number", field: "id", filter: true, floatingFilter: true, minWidth: 180 },
        {
            field: 'orderDate',
            filter: true,
            floatingFilter: true,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: "Customer Name", field: "customerName", filter: true, floatingFilter: true, minWidth: 200 },
        {
            field: "orderType",
            filter: true,
            floatingFilter: true,
            cellRenderer: (params) => mapOrderType(params?.value),
        },
        {
            field: "orderSource",
            filter: true,
            floatingFilter: true,
            cellRenderer: (params) => mapOrderSource(params?.value),
        },
        {
            headerName: 'Payment Status',
            field: "paymentStatus",
            filter: true,
            floatingFilter: true,
            cellRenderer: (params) => mapPaymentType(params.value),
            minWidth: 200
        },
        { field: "invoiceNumber", filter: true, floatingFilter: true, minWidth: 200 },
        { headerName: 'Amount', field: 'orderAmount', filter: true, floatingFilter: true, minWidth: 200 },
        {
            field: "orderStatus",
            filter: true,
            floatingFilter: true,
            cellRenderer: (params) => mapOrderStatus(params?.value),
            filterParams: {
                textCustomComparator: (filter, value, filterText) => {
                    const displayedText = mapOrderStatus(value);
                    return displayedText.toLowerCase().includes(filterText.toLowerCase());
                },
            },
        },
        {
            headerName: 'Action', field: 'id', cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                clicked: function (field) {
                    handleShow(field)
                },
            },
        },
    ]);
    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };
    return (
        <div className='' style={{}}>
            <div className="ag-theme-quartz" style={{ height: 600 }}>
                <AgGridReact
                    rowData={transformedOrders}
                    columnDefs={colDefs}
                    frameworkComponents={{ frameworkComponents }}
                    rowSelection={'single'}

                    getRowStyle={(params) => {
                        if (params.node.rowIndex % 2 === 0) {
                            return { background: '#F0EDF5' };
                        } else {
                            return { background: '#fff' };
                        }
                    }}
                    enableFilter={true}
                    floatingFilter={true}
                    suppressRowClickSelection={true}
                />
            </div>
        </div>

    );
};
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }

    
    render() {
        return (
            <button onClick={this.btnClickedHandler} className="delbtn p-0" style={{ height: "39px", padding: "0px" }}>View</button>
        )
    }
}
export default OrderDataGrid;
