// src/actions/types.js
export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const LOGIN = 'LOGIN';
export const USER_ROLE = 'USER_ROLE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const UPDATE_CART_ITEM_QUANTITY = 'UPDATE_CART_ITEM_QUANTITY';
export const DOWNGRADE_CART_ITEM_QUANTITY = 'DOWNGRADE_CART_ITEM_QUANTITY';
export const UPDATE_CART_DISCOUNT = 'UPDATE_CART_DISCOUNT';
export const CLEAR_CART = 'CLEAR_CART';
export const ADD_TABLE = 'ADD_TABLE';
export const CLEAR_TABLE = 'CLEAR_TABLE';
export const ADD_TO_ORDER = 'ADD_TO_ORDER';
export const UPDATE_ORDER_ITEM_DISCOUNT = 'UPDATE_ORDER_ITEM_DISCOUNT';
export const REMOVE_FROM_ORDER = 'REMOVE_FROM_ORDER';
export const UPDATE_ORDER_ITEM_QUANTITY = 'UPDATE_ORDER_ITEM_QUANTITY';
export const UPDATE_ORDER_ITEM = 'UPDATE_ORDER_ITEM';
export const DOWNGRADE_ORDER_ITEM_QUANTITY = 'DOWNGRADE_ORDER_ITEM_QUANTITY';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const ADD_COMBO_TO_CART = 'ADD_COMBO_TO_CART';
export const REMOVE_COMBO_FROM_CART = 'REMOVE_COMBO_FROM_CART';
export const UPDATE_COMBO_QUANTITY = 'UPDATE_COMBO_QUANTITY';
export const DOWNGRADE_COMBO_QUANTITY = 'DOWNGRADE_COMBO_QUANTITY';
export const CLEAR_COMBO_CART = 'CLEAR_COMBO_CART';
export const SET_EMPLOYEE_DATA = 'SET_EMPLOYEE_DATA';
// Add OnAction Types
export const ADD_ADDON = 'ADD_ADDON';
export const REMOVE_ADDON = 'REMOVE_ADDON';
export const UPDATE_ADDON_QUANTITY = 'UPDATE_ADDON_QUANTITY';
// User Rights
export const ADD_RIGHT = 'ADD_RIGHT';
export const CLEAR_RIGHT = 'CLEAR_RIGHT';
